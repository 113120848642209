import { Typography } from '@mui/material';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

export default function FriendsMentors() {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Navbar />
            <div style={{ flexGrow: 1 }}>
                <Typography>Coming Soon!...</Typography>
            </div>
            <Footer />
        </div>
    );
}
