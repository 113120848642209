import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CloseSharp, CheckSharp } from '@mui/icons-material';
import './ConfirmationPopup.css';

interface ConfirmationPopupProps {
    promptText: String;
    leftButtonText: String;
    rightButtonText: String;
    handleLeft: Function;
    handleRight: Function;

    handleClickout?: Function;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    leftUI?: React.ReactNode;
    rightUI?: React.ReactNode;
}

export default function ConfirmationPopup(props: ConfirmationPopupProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const clickOutFcn =
        props.handleClickout === undefined
            ? props.handleRight
            : props.handleClickout;
    return (
        <div
            className="reffy-confirmation-popup"
            onClick={(e) => {
                if (e.target['className'] === 'reffy-confirmation-popup') {
                    clickOutFcn();
                }
            }}
        >
            <div className="reffy-confirmation-popup-window">
                <div
                    style={{
                        width: '85%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        color={'#999999'}
                        variant="body1"
                        align="center"
                        marginY={'1rem'}
                        textAlign={isMobile ? 'left' : 'center'}
                    >
                        {props.promptText}
                    </Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                        rowGap: '10px',
                        width: '100%',
                    }}
                >
                    {props.leftUI !== undefined ? (
                        props.leftUI
                    ) : (
                        <Button
                            variant="contained"
                            startIcon={props.leftIcon || <CheckSharp />}
                            sx={{ width: '40%' }}
                            onClick={() => {
                                props.handleLeft();
                            }}
                        >
                            {props.leftButtonText}
                        </Button>
                    )}
                    {props.rightUI !== undefined ? (
                        props.rightUI
                    ) : (
                        <Button
                            variant="contained"
                            startIcon={props.rightIcon || <CloseSharp />}
                            sx={{
                                backgroundColor: '#444444',
                                color: 'white',
                                ':hover': {
                                    bgcolor: '#e9e9e9',
                                    color: '#444444',
                                },
                                width: '40%',
                            }}
                            onClick={() => {
                                props.handleRight();
                            }}
                        >
                            {props.rightButtonText}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
