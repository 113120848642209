import {
    Divider,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyView,
} from '../../components/form/ReffyForm';
import Loading from '../../components/loading/Loading';
import IPageProps from '../../interfaces/page.interface';
import './SignUpPage.css';
import SignInWithGoogle from './google/SignInWithGoogle';
import { useKratosSignupMutation } from '@cosmogony/common/reffy-hooks/kratos/kratos_hooks';
import { KratosSignupRequest } from '@cosmogony/common/reffy-pb/olympus/pkg/services/kratos/pb/kratos';
import { useEditUserMutation } from '@cosmogony/reffy-hooks';

const SignUpPage: React.FunctionComponent<IPageProps> = (_) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const signup = useKratosSignupMutation();

    if (signup.isSuccess) {
        const payload: any = signup.data;
        const user = payload.user;
        const uuid = user.uuid;
        navigate(`/auth/verify-account/${uuid}`);
    }

    const views: ReffyView[] = [
        {
            name: 'sign-up',
            category: '',
            subcategory: '',
            inputs: [
                {
                    name: 'email',
                    title: '',
                    fieldTitle: 'Email',
                    fieldType: ReffyFieldType.ShortAnswer,
                    isRequired: true,
                    repeat: false,
                },
                {
                    name: 'password',
                    title: '',
                    fieldTitle: 'Create a password',
                    fieldType: ReffyFieldType.Hidden,
                    isRequired: true,
                    repeat: false,
                    // UI?: (formik: any, viewName: string, viewIndex: number, fieldName: string, errMsg: string | undefined) => React.ReactNode;
                },
                {
                    name: 'termsConditions',
                    title: '',
                    fieldTitle: '',
                    fieldType: ReffyFieldType.Custom,
                    isRequired: false,
                    repeat: false,
                    UI: () => {
                        return (
                            <>
                                <Typography
                                    color={'#c9c9c9'}
                                    fontSize={{ xs: '12px', md: '14px' }}
                                >
                                    {'By signing up, you agree to our '}
                                    <Link
                                        href="/terms-of-service"
                                        target="_blank"
                                        color="primary"
                                    >
                                        Terms and Conditions of Use.
                                    </Link>
                                    {
                                        ' To learn about how Reference Builder collects, uses, shares and protects your personal data, please see our '
                                    }
                                    <Link href="/privacy" color="primary">
                                        Privacy Policy.
                                    </Link>
                                </Typography>
                            </>
                        );
                    },
                },
            ],
            repeat: false,
        },
    ];

    const form = GenerateReffyFormOptions(views);
    if (signup.isLoading) return <Loading />;

    return (
        <>
            <div className="sign-up-background"></div>
            <div className="signup-page">
                <div className="signup-container">
                    <div
                        className="signup-form"
                        style={{
                            maxHeight: isMobile ? '100%' : '750px',
                            maxWidth: isMobile ? '500px' : '600px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '.5rem',
                            }}
                        >
                            <img
                                width={'100px'}
                                alt="logo"
                                className="sign-up-logo"
                                src="assets/images/logos/reffy_logo.svg"
                            />
                            <Typography
                                color={'#999999'}
                                fontStyle="italic"
                                fontSize={{ sm: '20px', xs: '18px' }}
                            >
                                Reference letters made easy
                            </Typography>
                        </div>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                color={'#444444'}
                                fontSize={{ md: '20px', xs: '18px' }}
                            >
                                Create a free account to continue
                            </Typography>
                            <Typography
                                color={'#999999'}
                                fontSize={{ md: '20px', xs: '18px' }}
                            >
                                No credit card required
                            </Typography>
                        </div>

                        <div
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                            }}
                        >
                            <SignInWithGoogle />
                        </div>

                        <div className="signup-divider">
                            <Divider
                                sx={{
                                    width: '40%',
                                    height: '1px',
                                    color: '#444444',
                                }}
                            />
                            <Typography
                                color="#999999"
                                fontWeight="normal"
                                fontSize={{ xs: '14px', sm: '16px' }}
                            >
                                OR
                            </Typography>
                            <Divider sx={{ width: '40%', color: '#444444' }} />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {!!signup.error && (
                                <Typography
                                    color="error"
                                    fontSize={{ md: '16px', xs: '14px' }}
                                >
                                    Error: {JSON.stringify(signup.error)}
                                </Typography>
                            )}
                            <ReffyForm
                                initialValues={form}
                                views={views}
                                submitText="Create Account"
                                onSubmit={(data) => {
                                    const accountResponse = data['sign-up'][0];
                                    const email = accountResponse['email'];
                                    const password =
                                        accountResponse['password'];

                                    signup.mutate(
                                        KratosSignupRequest.create({
                                            email,
                                            password,
                                        })
                                    );
                                }}
                                padding="0px"
                            />
                        </div>
                        <Typography
                            color="secondary"
                            fontSize={{ md: '18px', xs: '16px' }}
                        >
                            Already have an account?{' '}
                            <Link href="/login">Login</Link>
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpPage;
