import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import {
    ReffyForm,
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyView,
    ReffyFormikValidationType,
} from '../../../components/form/ReffyForm';
import Loading from '../../../components/loading/Loading';
import {
    useCreateWriterMutation,
    useEditUserMutation,
} from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import { useCreateAssetsMutation } from '@cosmogony/common/reffy-hooks/morpheus/morpheus_hooks';
import {
    CreateWriterRequest,
    UserModel,
} from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import { Boolean } from '@cosmogony/common/reffy-pb/common/pb/common';
import { CreateAssetRequest } from '@cosmogony/common/reffy-pb/morpheus-svc/pkg/pb/morpheus';
import './CompleteAccount.css';

export default function CompleteAccount(props: {
    user: UserModel;
}): JSX.Element {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const user = props.user;
    const createAsset = useCreateAssetsMutation();
    const createWriter = useCreateWriterMutation();
    const editUser = useEditUserMutation();

    const views: ReffyView[] = [
        {
            name: 'profile',
            category: 'Profile',
            subcategory: '',
            repeat: false,
            inputs: [
                {
                    name: 'pfp',
                    title: 'Upload Your Profile Picture',
                    fieldTitle: '',
                    fieldType: ReffyFieldType.Image,
                    isRequired: false,
                    repeat: false,
                    default: user.pfp?.url ?? '',
                },
                {
                    title: 'Full Name',
                    name: 'name',
                    fieldTitle: 'Enter first and last name',
                    default: user.name === 'Default Default' ? '' : user.name,
                    fieldType: ReffyFieldType.ShortAnswer,
                    isRequired: true,
                    repeat: false,
                    validate: [
                        { type: ReffyFormikValidationType.Required },
                        {
                            type: ReffyFormikValidationType.LengthMax,
                            params: 100,
                            errMsg: 'The name is too long.',
                        },
                    ],
                },
                {
                    name: 'fieldOfWork',
                    title: 'Field of work',
                    fieldTitle: 'Select your field of work',
                    fieldType: ReffyFieldType.DropDown,
                    isRequired: true,
                    options: [
                        'Education (K-12)',
                        'Higher Education (college/university)',
                        'Arts',
                        'Small business',
                        'Medium or large business',
                        'Medical',
                        'Government',
                        'Parent/caregiver or homemaker',
                        'Religious organization',
                        'Nonprofit organization',
                        'Athletic',
                        'Financial',
                        'Military',
                        'Health Care',
                        'Other',
                    ],
                    repeat: false,
                    validate: [{ type: ReffyFormikValidationType.Required }],
                },
                {
                    name: 'organization',
                    title: 'Organization',
                    fieldTitle: 'Where do you work?',
                    fieldType: ReffyFieldType.ShortAnswer,
                    isRequired: true,
                    repeat: false,
                    validate: [
                        {
                            type: ReffyFormikValidationType.LengthMax,
                            params: 50,
                            errMsg: 'Please shorten the name of your organization',
                        },
                    ],
                },
                {
                    name: 'stayInformed',
                    title: '',
                    fieldTitle: '',
                    fieldType: ReffyFieldType.ConfirmCheck,
                    isRequired: false,
                    options: [
                        'Let me know about opportunities and offers from Reference Builder and our partners',
                    ],
                    repeat: false,
                },
            ],
        },
    ];

    const form = GenerateReffyFormOptions(views);
    if (editUser.isLoading || createWriter.isLoading) return <Loading />;

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div className="signup-page">
                <div className="signup-container">
                    <div
                        className="signup-form"
                        style={{ maxWidth: isMobile ? '500px' : '600px' }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                alt="logo"
                                className="intake-logo"
                                src="assets/images/logos/reffy_logo.svg"
                            />
                            <Typography
                                color={'#999999'}
                                fontStyle="italic"
                                fontSize={{ md: '18px', xs: '16px' }}
                                mx={'1rem'}
                            >
                                Reference letters made easy
                            </Typography>
                        </div>
                        <Typography
                            color={'#444444'}
                            fontSize={{ md: '18px', xs: '16px' }}
                            mx={'1rem'}
                        >
                            Set up your account
                        </Typography>
                        {editUser.error !== null && (
                            <Typography
                                color={'error'}
                                fontSize={{ md: '14px', sm: '12px' }}
                            >
                                {String(editUser.error)}
                            </Typography>
                        )}
                        <ReffyForm
                            submitText="Next"
                            initialValues={form}
                            views={views}
                            onSubmit={(data) => {
                                const profile = data['profile'][0];
                                const req = CreateWriterRequest.create({
                                    uuid: user.uuid,
                                    field: profile['fieldOfWork'],
                                    organization:
                                        profile['organization'] ?? 'DEFAULT',
                                    stayInformed: profile['stayInformed']
                                        ? Boolean.BOOLEAN_TRUE
                                        : Boolean.BOOLEAN_FALSE,
                                    preferredGreeting: 'none',
                                });

                                const f: string[] =
                                    profile['pfp'].split('FILENAMESPLIT');
                                if (f.length == 2) {
                                    const pfp = f[1].trim();
                                    const name = f[0].trim();
                                    createAsset.mutate({
                                        documents: [pfp],
                                        assetRequest: CreateAssetRequest.create(
                                            {
                                                uuid: user.uuid,
                                                public: Boolean.BOOLEAN_TRUE,
                                            }
                                        ),
                                        settle: async (downloadUrls) => {
                                            const updatedUser = {
                                                Name: profile['name'],
                                                Pfp: {
                                                    Url: downloadUrls[0],
                                                    Name: name,
                                                },
                                            };

                                            editUser
                                                .mutateAsync(updatedUser)
                                                .then(() => {
                                                    createWriter
                                                        .mutateAsync(req)
                                                        .then(() => {
                                                            navigate(
                                                                '/subscription'
                                                            );
                                                        });
                                                });
                                        },
                                    });
                                } else {
                                    editUser
                                        .mutateAsync({ Name: profile['name'] })
                                        .then(() => {
                                            createWriter
                                                .mutateAsync(req)
                                                .then(() => {
                                                    navigate('/subscription');
                                                });
                                        });
                                }
                            }}
                            padding="0px"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
