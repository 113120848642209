import { useLocation, useNavigate } from 'react-router-dom';
import { Check, Favorite } from '@mui/icons-material';
import {
    Button,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import './UserFunnel.css';
import { useRef, RefObject, useState } from 'react';
import InfoCards from '../../../components/infoCards/InfoCards';

interface UserFunnelProps {
    scrollToPricing?: boolean;
}

export default function UserFunnel(propsin: UserFunnelProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as UserFunnelProps;
    const [scrollToPricing, setScrollToPricing] = useState(
        state?.scrollToPricing ?? false
    );
    const myElementRef: RefObject<HTMLDivElement> = useRef(null);
    window.onload = () => {
        if (scrollToPricing) {
            const userInfo6Boxes = document.querySelector(
                '.user-info-6-boxes'
            ) as HTMLElement;
            if (userInfo6Boxes) {
                userInfo6Boxes.scrollIntoView({ behavior: 'smooth' });
            }
        }
        navigate('/learn/user', { state: { scrollToPricing: false } });
        setScrollToPricing(false);
    };

    return (
        <div className="home">
            <Navbar />
            <div className="user-info-1">
                <div className="user-info-1-text">
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', xs: '24px' }}
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                        mb={'.5rem'}
                    >
                        The ultimate time-saver.
                    </Typography>
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                    >
                        Reference Builder automatically collects and organizes
                        all of the information you need to write each
                        recommendation letter.
                    </Typography>
                    <div className="user-info-1-buttons">
                        <Button
                            style={{ width: isMobile ? '100%' : 'auto' }}
                            color="primary"
                            variant="contained"
                            href="/sign-up"
                            className="user-info-1-button"
                        >
                            CREATE FREE ACCOUNT
                        </Button>
                    </div>
                </div>

                <div className="user-info-1-image">
                    <img
                        alt="writing on paper"
                        className="user-info-1-image-img"
                        src="/assets/images/user_funnel/funnel_individual_hero_image.png"
                    />
                </div>
            </div>

            <div className="user-info-3">
                <div className="user-info-3-text">
                    <Typography
                        mb={'.5rem'}
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                    >
                        MASSIVE TIME SAVINGS:{' '}
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        Our powerful organizational tools save up to two hours
                        per letter and help you stay on top of your reference
                        letter game, making sure you never miss a deadline or
                        important detail.
                    </Typography>
                </div>
            </div>
            <div className="youtube-video-user">
                <div>
                    <iframe 
                        src="https://www.youtube.com/embed/OtfmjobMIXw?si=2QFWvnNZhRjOhzDy" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerPolicy="strict-origin-when-cross-origin" 
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="user-info-4">
                <div className="user-info-4-background"></div>
                <div
                    className="user-info-4-content"
                    style={{ zIndex: '7', width: '100%' }}
                >
                    <Typography
                        style={{
                            marginTop: '2rem',
                            marginBottom: '2rem',
                            textAlign: 'center',
                            width: '85%',
                        }}
                        color={'rgb(68, 68, 68)'}
                        fontSize={{ sm: '32px', xs: '24px' }}
                    >
                        Unlock time savings, efficiency and more
                    </Typography>
                    <InfoCards />
                </div>
            </div>

            <div className="user-info-5">
                <div className="user-info-5-text">
                    <Typography
                        mb={'.5rem'}
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="h5"
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                    >
                        Get started for free!
                    </Typography>
                    <Typography
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                    >
                        Try Reference Builder today and see for yourself how
                        much easier and more efficient reference letter writing
                        can be. Sign up for free and upgrade to a premium
                        account anytime!{' '}
                    </Typography>
                    <div className="user-info-1-buttons">
                        <Button
                            style={{
                                marginBottom: isMobile ? '1rem' : '0',
                                width: isMobile ? '100%' : 'auto',
                            }}
                            color="primary"
                            variant="contained"
                            href="/sign-up"
                            className="user-info-1-button"
                        >
                            CREATE FREE ACCOUNT
                        </Button>
                        <Typography
                            variant="body2"
                            style={{ color: '#999999' }}
                        >
                            No Credit Card Required.
                        </Typography>
                    </div>
                </div>
            </div>

            <div ref={myElementRef} className="user-info-6">
                <div className="user-info-6-boxes">
                    <div className="user-info-6-box">
                        <div className="user-info-box-img">
                            <img
                                style={{ alignSelf: 'center' }}
                                alt="Reference Builder Logo"
                                className="navbar-logo"
                                src="/assets/images/logos/reffy_logo.svg"
                            />
                        </div>
                        <div className="user-info-6-box-content">
                            <div style={{ display: 'flex', gap: '1.5rem' }}>
                                <Typography
                                    marginBottom={'1rem'}
                                    fontSize={{ xs: '16px' }}
                                    variant="subtitle2"
                                    color="secondary"
                                    fontWeight="bold"
                                >
                                    PRICE:
                                </Typography>
                                <Typography
                                    lineHeight={1}
                                    fontSize={{ xs: '22px' }}
                                    style={{ color: '#f9d37f' }}
                                    marginBottom={'1rem'}
                                    variant="subtitle2"
                                    color="secondary"
                                    fontWeight="bold"
                                >
                                    FREE! (no credit card required)
                                </Typography>
                            </div>
                            <Typography
                                fontSize={{ xs: '16px' }}
                                variant="subtitle2"
                                color="secondary"
                                fontWeight="bold"
                                width="90%"
                            >
                                FEATURES:
                            </Typography>
                            <List style={{ flexGrow: '1' }}>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            {' '}
                                            Time-Saving Organization Tools
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            Effortless Information Collection
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            User-Friendly Dashboard
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            Flexible Letter Templates
                                        </Typography>
                                    </div>
                                </ListItem>
                            </List>
                        </div>
                        <div className="user-info-1-buttons">
                            <Button
                                style={{
                                    marginBottom: isMobile ? '1rem' : '0',
                                    marginLeft: !isMobile ? '33px' : '0',
                                    width: 'auto',
                                }}
                                color="primary"
                                variant="contained"
                                href="/sign-up"
                                className="user-info-1-button"
                            >
                                CREATE FREE ACCOUNT
                            </Button>
                            <Typography
                                variant="body2"
                                style={{ color: '#999999' }}
                            >
                                Upgrade Anytime.
                            </Typography>
                        </div>
                    </div>

                    <div className="user-info-6-box">
                        <div className="user-info-box-img">
                            <img
                                style={{ alignSelf: 'center' }}
                                alt="Reference Builder Logo"
                                className="navbar-logo"
                                src="/assets/images/logos/reffy_pro_logo.svg"
                            />
                        </div>
                        <div className="user-info-6-box-content">
                            <div style={{ display: 'flex', gap: '1.5rem' }}>
                                <Typography
                                    marginBottom={'1rem'}
                                    fontSize={{ xs: '16px' }}
                                    variant="subtitle2"
                                    color="secondary"
                                    fontWeight="bold"
                                >
                                    PRICE:
                                </Typography>
                                <Typography
                                    lineHeight={1}
                                    fontSize={{ xs: '22px' }}
                                    style={{ color: '#f9d37f' }}
                                    marginBottom={'1rem'}
                                    variant="subtitle2"
                                    color="secondary"
                                    fontWeight="bold"
                                >
                                    $19.99+/mo
                                </Typography>
                            </div>
                            <Typography
                                fontSize={{ xs: '16px' }}
                                variant="subtitle2"
                                color="secondary"
                                fontWeight="bold"
                                width="90%"
                            >
                                FEATURES:
                            </Typography>
                            <List>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            All Free Features PLUS...
                                        </Typography>
                                    </div>
                                </ListItem>

                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            Customized first draft pre-written
                                            for you by RefBuilder AI!
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            Fully Customizable Applicant
                                            Questionnaires
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            Seamless Letterhead Integration
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            Comprehensive Applicant Document
                                            Collection
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem
                                    style={{
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                width: '20px',
                                                color: '#888B8D',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={{
                                                xs: '16px',
                                                sm: '18px',
                                            }}
                                            lineHeight={'1.6'}
                                        >
                                            Save Big with an Annual Subscription
                                        </Typography>
                                    </div>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </div>
            </div>

            <div className="user-info-7">
                <div className="user-info-7-overlay">
                    <div className="user-info-7-overlay-holder">
                        <Typography variant="h5" color="#FFFFFF">
                            Purchasing for a college, school, or department?
                            Your people will love you for it.
                        </Typography>
                        <Button
                            sx={{
                                textWrap: 'nowrap',
                                marginBottom: isMobile ? '1rem' : '0',
                                width: 'auto',
                            }}
                            href="/learn/schools"
                            variant="contained"
                            color="primary"
                        >
                            SHARE THE LOVE
                        </Button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
