import { useMutation } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import {
    CreateAssetRequest,
    CreateAssetResponse,
} from '../../reffy-pb/morpheus-svc/pkg/pb/morpheus';

export function useCreateAssetsMutation() {
    return useMutation({
        mutationFn: (req: {
            documents: string[];
            assetRequest: CreateAssetRequest;
            settle: (downloadUrls: string[]) => void;
        }) => {
            return new Promise(async (resolve) => {
                const requests: Promise<{
                    asset: CreateAssetResponse;
                    f: File;
                }>[] = req.documents.map(async (document) => {
                    return new Promise(async (res, rej) => {
                        const b = await fetch(document).then((res) =>
                            res.blob()
                        );
                        const type = (
                            (document.split(';')[0] || ('' as string)).split(
                                'data:'
                            )[1] || ('' as string)
                        ).trim();
                        const f = new File([b], 'file', { type });
                        console.log('stored file type: ', f.type);
                        const response = await genericHandler(
                            'POST',
                            'include',
                            '/morpheus/create',
                            CreateAssetRequest.toJSON(
                                req.assetRequest
                            ) as Object
                        );

                        if (!response || response.error) {
                            rej(
                                response?.payload ?? 'unable to fulfill request'
                            );
                        } else {
                            const asset = CreateAssetResponse.fromJSON(
                                response.payload
                            );
                            res({ asset, f });
                        }
                    });
                });

                await Promise.allSettled(requests)
                    .then(async (values) => {
                        return new Promise<string[]>(async (res) => {
                            const downloadUrls: string[] = [];
                            const uploads: (Promise<any> | undefined)[] = [];
                            for (const v of values) {
                                if (v.status === 'fulfilled') {
                                    uploads.push(
                                        fetch(v.value.asset.uploadUrl, {
                                            method: 'PUT',
                                            body: v.value.f,
                                        })
                                    );

                                    downloadUrls.push(
                                        v.value.asset.downloadUrl
                                    );
                                } else {
                                    console.error(
                                        'failed to create asset: ',
                                        v.reason
                                    );
                                    downloadUrls.push('');
                                }
                            }

                            await Promise.allSettled(uploads).then((ups) => {
                                for (const u of ups) {
                                    if (u.status === 'fulfilled') {
                                    } else {
                                        console.error(
                                            `failed to upload a file: ${u.reason}`
                                        );
                                    }
                                }

                                return res(downloadUrls);
                            });
                        });
                    })
                    .then((urls) => {
                        req.settle(urls);
                        resolve(urls);
                    });
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}
