import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import { Boolean } from '@cosmogony/reffy-pb/common/pb/common';
import { WriterSubscriptionModel } from '@cosmogony/reffy-pb/plutus-svc/pkg/pb/plutus';

export const API_URL =
	window.location.href.includes('localhost') ||
		window.location.href.includes('127.0.0.1')
		? 'http://localhost:8000/api/v2'
		: `https://api.refbuilder.com/api/v2`;

export async function genericHandler(
	method: 'GET' | 'POST',
	credentials: RequestCredentials | undefined,
	endpoint: string,
	obj?: Object
) {
	const options: RequestInit = {
		method: method,
		credentials: credentials,
	};

	if (method === 'POST') {
		options.headers = {
			'Content-Type': 'application/json',
		};
		options.body = JSON.stringify(obj);
	}

	const data = await fetch(`${API_URL + endpoint}`, options)
		.then(async (response) => {
			if (response.ok) {
				return await response.json();
			}
		})
		.catch((err) => {
			console.error(err);
		});

	return data;
}

export function isPro(
	subscription: WriterSubscriptionModel,
	enterprise: EnterpriseModel | undefined
): boolean {
	const now = Date.now() / 1000;
	return (
		subscription.active === Boolean.BOOLEAN_TRUE ||
		(enterprise !== undefined && enterprise.subscriptionStart <= now &&
			now <= enterprise.subscriptionEnd)
	);
}
