import {
    UserModel,
    WriterModel,
} from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import { Help } from '../dashboard_home/DashboardHome';

export default function HelpSupport(props: {
    user: UserModel;
    writer: WriterModel;
}) {
    return (
        <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
            <Help user={props.user} />
        </div>
    );
}
