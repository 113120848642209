import './ReferenceCard.css';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { GetDays } from '../../pages/applicant_profile/applicant_profile';
import { ResponseInformation } from '@cosmogony/common/reffy-hooks/litae/litae_hooks';
import ProfileAvatar from '../profileAvatar/ProfileAvatar';
import { RequestStatus } from '@cosmogony/reffy-pb/litae-svc/pkg/pb/litae';
import { Boolean } from '@cosmogony/reffy-pb/common/pb/common';

export interface ReferenceCardProps {
    picture: string;
    name: string;
    metDate: string;
    requests: ResponseInformation[];
    ruuid?: string;
}

export function ReferenceCard(props: ReferenceCardProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const pfp = props.picture.trim();
    let leastDays: number = 0;
    let earliestDueDate: string = '';
    for (const e of props.requests) {
        let daysLeft = GetDays(e.dateDue * 1000);
        if (earliestDueDate === '') {
            earliestDueDate = new Date(e.dateDue * 1000).toLocaleDateString(
                'en-us',
                {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                }
            );
        }

        if (leastDays === 0 && daysLeft > 0) {
            leastDays = daysLeft;
        } else if (daysLeft < leastDays && daysLeft > 0) {
            leastDays = daysLeft;
            earliestDueDate = new Date(e.dateDue * 1000).toLocaleDateString(
                'en-us',
                {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                }
            );
        }
    }

    return (
        <div className="reference-card">
            <div
                className="reference-card-pfp-wrapper"
                onClick={() => {
                    props.ruuid !== undefined
                        ? navigate(`requestor/${props.ruuid}`)
                        : null;
                }}
            >
                <ProfileAvatar
                    className="reference-card-pfp"
                    sx={{
                        width: isMobile ? 100 : 200,
                        height: isMobile ? 100 : 200,
                        zIndex: 1,
                    }}
                    url={pfp || ''}
                    name={props.name}
                    fontSize={{ xs: '25px', sm: '25px', md: '35px' }}
                />
            </div>
            <div className="reference-card-body">
                <DueDateIcon days={leastDays}></DueDateIcon>
                <div className="reference-card-body-text">
                    <Typography
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        fontSize={{ xs: '16px', md: '20px' }}
                        lineHeight={2}
                        color={'#444444'}
                        fontWeight="bold"
                    >
                        {props.name.toUpperCase()}
                    </Typography>
                    <Typography
                        className="reference-card-body-text-applications"
                        fontSize={{ xs: '18px', md: '22px' }}
                        lineHeight={1.3}
                        color={'#999999'}
                    >
                        Applying to{' '}
                        {props.requests
                            .filter(
                                (a) => a.status === RequestStatus.REQUEST_OPEN
                            )
                            .map((application) => application.title)
                            .join(', ')}
                        .
                    </Typography>
                    <Typography
                        fontSize={{ xs: '18px', md: '22px' }}
                        lineHeight={1.3}
                        color={'#999999'}
                    >
                        {props.requests.length > 1
                            ? 'First letter due '
                            : 'Due on'}{' '}
                        {earliestDueDate}
                    </Typography>
                </div>
                <Button
                    variant="contained"
                    className="reference-card-button"
                    onClick={() => {
                        navigate(
                            `requestor/${props.requests.find(
                                (a) => a.status === RequestStatus.REQUEST_OPEN
                            )?.ruuid}`
                        );
                    }}
                >
                    GET STARTED
                </Button>
            </div>
        </div>
    );
}

function DueDateIcon(props: { days: number }) {
    let color = '';
    if (props.days <= 7) {
        color = 'rgb(255, 105, 97)';
    } else if (props.days <= 14) {
        color = '#ffd069';
    }
    return (
        <>
            {props.days <= 14 ? (
                <div
                    className="due_date_icon"
                    style={{ backgroundColor: color, color: 'white' }}
                >
                    <CalendarMonthOutlinedIcon
                        sx={{ transform: 'scale(.6)' }}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export function MiniReferenceCard(componentProps: {
    props: ReferenceCardProps;
    status: RequestStatus;
}) {
    const { props, status } = componentProps;
    const isBlank = props.name === '' && props.picture === '';
    const pfp = props.picture.trim();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    let description = '';

    if (props.requests.some((x) => x.isWriteNow === Boolean.BOOLEAN_TRUE)) {
        description = new Date(
            props.requests[0].dateDue * 1000
        ).toLocaleDateString();
    } else if (!isBlank) {
        const first = props.requests.find(
            (application) => status === application['status']
        )?.title;
        description = `Applied to ${first}`;
        if (
            props.requests.filter(
                (application) => status === application['status']
            ).length > 1
        ) {
            description += ' and others';
        }
    }

    return (
        <div className="mini-reference-card">
            <Button
                variant="text"
                className="mini-reference-card-button"
                sx={{ maxHeight: '100%' }}
                fullWidth
                onClick={() => {
                    const ruuid = props.requests.find(
                        (application) => status === application['status']
                    )?.ruuid;
                    if (!isBlank) navigate(`/dashboard/requestor/${ruuid}`);
                }}
            >
                <div className="mini-reference-card-pfp">
                    <ProfileAvatar
                        className="mini-reference-card-pfp-avatar"
                        sx={{
                            width: isMobile ? 75 : 100,
                            height: isMobile ? 75 : 100,
                            zIndex: '1',
                        }}
                        url={
                            pfp ||
                            (props.requests.some(
                                (x) => x.isWriteNow === Boolean.BOOLEAN_TRUE
                            )
                                ? '/assets/images/writenow.png'
                                : '')
                        }
                        name={props.name}
                        fontSize={{ xs: '25px', sm: '25px', md: '35px' }}
                    />
                </div>
                <div
                    className="mini-reference-card-body"
                    style={{ border: isBlank ? '2px dashed #999999' : 'none' }}
                >
                    <div className="mini-reference-card-body-text">
                        <Typography
                            whiteSpace={'nowrap'}
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            fontSize={{ xs: '16px', sm: '18px' }}
                            lineHeight={1.6}
                            sx={{ color: '#444444', fontWeight: 'bold' }}
                        >
                            {props.name.toUpperCase()}
                        </Typography>
                        <Typography
                            className="reference-card-body-text-applications"
                            fontSize={{ xs: '18px', md: '20px' }}
                            textTransform="none"
                            color={'#999999'}
                        >
                            {description}
                        </Typography>
                    </div>
                </div>
            </Button>
        </div>
    );
}
