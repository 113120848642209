import IRoute from '../interfaces/route.interface';
import SignUpPage from '../pages/auth/SignUpPage';
import LoginPage from '../pages/auth/Login';
import Home from '../pages/home/Home';
import Intake from '../pages/intake/Intake';
import Dashboard from '../pages/dashboard/Dashboard';
import Pricing from '../pages/pricing/Pricing';
import ForgotPassword from '../pages/auth/Forgot';
import VerifyEmail from '../pages/auth/VerifyEmail';
import TipsTricks from '../pages/tips/TipsTricks';
import Educators from '../pages/more_info/Educators';
import About from '../pages/about/About';
import PrivacyPolicy from '../pages/privacy/PrivacyPolicy';
import Support from '../pages/support/Support';
import FriendsMentors from '../pages/more_info/FriendsMentors';
import SchoolsDepartments from '../pages/more_info/SchoolsDepartments';
import OtherUsers from '../pages/more_info/OtherUsers';
import Teams from '../pages/teams/Teams';
import ResetPassword from '../pages/auth/ResetPassword';
import TermsOfService from '../pages/privacy/TermsOfService';
import { ApplicantProfile } from '../pages/applicant_profile/applicant_profile';
import UserFunnel from '../pages/learn/user_funnel/UserFunnel';
import NotFound from '../pages/not_found/NotFound';
import OrganizationFunnel from '../pages/learn/organization_funnel/OrganizationFunnel';
import BSchoolFunnel from '../pages/learn/bschool_funnel/BSchoolFunnel';

import LawSchoolFunnel from '../pages/learn/lawschool_funnel/LawSchoolFunnel';
import MedSchoolFunnel from '../pages/learn/medschool_funnel/MedSchoolFunnel';

import ChooseSubscription from '../pages/choose_subscription/choose_subscription';

// const PROD = !window.location.href.includes("localhost");
const PROD = false;

const routes: IRoute[] = [
    {
        path: '/',
        component: Home,
        name: 'Home Page',
        protected: PROD,
    },
    {
        path: '/learn/user',
        component: UserFunnel,
        name: 'User Funnel',
        protected: PROD,
    },
    {
        path: '/learn/schools',
        component: OrganizationFunnel,
        name: 'Organization Funnel',
        protected: PROD,
    },
    {
        path: '/learn/bschool',
        component: BSchoolFunnel,
        name: 'Business School Funnel',
        protected: PROD,
    },
    {
        path: '/learn/law-school',
        component: LawSchoolFunnel,
        name: 'Law School Funnel',
        protected: PROD,
    },
    {
        path: '/learn/medical-school',
        component: MedSchoolFunnel,
        name: 'Medical School Funnel',
        protected: PROD,
    },
    {
        path: '/sign-up',
        component: SignUpPage,
        name: 'Signup Page',
        protected: PROD,
    },
    {
        path: '/login',
        component: LoginPage,
        name: 'Login Page',
        protected: PROD,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'Forgot Passworf Page',
        protected: PROD,
    },
    {
        path: '/pricing',
        component: Pricing,
        name: 'Pricing Page',
        protected: PROD,
    },
    {
        path: '/subscription',
        component: ChooseSubscription,
        name: 'Choose Subscription Page',
        protected: PROD,
    },
    {
        path: '/tips',
        component: TipsTricks,
        name: 'Tips and Tricks Page',
        protected: PROD,
    },
    {
        path: '/educators',
        component: Educators,
        name: 'Educators Page',
        protected: PROD,
    },
    {
        path: '/schools-departments',
        component: SchoolsDepartments,
        name: 'Schools / Departments Page',
        protected: PROD,
    },
    {
        path: '/friends-and-mentors',
        component: FriendsMentors,
        name: 'Friends and Mentors Page',
        protected: PROD,
    },
    {
        path: '/other-users',
        component: OtherUsers,
        name: 'Other Users Page',
        protected: PROD,
    },
    {
        path: '/about',
        component: About,
        name: 'About Page',
        protected: PROD,
    },
    {
        path: '/teams',
        component: Teams,
        name: 'Solutions for Teams Page',
        protected: PROD,
    },
    {
        path: '/privacy',
        component: PrivacyPolicy,
        name: 'Privacy Policy Page',
        protected: PROD,
    },
    {
        path: '/terms-of-service',
        component: TermsOfService,
        name: 'Terms Of Service Page',
        protected: PROD,
    },
    {
        path: '/support',
        component: Support,
        name: 'Support Page',
        protected: PROD,
    },
    {
        path: '/intake/:user',
        component: Intake,
        name: 'Intake Page',
        protected: false,
    },
    {
        path: '/dashboard/:view?',
        component: Dashboard,
        name: 'Dashboard Page',
        protected: PROD,
    },
    {
        path: '/dashboard/requestor/:ruuid',
        component: ApplicantProfile,
        name: 'Applicant Profile',
        protected: PROD,
    },
    {
        path: '/auth/verify-account/:identifier',
        component: VerifyEmail,
        name: 'Verify Email',
        protected: PROD,
    },
    {
        path: '/auth/reset-password',
        component: ResetPassword,
        name: 'Reset Password',
        protected: PROD,
    },
    {
        path: '*',
        component: NotFound,
        name: 'Not Found Page',
        protected: PROD,
    },
];
export default routes;
