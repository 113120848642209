import { Link, TextField, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import { useTheme } from '@mui/system';
import { useKratosVerifyMutation } from '@cosmogony/common/reffy-hooks/kratos/kratos_hooks';
import { KratosVerifyUserRequest } from '@cosmogony/common/reffy-pb/olympus/pkg/services/kratos/pb/kratos';

export default function VerifyEmail() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // @ts-ignore: We get id from URL params
    const { identifier } = useParams();
    const [hasRedirected, setHasRedirected] = useState(false);
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const verifyMutation = useKratosVerifyMutation();

    if (
        verifyMutation.isLoading &&
        !verifyMutation.isError &&
        !verifyMutation.isSuccess
    ) {
        return <Loading />;
    }

    if (identifier === undefined) {
        navigate('/');
        return <></>;
    }

    if (verifyMutation.isSuccess) {
        if (!hasRedirected) {
            setHasRedirected(true);

            setTimeout(() => {
                navigate('/dashboard');
            }, 3 * 1000);
        }

        return (
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: '85%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '1rem',
                    }}
                >
                    <Typography
                        textAlign={'center'}
                        variant="h4"
                        fontWeight="normal"
                        color="success"
                    >
                        Your account has been verified!
                    </Typography>
                    <Typography variant="body1">
                        Redirecting you to the{' '}
                        <Link href="/login" color="primary">
                            login page
                        </Link>
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '85%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <Typography
                    alignSelf={isMobile ? 'flex-start' : 'center'}
                    variant="h4"
                    fontWeight="normal"
                >
                    Verify Your Account
                </Typography>
                {verifyMutation.error ? (
                    <Typography
                        fontSize={{ sm: '20px', xs: '14px' }}
                        color={'red'}
                    >
                        {String(verifyMutation.error)}
                    </Typography>
                ) : (
                    <Typography
                        alignSelf={isMobile ? 'flex-start' : 'center'}
                        fontSize={{ sm: '18px', xs: '14px' }}
                    >
                        Enter the code we just sent to your email!
                    </Typography>
                )}

                <div
                    style={{
                        width: '100%',
                        maxWidth: '400px',
                        alignSelf: isMobile ? 'flex-start' : 'center',
                    }}
                >
                    <TextField
                        label="enter code"
                        variant="filled"
                        value={code}
                        onChange={(el) => {
                            const v = el.target.value;
                            const n = v.replace(/[^0-9]/g, '');
                            setCode(n);

                            if (n.length === 6) {
                                verifyMutation.mutate(
                                    KratosVerifyUserRequest.create({
                                        secret: n,
                                        identifier: identifier,
                                    })
                                );
                            }
                        }}
                    />
                </div>
                <Typography
                    fontSize={{ sm: '14px', xs: '14px' }}
                    alignSelf={isMobile ? 'flex-start' : 'center'}
                    color={'#494949'}
                >
                    Didn't receive an email? Check your spam or junk folder, or
                    click{' '}
                    <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.location.reload()}
                    >
                        here
                    </Link>{' '}
                    to resend email.
                </Typography>
            </div>
        </div>
    );
}
