import { Button, Link, TextField, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IAuthRouteProps {
    children: ReactNode;
    // any other props that come into the component
}

const REFFY_AUTHORIZATION =
    '$um*CIs8eT*jlc4O2vn@Gi6%5HdJ3DzcAK*hGMWJFeGk^7jmHG';
const REFFY_AUTHORIZATION_KEY =
    '%Es6nH^b%QBmnignc%ZXduCIDP2HuxA664iie^6Czg@xBMyncZ';

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
    const { children } = props;
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    if (
        window.localStorage.getItem('reffy-authorized') !== REFFY_AUTHORIZATION
    ) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                <Typography variant="h6" color="secondary">
                    Enter your beta key to access Refbuilder
                </Typography>

                <div style={{ width: '40%' }}>
                    <TextField
                        label="Beta Key"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />
                </div>

                <Button
                    variant="contained"
                    onClick={() => {
                        if (password === REFFY_AUTHORIZATION_KEY) {
                            window.localStorage.setItem(
                                'reffy-authorized',
                                REFFY_AUTHORIZATION
                            );
                            navigate('#');
                        }
                    }}
                >
                    Submit
                </Button>

                <Typography variant="caption">
                    Lost your key? Need help? Contact us at{' '}
                    <Link href="mailto:">notifications@refbuilder.com</Link>
                </Typography>
            </div>
        );
    }

    return <div style={{ width: '100%', height: '100%' }}>{children}</div>;
};

export default AuthRoute;
