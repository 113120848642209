import { useMutation, useQueryClient } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import {
    InviteApplicantRequest,
    OrganizationFormRequest,
    SupportFormRequest,
} from '../../reffy-pb/chronos-svc/pkg/pb/chronos';

export function useInviteMutation() {
    return useMutation({
        mutationKey: ['edit-response'],
        mutationFn: (req: InviteApplicantRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/chronos/invite/applicant',
                    InviteApplicantRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

export function useSupportMutation() {
    return useMutation({
        mutationKey: ['edit-response'],
        mutationFn: (req: SupportFormRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/chronos/support',
                    SupportFormRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

export function useOrganizationMutation() {
    return useMutation({
        mutationKey: ['edit-response'],
        mutationFn: (req: OrganizationFormRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/chronos/organization',
                    OrganizationFormRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}
