import { Avatar, SxProps, Typography, Theme } from '@mui/material';

interface AvatarInfo {
    url: string;
    name: string;
    sx: SxProps<Theme>;
    className?: string;
    fontSize?: { [key: string]: string };
}

export default function ProfileAvatar(props: AvatarInfo) {
    const {
        url,
        name,
        sx,
        className,
        fontSize = { xs: '20px', sm: '20px', md: '20px' },
    } = props;
    const n = name;
    const names = n.split(' ');
    var initials = names.map((name) => name.charAt(0)).join('');
    if (initials.length > 2)
        initials = initials.charAt(0) + initials.charAt(initials.length - 1);

    return (
        <Avatar
            className={className}
            src={url}
            alt="profile picture"
            sx={{ ...{ bgcolor: '#077187' }, ...sx }}
            imgProps={{ referrerPolicy: 'no-referrer' }}
        >
            <Typography color={'white'} fontSize={fontSize}>
                {initials}
            </Typography>
        </Avatar>
    );
}
