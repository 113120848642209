import {
    Button,
    List,
    Typography,
    ListItem,
    ListItemIcon,
    useMediaQuery,
    useTheme,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { Check, NavigateBefore } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import './Pricing.css';
import Loading from '../../components/loading/Loading';
import FeedbackPopup from '../../components/notification/FeedbackPopup';
import { UserModel } from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import { useUserQuery } from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import {
    useCheckoutRequestMutation,
    useWriterSubscriptionQuery,
} from '@cosmogony/common/reffy-hooks/plutus/plutus_hooks';
import {
    WriterCheckoutRequest,
    WriterSubscriptionModel,
} from '@cosmogony/common/reffy-pb/plutus-svc/pkg/pb/plutus';
import { useNavigate } from 'react-router';
import { Boolean } from '@cosmogony/common/reffy-pb/common/pb/common';
import { DashboardNav } from '../dashboard/DashboardNav';
import { PrivacyFooter } from '../../components/footer/Footer';
import { useEnterpriseFromUUIDQuery } from '@cosmogony/reffy-hooks/apollo/apollo_hooks';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';

function PricingBoxes(props: {
    user: UserModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel;
    upgrade: any;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [subscriptionPlan, setSubscriptionPlan] = useState<number>(0);
    // 2 monthly, 3 quarterly, 4 yearly
    const subtypes = [
        'Annual Plan: $19.99/mo ($239.88 annually) - Best Value!',
        '3-months: $24.99/mo ($74.97 quarterly) – Great for short term needs',
        '1-month: $29.99 (monthly) - Maximum flexibility',
    ];
    const handleChange = (event: { target: { value: any } }) => {
        const {
            target: { value },
        } = event;
        setSubscriptionPlan(subtypes.indexOf(value));
    };

    const handleSubmit = () => {
        const checkoutReq: WriterCheckoutRequest = {
            email: props.user.email,
            // Subscription Type enums are from 1month to annual, so subtraction from 2 flips the number as necessary
            type: 2 - subscriptionPlan,
            wuuid: props.user.uuid,
        };
        props.upgrade(checkoutReq);
    };

    const now = Date.now() / 1000;
    if (
        props.enterprise.subscriptionStart <= now &&
        now <= props.enterprise.subscriptionEnd
    ) {
        return (
            <>
                <div className="pricing-6-box">
                    <img
                        style={{
                            alignItems: isMobile ? 'start' : 'center',
                            paddingLeft: '1rem',
                        }}
                        alt="Reference Builder Logo"
                        className="navbar-logo"
                        src="/assets/images/logos/reffy_logo.svg"
                    />
                    <div className="pricing-6-box-content">
                        <Typography
                            marginBottom={'.5rem'}
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                        >
                            Free/Basic Reference Builder
                        </Typography>
                        <Typography
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                            width="90%"
                        >
                            Features:
                        </Typography>
                        <List style={{ flexGrow: '1', paddingBottom: '0px' }}>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        {' '}
                                        Time-Saving Organization Tools
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Effortless Information Collection
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        User-Friendly Dashboard
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Flexible Letter Templates
                                    </Typography>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div
                    style={{ border: '5px solid #f9d37f' }}
                    className="pricing-6-box"
                >
                    <img
                        style={{
                            alignItems: isMobile ? 'start' : 'center',
                            paddingLeft: '1rem',
                        }}
                        alt="Reference Builder Logo"
                        className="navbar-logo"
                        src="/assets/images/logos/reffy_pro_logo.svg"
                    />
                    <div className="pricing-6-box-content">
                        <Typography
                            marginBottom={'.5rem'}
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                        >
                            {`Your current plan: Reference Builder Pro managed by ${props.enterprise.name}`}
                        </Typography>
                        <Typography
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                            width="90%"
                        >
                            Features:
                        </Typography>
                        <List>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        All Free Features PLUS...
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Customized first drafts pre-written for
                                        you by RefBuilder AI!
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Customizable Applicant Questionnaires
                                    </Typography>{' '}
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Seamless Letterhead Integration
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Comprehensive Applicant Document
                                        Collection
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Save Big with an Annual Subscription
                                    </Typography>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </>
        );
    }

    if (props.subscription.active === Boolean.BOOLEAN_TRUE) {
        return (
            <>
                <div className="pricing-6-box">
                    <img
                        style={{
                            alignItems: isMobile ? 'start' : 'center',
                            paddingLeft: '1rem',
                        }}
                        alt="Reference Builder Logo"
                        className="navbar-logo"
                        src="/assets/images/logos/reffy_logo.svg"
                    />
                    <div className="pricing-6-box-content">
                        <Typography
                            marginBottom={'.5rem'}
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                        >
                            Free/Basic Reference Builder
                        </Typography>
                        <Typography
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                            width="90%"
                        >
                            Features:
                        </Typography>
                        <List style={{ flexGrow: '1', paddingBottom: '0px' }}>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        {' '}
                                        Time-Saving Organization Tools
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Effortless Information Collection
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        User-Friendly Dashboard
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Flexible Letter Templates
                                    </Typography>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div
                    style={{ border: '5px solid #f9d37f' }}
                    className="pricing-6-box"
                >
                    <img
                        style={{
                            alignItems: isMobile ? 'start' : 'center',
                            paddingLeft: '1rem',
                        }}
                        alt="Reference Builder Logo"
                        className="navbar-logo"
                        src="/assets/images/logos/reffy_pro_logo.svg"
                    />
                    <div className="pricing-6-box-content">
                        <Typography
                            marginBottom={'.5rem'}
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                        >
                            Your current plan: Reference Builder Pro
                        </Typography>
                        <Typography
                            fontSize={{ sm: '18px' }}
                            variant="subtitle2"
                            color="secondary"
                            fontWeight="bold"
                            width="90%"
                        >
                            Features:
                        </Typography>
                        <List>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        All Free Features PLUS...
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Customized first drafts pre-written for
                                        you by RefBuilder AI!
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Customizable Applicant Questionnaires
                                    </Typography>{' '}
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Seamless Letterhead Integration
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Comprehensive Applicant Document
                                        Collection
                                    </Typography>
                                </div>
                            </ListItem>
                            <ListItem
                                style={{
                                    paddingBottom: '0px',
                                    paddingTop: '0px',
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        alignSelf: 'flex-start',
                                        minWidth: '24px',
                                    }}
                                >
                                    <Check
                                        sx={{ width: '20px', color: '#888B8D' }}
                                    />
                                </ListItemIcon>
                                <div>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                        lineHeight={'1.6'}
                                    >
                                        Save Big with an Annual Subscription
                                    </Typography>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="pricing-6-box">
                <img
                    style={{
                        alignItems: isMobile ? 'start' : 'center',
                        paddingLeft: '1rem',
                    }}
                    alt="Reference Builder Logo"
                    className="navbar-logo"
                    src="/assets/images/logos/reffy_logo.svg"
                />
                <div className="pricing-6-box-content">
                    <Typography
                        marginBottom={'.5rem'}
                        fontSize={{ sm: '18px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                    >
                        Your current plan: Free/Basic Reference Builder
                    </Typography>
                    <Typography
                        fontSize={{ sm: '18px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                        width="90%"
                    >
                        Features:
                    </Typography>
                    <List style={{ flexGrow: '1', paddingBottom: '0px' }}>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    {' '}
                                    Time-Saving Organization Tools
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    Effortless Information Collection
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    User-Friendly Dashboard
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    Flexible Letter Templates
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </div>
            </div>
            <div
                style={{ border: '5px solid #f9d37f' }}
                className="pricing-6-box"
            >
                <div className="pricing-6-buttons"></div>
                <img
                    style={{
                        alignItems: isMobile ? 'start' : 'center',
                        paddingLeft: '1rem',
                    }}
                    alt="Reference Builder Logo"
                    className="navbar-logo"
                    src="/assets/images/logos/reffy_pro_logo.svg"
                />
                <div className="pricing-6-box-content">
                    <Typography
                        marginBottom={'.5rem'}
                        fontSize={{ sm: '18px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                    >
                        Reference Builder Pro - Elevate Your Letters and Save
                        Time
                    </Typography>
                    <Typography
                        fontSize={{ sm: '18px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                        width="90%"
                    >
                        Features:
                    </Typography>
                    <List style={{ paddingBottom: '0px' }}>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    All Free Features PLUS...
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    Customized first draft pre-written for you
                                    by RefBuilder AI!
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    Customizable Applicant Questionnaires
                                </Typography>{' '}
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    Seamless Letterhead Integration
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    Comprehensive Applicant Document Collection
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem
                            style={{ paddingBottom: '0px', paddingTop: '0px' }}
                        >
                            <ListItemIcon
                                sx={{
                                    alignSelf: 'flex-start',
                                    minWidth: '24px',
                                }}
                            >
                                <Check
                                    sx={{ width: '20px', color: '#888B8D' }}
                                />
                            </ListItemIcon>
                            <div>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                    lineHeight={'1.6'}
                                >
                                    Save Big with an Annual Subscription
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                    <Typography
                        sx={{ mb: '.5rem', borderWidth: '0px' }}
                        fontSize={{ sm: '18px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                        width="90%"
                    >
                        Choose a plan that fits your needs:
                    </Typography>
                    <div className="pricing-6-box-content-price">
                        <FormControl
                            sx={{
                                paddingLeft: '1rem',
                                width: isMobile ? '100%' : '100%',
                                minWidth: 0,
                                fontSize: '.5rem',
                            }}
                        >
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={subtypes[subscriptionPlan]}
                                onChange={handleChange}
                            >
                                {subtypes.map((name, index) => (
                                    <FormControlLabel
                                        value={name}
                                        key={index}
                                        control={
                                            <Radio
                                                sx={{
                                                    padding: '5px 1rem 5px 0',
                                                }}
                                                size={'small'}
                                            />
                                        }
                                        label={
                                            <Typography
                                                style={{
                                                    fontWeight:
                                                        subtypes[
                                                            subscriptionPlan
                                                        ] === name
                                                            ? 'bold'
                                                            : 'normal',
                                                    fontSize:
                                                        subtypes[
                                                            subscriptionPlan
                                                        ] === name
                                                            ? '17px'
                                                            : '18px',
                                                }}
                                                color="#999999"
                                            >
                                                {name}
                                            </Typography>
                                        }
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <div
                            style={{
                                paddingTop: '1rem',
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                gap: '0.5rem',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                onClick={() => handleSubmit()}
                                style={{
                                    boxShadow: 'rgba(0, 0, 0, 0.5) 0px 1px 3px',
                                    width: isMobile ? '100%' : '200px',
                                }}
                                color="primary"
                                variant="contained"
                                className="user-info-1-button"
                            >
                                Get Pro Features
                            </Button>

                            <Typography
                                style={{ color: '#999999' }}
                                fontSize={{ sm: '16px' }}
                            >
                                <span title="Discounts are applied to all current and future payments for as long as you maintain your Pro subscription. RefBuilder’s flexible cancellation policy also allows you to continue using Pro features until the end of your subscription period even if you cancel early, but we don’t offer refunds for early cancellations.">
                                    Cancel anytime, for any reason.
                                </span>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default function Pricing() {
    const [, setMessage] = useState('');
    const [hasRedirected, setHasRedirected] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setMessage('Order placed! You will receive an email confirmation.');
        }

        if (query.get('canceled')) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    const userQuery = useUserQuery();
    const enterpriseQuery = useEnterpriseFromUUIDQuery();
    const subscriptionQuery = useWriterSubscriptionQuery();
    const checkout = useCheckoutRequestMutation();

    if (
        userQuery.isLoading ||
        subscriptionQuery.isLoading ||
        enterpriseQuery.isLoading
    )
        return <Loading />;
    if (userQuery.isError || subscriptionQuery.isError) {
        navigate('/');
    }
    const user: UserModel = userQuery.data as UserModel;
    const enterprise: EnterpriseModel = enterpriseQuery.isError
        ? EnterpriseModel.create()
        : (enterpriseQuery.data as EnterpriseModel);
    const subscription: WriterSubscriptionModel =
        subscriptionQuery.data as WriterSubscriptionModel;
    if (checkout.isSuccess) {
        if (!hasRedirected) {
            setHasRedirected(true);
            setTimeout(() => {
                const payload: any = checkout.data;
                window.location.href = payload.url;
            }, 2 * 1000);
        }
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <Typography variant="body1" color="success">
                    Redirecting you to the Checkout Page!
                </Typography>
            </div>
        );
    }
    if (checkout.isLoading) {
        return <Loading />;
    }

    function handleSubmit(request: WriterCheckoutRequest) {
        checkout.mutate(request);
    }

    return (
        <>
            <div
                style={{ width: '100%', height: '100%' }}
                className="pricing-6"
            >
                <div
                    style={{ width: '100%', position: 'relative', bottom: '0' }}
                >
                    <DashboardNav user={user} enterprise={enterprise} />
                </div>
                {checkout.isError && (
                    <FeedbackPopup
                        message={
                            typeof checkout.error === 'string'
                                ? checkout.error
                                : ''
                        }
                    />
                )}
                <div className="pricing-boxes-container">
                    <div
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                        className="pricing-6-boxes"
                    >
                        <PricingBoxes
                            user={user!}
                            subscription={subscription!}
                            enterprise={enterprise}
                            upgrade={handleSubmit}
                        />
                    </div>
                    <Button
                        className="pricing-back-button"
                        variant="text"
                        color="secondary"
                        startIcon={<NavigateBefore />}
                        onClick={() => navigate('/dashboard')}
                    >
                        BACK TO DASHBOARD
                    </Button>
                </div>
                <div style={{ position: 'relative', bottom: 0, width: '100%' }}>
                    <PrivacyFooter />
                </div>
            </div>
        </>
    );
}
