/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Boolean, booleanFromJSON, booleanToJSON } from "../../../common/pb/common";

export const protobufPackage = "plutus";

export enum SubscriptionType {
  /** SUBSCRIPTION_PAID_TIER_1_MONTH - Defines a user who is on the paid subscription plan for 1 month */
  SUBSCRIPTION_PAID_TIER_1_MONTH = 0,
  /** SUBSCRIPTION_PAID_TIER_4_MONTH - Defines a user who is on the paid subscription plan for 4 months */
  SUBSCRIPTION_PAID_TIER_4_MONTH = 1,
  /** SUBSCRIPTION_PAID_TIER_12_MONTH - Defines a user who is on the paid subscription plan for 12 months */
  SUBSCRIPTION_PAID_TIER_12_MONTH = 2,
  /** SUBSCRIPTION_OTHER - Defines a user who is on a different length subscription */
  SUBSCRIPTION_OTHER = 3,
  UNRECOGNIZED = -1,
}

export function subscriptionTypeFromJSON(object: any): SubscriptionType {
  switch (object) {
    case 0:
    case "SUBSCRIPTION_PAID_TIER_1_MONTH":
      return SubscriptionType.SUBSCRIPTION_PAID_TIER_1_MONTH;
    case 1:
    case "SUBSCRIPTION_PAID_TIER_4_MONTH":
      return SubscriptionType.SUBSCRIPTION_PAID_TIER_4_MONTH;
    case 2:
    case "SUBSCRIPTION_PAID_TIER_12_MONTH":
      return SubscriptionType.SUBSCRIPTION_PAID_TIER_12_MONTH;
    case 3:
    case "SUBSCRIPTION_OTHER":
      return SubscriptionType.SUBSCRIPTION_OTHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubscriptionType.UNRECOGNIZED;
  }
}

export function subscriptionTypeToJSON(object: SubscriptionType): number {
  switch (object) {
    case SubscriptionType.SUBSCRIPTION_PAID_TIER_1_MONTH:
      return 0;
    case SubscriptionType.SUBSCRIPTION_PAID_TIER_4_MONTH:
      return 1;
    case SubscriptionType.SUBSCRIPTION_PAID_TIER_12_MONTH:
      return 2;
    case SubscriptionType.SUBSCRIPTION_OTHER:
      return 3;
    case SubscriptionType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum CreditPurchaseType {
  CREDIT_PACKAGE_UNSPECIFIED = 0,
  CREDIT_PACKAGE_1_CREDIT = 1,
  CREDIT_PACKAGE_3_CREDITS = 2,
  CREDIT_PACKAGE_10_CREDITS = 3,
  UNRECOGNIZED = -1,
}

export function creditPurchaseTypeFromJSON(object: any): CreditPurchaseType {
  switch (object) {
    case 0:
    case "CREDIT_PACKAGE_UNSPECIFIED":
      return CreditPurchaseType.CREDIT_PACKAGE_UNSPECIFIED;
    case 1:
    case "CREDIT_PACKAGE_1_CREDIT":
      return CreditPurchaseType.CREDIT_PACKAGE_1_CREDIT;
    case 2:
    case "CREDIT_PACKAGE_3_CREDITS":
      return CreditPurchaseType.CREDIT_PACKAGE_3_CREDITS;
    case 3:
    case "CREDIT_PACKAGE_10_CREDITS":
      return CreditPurchaseType.CREDIT_PACKAGE_10_CREDITS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CreditPurchaseType.UNRECOGNIZED;
  }
}

export function creditPurchaseTypeToJSON(object: CreditPurchaseType): number {
  switch (object) {
    case CreditPurchaseType.CREDIT_PACKAGE_UNSPECIFIED:
      return 0;
    case CreditPurchaseType.CREDIT_PACKAGE_1_CREDIT:
      return 1;
    case CreditPurchaseType.CREDIT_PACKAGE_3_CREDITS:
      return 2;
    case CreditPurchaseType.CREDIT_PACKAGE_10_CREDITS:
      return 3;
    case CreditPurchaseType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface BragsheetCheckoutRequest {
  uuid: string;
  email: string;
  creditPackage: CreditPurchaseType;
}

export interface CreateWriterEnterpriseMemberSubscriptionRequest {
  wuuid: string;
  type: SubscriptionType;
}

export interface WriterCheckoutRequest {
  wuuid: string;
  email: string;
  type: SubscriptionType;
}

export interface CheckoutResponse {
  url: string;
}

export interface GetWriterSubscriptionRequestFromCustomerID {
  customerId: string;
}

export interface GetWriterSubscriptionRequest {
  wuuid?: string | undefined;
  suuid?: string | undefined;
  stripeSubscriptionId?: string | undefined;
}

export interface WriterSubscriptionModel {
  suuid: string;
  wuuid: string;
  /** given by stripe */
  customerId: string;
  /** given by stripe */
  stripeSubscriptionId: string;
  /** time at subscription creation */
  subscriptionStart: number;
  /** time at subscription renewal */
  subscriptionRenewal: number;
  /** time at subscription expiration */
  subscriptionExpiration: number;
  /** whether the subscription will autorenew */
  autoRenew: Boolean;
  /** whether the subscription is active */
  active: Boolean;
  /** Length of subscription renewal time */
  type: SubscriptionType;
  createdAt: number;
  updatedAt: number;
}

function createBaseBragsheetCheckoutRequest(): BragsheetCheckoutRequest {
  return { uuid: "", email: "", creditPackage: 0 };
}

export const BragsheetCheckoutRequest = {
  encode(message: BragsheetCheckoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.creditPackage !== 0) {
      writer.uint32(24).int32(message.creditPackage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BragsheetCheckoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBragsheetCheckoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.creditPackage = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BragsheetCheckoutRequest {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      email: isSet(object.email) ? String(object.email) : "",
      creditPackage: isSet(object.creditPackage) ? creditPurchaseTypeFromJSON(object.creditPackage) : 0,
    };
  },

  toJSON(message: BragsheetCheckoutRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.email !== undefined && (obj.email = message.email);
    message.creditPackage !== undefined && (obj.creditPackage = creditPurchaseTypeToJSON(message.creditPackage));
    return obj;
  },

  create<I extends Exact<DeepPartial<BragsheetCheckoutRequest>, I>>(base?: I): BragsheetCheckoutRequest {
    return BragsheetCheckoutRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BragsheetCheckoutRequest>, I>>(object: I): BragsheetCheckoutRequest {
    const message = createBaseBragsheetCheckoutRequest();
    message.uuid = object.uuid ?? "";
    message.email = object.email ?? "";
    message.creditPackage = object.creditPackage ?? 0;
    return message;
  },
};

function createBaseCreateWriterEnterpriseMemberSubscriptionRequest(): CreateWriterEnterpriseMemberSubscriptionRequest {
  return { wuuid: "", type: 0 };
}

export const CreateWriterEnterpriseMemberSubscriptionRequest = {
  encode(
    message: CreateWriterEnterpriseMemberSubscriptionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.wuuid !== "") {
      writer.uint32(10).string(message.wuuid);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWriterEnterpriseMemberSubscriptionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWriterEnterpriseMemberSubscriptionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wuuid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWriterEnterpriseMemberSubscriptionRequest {
    return {
      wuuid: isSet(object.wuuid) ? String(object.wuuid) : "",
      type: isSet(object.type) ? subscriptionTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: CreateWriterEnterpriseMemberSubscriptionRequest): unknown {
    const obj: any = {};
    message.wuuid !== undefined && (obj.wuuid = message.wuuid);
    message.type !== undefined && (obj.type = subscriptionTypeToJSON(message.type));
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWriterEnterpriseMemberSubscriptionRequest>, I>>(
    base?: I,
  ): CreateWriterEnterpriseMemberSubscriptionRequest {
    return CreateWriterEnterpriseMemberSubscriptionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateWriterEnterpriseMemberSubscriptionRequest>, I>>(
    object: I,
  ): CreateWriterEnterpriseMemberSubscriptionRequest {
    const message = createBaseCreateWriterEnterpriseMemberSubscriptionRequest();
    message.wuuid = object.wuuid ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseWriterCheckoutRequest(): WriterCheckoutRequest {
  return { wuuid: "", email: "", type: 0 };
}

export const WriterCheckoutRequest = {
  encode(message: WriterCheckoutRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wuuid !== "") {
      writer.uint32(10).string(message.wuuid);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WriterCheckoutRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWriterCheckoutRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wuuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WriterCheckoutRequest {
    return {
      wuuid: isSet(object.wuuid) ? String(object.wuuid) : "",
      email: isSet(object.email) ? String(object.email) : "",
      type: isSet(object.type) ? subscriptionTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: WriterCheckoutRequest): unknown {
    const obj: any = {};
    message.wuuid !== undefined && (obj.wuuid = message.wuuid);
    message.email !== undefined && (obj.email = message.email);
    message.type !== undefined && (obj.type = subscriptionTypeToJSON(message.type));
    return obj;
  },

  create<I extends Exact<DeepPartial<WriterCheckoutRequest>, I>>(base?: I): WriterCheckoutRequest {
    return WriterCheckoutRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WriterCheckoutRequest>, I>>(object: I): WriterCheckoutRequest {
    const message = createBaseWriterCheckoutRequest();
    message.wuuid = object.wuuid ?? "";
    message.email = object.email ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseCheckoutResponse(): CheckoutResponse {
  return { url: "" };
}

export const CheckoutResponse = {
  encode(message: CheckoutResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckoutResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckoutResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckoutResponse {
    return { url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: CheckoutResponse): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckoutResponse>, I>>(base?: I): CheckoutResponse {
    return CheckoutResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CheckoutResponse>, I>>(object: I): CheckoutResponse {
    const message = createBaseCheckoutResponse();
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseGetWriterSubscriptionRequestFromCustomerID(): GetWriterSubscriptionRequestFromCustomerID {
  return { customerId: "" };
}

export const GetWriterSubscriptionRequestFromCustomerID = {
  encode(message: GetWriterSubscriptionRequestFromCustomerID, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWriterSubscriptionRequestFromCustomerID {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWriterSubscriptionRequestFromCustomerID();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWriterSubscriptionRequestFromCustomerID {
    return { customerId: isSet(object.customer_id) ? String(object.customer_id) : "" };
  },

  toJSON(message: GetWriterSubscriptionRequestFromCustomerID): unknown {
    const obj: any = {};
    message.customerId !== undefined && (obj.customer_id = message.customerId);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWriterSubscriptionRequestFromCustomerID>, I>>(
    base?: I,
  ): GetWriterSubscriptionRequestFromCustomerID {
    return GetWriterSubscriptionRequestFromCustomerID.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetWriterSubscriptionRequestFromCustomerID>, I>>(
    object: I,
  ): GetWriterSubscriptionRequestFromCustomerID {
    const message = createBaseGetWriterSubscriptionRequestFromCustomerID();
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseGetWriterSubscriptionRequest(): GetWriterSubscriptionRequest {
  return { wuuid: undefined, suuid: undefined, stripeSubscriptionId: undefined };
}

export const GetWriterSubscriptionRequest = {
  encode(message: GetWriterSubscriptionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wuuid !== undefined) {
      writer.uint32(10).string(message.wuuid);
    }
    if (message.suuid !== undefined) {
      writer.uint32(26).string(message.suuid);
    }
    if (message.stripeSubscriptionId !== undefined) {
      writer.uint32(34).string(message.stripeSubscriptionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWriterSubscriptionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWriterSubscriptionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wuuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.suuid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stripeSubscriptionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWriterSubscriptionRequest {
    return {
      wuuid: isSet(object.wuuid) ? String(object.wuuid) : undefined,
      suuid: isSet(object.suuid) ? String(object.suuid) : undefined,
      stripeSubscriptionId: isSet(object.stripe_subscription_id) ? String(object.stripe_subscription_id) : undefined,
    };
  },

  toJSON(message: GetWriterSubscriptionRequest): unknown {
    const obj: any = {};
    message.wuuid !== undefined && (obj.wuuid = message.wuuid);
    message.suuid !== undefined && (obj.suuid = message.suuid);
    message.stripeSubscriptionId !== undefined && (obj.stripe_subscription_id = message.stripeSubscriptionId);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWriterSubscriptionRequest>, I>>(base?: I): GetWriterSubscriptionRequest {
    return GetWriterSubscriptionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetWriterSubscriptionRequest>, I>>(object: I): GetWriterSubscriptionRequest {
    const message = createBaseGetWriterSubscriptionRequest();
    message.wuuid = object.wuuid ?? undefined;
    message.suuid = object.suuid ?? undefined;
    message.stripeSubscriptionId = object.stripeSubscriptionId ?? undefined;
    return message;
  },
};

function createBaseWriterSubscriptionModel(): WriterSubscriptionModel {
  return {
    suuid: "",
    wuuid: "",
    customerId: "",
    stripeSubscriptionId: "",
    subscriptionStart: 0,
    subscriptionRenewal: 0,
    subscriptionExpiration: 0,
    autoRenew: 0,
    active: 0,
    type: 0,
    createdAt: 0,
    updatedAt: 0,
  };
}

export const WriterSubscriptionModel = {
  encode(message: WriterSubscriptionModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.suuid !== "") {
      writer.uint32(10).string(message.suuid);
    }
    if (message.wuuid !== "") {
      writer.uint32(18).string(message.wuuid);
    }
    if (message.customerId !== "") {
      writer.uint32(26).string(message.customerId);
    }
    if (message.stripeSubscriptionId !== "") {
      writer.uint32(34).string(message.stripeSubscriptionId);
    }
    if (message.subscriptionStart !== 0) {
      writer.uint32(40).int64(message.subscriptionStart);
    }
    if (message.subscriptionRenewal !== 0) {
      writer.uint32(48).int64(message.subscriptionRenewal);
    }
    if (message.subscriptionExpiration !== 0) {
      writer.uint32(56).int64(message.subscriptionExpiration);
    }
    if (message.autoRenew !== 0) {
      writer.uint32(64).int32(message.autoRenew);
    }
    if (message.active !== 0) {
      writer.uint32(72).int32(message.active);
    }
    if (message.type !== 0) {
      writer.uint32(80).int32(message.type);
    }
    if (message.createdAt !== 0) {
      writer.uint32(96).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(104).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WriterSubscriptionModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWriterSubscriptionModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.suuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.wuuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stripeSubscriptionId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.subscriptionStart = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.subscriptionRenewal = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.subscriptionExpiration = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.autoRenew = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.active = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WriterSubscriptionModel {
    return {
      suuid: isSet(object.suuid) ? String(object.suuid) : "",
      wuuid: isSet(object.wuuid) ? String(object.wuuid) : "",
      customerId: isSet(object.customer_id) ? String(object.customer_id) : "",
      stripeSubscriptionId: isSet(object.stripe_subscription_id) ? String(object.stripe_subscription_id) : "",
      subscriptionStart: isSet(object.subscription_start) ? Number(object.subscription_start) : 0,
      subscriptionRenewal: isSet(object.subscription_renewal) ? Number(object.subscription_renewal) : 0,
      subscriptionExpiration: isSet(object.subscription_expiration) ? Number(object.subscription_expiration) : 0,
      autoRenew: isSet(object.auto_renew) ? booleanFromJSON(object.auto_renew) : 0,
      active: isSet(object.active) ? booleanFromJSON(object.active) : 0,
      type: isSet(object.type) ? subscriptionTypeFromJSON(object.type) : 0,
      createdAt: isSet(object.created_at) ? Number(object.created_at) : 0,
      updatedAt: isSet(object.updated_at) ? Number(object.updated_at) : 0,
    };
  },

  toJSON(message: WriterSubscriptionModel): unknown {
    const obj: any = {};
    message.suuid !== undefined && (obj.suuid = message.suuid);
    message.wuuid !== undefined && (obj.wuuid = message.wuuid);
    message.customerId !== undefined && (obj.customer_id = message.customerId);
    message.stripeSubscriptionId !== undefined && (obj.stripe_subscription_id = message.stripeSubscriptionId);
    message.subscriptionStart !== undefined && (obj.subscription_start = Math.round(message.subscriptionStart));
    message.subscriptionRenewal !== undefined && (obj.subscription_renewal = Math.round(message.subscriptionRenewal));
    message.subscriptionExpiration !== undefined &&
      (obj.subscription_expiration = Math.round(message.subscriptionExpiration));
    message.autoRenew !== undefined && (obj.auto_renew = booleanToJSON(message.autoRenew));
    message.active !== undefined && (obj.active = booleanToJSON(message.active));
    message.type !== undefined && (obj.type = subscriptionTypeToJSON(message.type));
    message.createdAt !== undefined && (obj.created_at = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updated_at = Math.round(message.updatedAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<WriterSubscriptionModel>, I>>(base?: I): WriterSubscriptionModel {
    return WriterSubscriptionModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WriterSubscriptionModel>, I>>(object: I): WriterSubscriptionModel {
    const message = createBaseWriterSubscriptionModel();
    message.suuid = object.suuid ?? "";
    message.wuuid = object.wuuid ?? "";
    message.customerId = object.customerId ?? "";
    message.stripeSubscriptionId = object.stripeSubscriptionId ?? "";
    message.subscriptionStart = object.subscriptionStart ?? 0;
    message.subscriptionRenewal = object.subscriptionRenewal ?? 0;
    message.subscriptionExpiration = object.subscriptionExpiration ?? 0;
    message.autoRenew = object.autoRenew ?? 0;
    message.active = object.active ?? 0;
    message.type = object.type ?? 0;
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    return message;
  },
};

export interface PlutusService {
  CreateBragsheetCheckout(request: BragsheetCheckoutRequest): Promise<CheckoutResponse>;
  CreateWriterCheckout(request: WriterCheckoutRequest): Promise<CheckoutResponse>;
  CompleteWriterSubscription(request: WriterSubscriptionModel): Promise<WriterSubscriptionModel>;
  GetWriterSubscription(request: GetWriterSubscriptionRequest): Promise<WriterSubscriptionModel>;
  GetWriterSubscriptionFromCustomerID(
    request: GetWriterSubscriptionRequestFromCustomerID,
  ): Promise<WriterSubscriptionModel>;
  ExpireWriterSubscription(request: GetWriterSubscriptionRequestFromCustomerID): Promise<WriterSubscriptionModel>;
  CancelWriterSubscription(request: GetWriterSubscriptionRequest): Promise<WriterSubscriptionModel>;
  CreateWriterEnterpriseMemberSubscription(
    request: CreateWriterEnterpriseMemberSubscriptionRequest,
  ): Promise<WriterSubscriptionModel>;
}

export class PlutusServiceClientImpl implements PlutusService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "plutus.PlutusService";
    this.rpc = rpc;
    this.CreateBragsheetCheckout = this.CreateBragsheetCheckout.bind(this);
    this.CreateWriterCheckout = this.CreateWriterCheckout.bind(this);
    this.CompleteWriterSubscription = this.CompleteWriterSubscription.bind(this);
    this.GetWriterSubscription = this.GetWriterSubscription.bind(this);
    this.GetWriterSubscriptionFromCustomerID = this.GetWriterSubscriptionFromCustomerID.bind(this);
    this.ExpireWriterSubscription = this.ExpireWriterSubscription.bind(this);
    this.CancelWriterSubscription = this.CancelWriterSubscription.bind(this);
    this.CreateWriterEnterpriseMemberSubscription = this.CreateWriterEnterpriseMemberSubscription.bind(this);
  }
  CreateBragsheetCheckout(request: BragsheetCheckoutRequest): Promise<CheckoutResponse> {
    const data = BragsheetCheckoutRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateBragsheetCheckout", data);
    return promise.then((data) => CheckoutResponse.decode(_m0.Reader.create(data)));
  }

  CreateWriterCheckout(request: WriterCheckoutRequest): Promise<CheckoutResponse> {
    const data = WriterCheckoutRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateWriterCheckout", data);
    return promise.then((data) => CheckoutResponse.decode(_m0.Reader.create(data)));
  }

  CompleteWriterSubscription(request: WriterSubscriptionModel): Promise<WriterSubscriptionModel> {
    const data = WriterSubscriptionModel.encode(request).finish();
    const promise = this.rpc.request(this.service, "CompleteWriterSubscription", data);
    return promise.then((data) => WriterSubscriptionModel.decode(_m0.Reader.create(data)));
  }

  GetWriterSubscription(request: GetWriterSubscriptionRequest): Promise<WriterSubscriptionModel> {
    const data = GetWriterSubscriptionRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetWriterSubscription", data);
    return promise.then((data) => WriterSubscriptionModel.decode(_m0.Reader.create(data)));
  }

  GetWriterSubscriptionFromCustomerID(
    request: GetWriterSubscriptionRequestFromCustomerID,
  ): Promise<WriterSubscriptionModel> {
    const data = GetWriterSubscriptionRequestFromCustomerID.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetWriterSubscriptionFromCustomerID", data);
    return promise.then((data) => WriterSubscriptionModel.decode(_m0.Reader.create(data)));
  }

  ExpireWriterSubscription(request: GetWriterSubscriptionRequestFromCustomerID): Promise<WriterSubscriptionModel> {
    const data = GetWriterSubscriptionRequestFromCustomerID.encode(request).finish();
    const promise = this.rpc.request(this.service, "ExpireWriterSubscription", data);
    return promise.then((data) => WriterSubscriptionModel.decode(_m0.Reader.create(data)));
  }

  CancelWriterSubscription(request: GetWriterSubscriptionRequest): Promise<WriterSubscriptionModel> {
    const data = GetWriterSubscriptionRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CancelWriterSubscription", data);
    return promise.then((data) => WriterSubscriptionModel.decode(_m0.Reader.create(data)));
  }

  CreateWriterEnterpriseMemberSubscription(
    request: CreateWriterEnterpriseMemberSubscriptionRequest,
  ): Promise<WriterSubscriptionModel> {
    const data = CreateWriterEnterpriseMemberSubscriptionRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateWriterEnterpriseMemberSubscription", data);
    return promise.then((data) => WriterSubscriptionModel.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
