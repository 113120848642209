import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';
import routes from './config/routes';
import AuthRoute from './modules/auth/AuthRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import './App.css';
import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

export interface IApplicationProps {}

const App: React.FunctionComponent<IApplicationProps> = (_) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 60 * 3, // 3 minutes, data is considered stale
                refetchOnWindowFocus: false,
                refetchOnMount: false,
            },
        },
    });

    useEffect(() => {
        if (window.location.href.toLowerCase().includes('www.refbuilder')) {
            window.location.replace('https://refbuilder.com');
        }
    }, [window.location.href]);

    let theme = createTheme({
        palette: {
            primary: {
                light: '#f9d37f',
                main: '#f9d37f',
                dark: '#ffd069',
            },
            secondary: {
                main: '#444444',
            },
            background: {
                default: '#f5f5f5',
            },
            // Error color could be yellow
            error: {
                main: '#f72c25',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 1200,
                lg: 1536,
                xl: 2000,
            },
        },
        typography: {
            fontFamily: [
                '"GT Pressura Standard"',
                '"Tahoma"',
                '"Segoe"',
                '"Verdana"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            fontSize: 18,
        },
        components: {
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        top: '-6px',
                        color: '#444444',
                        cursor: 'small',
                    },
                },
            },

            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        boxShadow: 'none',
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                        textAlign: 'center',
                        minHeight: '50px',
                        maxHeight: '50px',
                        minWidth: '110px',
                        padding: '1rem',
                        '@media (max-width: 600px)': {
                            fontSize: '16px !important',
                        },
                        color: '#444444',
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        width: 0,
                        minWidth: '100%',
                        boxSizing: 'border-box',
                        display: 'block',
                        marginRight: 0,
                        marginLeft: 0,
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        width: 0,
                        minWidth: '100%',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    body1: {
                        color: '#444444',
                        lineHeight: '1.3',
                    },
                    body2: {
                        color: '#444444',
                    },
                    h1: {
                        color: '#444444',
                    },
                    h2: {
                        color: '#444444',
                    },
                    h3: {
                        color: '#444444',
                    },
                    h4: {
                        color: '#444444',
                    },
                    h5: {
                        color: '#444444',
                        lineHeight: '1.3',
                        '@media (max-width: 600px)': {
                            lineHeight: '1.2',
                        },
                    },
                    h6: {
                        color: '#444444',
                    },
                    subtitle1: {
                        color: '#444444',
                    },
                    subtitle2: {
                        color: '#444444',
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& input': {
                            textAlign: 'left',
                            lineHeight: '.8',
                            paddingTop: '12px',
                            paddingBottom: '10px',
                        },
                        '& .MuiInputBase-root': {
                            borderRadius: '0',
                            '&:before': {
                                border: 'none',
                            },
                            '&:after': {
                                borderBottom: '1px solid #f9d37f',
                            },
                            '&:hover:before': {
                                borderBottom: 'none',
                            },
                            '&:hover:after': {
                                borderBottom: '1px solid #f9d37f',
                            },
                        },
                    },
                },
            },
        },
    });

    theme = responsiveFontSizes(theme);

    // const faviconMode = useMediaQuery('(prefers-color-scheme: dark)') ? "dark" : "light";
    const faviconMode = 'light';

    // Favicons injected into head to dynamically set based on computer dark/light mode
    useEffect(() => {
        const head = document.getElementsByTagName('head').item(0);

        const favicon = document.createElement('link');
        favicon.rel = 'shortcut icon';
        favicon.href = `/assets/favicons/${faviconMode}/favicon.ico`;

        const thirtyTwo = document.createElement('link');
        thirtyTwo.rel = 'icon';
        thirtyTwo.href = `/assets/favicons/${faviconMode}/favicon-32x32.png`;
        thirtyTwo.type = 'image/png';

        const sixteen = document.createElement('link');
        sixteen.rel = 'icon';
        sixteen.href = `/assets/favicons/${faviconMode}/favicon-16x16.png`;
        sixteen.type = 'image/png';

        const apple = document.createElement('link');
        apple.setAttribute('sizes', '180x180');
        apple.rel = 'apple-touch-icon';
        apple.href = `/assets/favicons/${faviconMode}/apple-touch-icon.png`;

        const manifest = document.createElement('link');
        manifest.rel = 'manifest';
        manifest.href = `/assets/favicons/${faviconMode}/site.webmanifest`;

        head?.appendChild(favicon);
        head?.appendChild(thirtyTwo);
        head?.appendChild(sixteen);
        head?.appendChild(apple);
        head?.appendChild(manifest);
    }, []);

    let router = createBrowserRouter(
        createRoutesFromElements(
            routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        <ScrollToTop>
                            {route.protected && (
                                <AuthRoute>
                                    <route.component />
                                </AuthRoute>
                            )}

                            {!route.protected && <route.component />}
                        </ScrollToTop>
                    }
                />
            ))
        )
    );

    return (
        <ThemeProvider theme={theme}>
            <GoogleReCaptchaProvider reCaptchaKey="6LdT15UjAAAAAIR8cjCVoC5z_xZrmjrTW3RxAouj">
                <QueryClientProvider client={queryClient}>
                    <GoogleOAuthProvider clientId="178236683949-n7mi9b73s11b61l879olsj5phttg4k0s.apps.googleusercontent.com">
                        <RouterProvider router={router} />
                    </GoogleOAuthProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </GoogleReCaptchaProvider>
        </ThemeProvider>
    );
};

export default App;
