import { ArrowRightAlt } from '@mui/icons-material';
import {
    Button,
    Typography,
    useTheme,
    useMediaQuery,
    Link,
} from '@mui/material';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import './Home.css';
import { useState } from 'react';

export default function Home() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const home1Images = [
        'image1.jpg',
        'image2.jpeg',
        'image3.jpeg',
        'image4.jpeg',
    ];

    const getHome1Image = () => {
        const image =
            'assets/images/home/header/' +
            home1Images[Math.floor(Math.random() * home1Images.length)];
        return image;
    };

    const [home1RandomImage] = useState(getHome1Image);

    return (
        <div className="home">
            <Navbar />
            <div className="home-1">
                <div className="home-1-text">
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', xs: '24px' }}
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                        mb={'.5rem'}
                    >
                        Save Time + Write Better Letters
                    </Typography>
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                    >
                        RefBuilder is the world’s easiest way to write and
                        organize reference letters.
                    </Typography>
                    <div className="home-1-buttons">
                        <Button
                            style={{ width: isMobile ? '100%' : 'auto' }}
                            color="primary"
                            variant="contained"
                            href="/sign-up"
                            className="home-1-button"
                        >
                            TRY IT FREE
                        </Button>
                        <Button
                            sx={{
                                color: '#999999',
                                ml: '1rem',
                                ':hover': {
                                    boxShadow:
                                        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                                },
                            }}
                            variant="text"
                            href="/learn/user"
                            endIcon={<ArrowRightAlt />}
                        >
                            <Typography
                                variant="body2"
                                style={{ color: '#999999' }}
                            >
                                Learn More
                            </Typography>
                        </Button>
                    </div>
                </div>

                <div className="home-1-image">
                    <img
                        alt="writing on paper"
                        className="home-1-image-img"
                        src={home1RandomImage}
                    />
                </div>
            </div>

            <div className="home-2">
                <div className="home-2-overlay">
                    <div className="home-2-overlay-text">
                        <Typography
                            fontSize={{ sm: '24px', xs: '18px' }}
                            color={'#ffffff'}
                        >
                            ”I have used your app with great success in crafting
                            seventeen letters of recommendation already!”{' '}
                        </Typography>
                        <Typography
                            mt={'1rem'}
                            fontSize={{ sm: '24px', xs: '18px' }}
                            color={'#f9d37f'}
                        >
                            - Emory University Professor
                        </Typography>
                    </div>
                </div>
            </div>
            {isMobile && (
                <div className="youtube-video-mobile-1">
                    <iframe 
                        src="https://www.youtube.com/embed/OtfmjobMIXw?si=3ASzNX3iE6xlOBAV" 
                        title="YouTube video player" frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerPolicy="strict-origin-when-cross-origin" 
                        allowFullScreen>
                    </iframe>
                </div>
            )}
            {!isMobile && (
                <div className="home-chat-bubble">
                    <img
                        alt="writing on paper"
                        className="home-chat-bubble-img"
                        src="assets/images/home/chat_bubble.jpeg"
                    />
                </div>
            )}
            <div className="home-3">
                <div className="home-3-text">
                    <Typography
                        mb={'.5rem'}
                        fontSize={{ sm: '18px', xs: '18px' }}
                        fontWeight="bold"
                    >
                        HOW IT WORKS:
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        To get started, simply create a free account, connect
                        with the person you’re writing a letter for, then
                        RefBuilder helps you write a top notch letter. It’s that
                        easy!
                    </Typography>
                </div>
            </div>
            {!isMobile && (
                <div className="youtube-video-desktop-1">
                    <div>
                        <iframe 
                            src="https://www.youtube.com/embed/OtfmjobMIXw?si=3ASzNX3iE6xlOBAV" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>        
            )}
            
            <div className="home-4">
                <div className="home-4-box">
                    <Typography
                        fontSize={{ sm: '100px', md: '120px', xs: '60px' }}
                        variant="h1"
                        color="primary"
                    >
                        <Link href="/sign-up" underline="none" color="primary">
                            01.
                        </Link>
                    </Typography>
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                    >
                        <Link
                            href="/sign-up"
                            underline="none"
                            color="secondary"
                        >
                            CREATE AN ACCOUNT
                        </Link>
                    </Typography>
                    <Typography
                        fontSize={{ sm: '20px', xs: '18px' }}
                        variant="body2"
                        color="rgb(153, 153, 153)"
                    >
                        <Link
                            href="/sign-up"
                            underline="none"
                            color={'rgb(153, 153, 153)'}
                        >
                            Create your free account. No credit card required.
                        </Link>
                    </Typography>
                </div>

                <div className="home-4-box" style={{ marginTop: '34px' }}>
                    <Typography
                        fontSize={{ sm: '100px', md: '120px', xs: '60px' }}
                        variant="h1"
                        color="primary"
                    >
                        02.
                    </Typography>
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                    >
                        SEND OUT YOUR CUSTOM LINK
                    </Typography>
                    <Typography
                        fontSize={{ sm: '20px', xs: '18px' }}
                        variant="body2"
                        color="rgb(153, 153, 153)"
                    >
                        Connect with the person who requested a letter. Then,
                        the app gathers all the info you need to write a great
                        letter.
                    </Typography>
                </div>

                <div className="home-4-box" style={{ marginTop: '81px' }}>
                    <Typography
                        fontSize={{ sm: '100px', md: '120px', xs: '60px' }}
                        variant="h1"
                        color="primary"
                    >
                        03.
                    </Typography>
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        variant="subtitle2"
                        color="secondary"
                        fontWeight="bold"
                    >
                        WRITE BETTER REFERENCE LETTERS
                    </Typography>
                    <Typography
                        fontSize={{ sm: '20px', xs: '18px' }}
                        variant="body2"
                        color="rgb(153, 153, 153)"
                    >
                        Write an original letter with our easy-to-use templates,
                        or let RefBuilder AI write it for you!
                    </Typography>
                </div>
            </div>

            <div className="home-5">
                <div className="home-5-buttons">
                    <Button
                        style={{
                            marginBottom: isMobile ? '1rem' : '0',
                            width: isMobile ? '100%' : 'auto',
                        }}
                        color="primary"
                        variant="contained"
                        href="/sign-up"
                        className="home-1-button"
                    >
                        TRY IT FREE
                    </Button>
                    <Button
                        sx={{
                            color: '#999999',
                            ml: '1rem',
                            ':hover': {
                                boxShadow:
                                    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                            },
                        }}
                        variant="text"
                        href="/learn/user"
                        endIcon={<ArrowRightAlt />}
                    >
                        <Typography
                            variant="body2"
                            style={{ color: '#999999' }}
                        >
                            Learn More
                        </Typography>
                    </Button>
                </div>
            </div>

            <div className="home-6">
                <div className="home-6-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="h5"
                        color="#999999"
                        mb={'.5rem'}
                    >
                        Law School Spotlight
                    </Typography>
                    <Typography
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        variant="h5"
                        color="secondary"
                    >
                        Learn how RefBuilder can help your students land more
                        judicial clerkships, fellowships, and other job
                        opportunities.
                    </Typography>
                    <Button
                        style={{
                            marginBottom: isMobile ? '1rem' : '0',
                            width: isMobile ? '100%' : 'auto',
                            marginTop: '1rem',
                        }}
                        color="primary"
                        variant="contained"
                        href="/learn/law-school"
                        className="home-1-button"
                    >
                        RefBuilder for Law Schools
                    </Button>
                </div>

                <div className="home-6-img">
                    <img alt="writing" src="assets/images/home/home-6-2.jpg" />
                </div>
            </div>
            
                <div className="home-7">
                    <div className="home-7-overlay">
                        <div className="home-7-overlay-holder">
                            <Typography
                                fontSize={{ sm: '32px', xs: '24px' }}
                                variant="h5"
                                color="#FFFFFF"
                            >
                                How to Write a Top-Notch Reference Letter
                            </Typography>
                            <Button
                                style={{
                                    marginBottom: isMobile ? '1rem' : '0',
                                    width: isMobile ? '100%' : 'auto',
                                }}
                                href="/assets/docs/Reference_Builder_Quick_Guide_to_Reference_Letters.pdf"
                                variant="contained"
                                color="primary"
                            >
                                Download Free Guide!
                            </Button>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    );
}
