import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormikValues } from 'formik';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import './BSchoolFunnel.css';
import FeedbackPopup from '../../../components/notification/FeedbackPopup';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../../components/form/ReffyForm';
import { useState, useEffect, useRef } from 'react';
import InfoCards from '../../../components/infoCards/InfoCards';
import { useOrganizationMutation } from '@cosmogony/reffy-hooks/chronos/chronos_hooks';
import { OrganizationFormRequest } from '@cosmogony/reffy-pb/chronos-svc/pkg/pb/chronos';
const views: ReffyView[] = [
    {
        name: 'sign-up',
        category: '',
        subcategory: '',
        inputs: [
            {
                name: 'name',
                title: 'Name:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'email',
                title: 'Email Address:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'phone',
                title: 'Phone Number:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'bschool',
                title: 'Business School and Location:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'questions',
                title: 'Questions?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
            },
        ],
        repeat: false,
    },
];

var form = GenerateReffyFormOptions(views);

export default function BSchoolFunnel() {
    const moreRef = useRef<HTMLDivElement>(null);
    const handleShowMore = () => {
        if (moreRef.current) {
            moreRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [showFeedback, setShowFeedback] = useState(false);
    const business = useOrganizationMutation();

    useEffect(() => {
        if (showFeedback !== false) {
            setTimeout(() => {
                setShowFeedback(false);
            }, 5000);
        }
    }, []);

    const handleSubmit = async (data: FormikValues) => {
        const accountResponse = data['sign-up'][0];
        const name = accountResponse['name'];
        const email = accountResponse['email'];
        const phone = accountResponse['phone'];
        const bschool_info = `Business School: ${accountResponse['bschool']}`;
        const questions = accountResponse['questions'];
        await business
            .mutateAsync(
                OrganizationFormRequest.create({
                    name: name,
                    email: email,
                    phoneNumber: phone,
                    organizationInfo: bschool_info,
                    question: questions,
                })
            )
            .then(() => {
                setShowFeedback(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="home">
            {showFeedback ? (
                <FeedbackPopup message={'Your form has been received!'} />
            ) : (
                <></>
            )}
            <Navbar />
            <div className="business_funnel-1">
                <div className="business_funnel-1-text">
                    <Typography
                        variant="h5"
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                    >
                        Better references = Better results.
                    </Typography>
                    <Typography variant="h5" fontWeight="normal" pb={1}>
                        RefBuilder is tailor-made for business schools.
                    </Typography>
                    <Typography fontWeight="normal" color="rgb(153, 153, 153)">
                        50% of a business school’s ranking is based on its
                        students’ job placement and starting salaries.
                        RefBuilder gives your graduates a competitive advantage.
                    </Typography>
                    <div className="business_funnel-1-buttons">
                        <Button
                            style={{
                                marginBottom: isMobile ? '1rem' : '0',
                                width: isMobile ? '100%' : 'auto',
                            }}
                            color="primary"
                            variant="contained"
                            onClick={handleShowMore}
                            className="business_funnel-1-button"
                        >
                            REQUEST A QUOTE
                        </Button>
                        <Typography
                            variant="body2"
                            style={{ color: '#999999' }}
                        >
                            Our team will reach out
                        </Typography>
                    </div>
                </div>
                <div className="business_funnel-1-image">
                    <img
                        alt="writing on paper"
                        className="business_funnel-1-image-img"
                        src="/assets/images/bschool_funnel/bschool_funnel.jpg"
                    />
                </div>
            </div>

            <div className="business_funnel-3">
                <div className="business_funnel-3-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'0.5rem'}
                    >
                        BUSINESS IS BUILT ON RELATIONSHIPS.
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        Whether it’s a reference letter, personal introduction,
                        or vouching for a student’s skills on LinkedIn, your
                        faculty can help graduates land great jobs – but only if
                        they have the right tools.
                    </Typography>
                </div>
            </div>

            <div className="business_funnel-4">
                <div className="business_funnel-4-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'0.5rem'}
                    >
                        HIGHLY TAILORED REFERENCES FOR EVERY STUDENT
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        It’s hard for faculty members to manage so many
                        reference requests, especially at busy times of the
                        semester. But, with RefBuilder, it’s easy to organize
                        and quickly write dozens of personalized reference
                        letters – even on short notice. Giving your students a
                        leg up has never been easier.
                    </Typography>
                </div>
                <InfoCards
                    firstText="RefBuilder’s specialized AI (artificial intelligence) crafts a personalized first draft of every letter, saving faculty up to two hours per letter."
                    secondText="No more generic letters! RefBuilder empowers your faculty to provide personalized references highlighting student’s unique qualifications every time."
                    thirdText="RefBuilder streamlines the process of requesting, organizing, and submitting all kinds of references."
                />
            </div>
            <div className="youtube-video-business">
                <div>
                    <iframe 
                        src="https://www.youtube.com/embed/OtfmjobMIXw?si=2QFWvnNZhRjOhzDy" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerPolicy="strict-origin-when-cross-origin" 
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="business_funnel-5">
                <div className="business_funnel-5-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                    >
                        Say no to the status quo.
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                    >
                        No more leaving your graduates’ references to chance. No
                        more stress for your faculty and staff. And no more
                        cookie-cutter reference letters that hurt your students’
                        chances of success.
                    </Typography>
                </div>
            </div>

            <div ref={moreRef} className="business_funnel-6">
                <div className="business_funnel-6-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                    >
                        Elevate Your Students' Success
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                    >
                        Join other top business schools in improving student
                        outcomes with RefBuilder. Submit your details and
                        questions below, and we’ll help you determine if
                        RefBuilder is a good fit for your institution.
                    </Typography>
                </div>
                <div className="business_funnel-6-form">
                    <ReffyForm
                        initialValues={form}
                        views={views}
                        onSubmit={(data) => {
                            handleSubmit(data);
                        }}
                        padding="0px"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}
