import { useState, useEffect, useRef } from 'react';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormikValues } from 'formik';
import { useOrganizationMutation } from '@cosmogony/reffy-hooks/chronos/chronos_hooks';
import { OrganizationFormRequest } from '@cosmogony/reffy-pb/chronos-svc/pkg/pb/chronos';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import FeedbackPopup from '../../../components/notification/FeedbackPopup';
import InfoCards from '../../../components/infoCards/InfoCards';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../../components/form/ReffyForm';
import './LawSchoolFunnel.css';

const views: ReffyView[] = [
    {
        name: 'sign-up',
        category: '',
        subcategory: '',
        inputs: [
            {
                name: 'name',
                title: 'Name:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'email',
                title: 'Email Address:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'phone',
                title: 'Phone Number:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'law-school',
                title: 'Law School Name and Location:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'questions',
                title: 'Questions?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
            },
        ],
        repeat: false,
    },
];

var form = GenerateReffyFormOptions(views);

export default function LawSchoolFunnel() {
    const moreRef = useRef<HTMLDivElement>(null);
    const handleShowMore = () => {
        if (moreRef.current) {
            moreRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [showFeedback, setShowFeedback] = useState(false);
    const lawSchool = useOrganizationMutation();

    useEffect(() => {
        if (showFeedback !== false) {
            setTimeout(() => {
                setShowFeedback(false);
            }, 5000);
        }
    }, []);

    const handleSubmit = async (data: FormikValues) => {
        const accountResponse = data['sign-up'][0];
        const name = accountResponse['name'];
        const email = accountResponse['email'];
        const phone = accountResponse['phone'];
        const lawSchoolInfo = `Law School: ${accountResponse['law-school']}`;
        const questions = accountResponse['questions'];
        await lawSchool
            .mutateAsync(
                OrganizationFormRequest.create({
                    name: name,
                    email: email,
                    phoneNumber: phone,
                    organizationInfo: lawSchoolInfo,
                    question: questions,
                })
            )
            .then(() => {
                setShowFeedback(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="home">
            {showFeedback ? (
                <FeedbackPopup message={'Your form has been received!'} />
            ) : (
                <></>
            )}
            <Navbar />
            <div className="law_funnel-1">
                <div className="law_funnel-1-text">
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                        mb={'.5rem'}
                    >
                        Give your students a leg up
                    </Typography>
                    <Typography fontWeight="normal" color="rgb(153, 153, 153)">
                        Whether they’re applying to clerkships, law firms, or non-profits, 
                        RefBuilder gives your graduates a competitive advantage.{' '}
                    </Typography>
                    <div className="law_funnel-1-buttons">
                        <Button
                            style={{
                                marginBottom: isMobile ? '1rem' : '0',
                                width: isMobile ? '100%' : 'auto',
                                marginRight: '1rem',
                            }}
                            color="primary"
                            variant="contained"
                            onClick={handleShowMore}
                            className="law_funnel-1-button"
                        >
                            Learn More
                        </Button>
                        <Typography
                            variant="body2"
                            style={{ color: '#999999' }}
                        >
                            Our team will reach out
                        </Typography>
                    </div>
                </div>
                <div className="law_funnel-1-image">
                    <img
                        alt="lawyer smiling"
                        className="law_funnel-1-image-img"
                        src="/assets/images/lawschool_funnel/lawschool_funnel_hero.jpg"
                    />
                </div>
            </div>

            <div className="law_funnel-3">
                <div className="law_funnel-3-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'.5rem'}
                    >
                        BETTER REFERENCES = BETTER RESULTS
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        A strong faculty reference can change 
                        the trajectory of someone’s career. But, 
                        it’s hard for professors to manage so many
                        student requests, especially at busy times
                        of the semester.{' '}
                    </Typography>
                </div>
            </div>

            <div className="law_funnel-4">
                <div className="law_funnel-4-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'.5rem'}
                    >
                        HIGHLY TAILORED REFERENCES FOR EVERY STUDENT
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        RefBuilder enables students to easily share 
                        all of the important details about their 
                        qualifications and experience with their professors. 


                    </Typography>
                </div>
                <InfoCards
                    firstText="RefBuilder AI automatically crafts a unique first draft of each letter, saving faculty up to two hours per letter."
                    secondText="No more generic letters. Maximize student outcomes with high-impact, accurate letters that help applicants stand out."
                    thirdTitle="EASY INTEGRATION"
                    thirdText="RefBuilder is easy to integrate into existing career center workflows and application management systems. It improves your current system without replacing it."
                />
            </div>
            <div className="youtube-video-law">
                <div>
                    <iframe 
                        src="https://www.youtube.com/embed/OtfmjobMIXw?si=2QFWvnNZhRjOhzDy" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="law_funnel-5">
                <div className="law_funnel-5-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                        mb={'.5rem'}
                    >
                        Your students and faculty need help
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                    >
                        Clerkship and job applications are hard work – for
                        everyone. RefBuilder is a cost-effective way to 
                        support law school students, faculty, and staff. 
                    </Typography>
                </div>
            </div>

            <div ref={moreRef} className="law_funnel-6">
                <div className="law_funnel-6-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                        mb={'.5rem'}
                    >
                        Need more information?
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                    >
                        Please fill out this form to share your 
                        inquiries and specify the information you 
                        need to determine if RefBuilder makes sense 
                        for your school.
                    </Typography>
                </div>
                <div className="law_funnel-6-form">
                    <ReffyForm
                        initialValues={form}
                        views={views}
                        onSubmit={(data) => {
                            handleSubmit(data);
                        }}
                        padding="0px"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}
