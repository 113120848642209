import {
    Button,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import './InfoCards.css';

export default function InfoCards(text?: {
    firstTitle?: string;
    firstText?: string;
    secondTitle?: string;
    secondText?: string;
    thirdTitle?: string;
    thirdText?: string;
}) {
    const theme = useTheme();
    const ismobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div className="info-cards-boxes">
            <div className="info-cards-box">
                <DescriptionOutlinedIcon
                    sx={{
                        stroke: 'white',
                        strokeWidth: '1',
                        fontSize: ismobile ? '60px' : '80px',
                        marginBottom: '0.5rem',
                    }}
                />
                <Typography
                    marginBottom={'0.5rem'}
                    fontSize={{ sm: '18px', xs: '16px' }}
                    variant="subtitle2"
                    color="secondary"
                    fontWeight="bold"
                >
                    {text?.firstTitle ?? 'EFFORTLESS LETTER WRITING.'}
                </Typography>
                <Typography
                    fontSize={{ sm: '20px', xs: '18px' }}
                    variant="body2"
                    color="rgb(153, 153, 153)"
                >
                    {text?.firstText ??
                        'Reference Builder uses artificial intelligence (AI) to write the first draft for you! Or, you can choose a customizable template that makes writing super easy.'}
                </Typography>
            </div>

            <div className="info-cards-box">
                <CheckCircleOutlineOutlinedIcon
                    sx={{
                        stroke: 'white',
                        strokeWidth: '1',
                        fontSize: ismobile ? '60px' : '80px',
                        marginBottom: '.5rem',
                    }}
                />
                <Typography
                    marginBottom={'.5rem'}
                    fontSize={{ sm: '18px', xs: '16px' }}
                    variant="subtitle2"
                    color="secondary"
                    fontWeight="bold"
                >
                    {text?.secondTitle ?? 'BETTER LETTERS = BETTER RESULTS.'}
                </Typography>
                <Typography
                    fontSize={{ sm: '20px', xs: '18px' }}
                    variant="body2"
                    color="rgb(153, 153, 153)"
                >
                    {text?.secondText ??
                        "Write reference letters that will give applicants the best shot at success. Whether it's for college apps, jobs, scholarships, internships, or something else, our app is designed to help you craft the perfect letter every time."}
                </Typography>
            </div>

            <div className="info-cards-box">
                <LanOutlinedIcon
                    sx={{
                        stroke: 'white',
                        strokeWidth: '1',
                        fontSize: ismobile ? '60px' : '80px',
                        fontWeight: '500',
                        marginBottom: '0.5rem',
                    }}
                />
                <Typography
                    marginBottom={'0.5rem'}
                    fontSize={{ sm: '18px', xs: '16px' }}
                    variant="subtitle2"
                    color="secondary"
                    fontWeight="bold"
                >
                    {text?.thirdTitle ?? 'BUILT IN ORGANIZATION.'}
                </Typography>
                <Typography
                    fontSize={{ sm: '20px', xs: '18px' }}
                    variant="body2"
                    color="rgb(153, 153, 153)"
                >
                    {text?.thirdText ??
                        'Reference Builder keeps track of all of your letter requests in one place – it’s perfect for professors, teachers, or anyone who writes reference letters on a regular basis.'}
                </Typography>
            </div>
        </div>
    );
}
