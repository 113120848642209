/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Boolean, booleanFromJSON, booleanToJSON } from "../../../common/pb/common";

export const protobufPackage = "morpheus";

export interface AssetModel {
  id: string;
  uuid: string;
  public: Boolean;
}

export interface CreateAssetRequest {
  /** ID of the owner of this resource */
  uuid: string;
  /** is the resource public availably or should only the UUID have access to it */
  public: Boolean;
}

export interface CreateAssetResponse {
  /** PUT Signed URL to Cloud Storage with embedded authentication */
  uploadUrl: string;
  /** URL to morpheus with the document URI embedded in it */
  downloadUrl: string;
}

export interface GetAssetRequest {
  /** Document Identifier */
  uri: string;
}

export interface GetAssetResponse {
  /** GET Signed URL to Cloud Storage with embedded authentication */
  downloadUrl: string;
  /** is the resource public availably or should only the UUID have access to it */
  public: Boolean;
}

export interface EditAssetRequest {
  /** Document Identifier */
  uri: string;
}

export interface EditAssetResponse {
  /** PUT Signed URL to Cloud Storage with embedded authentication */
  uploadUrl: string;
}

function createBaseAssetModel(): AssetModel {
  return { id: "", uuid: "", public: 0 };
}

export const AssetModel = {
  encode(message: AssetModel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.uuid !== "") {
      writer.uint32(18).string(message.uuid);
    }
    if (message.public !== 0) {
      writer.uint32(24).int32(message.public);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssetModel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.public = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssetModel {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      public: isSet(object.public) ? booleanFromJSON(object.public) : 0,
    };
  },

  toJSON(message: AssetModel): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.public !== undefined && (obj.public = booleanToJSON(message.public));
    return obj;
  },

  create<I extends Exact<DeepPartial<AssetModel>, I>>(base?: I): AssetModel {
    return AssetModel.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AssetModel>, I>>(object: I): AssetModel {
    const message = createBaseAssetModel();
    message.id = object.id ?? "";
    message.uuid = object.uuid ?? "";
    message.public = object.public ?? 0;
    return message;
  },
};

function createBaseCreateAssetRequest(): CreateAssetRequest {
  return { uuid: "", public: 0 };
}

export const CreateAssetRequest = {
  encode(message: CreateAssetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.public !== 0) {
      writer.uint32(16).int32(message.public);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAssetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAssetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.public = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAssetRequest {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      public: isSet(object.public) ? booleanFromJSON(object.public) : 0,
    };
  },

  toJSON(message: CreateAssetRequest): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.public !== undefined && (obj.public = booleanToJSON(message.public));
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAssetRequest>, I>>(base?: I): CreateAssetRequest {
    return CreateAssetRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateAssetRequest>, I>>(object: I): CreateAssetRequest {
    const message = createBaseCreateAssetRequest();
    message.uuid = object.uuid ?? "";
    message.public = object.public ?? 0;
    return message;
  },
};

function createBaseCreateAssetResponse(): CreateAssetResponse {
  return { uploadUrl: "", downloadUrl: "" };
}

export const CreateAssetResponse = {
  encode(message: CreateAssetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uploadUrl !== "") {
      writer.uint32(10).string(message.uploadUrl);
    }
    if (message.downloadUrl !== "") {
      writer.uint32(18).string(message.downloadUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAssetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAssetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uploadUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.downloadUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAssetResponse {
    return {
      uploadUrl: isSet(object.upload_url) ? String(object.upload_url) : "",
      downloadUrl: isSet(object.download_url) ? String(object.download_url) : "",
    };
  },

  toJSON(message: CreateAssetResponse): unknown {
    const obj: any = {};
    message.uploadUrl !== undefined && (obj.upload_url = message.uploadUrl);
    message.downloadUrl !== undefined && (obj.download_url = message.downloadUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAssetResponse>, I>>(base?: I): CreateAssetResponse {
    return CreateAssetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateAssetResponse>, I>>(object: I): CreateAssetResponse {
    const message = createBaseCreateAssetResponse();
    message.uploadUrl = object.uploadUrl ?? "";
    message.downloadUrl = object.downloadUrl ?? "";
    return message;
  },
};

function createBaseGetAssetRequest(): GetAssetRequest {
  return { uri: "" };
}

export const GetAssetRequest = {
  encode(message: GetAssetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uri !== "") {
      writer.uint32(10).string(message.uri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAssetRequest {
    return { uri: isSet(object.uri) ? String(object.uri) : "" };
  },

  toJSON(message: GetAssetRequest): unknown {
    const obj: any = {};
    message.uri !== undefined && (obj.uri = message.uri);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAssetRequest>, I>>(base?: I): GetAssetRequest {
    return GetAssetRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAssetRequest>, I>>(object: I): GetAssetRequest {
    const message = createBaseGetAssetRequest();
    message.uri = object.uri ?? "";
    return message;
  },
};

function createBaseGetAssetResponse(): GetAssetResponse {
  return { downloadUrl: "", public: 0 };
}

export const GetAssetResponse = {
  encode(message: GetAssetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.downloadUrl !== "") {
      writer.uint32(10).string(message.downloadUrl);
    }
    if (message.public !== 0) {
      writer.uint32(16).int32(message.public);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.downloadUrl = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.public = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAssetResponse {
    return {
      downloadUrl: isSet(object.download_url) ? String(object.download_url) : "",
      public: isSet(object.public) ? booleanFromJSON(object.public) : 0,
    };
  },

  toJSON(message: GetAssetResponse): unknown {
    const obj: any = {};
    message.downloadUrl !== undefined && (obj.download_url = message.downloadUrl);
    message.public !== undefined && (obj.public = booleanToJSON(message.public));
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAssetResponse>, I>>(base?: I): GetAssetResponse {
    return GetAssetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAssetResponse>, I>>(object: I): GetAssetResponse {
    const message = createBaseGetAssetResponse();
    message.downloadUrl = object.downloadUrl ?? "";
    message.public = object.public ?? 0;
    return message;
  },
};

function createBaseEditAssetRequest(): EditAssetRequest {
  return { uri: "" };
}

export const EditAssetRequest = {
  encode(message: EditAssetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uri !== "") {
      writer.uint32(10).string(message.uri);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditAssetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditAssetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uri = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditAssetRequest {
    return { uri: isSet(object.uri) ? String(object.uri) : "" };
  },

  toJSON(message: EditAssetRequest): unknown {
    const obj: any = {};
    message.uri !== undefined && (obj.uri = message.uri);
    return obj;
  },

  create<I extends Exact<DeepPartial<EditAssetRequest>, I>>(base?: I): EditAssetRequest {
    return EditAssetRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditAssetRequest>, I>>(object: I): EditAssetRequest {
    const message = createBaseEditAssetRequest();
    message.uri = object.uri ?? "";
    return message;
  },
};

function createBaseEditAssetResponse(): EditAssetResponse {
  return { uploadUrl: "" };
}

export const EditAssetResponse = {
  encode(message: EditAssetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uploadUrl !== "") {
      writer.uint32(10).string(message.uploadUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditAssetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditAssetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uploadUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EditAssetResponse {
    return { uploadUrl: isSet(object.upload_url) ? String(object.upload_url) : "" };
  },

  toJSON(message: EditAssetResponse): unknown {
    const obj: any = {};
    message.uploadUrl !== undefined && (obj.upload_url = message.uploadUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<EditAssetResponse>, I>>(base?: I): EditAssetResponse {
    return EditAssetResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EditAssetResponse>, I>>(object: I): EditAssetResponse {
    const message = createBaseEditAssetResponse();
    message.uploadUrl = object.uploadUrl ?? "";
    return message;
  },
};

export interface MorpheusService {
  CreateAsset(request: CreateAssetRequest): Promise<CreateAssetResponse>;
  GetAsset(request: GetAssetRequest): Promise<GetAssetResponse>;
  EditAsset(request: EditAssetRequest): Promise<EditAssetResponse>;
}

export class MorpheusServiceClientImpl implements MorpheusService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "morpheus.MorpheusService";
    this.rpc = rpc;
    this.CreateAsset = this.CreateAsset.bind(this);
    this.GetAsset = this.GetAsset.bind(this);
    this.EditAsset = this.EditAsset.bind(this);
  }
  CreateAsset(request: CreateAssetRequest): Promise<CreateAssetResponse> {
    const data = CreateAssetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "CreateAsset", data);
    return promise.then((data) => CreateAssetResponse.decode(_m0.Reader.create(data)));
  }

  GetAsset(request: GetAssetRequest): Promise<GetAssetResponse> {
    const data = GetAssetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "GetAsset", data);
    return promise.then((data) => GetAssetResponse.decode(_m0.Reader.create(data)));
  }

  EditAsset(request: EditAssetRequest): Promise<EditAssetResponse> {
    const data = EditAssetRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "EditAsset", data);
    return promise.then((data) => EditAssetResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
