import { useMutation, useQueryClient } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import { UserModel } from '../../reffy-pb/artemis-svc/pkg/pb/artemis';
import {
    KratosContinueWithProvider,
    KratosProviderGoogle,
} from '../../reffy-pb/olympus/pkg/services/kratos/pb/kratos';

export function useGoogleMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (params: { requestCode: string; scope: string }) => {
            return new Promise<UserModel | string>(async (resolve, reject) => {
                const request = KratosContinueWithProvider.create({
                    google: KratosProviderGoogle.create({
                        code: params.requestCode,
                        scope: params.scope,
                    }),
                });

                const response = await genericHandler(
                    'POST',
                    'include',
                    '/kratos/provider',
                    KratosContinueWithProvider.toJSON(request) as Object
                );

                if (!response || response.error) {
                    console.error(response.msg);
                    reject(response.error);
                } else {
                    // force re-fetch of user
                    queryClient.invalidateQueries(['user-authenticated']);
                    resolve(UserModel.fromJSON(response.payload.user));
                }
            });
        },
    });
}
