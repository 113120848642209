import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../components/form/ReffyForm';
import Loading from '../../components/loading/Loading';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useKratosResetPasswordMutation } from '@cosmogony/common/reffy-hooks/kratos/kratos_hooks';
import { KratosResetPasswordRequest } from '@cosmogony/common/reffy-pb/olympus/pkg/services/kratos/pb/kratos';
import { FeedbackPopupMessage } from '../../components/notification/FeedbackPopup';

export default function ResetPassword() {
    // @ts-ignore: We get id from URL params
    const params = new URLSearchParams(window.location.search);
    const uuid = params.get('id') || '';
    const id = params.get('vs') || '';
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const reset = useKratosResetPasswordMutation();

    if (reset.isSuccess) {
        const feedbackPopup: FeedbackPopupMessage = {
            isSuccess: true,
            message: 'Your password has been reset!',
        };
        navigate('/login', { state: feedbackPopup });
    }

    const form: ReffyView = {
        name: 'rp',
        category: 'rp',
        subcategory: '',
        repeat: false,
        inputs: [
            {
                name: 'password',
                title: '',
                fieldTitle: 'Password',
                fieldType: ReffyFieldType.Hidden,
                isRequired: true,
                repeat: false,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.RegexMatch,
                        params: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        errMsg: 'Invalid Password: must be >8 characters long, at least one uppercase, one lowercase, one number, and one special character',
                    },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                        errMsg: 'Invaild Password: too long',
                    },
                ],
            },
            {
                name: 'confirm-password',
                title: '',
                fieldTitle: 'Confirm Password',
                fieldType: ReffyFieldType.Hidden,
                isRequired: true,
                repeat: false,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.RegexMatch,
                        params: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        errMsg: 'Invalid Password: must be at >8 characters, at least one uppercase, one lowercase, one number, and one special character',
                    },
                    { type: ReffyFormikValidationType.MatchPrevious },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                        errMsg: 'Invaild Password: too long',
                    },
                ],
            },
        ],
    };

    const view = [form];
    const initialValues = GenerateReffyFormOptions(view);

    return (
        <div className="signup-page">
            <div className="signup-container">
                <div
                    className="signup-form"
                    style={{
                        maxHeight: isMobile ? '100%' : '750px',
                        maxWidth: isMobile ? '100%' : '400px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            alt="logo"
                            className="intake-logo"
                            src="/assets/images/logos/reffy_logo.svg"
                        />
                        <Typography
                            color={'#999999'}
                            fontStyle="italic"
                            fontSize={{ md: '18px', sm: '14px' }}
                        >
                            Reference letters made easy
                        </Typography>
                    </div>
                    <Typography
                        color={'#444444'}
                        fontSize={{ md: '18px', sm: '14px' }}
                    >
                        Set Your New Password
                    </Typography>

                    {reset.isError && (
                        <Typography className="login-err">{`Error: ${reset.error}`}</Typography>
                    )}
                    {reset.isLoading && <Loading />}
                    <div style={{ width: '100%', flexGrow: 1 }}>
                        {!reset.isLoading && (
                            <ReffyForm
                                initialValues={initialValues}
                                views={view}
                                onSubmit={(results) =>
                                    reset.mutate(
                                        KratosResetPasswordRequest.create({
                                            identifier: uuid,
                                            secret: id,
                                            newPassword:
                                                results['rp'][0]['password'],
                                        })
                                    )
                                }
                                padding="0px"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
