import {
    ReffyField,
    ReffyFieldType,
    ReffyFormikValidationType,
    ReffyView,
} from '../../components/form/ReffyForm';
import { Typography, Link } from '@mui/material';
import { UserModel } from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';

export default function getForm(user: UserModel | undefined): Array<ReffyView> {
    return [
        getScreen2(),
        getApplicationType(),
        getSchoolScreen(),
        getEmploymentScreen(),
        getOtherScreen(),
        getRelationshipBasics(),
        getTeacherScreen(),
        getMentorScreen(),
        getAthleticCoachScreen(),
        getSpiritualScreen(),
        getOtherRelationshipScreen(),
        getStrengthAndSkills(),
        getEducationScreen(),
        getProfessionalExperience(),
        getProfessionalExperienceManual(),
        getProfessionalExperienceUploadResume(),
        getInterviewQuestions(user),
        submitFormScreen(),
    ];
    //      	0				 1						  2					   3					  4					 5					6					7				 8						9						10						  11						12						13							14								15											16					17
}

function getScreen2(): ReffyView {
    const view: ReffyView = {
        name: 'Contact Info',
        category: 'Contact Info',
        subcategory: 'Please tell me a bit about yourself.',
        inputs: [
            {
                title: 'Name (First Last):',
                name: '1,1',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.RegexMatch,
                        // https://stackoverflow.com/questions/3073850/javascript-regex-test-peoples-name
                        params: /^(([A-Za-z]+[-']?)*([A-Za-z]+)?\s)+([A-Za-z]+[-']?)*([A-Za-z]+)?$/,
                        errMsg: 'Please use this format: Firstname Lastname',
                    },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                        errMsg: 'The name is too long.',
                    },
                ],
            },
            {
                name: '1,2',
                title: 'Email address:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.RegexMatch,
                        // email regex
                        params: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        errMsg: 'Please use this format: email@domain.com',
                    },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 62,
                        errMsg: 'The email is too long.',
                    },
                ],
            },
            {
                name: '1,3',
                title: 'Phone number:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: false,
                repeat: false,
                category: 'O',
                validate: [
                    {
                        type: ReffyFormikValidationType.RegexMatch,
                        // email regex
                        params: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        errMsg: 'Please use this format: 000-000-0000',
                    },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 15,
                        errMsg: 'The phone number is too long.',
                    },
                ],
            },
            {
                name: '1,4',
                title: 'Pronouns:',
                fieldTitle: '',
                fieldType: ReffyFieldType.DropDown,
                isRequired: true,
                repeat: false,
                category: 'S',
                options: [
                    'She/Her',
                    'He/Him',
                    'They/Them',
                    'Prefer not to say',
                    'Other',
                ],
                validate: [
                    {
                        type: ReffyFormikValidationType.Required,
                    },
                ],
            },
            {
                name: '1,5',
                title: 'Please upload your profile picture.',
                fieldTitle: 'Profile Picture',
                fieldType: ReffyFieldType.Image,
                isRequired: true,
                repeat: false,
                category: 'Docs',
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '1,6',
                title: 'In order to help me understand why you are requesting a letter, please describe yourself in two sentences or less. For example, “I am a student athlete who is passionate about helping people recover from serious injuries. I am applying for college in order to become a physical therapist or sports psychologist.” ',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'S',
                initBoxSize: 'medium',
                controlNext: (_: any, _2: number): number => {
                    return 1;
                },
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 350,
                    },
                ],
            },
        ],
        repeat: false,
    };

    return view;
}

function getApplicationType(): ReffyView {
    const view: ReffyView = {
        name: 'Application Type',
        category: 'Application Type',
        subcategory: 'Application Type',
        inputs: [
            {
                name: '2,1',
                title: 'What type of applications are you working on?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Radio2,
                isRequired: true,
                category: 'S',
                options: ['Education', 'Job applications', 'Other'],
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
                controlNext: (value: any, _: number): number => {
                    switch (value) {
                        case 'Education':
                            return 2;
                        case 'Job applications':
                            return 3;
                        default:
                            return 4;
                    }
                },
            },
            {
                name: '2,2',
                title: 'What are your personal or professional goals, and how will this application(s) help you achieve those goals?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'S',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1000,
                    },
                ],
            },
            {
                name: '2,3',
                title: 'In which ways am I qualified to serve as your reference or recommender?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
                category: 'S',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 350,
                    },
                ],
            },
        ],
        repeat: false,
    };
    return view;
}

function getTeacherScreen(): ReffyView {
    const view: ReffyView = {
        name: 'RelationshipT',
        category: 'Relationship 2T',
        subcategory: '',
        inputs: [
            {
                name: '3,1',
                title: 'Which of my classes have you taken? (List the course title(s), semester/year, and your final grade for each course.)',
                fieldTitle: 'Class Name - Semester - Grade',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'R',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },

            {
                name: '3,2',
                title: 'Are there any highlights or noteworthy accomplishments from your time as my student that you would like me to mention in my letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'R',
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1000,
                    },
                ],
                controlNext: (_: any, _2: number): number => {
                    return 11;
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getOtherRelationshipScreen(): ReffyView {
    const view: ReffyView = {
        name: 'RelationshipO',
        category: 'RelationshipO',
        subcategory: '',
        inputs: [
            {
                name: '4,1',
                title: 'How would you describe our relationship, and why do you think I am a good person to write a reference letter on your behalf?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },
            {
                name: '4,2',
                title: "What are some specific examples of achievements or activities you've completed either directly with me or under my supervision that demonstrate your qualifications for this opportunity?",
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 2500,
                    },
                ],
                controlNext: (_: any, _2: number): number => {
                    return 11;
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getAthleticCoachScreen(): ReffyView {
    const view: ReffyView = {
        name: 'RelationshipC',
        category: 'Relationship 2C',
        subcategory: '',
        inputs: [
            {
                name: '5,1',
                title: 'In which sports have I coached you? Please list the sport, your years of participation, and the level of play (e.g. varsity, intramural, etc.)',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'R',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },
            {
                name: '5,2',
                title: 'Are there any highlights or noteworthy accomplishments from the time that I’ve coached you that you would like me to mention in my letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'R',
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
                controlNext: (_: any, _2: number): number => {
                    return 11;
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getSpiritualScreen(): ReffyView {
    const view: ReffyView = {
        name: 'RelationshipS',
        category: 'RelationshipS',
        subcategory: '',
        inputs: [
            {
                name: '6,1',
                title: 'How long have you been involved in our religious community, and how have you participated in various activities?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                category: 'R',
                repeat: false,
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },
            {
                name: '6,2',
                title: 'Are there any highlights or noteworthy accomplishments from the time that I’ve known you that you would like me to mention in my letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1000,
                    },
                ],
                controlNext: (_: any, _2: number): number => {
                    return 11;
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getMentorScreen(): ReffyView {
    const view: ReffyView = {
        name: 'RelationshipM',
        category: 'RelationshipM',
        subcategory: '',
        inputs: [
            {
                name: '7,1',
                title: 'How have I been a mentor to you?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'R',
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 700,
                    },
                ],
            },

            {
                name: '7,2',
                title: 'What are some key achievements or standout moments from our mentor-mentee relationship that I can include in my recommendation letter for you?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                category: 'Q',
                repeat: false,
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1000,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 11;
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getSchoolScreen(): ReffyView {
    const view: ReffyView = {
        name: 'Application InfoG',
        category: 'Application InfoG',
        subcategory: 'Which schools are you applying to?',
        inputs: [
            {
                name: '8,1',
                title: 'Enter the name of the school or centralized application system to which you are applying. Be sure to spell correctly and avoid abbreviations.',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '8,8',
                title: 'Is this application for one specific school, or for multiple schools through a centralized application system (e.g. the Common App, LSAC, AMCAS, etc.)?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Radio2,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
                options: [
                    'One school',
                    'Multiple schools / centralized application system',
                ],
            },
            {
                name: '8,2',
                title: 'Website:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '8,3',
                title: 'Expected degree or certification:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '8,4',
                title: 'Expected major or focus of studies',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'Q',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '8,5',
                title: 'How will I submit my recommendation letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Radio2,
                isRequired: true,
                category: 'S+AD',
                options: [
                    'This school will email you a request and instructions for submitting your letter. Please check your inbox.',
                    'Other',
                ],
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '8,6',
                title: 'Letter due-date:',
                fieldTitle: '',
                fieldType: ReffyFieldType.DatePicker,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '8,7',
                title: 'When writing this recommendation letter, what are the most important details for me to know about your application and/or qualifications for this school(s)?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                category: 'S+AD',
                repeat: false,
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 5;
                },
            },
        ],

        repeat: true,
    };
    return view;
}

function getEmploymentScreen(): ReffyView {
    const view: ReffyView = {
        name: 'Application InfoE',
        category: 'Application InfoE',
        subcategory: 'Where are you applying? ',
        inputs: [
            {
                name: '9,1',
                title: 'Business or organization name: ',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '9,2',
                title: 'Business website:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '9,3',
                title: 'Job title or role:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                category: 'AD',
                repeat: false,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '9,4',
                title: 'Job description: ',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                category: 'AD',
                repeat: false,
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 2500,
                    },
                ],
            },
            {
                name: '9,5',
                title: 'How will I submit my reference letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Radio2,
                isRequired: true,
                category: 'S+AD',
                options: [
                    'You will receive an email request or prompt from the company or organization',
                    'Other',
                ],
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '9,6',
                title: 'What type of reference do you need me to provide for this application?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Radio2,
                isRequired: true,
                category: 'S+AD',
                options: [
                    'Letter of recommendation',
                    'Complete an online reference form',
                    'Be listed as a reference who may be contacted during the application process',
                    'Other',
                ],
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '9,7',
                title: 'Letter due date or desired completion date:',
                fieldTitle: '',
                fieldType: ReffyFieldType.DatePicker,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '9,9',
                title: 'In which ways are you well qualified for this opportunity?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1000,
                    },
                ],
            },
            {
                name: '9,8',
                title: 'Is there anything else that you would like me to know about this application?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 5;
                },
            },
        ],
        repeat: true,
    };
    return view;
}
function getOtherScreen(): ReffyView {
    const view: ReffyView = {
        name: 'Application InfoO',
        category: 'Application Info',
        subcategory: 'Which applications or opportunities are you pursuing?',
        inputs: [
            {
                name: '10,1',
                title: 'What type of application is this?',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '10,2',
                title: 'Which goal or opportunity are you applying for?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },
            {
                name: '10,3',
                title: 'Which information or details do you want me to know about this application or opportunity?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
            },
            {
                name: '10,4',
                title: 'How will I submit my reference letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                category: 'S+AD',
                repeat: false,
                initBoxSize: 'small',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 150,
                    },
                ],
            },
            {
                name: '10,5',
                title: 'Letter due date or desired completion date:',
                fieldTitle: '',
                fieldType: ReffyFieldType.DatePicker,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '10,6',
                title: 'Is there anything else that you would like me to know about this application?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
            },
        ],
        repeat: true,
    };
    return view;
}

function getStrengthAndSkills(): ReffyView {
    const view: ReffyView = {
        name: 'Strength and Skills',
        category: 'Strength and Skills',
        subcategory: 'Help me write the best letter possible.',
        inputs: [
            {
                name: '11,1',
                title: 'Which of your personal strengths, skills, or character traits would you like me to emphasize in my letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },
            {
                name: '11,2',
                title: 'Can you think of any specific examples or times when you demonstrated these skills and strengths during the time we’ve known each other?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'large',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1250,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 12;
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getEducationScreen(): ReffyView {
    const view: ReffyView = {
        name: 'Education',
        category: 'Education',
        subcategory:
            'Please tell me about your education. Which schools, certifications, or degree programs have you attended or graduated from?',
        inputs: [
            {
                name: '12,1',
                title: 'School name:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '12,2',
                title: 'Years of attendance:',
                fieldTitle: 'e.g. 2016-2020',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '12,3',
                title: 'GPA:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '12,7',
                title: 'Degree earned/expected (degree type, year)',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '12,4',
                title: 'Major or primary focus (if applicable):',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '12,5',
                title: 'Extracurricular Activities:',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
            },
            {
                name: '12,6',
                title: 'Awards and Achievements:',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 13;
                },
            },
        ],
        repeat: true,
    };
    return view;
}

function getProfessionalExperience(): ReffyView {
    const view: ReffyView = {
        name: 'Professional Experience',
        category: 'Professional Experience',
        subcategory: 'Do you have relevant work or volunteer experience?',
        inputs: [
            {
                name: '13,1',
                title: 'If so, please tell me about your professional background and qualifications.',
                fieldTitle: '',
                fieldType: ReffyFieldType.Radio2,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                options: [
                    'Enter my professional experience manually',
                    'Upload my résumé or LinkedIn profile',
                    'Skip this step.',
                ],
                validate: [{ type: ReffyFormikValidationType.Required }],
                controlNext: (value: any, _: number): number => {
                    switch (value) {
                        case 'Enter my professional experience manually':
                            return 14;
                        case 'Upload my résumé or LinkedIn profile':
                            return 15;
                        case 'Skip this step. I do not wish to provide information about my professional experience.':
                            return 16;
                        default:
                            return 16;
                    }
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getProfessionalExperienceManual(): ReffyView {
    const view: ReffyView = {
        name: 'Professional ExperienceM',
        category: 'Professional Experience',
        subcategory: 'Where did you work or volunteer?',
        inputs: [
            {
                name: '14,1',
                title: 'Name of business or organization:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '14,2',
                title: 'Dates of employment:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '14,3',
                title: 'Job title/role:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 100,
                    },
                ],
            },
            {
                name: '14,4',
                title: 'Responsibilities:',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
            },
            {
                name: '14,5',
                title: 'Skills used: ',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },
            {
                name: '14,6',
                title: 'Noteworthy achievements: ',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 16;
                },
            },
        ],
        repeat: true,
    };
    return view;
}

function getProfessionalExperienceUploadResume(): ReffyView {
    const view: ReffyView = {
        name: 'Professional ExperienceR',
        category: 'Professional ExperienceR',
        subcategory:
            'Please provide a link to your LinkedIn profile and/or a copy of your résumé.',
        inputs: [
            {
                name: '15,1',
                title: 'LinkedIn Profile link:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 200,
                    },
                    {
                        type: ReffyFormikValidationType.RegexMatch,
                        // email regex
                        params: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
                        errMsg: 'Please use this format: https://www.linkedin.com/in/username/',
                    },
                ],
            },
            {
                name: '15,2',
                title: 'Upload résumé or CV ',
                fieldTitle: 'Resume',
                fieldType: ReffyFieldType.File,
                options: [
                    'application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ],
                isRequired: false,
                repeat: false,
                category: 'Docs',
                // validate: [
                // 	{ type: ReffyFormikValidationType.None }
                // ]
            },
            {
                name: '15,3',
                title: 'What is the most important thing(s) for me to know about your professional background?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
            },
            {
                name: '15,4',
                title: 'Is there anything else you want to tell me about your work experience?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
                category: 'S+AD',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 16;
                },
            },
        ],
        repeat: false,
    };
    return view;
}

function getInterviewQuestions(user: UserModel | undefined): ReffyView {
    const view: ReffyView = {
        name: 'Interview Questions',
        category: 'Interview Questions',
        subcategory:
            'Just a few more questions to help me write a more personalized letter. ',
        inputs: [
            {
                name: '16,2',
                title: 'Is there anything else you would like me to know about your goals, applications, or qualifications before you submit this form?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: false,
                category: 'Q',
                initBoxSize: 'medium',
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
                controlNext: (_: any, _a: number): number => {
                    return 17;
                },
            },
        ],
        repeat: false,
    };

    if (user && (user['user_subscription'] ?? 1) > 1) {
        const questions: ReffyField[] = [];
        let i = 0;

        // logic depends that i < 3; should only have 3 custom questions
        for (const question of user['additional_questions']) {
            if (question) {
                questions.push({
                    name: `99,${i}`,
                    title: question,
                    fieldTitle: '',
                    fieldType: ReffyFieldType.Paragraph,
                    isRequired: true,
                    repeat: false,
                    category: 'S+AD',
                    initBoxSize: 'large',
                    validate: [
                        { type: ReffyFormikValidationType.Required },
                        {
                            type: ReffyFormikValidationType.LengthMax,
                            params: 400,
                        },
                    ],
                });
            }

            i += 1;
        }

        // logic depends that i < 3; should only have 3 custom questions
        for (const question of user['additional_documents']) {
            if (question) {
                const isFileUpload =
                    question !==
                    'URL/link to a shared cloud drive with art/work portfolio';
                questions.push({
                    name: `99,${i}`,
                    title: question,
                    options: !isFileUpload
                        ? []
                        : [
                              'image/*, application/pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          ],
                    fieldTitle: '',
                    fieldType: !isFileUpload
                        ? ReffyFieldType.ShortAnswer
                        : ReffyFieldType.File,
                    isRequired: true,
                    repeat: false,
                    category: 'S+AD',
                    validate: [
                        !isFileUpload
                            ? { type: ReffyFormikValidationType.None }
                            : {
                                  type: ReffyFormikValidationType.LimitUploadSize,
                                  params: 10000,
                              },
                    ],
                });
            }
            i += 1;
        }

        view.inputs = [...view.inputs, ...questions];
    }

    return view;
}

function submitFormScreen(): ReffyView {
    const view: ReffyView = {
        name: 'Submit',
        category: 'Submit',
        subcategory: 'That’s everything! Are you ready to submit your request?',
        inputs: [
            {
                name: '18,1',
                title: '',
                fieldTitle: '',
                fieldType: ReffyFieldType.ConfirmCheck,
                isRequired: true,
                category: 'O',
                options: [
                    'Do you affirm that the information you’ve provided is accurate?',
                ],
                validate: [{ type: ReffyFormikValidationType.Required }],
                repeat: false,
            },
            {
                name: '18,2',
                title: '',
                fieldTitle: '',
                fieldType: ReffyFieldType.ConfirmCheck,
                isRequired: false,
                category: 'O',
                options: [
                    'Would you like to stay informed about the progress of your request and receive information about opportunities, products, or services that align with your goals?',
                ],
                validate: [],
                repeat: false,
            },
            {
                name: '18,3',
                title: '',
                fieldTitle: '',
                fieldType: ReffyFieldType.Custom,
                isRequired: false,
                category: 'O',
                UI: () => {
                    return (
                        <div
                            style={{
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                color={'#c9c9c9'}
                                fontSize={{ xs: '14px', md: '17px' }}
                            >
                                {'Click '}
                                <Link
                                    href="/terms-of-service"
                                    target="_blank"
                                    color="primary"
                                >
                                    here
                                </Link>
                                {
                                    ' to learn more about our Privacy Policy and Terms of Service. '
                                }
                            </Typography>
                        </div>
                    );
                },
                repeat: false,
            },
        ],
        repeat: false,
    };
    return view;
}

function getRelationshipBasics(): ReffyView {
    const view: ReffyView = {
        name: 'Relationship',
        category: 'Relationship',
        subcategory:
            'Help me to include relevant details about our relationship in my letter.',
        inputs: [
            {
                name: '17,1',
                title: 'What is my primary relationship to you?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Radio2,
                isRequired: true,
                category: 'R',
                options: [
                    'Teacher',
                    'Professor',
                    'Mentor',
                    'Athletic Coach',
                    'Spiritual or religious leader',
                    'Other',
                ],
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
                controlNext: (value: any, _: number): number => {
                    switch (value) {
                        case 'Teacher':
                            return 6;
                        case 'Professor':
                            return 6;
                        case 'Mentor':
                            return 7;
                        case 'Athletic Coach':
                            return 8;
                        case 'Spiritual or religious leader':
                            return 9;
                        default:
                            return 10;
                    }
                },
            },
            {
                name: '17,2',
                title: 'When did we first meet each other? (Year)',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                category: 'R',
                repeat: false,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.RegexMatch,
                        params: /\d{4}/,
                        errMsg: 'We are looking for a year (2023)',
                    },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 500,
                    },
                ],
            },
            {
                name: '17,3',
                title: 'How did we meet?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                category: 'R',
                repeat: false,
                initBoxSize: 'medium',
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1000,
                    },
                ],
            },
        ],
        repeat: false,
    };
    return view;
}
