import { useMutation, useQuery } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import {
    WriterCheckoutRequest,
    GetWriterSubscriptionRequest,
    WriterSubscriptionModel,
} from '../../reffy-pb/plutus-svc/pkg/pb/plutus';

export function useCheckoutRequestMutation() {
    return useMutation({
        mutationFn: async (req: WriterCheckoutRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/plutus/billing/checkout',
                    WriterCheckoutRequest.toJSON(req) as Object
                );
                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

export function useCancelSubscriptionMutation() {
    return useMutation({
        mutationFn: async (req: GetWriterSubscriptionRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/plutus/billing/cancel',
                    GetWriterSubscriptionRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    const subscription = WriterSubscriptionModel.fromJSON(
                        response.payload
                    );
                    resolve(subscription);
                }
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

// Get's a SubscriptionModel using either the authenticated JWT or provided identifier
export function useWriterSubscriptionQuery(identifier?: string) {
    const key = identifier ? `wuuid=${identifier}` : `writer_request=true`;
    return useQuery({
        queryKey: [key],
        queryFn: async () => {
            return new Promise<WriterSubscriptionModel>(
                async (resolve, reject) => {
                    const response = await genericHandler(
                        'GET',
                        'include',
                        `/plutus/billing?${key}`
                    );

                    if (!response || response.error) {
                        reject(
                            response?.payload ?? 'unable to fulfill request'
                        );
                    } else {
                        const subscription = WriterSubscriptionModel.fromJSON(
                            response.payload
                        );
                        resolve(subscription);
                    }
                }
            );
        },

        retry: 0,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,

        onError: (err) => {
            console.error(err);
        },
    });
}
