import './Loading.css';
import { CircularProgress } from '@mui/material';

export default function Loading() {
    return (
        <div className="loading-box">
            <img
                className="loading-logo"
                src="/assets/images/logos/reffy_logo.svg"
                alt="Logo"
            />
            <CircularProgress
                style={{ width: '100px', height: '100px' }}
                className="loading-spinner"
            />
        </div>
    );
}
