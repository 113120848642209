/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "chronos";

export enum ServiceOwner {
  SERVICE_OWNER_UNSPECIFIED = 0,
  SERVICE_OWNER_REFBUILDER = 1,
  SERVICE_OWNER_BRAGSHEET = 2,
  UNRECOGNIZED = -1,
}

export function serviceOwnerFromJSON(object: any): ServiceOwner {
  switch (object) {
    case 0:
    case "SERVICE_OWNER_UNSPECIFIED":
      return ServiceOwner.SERVICE_OWNER_UNSPECIFIED;
    case 1:
    case "SERVICE_OWNER_REFBUILDER":
      return ServiceOwner.SERVICE_OWNER_REFBUILDER;
    case 2:
    case "SERVICE_OWNER_BRAGSHEET":
      return ServiceOwner.SERVICE_OWNER_BRAGSHEET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ServiceOwner.UNRECOGNIZED;
  }
}

export function serviceOwnerToJSON(object: ServiceOwner): number {
  switch (object) {
    case ServiceOwner.SERVICE_OWNER_UNSPECIFIED:
      return 0;
    case ServiceOwner.SERVICE_OWNER_REFBUILDER:
      return 1;
    case ServiceOwner.SERVICE_OWNER_BRAGSHEET:
      return 2;
    case ServiceOwner.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface NotificationResponse {
}

export interface NotificationRequest {
  owner: ServiceOwner;
  to: string[];
  createUser?: CreateUserNotificationRequest | undefined;
  verifyUser?: VerifyUserNotificationRequest | undefined;
  forgotPassword?: ForgotPasswordNotificationRequest | undefined;
  createWriter?: CreateWriterNotificationRequest | undefined;
  newApplication?: NewApplicationNotificationRequest | undefined;
  inviteApplicant?: InviteApplicantNotificationRequest | undefined;
  supportForm?: SupportFormNotificationRequest | undefined;
  organizationForm?: OrganizationFormNotificationRequest | undefined;
  subscriptionCreated?: SubscriptionCreatedNotificationRequest | undefined;
  subscriptionRenewingSoon?: SubscriptionRenewingSoonNotificationRequest | undefined;
  subscriptionEnded?: SubscriptionEndedNotificationRequest | undefined;
  createEnterpriseUser?: CreateEnterpriseUserNotificationRequest | undefined;
  declineRequest?: DeclineRequestNotificationRequest | undefined;
}

export interface CreateUserNotificationRequest {
}

export interface VerifyUserNotificationRequest {
  code: string;
}

export interface ForgotPasswordNotificationRequest {
  code: string;
  name: string;
  link: string;
}

export interface InviteApplicantNotificationRequest {
  name: string;
  link: string;
}

export interface SupportFormNotificationRequest {
  time: string;
  name: string;
  email: string;
  question: string;
}

export interface OrganizationFormNotificationRequest {
  time: string;
  name: string;
  email: string;
  phoneNumber: string;
  organizationInfo: string;
  question: string;
}

export interface CreateWriterNotificationRequest {
  name: string;
}

export interface NewApplicationNotificationRequest {
  writerName: string;
  applicantName: string;
  link: string;
  target: string;
}

/** Olympus route request model */
export interface InviteApplicantRequest {
  to: string;
  name: string;
  username: string;
}

export interface SupportFormRequest {
  name: string;
  email: string;
  question: string;
}

export interface OrganizationFormRequest {
  name: string;
  email: string;
  phoneNumber: string;
  organizationInfo: string;
  question: string;
}

export interface SubscriptionCreatedNotificationRequest {
  name: string;
  receipt: string;
}

export interface SubscriptionRenewingSoonNotificationRequest {
  name: string;
  price: string;
}

export interface SubscriptionEndedNotificationRequest {
  name: string;
}

export interface CreateEnterpriseUserNotificationRequest {
  enterpriseName: string;
  email: string;
  name: string;
  password: string;
  ownerName: string;
  adminName: string;
  adminEmail: string;
}

export interface DeclineRequestNotificationRequest {
  writerName: string;
  applicantName: string;
  applyingTo: string;
  writerMessage: string;
}

function createBaseNotificationResponse(): NotificationResponse {
  return {};
}

export const NotificationResponse = {
  encode(_: NotificationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): NotificationResponse {
    return {};
  },

  toJSON(_: NotificationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationResponse>, I>>(base?: I): NotificationResponse {
    return NotificationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotificationResponse>, I>>(_: I): NotificationResponse {
    const message = createBaseNotificationResponse();
    return message;
  },
};

function createBaseNotificationRequest(): NotificationRequest {
  return {
    owner: 0,
    to: [],
    createUser: undefined,
    verifyUser: undefined,
    forgotPassword: undefined,
    createWriter: undefined,
    newApplication: undefined,
    inviteApplicant: undefined,
    supportForm: undefined,
    organizationForm: undefined,
    subscriptionCreated: undefined,
    subscriptionRenewingSoon: undefined,
    subscriptionEnded: undefined,
    createEnterpriseUser: undefined,
    declineRequest: undefined,
  };
}

export const NotificationRequest = {
  encode(message: NotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.owner !== 0) {
      writer.uint32(8).int32(message.owner);
    }
    for (const v of message.to) {
      writer.uint32(18).string(v!);
    }
    if (message.createUser !== undefined) {
      CreateUserNotificationRequest.encode(message.createUser, writer.uint32(26).fork()).ldelim();
    }
    if (message.verifyUser !== undefined) {
      VerifyUserNotificationRequest.encode(message.verifyUser, writer.uint32(34).fork()).ldelim();
    }
    if (message.forgotPassword !== undefined) {
      ForgotPasswordNotificationRequest.encode(message.forgotPassword, writer.uint32(42).fork()).ldelim();
    }
    if (message.createWriter !== undefined) {
      CreateWriterNotificationRequest.encode(message.createWriter, writer.uint32(50).fork()).ldelim();
    }
    if (message.newApplication !== undefined) {
      NewApplicationNotificationRequest.encode(message.newApplication, writer.uint32(58).fork()).ldelim();
    }
    if (message.inviteApplicant !== undefined) {
      InviteApplicantNotificationRequest.encode(message.inviteApplicant, writer.uint32(66).fork()).ldelim();
    }
    if (message.supportForm !== undefined) {
      SupportFormNotificationRequest.encode(message.supportForm, writer.uint32(74).fork()).ldelim();
    }
    if (message.organizationForm !== undefined) {
      OrganizationFormNotificationRequest.encode(message.organizationForm, writer.uint32(82).fork()).ldelim();
    }
    if (message.subscriptionCreated !== undefined) {
      SubscriptionCreatedNotificationRequest.encode(message.subscriptionCreated, writer.uint32(90).fork()).ldelim();
    }
    if (message.subscriptionRenewingSoon !== undefined) {
      SubscriptionRenewingSoonNotificationRequest.encode(message.subscriptionRenewingSoon, writer.uint32(98).fork())
        .ldelim();
    }
    if (message.subscriptionEnded !== undefined) {
      SubscriptionEndedNotificationRequest.encode(message.subscriptionEnded, writer.uint32(106).fork()).ldelim();
    }
    if (message.createEnterpriseUser !== undefined) {
      CreateEnterpriseUserNotificationRequest.encode(message.createEnterpriseUser, writer.uint32(114).fork()).ldelim();
    }
    if (message.declineRequest !== undefined) {
      DeclineRequestNotificationRequest.encode(message.declineRequest, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.owner = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createUser = CreateUserNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.verifyUser = VerifyUserNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.forgotPassword = ForgotPasswordNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createWriter = CreateWriterNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.newApplication = NewApplicationNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.inviteApplicant = InviteApplicantNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.supportForm = SupportFormNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.organizationForm = OrganizationFormNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.subscriptionCreated = SubscriptionCreatedNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.subscriptionRenewingSoon = SubscriptionRenewingSoonNotificationRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.subscriptionEnded = SubscriptionEndedNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.createEnterpriseUser = CreateEnterpriseUserNotificationRequest.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.declineRequest = DeclineRequestNotificationRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationRequest {
    return {
      owner: isSet(object.owner) ? serviceOwnerFromJSON(object.owner) : 0,
      to: Array.isArray(object?.to) ? object.to.map((e: any) => String(e)) : [],
      createUser: isSet(object.create_user) ? CreateUserNotificationRequest.fromJSON(object.create_user) : undefined,
      verifyUser: isSet(object.verify_user) ? VerifyUserNotificationRequest.fromJSON(object.verify_user) : undefined,
      forgotPassword: isSet(object.forgot_password)
        ? ForgotPasswordNotificationRequest.fromJSON(object.forgot_password)
        : undefined,
      createWriter: isSet(object.create_writer)
        ? CreateWriterNotificationRequest.fromJSON(object.create_writer)
        : undefined,
      newApplication: isSet(object.new_application)
        ? NewApplicationNotificationRequest.fromJSON(object.new_application)
        : undefined,
      inviteApplicant: isSet(object.invite_applicant)
        ? InviteApplicantNotificationRequest.fromJSON(object.invite_applicant)
        : undefined,
      supportForm: isSet(object.support_form)
        ? SupportFormNotificationRequest.fromJSON(object.support_form)
        : undefined,
      organizationForm: isSet(object.organization_form)
        ? OrganizationFormNotificationRequest.fromJSON(object.organization_form)
        : undefined,
      subscriptionCreated: isSet(object.subscription_created)
        ? SubscriptionCreatedNotificationRequest.fromJSON(object.subscription_created)
        : undefined,
      subscriptionRenewingSoon: isSet(object.subscription_renewing_soon)
        ? SubscriptionRenewingSoonNotificationRequest.fromJSON(object.subscription_renewing_soon)
        : undefined,
      subscriptionEnded: isSet(object.subscription_ended)
        ? SubscriptionEndedNotificationRequest.fromJSON(object.subscription_ended)
        : undefined,
      createEnterpriseUser: isSet(object.create_enterprise_user)
        ? CreateEnterpriseUserNotificationRequest.fromJSON(object.create_enterprise_user)
        : undefined,
      declineRequest: isSet(object.decline_request)
        ? DeclineRequestNotificationRequest.fromJSON(object.decline_request)
        : undefined,
    };
  },

  toJSON(message: NotificationRequest): unknown {
    const obj: any = {};
    message.owner !== undefined && (obj.owner = serviceOwnerToJSON(message.owner));
    if (message.to) {
      obj.to = message.to.map((e) => e);
    } else {
      obj.to = [];
    }
    message.createUser !== undefined &&
      (obj.create_user = message.createUser ? CreateUserNotificationRequest.toJSON(message.createUser) : undefined);
    message.verifyUser !== undefined &&
      (obj.verify_user = message.verifyUser ? VerifyUserNotificationRequest.toJSON(message.verifyUser) : undefined);
    message.forgotPassword !== undefined && (obj.forgot_password = message.forgotPassword
      ? ForgotPasswordNotificationRequest.toJSON(message.forgotPassword)
      : undefined);
    message.createWriter !== undefined && (obj.create_writer = message.createWriter
      ? CreateWriterNotificationRequest.toJSON(message.createWriter)
      : undefined);
    message.newApplication !== undefined && (obj.new_application = message.newApplication
      ? NewApplicationNotificationRequest.toJSON(message.newApplication)
      : undefined);
    message.inviteApplicant !== undefined && (obj.invite_applicant = message.inviteApplicant
      ? InviteApplicantNotificationRequest.toJSON(message.inviteApplicant)
      : undefined);
    message.supportForm !== undefined &&
      (obj.support_form = message.supportForm ? SupportFormNotificationRequest.toJSON(message.supportForm) : undefined);
    message.organizationForm !== undefined && (obj.organization_form = message.organizationForm
      ? OrganizationFormNotificationRequest.toJSON(message.organizationForm)
      : undefined);
    message.subscriptionCreated !== undefined && (obj.subscription_created = message.subscriptionCreated
      ? SubscriptionCreatedNotificationRequest.toJSON(message.subscriptionCreated)
      : undefined);
    message.subscriptionRenewingSoon !== undefined && (obj.subscription_renewing_soon = message.subscriptionRenewingSoon
      ? SubscriptionRenewingSoonNotificationRequest.toJSON(message.subscriptionRenewingSoon)
      : undefined);
    message.subscriptionEnded !== undefined && (obj.subscription_ended = message.subscriptionEnded
      ? SubscriptionEndedNotificationRequest.toJSON(message.subscriptionEnded)
      : undefined);
    message.createEnterpriseUser !== undefined && (obj.create_enterprise_user = message.createEnterpriseUser
      ? CreateEnterpriseUserNotificationRequest.toJSON(message.createEnterpriseUser)
      : undefined);
    message.declineRequest !== undefined && (obj.decline_request = message.declineRequest
      ? DeclineRequestNotificationRequest.toJSON(message.declineRequest)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<NotificationRequest>, I>>(base?: I): NotificationRequest {
    return NotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NotificationRequest>, I>>(object: I): NotificationRequest {
    const message = createBaseNotificationRequest();
    message.owner = object.owner ?? 0;
    message.to = object.to?.map((e) => e) || [];
    message.createUser = (object.createUser !== undefined && object.createUser !== null)
      ? CreateUserNotificationRequest.fromPartial(object.createUser)
      : undefined;
    message.verifyUser = (object.verifyUser !== undefined && object.verifyUser !== null)
      ? VerifyUserNotificationRequest.fromPartial(object.verifyUser)
      : undefined;
    message.forgotPassword = (object.forgotPassword !== undefined && object.forgotPassword !== null)
      ? ForgotPasswordNotificationRequest.fromPartial(object.forgotPassword)
      : undefined;
    message.createWriter = (object.createWriter !== undefined && object.createWriter !== null)
      ? CreateWriterNotificationRequest.fromPartial(object.createWriter)
      : undefined;
    message.newApplication = (object.newApplication !== undefined && object.newApplication !== null)
      ? NewApplicationNotificationRequest.fromPartial(object.newApplication)
      : undefined;
    message.inviteApplicant = (object.inviteApplicant !== undefined && object.inviteApplicant !== null)
      ? InviteApplicantNotificationRequest.fromPartial(object.inviteApplicant)
      : undefined;
    message.supportForm = (object.supportForm !== undefined && object.supportForm !== null)
      ? SupportFormNotificationRequest.fromPartial(object.supportForm)
      : undefined;
    message.organizationForm = (object.organizationForm !== undefined && object.organizationForm !== null)
      ? OrganizationFormNotificationRequest.fromPartial(object.organizationForm)
      : undefined;
    message.subscriptionCreated = (object.subscriptionCreated !== undefined && object.subscriptionCreated !== null)
      ? SubscriptionCreatedNotificationRequest.fromPartial(object.subscriptionCreated)
      : undefined;
    message.subscriptionRenewingSoon =
      (object.subscriptionRenewingSoon !== undefined && object.subscriptionRenewingSoon !== null)
        ? SubscriptionRenewingSoonNotificationRequest.fromPartial(object.subscriptionRenewingSoon)
        : undefined;
    message.subscriptionEnded = (object.subscriptionEnded !== undefined && object.subscriptionEnded !== null)
      ? SubscriptionEndedNotificationRequest.fromPartial(object.subscriptionEnded)
      : undefined;
    message.createEnterpriseUser = (object.createEnterpriseUser !== undefined && object.createEnterpriseUser !== null)
      ? CreateEnterpriseUserNotificationRequest.fromPartial(object.createEnterpriseUser)
      : undefined;
    message.declineRequest = (object.declineRequest !== undefined && object.declineRequest !== null)
      ? DeclineRequestNotificationRequest.fromPartial(object.declineRequest)
      : undefined;
    return message;
  },
};

function createBaseCreateUserNotificationRequest(): CreateUserNotificationRequest {
  return {};
}

export const CreateUserNotificationRequest = {
  encode(_: CreateUserNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateUserNotificationRequest {
    return {};
  },

  toJSON(_: CreateUserNotificationRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUserNotificationRequest>, I>>(base?: I): CreateUserNotificationRequest {
    return CreateUserNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateUserNotificationRequest>, I>>(_: I): CreateUserNotificationRequest {
    const message = createBaseCreateUserNotificationRequest();
    return message;
  },
};

function createBaseVerifyUserNotificationRequest(): VerifyUserNotificationRequest {
  return { code: "" };
}

export const VerifyUserNotificationRequest = {
  encode(message: VerifyUserNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyUserNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyUserNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VerifyUserNotificationRequest {
    return { code: isSet(object.code) ? String(object.code) : "" };
  },

  toJSON(message: VerifyUserNotificationRequest): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    return obj;
  },

  create<I extends Exact<DeepPartial<VerifyUserNotificationRequest>, I>>(base?: I): VerifyUserNotificationRequest {
    return VerifyUserNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VerifyUserNotificationRequest>, I>>(
    object: I,
  ): VerifyUserNotificationRequest {
    const message = createBaseVerifyUserNotificationRequest();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseForgotPasswordNotificationRequest(): ForgotPasswordNotificationRequest {
  return { code: "", name: "", link: "" };
}

export const ForgotPasswordNotificationRequest = {
  encode(message: ForgotPasswordNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.link !== "") {
      writer.uint32(26).string(message.link);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ForgotPasswordNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseForgotPasswordNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.link = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ForgotPasswordNotificationRequest {
    return {
      code: isSet(object.code) ? String(object.code) : "",
      name: isSet(object.name) ? String(object.name) : "",
      link: isSet(object.link) ? String(object.link) : "",
    };
  },

  toJSON(message: ForgotPasswordNotificationRequest): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.name !== undefined && (obj.name = message.name);
    message.link !== undefined && (obj.link = message.link);
    return obj;
  },

  create<I extends Exact<DeepPartial<ForgotPasswordNotificationRequest>, I>>(
    base?: I,
  ): ForgotPasswordNotificationRequest {
    return ForgotPasswordNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ForgotPasswordNotificationRequest>, I>>(
    object: I,
  ): ForgotPasswordNotificationRequest {
    const message = createBaseForgotPasswordNotificationRequest();
    message.code = object.code ?? "";
    message.name = object.name ?? "";
    message.link = object.link ?? "";
    return message;
  },
};

function createBaseInviteApplicantNotificationRequest(): InviteApplicantNotificationRequest {
  return { name: "", link: "" };
}

export const InviteApplicantNotificationRequest = {
  encode(message: InviteApplicantNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.link !== "") {
      writer.uint32(18).string(message.link);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteApplicantNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteApplicantNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.link = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteApplicantNotificationRequest {
    return { name: isSet(object.name) ? String(object.name) : "", link: isSet(object.link) ? String(object.link) : "" };
  },

  toJSON(message: InviteApplicantNotificationRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.link !== undefined && (obj.link = message.link);
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteApplicantNotificationRequest>, I>>(
    base?: I,
  ): InviteApplicantNotificationRequest {
    return InviteApplicantNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InviteApplicantNotificationRequest>, I>>(
    object: I,
  ): InviteApplicantNotificationRequest {
    const message = createBaseInviteApplicantNotificationRequest();
    message.name = object.name ?? "";
    message.link = object.link ?? "";
    return message;
  },
};

function createBaseSupportFormNotificationRequest(): SupportFormNotificationRequest {
  return { time: "", name: "", email: "", question: "" };
}

export const SupportFormNotificationRequest = {
  encode(message: SupportFormNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== "") {
      writer.uint32(10).string(message.time);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.question !== "") {
      writer.uint32(50).string(message.question);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SupportFormNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupportFormNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.question = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SupportFormNotificationRequest {
    return {
      time: isSet(object.time) ? String(object.time) : "",
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      question: isSet(object.question) ? String(object.question) : "",
    };
  },

  toJSON(message: SupportFormNotificationRequest): unknown {
    const obj: any = {};
    message.time !== undefined && (obj.time = message.time);
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.question !== undefined && (obj.question = message.question);
    return obj;
  },

  create<I extends Exact<DeepPartial<SupportFormNotificationRequest>, I>>(base?: I): SupportFormNotificationRequest {
    return SupportFormNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SupportFormNotificationRequest>, I>>(
    object: I,
  ): SupportFormNotificationRequest {
    const message = createBaseSupportFormNotificationRequest();
    message.time = object.time ?? "";
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.question = object.question ?? "";
    return message;
  },
};

function createBaseOrganizationFormNotificationRequest(): OrganizationFormNotificationRequest {
  return { time: "", name: "", email: "", phoneNumber: "", organizationInfo: "", question: "" };
}

export const OrganizationFormNotificationRequest = {
  encode(message: OrganizationFormNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time !== "") {
      writer.uint32(10).string(message.time);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(34).string(message.phoneNumber);
    }
    if (message.organizationInfo !== "") {
      writer.uint32(42).string(message.organizationInfo);
    }
    if (message.question !== "") {
      writer.uint32(50).string(message.question);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationFormNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationFormNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.time = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.organizationInfo = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.question = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganizationFormNotificationRequest {
    return {
      time: isSet(object.time) ? String(object.time) : "",
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      phoneNumber: isSet(object.phone_number) ? String(object.phone_number) : "",
      organizationInfo: isSet(object.organization_info) ? String(object.organization_info) : "",
      question: isSet(object.question) ? String(object.question) : "",
    };
  },

  toJSON(message: OrganizationFormNotificationRequest): unknown {
    const obj: any = {};
    message.time !== undefined && (obj.time = message.time);
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined && (obj.phone_number = message.phoneNumber);
    message.organizationInfo !== undefined && (obj.organization_info = message.organizationInfo);
    message.question !== undefined && (obj.question = message.question);
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganizationFormNotificationRequest>, I>>(
    base?: I,
  ): OrganizationFormNotificationRequest {
    return OrganizationFormNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationFormNotificationRequest>, I>>(
    object: I,
  ): OrganizationFormNotificationRequest {
    const message = createBaseOrganizationFormNotificationRequest();
    message.time = object.time ?? "";
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.organizationInfo = object.organizationInfo ?? "";
    message.question = object.question ?? "";
    return message;
  },
};

function createBaseCreateWriterNotificationRequest(): CreateWriterNotificationRequest {
  return { name: "" };
}

export const CreateWriterNotificationRequest = {
  encode(message: CreateWriterNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWriterNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWriterNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWriterNotificationRequest {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: CreateWriterNotificationRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWriterNotificationRequest>, I>>(base?: I): CreateWriterNotificationRequest {
    return CreateWriterNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateWriterNotificationRequest>, I>>(
    object: I,
  ): CreateWriterNotificationRequest {
    const message = createBaseCreateWriterNotificationRequest();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseNewApplicationNotificationRequest(): NewApplicationNotificationRequest {
  return { writerName: "", applicantName: "", link: "", target: "" };
}

export const NewApplicationNotificationRequest = {
  encode(message: NewApplicationNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.writerName !== "") {
      writer.uint32(10).string(message.writerName);
    }
    if (message.applicantName !== "") {
      writer.uint32(18).string(message.applicantName);
    }
    if (message.link !== "") {
      writer.uint32(26).string(message.link);
    }
    if (message.target !== "") {
      writer.uint32(34).string(message.target);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewApplicationNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewApplicationNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.writerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applicantName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.link = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.target = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewApplicationNotificationRequest {
    return {
      writerName: isSet(object.writer_name) ? String(object.writer_name) : "",
      applicantName: isSet(object.applicant_name) ? String(object.applicant_name) : "",
      link: isSet(object.link) ? String(object.link) : "",
      target: isSet(object.target) ? String(object.target) : "",
    };
  },

  toJSON(message: NewApplicationNotificationRequest): unknown {
    const obj: any = {};
    message.writerName !== undefined && (obj.writer_name = message.writerName);
    message.applicantName !== undefined && (obj.applicant_name = message.applicantName);
    message.link !== undefined && (obj.link = message.link);
    message.target !== undefined && (obj.target = message.target);
    return obj;
  },

  create<I extends Exact<DeepPartial<NewApplicationNotificationRequest>, I>>(
    base?: I,
  ): NewApplicationNotificationRequest {
    return NewApplicationNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NewApplicationNotificationRequest>, I>>(
    object: I,
  ): NewApplicationNotificationRequest {
    const message = createBaseNewApplicationNotificationRequest();
    message.writerName = object.writerName ?? "";
    message.applicantName = object.applicantName ?? "";
    message.link = object.link ?? "";
    message.target = object.target ?? "";
    return message;
  },
};

function createBaseInviteApplicantRequest(): InviteApplicantRequest {
  return { to: "", name: "", username: "" };
}

export const InviteApplicantRequest = {
  encode(message: InviteApplicantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.to !== "") {
      writer.uint32(10).string(message.to);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.username !== "") {
      writer.uint32(26).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteApplicantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteApplicantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.to = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteApplicantRequest {
    return {
      to: isSet(object.to) ? String(object.to) : "",
      name: isSet(object.name) ? String(object.name) : "",
      username: isSet(object.username) ? String(object.username) : "",
    };
  },

  toJSON(message: InviteApplicantRequest): unknown {
    const obj: any = {};
    message.to !== undefined && (obj.to = message.to);
    message.name !== undefined && (obj.name = message.name);
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteApplicantRequest>, I>>(base?: I): InviteApplicantRequest {
    return InviteApplicantRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InviteApplicantRequest>, I>>(object: I): InviteApplicantRequest {
    const message = createBaseInviteApplicantRequest();
    message.to = object.to ?? "";
    message.name = object.name ?? "";
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseSupportFormRequest(): SupportFormRequest {
  return { name: "", email: "", question: "" };
}

export const SupportFormRequest = {
  encode(message: SupportFormRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.question !== "") {
      writer.uint32(26).string(message.question);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SupportFormRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSupportFormRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.question = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SupportFormRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      question: isSet(object.question) ? String(object.question) : "",
    };
  },

  toJSON(message: SupportFormRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.question !== undefined && (obj.question = message.question);
    return obj;
  },

  create<I extends Exact<DeepPartial<SupportFormRequest>, I>>(base?: I): SupportFormRequest {
    return SupportFormRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SupportFormRequest>, I>>(object: I): SupportFormRequest {
    const message = createBaseSupportFormRequest();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.question = object.question ?? "";
    return message;
  },
};

function createBaseOrganizationFormRequest(): OrganizationFormRequest {
  return { name: "", email: "", phoneNumber: "", organizationInfo: "", question: "" };
}

export const OrganizationFormRequest = {
  encode(message: OrganizationFormRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(26).string(message.phoneNumber);
    }
    if (message.organizationInfo !== "") {
      writer.uint32(34).string(message.organizationInfo);
    }
    if (message.question !== "") {
      writer.uint32(42).string(message.question);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationFormRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationFormRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.organizationInfo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.question = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganizationFormRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      phoneNumber: isSet(object.phone_number) ? String(object.phone_number) : "",
      organizationInfo: isSet(object.organization_info) ? String(object.organization_info) : "",
      question: isSet(object.question) ? String(object.question) : "",
    };
  },

  toJSON(message: OrganizationFormRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined && (obj.phone_number = message.phoneNumber);
    message.organizationInfo !== undefined && (obj.organization_info = message.organizationInfo);
    message.question !== undefined && (obj.question = message.question);
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganizationFormRequest>, I>>(base?: I): OrganizationFormRequest {
    return OrganizationFormRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationFormRequest>, I>>(object: I): OrganizationFormRequest {
    const message = createBaseOrganizationFormRequest();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.organizationInfo = object.organizationInfo ?? "";
    message.question = object.question ?? "";
    return message;
  },
};

function createBaseSubscriptionCreatedNotificationRequest(): SubscriptionCreatedNotificationRequest {
  return { name: "", receipt: "" };
}

export const SubscriptionCreatedNotificationRequest = {
  encode(message: SubscriptionCreatedNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.receipt !== "") {
      writer.uint32(18).string(message.receipt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscriptionCreatedNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionCreatedNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.receipt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionCreatedNotificationRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      receipt: isSet(object.receipt) ? String(object.receipt) : "",
    };
  },

  toJSON(message: SubscriptionCreatedNotificationRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.receipt !== undefined && (obj.receipt = message.receipt);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionCreatedNotificationRequest>, I>>(
    base?: I,
  ): SubscriptionCreatedNotificationRequest {
    return SubscriptionCreatedNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionCreatedNotificationRequest>, I>>(
    object: I,
  ): SubscriptionCreatedNotificationRequest {
    const message = createBaseSubscriptionCreatedNotificationRequest();
    message.name = object.name ?? "";
    message.receipt = object.receipt ?? "";
    return message;
  },
};

function createBaseSubscriptionRenewingSoonNotificationRequest(): SubscriptionRenewingSoonNotificationRequest {
  return { name: "", price: "" };
}

export const SubscriptionRenewingSoonNotificationRequest = {
  encode(message: SubscriptionRenewingSoonNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.price !== "") {
      writer.uint32(18).string(message.price);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscriptionRenewingSoonNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionRenewingSoonNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.price = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionRenewingSoonNotificationRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      price: isSet(object.price) ? String(object.price) : "",
    };
  },

  toJSON(message: SubscriptionRenewingSoonNotificationRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.price !== undefined && (obj.price = message.price);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionRenewingSoonNotificationRequest>, I>>(
    base?: I,
  ): SubscriptionRenewingSoonNotificationRequest {
    return SubscriptionRenewingSoonNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionRenewingSoonNotificationRequest>, I>>(
    object: I,
  ): SubscriptionRenewingSoonNotificationRequest {
    const message = createBaseSubscriptionRenewingSoonNotificationRequest();
    message.name = object.name ?? "";
    message.price = object.price ?? "";
    return message;
  },
};

function createBaseSubscriptionEndedNotificationRequest(): SubscriptionEndedNotificationRequest {
  return { name: "" };
}

export const SubscriptionEndedNotificationRequest = {
  encode(message: SubscriptionEndedNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscriptionEndedNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionEndedNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubscriptionEndedNotificationRequest {
    return { name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: SubscriptionEndedNotificationRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionEndedNotificationRequest>, I>>(
    base?: I,
  ): SubscriptionEndedNotificationRequest {
    return SubscriptionEndedNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionEndedNotificationRequest>, I>>(
    object: I,
  ): SubscriptionEndedNotificationRequest {
    const message = createBaseSubscriptionEndedNotificationRequest();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateEnterpriseUserNotificationRequest(): CreateEnterpriseUserNotificationRequest {
  return { enterpriseName: "", email: "", name: "", password: "", ownerName: "", adminName: "", adminEmail: "" };
}

export const CreateEnterpriseUserNotificationRequest = {
  encode(message: CreateEnterpriseUserNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enterpriseName !== "") {
      writer.uint32(10).string(message.enterpriseName);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.password !== "") {
      writer.uint32(34).string(message.password);
    }
    if (message.ownerName !== "") {
      writer.uint32(42).string(message.ownerName);
    }
    if (message.adminName !== "") {
      writer.uint32(50).string(message.adminName);
    }
    if (message.adminEmail !== "") {
      writer.uint32(58).string(message.adminEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateEnterpriseUserNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateEnterpriseUserNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.enterpriseName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ownerName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.adminName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.adminEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateEnterpriseUserNotificationRequest {
    return {
      enterpriseName: isSet(object.enterprise_name) ? String(object.enterprise_name) : "",
      email: isSet(object.email) ? String(object.email) : "",
      name: isSet(object.name) ? String(object.name) : "",
      password: isSet(object.password) ? String(object.password) : "",
      ownerName: isSet(object.owner_name) ? String(object.owner_name) : "",
      adminName: isSet(object.admin_name) ? String(object.admin_name) : "",
      adminEmail: isSet(object.admin_email) ? String(object.admin_email) : "",
    };
  },

  toJSON(message: CreateEnterpriseUserNotificationRequest): unknown {
    const obj: any = {};
    message.enterpriseName !== undefined && (obj.enterprise_name = message.enterpriseName);
    message.email !== undefined && (obj.email = message.email);
    message.name !== undefined && (obj.name = message.name);
    message.password !== undefined && (obj.password = message.password);
    message.ownerName !== undefined && (obj.owner_name = message.ownerName);
    message.adminName !== undefined && (obj.admin_name = message.adminName);
    message.adminEmail !== undefined && (obj.admin_email = message.adminEmail);
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateEnterpriseUserNotificationRequest>, I>>(
    base?: I,
  ): CreateEnterpriseUserNotificationRequest {
    return CreateEnterpriseUserNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateEnterpriseUserNotificationRequest>, I>>(
    object: I,
  ): CreateEnterpriseUserNotificationRequest {
    const message = createBaseCreateEnterpriseUserNotificationRequest();
    message.enterpriseName = object.enterpriseName ?? "";
    message.email = object.email ?? "";
    message.name = object.name ?? "";
    message.password = object.password ?? "";
    message.ownerName = object.ownerName ?? "";
    message.adminName = object.adminName ?? "";
    message.adminEmail = object.adminEmail ?? "";
    return message;
  },
};

function createBaseDeclineRequestNotificationRequest(): DeclineRequestNotificationRequest {
  return { writerName: "", applicantName: "", applyingTo: "", writerMessage: "" };
}

export const DeclineRequestNotificationRequest = {
  encode(message: DeclineRequestNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.writerName !== "") {
      writer.uint32(10).string(message.writerName);
    }
    if (message.applicantName !== "") {
      writer.uint32(18).string(message.applicantName);
    }
    if (message.applyingTo !== "") {
      writer.uint32(26).string(message.applyingTo);
    }
    if (message.writerMessage !== "") {
      writer.uint32(34).string(message.writerMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeclineRequestNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeclineRequestNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.writerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applicantName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.applyingTo = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.writerMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeclineRequestNotificationRequest {
    return {
      writerName: isSet(object.writer_name) ? String(object.writer_name) : "",
      applicantName: isSet(object.applicant_name) ? String(object.applicant_name) : "",
      applyingTo: isSet(object.applying_to) ? String(object.applying_to) : "",
      writerMessage: isSet(object.writer_message) ? String(object.writer_message) : "",
    };
  },

  toJSON(message: DeclineRequestNotificationRequest): unknown {
    const obj: any = {};
    message.writerName !== undefined && (obj.writer_name = message.writerName);
    message.applicantName !== undefined && (obj.applicant_name = message.applicantName);
    message.applyingTo !== undefined && (obj.applying_to = message.applyingTo);
    message.writerMessage !== undefined && (obj.writer_message = message.writerMessage);
    return obj;
  },

  create<I extends Exact<DeepPartial<DeclineRequestNotificationRequest>, I>>(
    base?: I,
  ): DeclineRequestNotificationRequest {
    return DeclineRequestNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DeclineRequestNotificationRequest>, I>>(
    object: I,
  ): DeclineRequestNotificationRequest {
    const message = createBaseDeclineRequestNotificationRequest();
    message.writerName = object.writerName ?? "";
    message.applicantName = object.applicantName ?? "";
    message.applyingTo = object.applyingTo ?? "";
    message.writerMessage = object.writerMessage ?? "";
    return message;
  },
};

export interface ChronosService {
  SendNotification(request: NotificationRequest): Promise<NotificationResponse>;
}

export class ChronosServiceClientImpl implements ChronosService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || "chronos.ChronosService";
    this.rpc = rpc;
    this.SendNotification = this.SendNotification.bind(this);
  }
  SendNotification(request: NotificationRequest): Promise<NotificationResponse> {
    const data = NotificationRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "SendNotification", data);
    return promise.then((data) => NotificationResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
