import {
    List,
    ListItem,
    ListItemIcon,
    Button,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import getForm from './IntakeForm2';
import { API_URL } from '../../api/api';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Loading from '../../components/loading/Loading';
import {
    GenerateReffyFormOptions,
    ReffyForm,
    ReffyView,
} from '../../components/form/ReffyForm';

import './Intake.css';
import { UserModel } from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import { useUserQuery } from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import { CreateResponseRequest } from '@cosmogony/common/reffy-pb/litae-svc/pkg/pb/litae';
import { useCreateResponseMutation } from '@cosmogony/common/reffy-hooks/litae/litae_hooks';
import { useCreateAssetsMutation } from '@cosmogony/common/reffy-hooks/morpheus/morpheus_hooks';
import { CreateAssetRequest } from '@cosmogony/common/reffy-pb/morpheus-svc/pkg/pb/morpheus';
import {
    Boolean,
    DocumentRecord,
} from '@cosmogony/common/reffy-pb/common/pb/common';
import ProfileAvatar from '../../components/profileAvatar/ProfileAvatar';
import { Check } from '@mui/icons-material';

type RouteParams = {
    user: string; // The username of the referer
};

function RenderFormIntroduction(
    formOwner: UserModel,
    finishForm: React.Dispatch<React.SetStateAction<boolean>>
): React.ReactElement {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                alignContent="center"
                justifyContent="center"
                marginTop={'15vh'}
                sx={{
                    background: '#ffffff',
                    padding: '110px 5vw 1rem 5vw',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >
                <ProfileAvatar
                    className="reference-card-pfp"
                    sx={{
                        height: 200,
                        width: 200,
                        borderRadius: '50%',
                        border: '5px solid #ffffff',
                        alignSelf: 'center',
                        maxHeight: { xs: 233, md: 233 },
                        maxWidth: { xs: 350, md: 350 },
                        position: 'absolute',
                        top: '-100px',
                    }}
                    url={formOwner.pfp?.url || ''}
                    name={formOwner.name}
                    fontSize={{ xs: '35px' }}
                />

                <Typography
                    textAlign={'left'}
                    fontSize={'18px !important'}
                    color={'#444444'}
                >
                    Please complete the following questionnaire in order to help
                    me write a detailed reference letter on your behalf.
                </Typography>

                <List style={{ flexGrow: '1' }}>
                    <ListItem
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    >
                        <ListItemIcon
                            sx={{ alignSelf: 'flex-start', minWidth: '24px' }}
                        >
                            <Check sx={{ width: '20px', color: '#888B8D' }} />
                        </ListItemIcon>
                        <div>
                            <Typography
                                color="#444444"
                                fontSize={'18px !important'}
                                lineHeight={'1.6'}
                            >
                                The questionnaire takes about 10-15 minutes
                            </Typography>
                        </div>
                    </ListItem>
                    <ListItem
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    >
                        <ListItemIcon
                            sx={{ alignSelf: 'flex-start', minWidth: '24px' }}
                        >
                            <Check sx={{ width: '20px', color: '#888B8D' }} />
                        </ListItemIcon>
                        <div>
                            <Typography
                                color="#444444"
                                fontSize={'18px !important'}
                                lineHeight={'1.6'}
                            >
                                It's easiest to complete on a computer (not a
                                phone)
                            </Typography>
                        </div>
                    </ListItem>
                    <ListItem
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    >
                        <ListItemIcon
                            sx={{ alignSelf: 'flex-start', minWidth: '24px' }}
                        >
                            <Check sx={{ width: '20px', color: '#888B8D' }} />
                        </ListItemIcon>
                        <div>
                            <Typography
                                color="#444444"
                                fontSize={'18px !important'}
                                lineHeight={'1.6'}
                            >
                                Before you start, gather information and
                                documents related to your applications and
                                qualifications in order to help me write a
                                detailed letter.
                            </Typography>
                        </div>
                    </ListItem>
                </List>

                <Typography
                    textAlign={'left'}
                    fontSize={'18px !important'}
                    color={'#444444'}
                >
                    It takes a bit of effort, but providing this information
                    will help me write the best possible letter to improve your
                    chances of success.
                </Typography>

                <Typography
                    textAlign={'left'}
                    fontSize={'18px !important'}
                    color={'#444444'}
                >
                    Thank you, and good luck!
                </Typography>
                <Typography
                    textAlign={'left'}
                    fontSize={'18px !important'}
                    color={'#444444'}
                >
                    {' '}
                    -{formOwner.name}{' '}
                </Typography>

                <Button
                    variant="contained"
                    sx={{
                        alignSelf: 'center',
                        width: 'fit-content',
                        height: 'fit-content',
                    }}
                    onClick={() => finishForm(true)}
                >
                    LET'S GO
                </Button>
            </Stack>
        </div>
    );
}

function RenderFormSubmission(formOwner: UserModel): React.ReactElement {
    const finalMessage = `Your reference request has been sent to ${formOwner.name}`;

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                alignContent="center"
                justifyContent="center"
                sx={{
                    background: '#ffffff',
                    padding: '50px',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >
                <Typography
                    fontSize={{ xs: '28px', sm: '30px', md: '32px' }}
                    color={'#999999'}
                    sx={{ marginBottom: '30px' }}
                >
                    {finalMessage}
                </Typography>
                <Typography
                    fontSize={{ xs: '28px', sm: '30px', md: '32px' }}
                    color={'#999999'}
                >
                    Thanks, and Good luck!{' '}
                </Typography>
            </Stack>
        </div>
    );
}

function RenderForm(
    views: Array<ReffyView>,
    introFinished: boolean,
    setIntroFinished: React.Dispatch<React.SetStateAction<boolean>>,
    formFinished: boolean,
    formOwner: UserModel,
    mutation: any,
    isMobile: boolean
): React.ReactElement {
    const initialValues = GenerateReffyFormOptions(views);
    return (
        <Stack
            maxWidth="100%"
            minWidth="100%"
            className="intake-form"
            direction="column"
            justifyContent="center"
            height="100%"
            alignItems="center"
        >
            <Stack
                className="intake"
                width={isMobile ? '90%' : '60%'}
                marginTop="1rem"
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                {!introFinished &&
                    RenderFormIntroduction(formOwner, setIntroFinished)}
                {introFinished && !formFinished && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '15%',
                            minWidth: '100%',
                        }}
                    >
                        <img
                            alt="Reference Builder Logo"
                            className="intake-logo"
                            src="/assets/images/logos/reffy_logo.svg"
                            style={{ marginTop: '40px', marginBottom: '37px' }}
                        />
                        <ReffyForm
                            initialValues={initialValues}
                            views={views}
                            onSubmit={(results) => {
                                mutation(results);
                            }}
                        />
                    </div>
                )}
                {formFinished && RenderFormSubmission(formOwner)}
            </Stack>
            {!introFinished && (
                <img
                    alt="Reference Builder Logo"
                    style={{ marginTop: '25px' }}
                    className="intake-logo"
                    src="/assets/images/logos/reffy_logo.svg"
                />
            )}
        </Stack>
    );
}

function Intake() {
    let { user: userName } = useParams<RouteParams>();

    const [verified, setVerified] = useState(true);
    const [err, setError] = useState('');
    const [introFinished, setIntroFinished] = useState(false);
    const [formFinished, setFormFinished] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { data: user, isFetched } = useUserQuery(userName);
    const createResponse = useCreateResponseMutation();
    const createAssets = useCreateAssetsMutation();

    let form: ReffyView[] | undefined = undefined;

    if (isFetched) {
        form = getForm(user);
    }

    return (
        <div
            style={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <GoogleReCaptcha
                onVerify={async (token) => {
                    if (!verified) {
                        await fetch(`${API_URL}/user/recaptcha-verify`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                token,
                            }),
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data['error']) {
                                    setError(data['msg']);
                                } else if (!data['recaptcha']['success']) {
                                    setError(JSON.stringify(data['recaptcha']));
                                } else if (data['recaptcha']['score'] > 0.4) {
                                    setVerified(true);
                                } else {
                                    setError(
                                        'Could not verify that you are not a robot!'
                                    );
                                }
                            });
                    }
                }}
            />
            {!verified && !err && (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <h1>Verifying you are not a robot...</h1>
                    <Loading />
                </div>
            )}
            {!verified && err && <h1>{err}</h1>}
            {createResponse.isLoading && <Loading />}
            {!createResponse.isLoading &&
                user &&
                form &&
                RenderForm(
                    form,
                    introFinished,
                    setIntroFinished,
                    formFinished,
                    user,
                    (values: { [x: string]: any }) => {
                        const contactInfo = values['Contact Info'][0];
                        const applicationType = values['Application Type'][0];
                        const strengths = values['Strength and Skills'][0];
                        const interviewQuestions =
                            values['Interview Questions'][0];

                        const response: string[] = [];

                        for (const view of [
                            contactInfo,
                            applicationType,
                            strengths,
                            interviewQuestions,
                        ]) {
                            for (const question of Object.keys(view)) {
                                if (
                                    user &&
                                    user['user_subscription'] > 1 &&
                                    question.startsWith('99,')
                                ) {
                                    const id = Number(
                                        question.charAt(question.length - 1)
                                    );
                                    const proTitles = [
                                        ...user['additional_questions'],
                                        ...user['additional_documents'],
                                    ];
                                    response.push(
                                        `${question} MAGIC ${proTitles[id]} TITLESPLIT ${view[question]}`
                                    );
                                } else {
                                    response.push(
                                        `${question} MAGIC ${view[question]}`
                                    );
                                }
                            }
                        }

                        // relationship
                        for (const view of [
                            values['Relationship'],
                            values['RelationshipT'],
                            values['RelationshipO'],
                            values['RelationshipC'],
                            values['RelationshipS'],
                            values['RelationshipM'],
                        ]) {
                            for (const question of Object.keys(view[0])) {
                                if (!view[0][question]) {
                                    continue;
                                }

                                response.push(
                                    `${question} MAGIC ${view[0][question]}`
                                );
                            }
                        }

                        const education = values['Education'];
                        for (let i = 0; i < education.length; i++) {
                            for (const question of Object.keys(education[i])) {
                                if (!education[i][question]) {
                                    continue;
                                }

                                response.push(
                                    `${question},${i} MAGIC ${education[i][question]}`
                                );
                            }
                        }

                        const submit = values['Submit'];
                        for (let i = 0; i < submit.length; i++) {
                            for (const question of Object.keys(submit[i])) {
                                if (!submit[i][question]) {
                                    continue;
                                }
                                response.push(
                                    `${question},${i} MAGIC ${submit[i][question]}`
                                );
                            }
                        }

                        // Professional Experience
                        for (const view of [
                            values['Professional Experience'],
                            values['Professional ExperienceM'],
                            values['Professional ExperienceR'],
                        ]) {
                            for (let i = 0; i < view.length; i++) {
                                for (const question of Object.keys(view[i])) {
                                    if (!view[i][question]) {
                                        continue;
                                    }

                                    response.push(
                                        `${question},${i} MAGIC ${view[i][question]}`
                                    );
                                }
                            }
                        }

                        type Application = {
                            responses: string[];
                            dateDue: string;
                        };
                        const applications: Application[] = [];
                        // Application Info
                        for (const [dueDateKey, view] of [
                            ['8,6', values['Application InfoG']],
                            ['9,7', values['Application InfoE']],
                            ['10,5', values['Application InfoO']],
                        ]) {
                            for (let i = 0; i < view.length; i++) {
                                // Verify due date is present!
                                let dueDate = view[i][dueDateKey];
                                if (dueDate !== '') {
                                    const application = [...response];
                                    for (const question of Object.keys(
                                        view[i]
                                    )) {
                                        if (!view[i][question]) {
                                            continue;
                                        }
                                        application.push(
                                            `${question} MAGIC ${view[i][question]}`
                                        );
                                    }

                                    // Save the application to upload to DB
                                    applications.push({
                                        responses: application,
                                        dateDue: dueDate,
                                    });
                                }
                            }
                        }

                        if (applications.length > 0) {
                            // Split responses from raw document & meta data (key & filename - stored in DocumentRecord.name)
                            const { documentsMeta, documentsRaw } =
                                splitDocuments(applications[0].responses);

                            createAssets.mutate({
                                documents: documentsRaw,
                                assetRequest: CreateAssetRequest.create({
                                    uuid: user?.uuid,
                                    public: Boolean.BOOLEAN_FALSE,
                                }),
                                settle: (docs) => {
                                    // Create the document records
                                    const documentRecords = documentsMeta.map(
                                        (v, i) =>
                                            DocumentRecord.create({
                                                url: docs[i],
                                                name: v,
                                            })
                                    );
                                    const applicationPromises: Promise<unknown>[] =
                                        [];
                                    // Create all the responses
                                    applications.map((application) => {
                                        // Sanity check, we should never error on this!
                                        if (String(application.dateDue)) {
                                            const body =
                                                CreateResponseRequest.create({
                                                    ouuid: user?.uuid,
                                                    responses:
                                                        application.responses.filter(
                                                            (v: string) =>
                                                                FORM_DOCUMENT_KEYS.find(
                                                                    (k) =>
                                                                        v.startsWith(
                                                                            k
                                                                        )
                                                                ) === undefined
                                                        ),
                                                    documents: documentRecords,
                                                    dateDue:
                                                        Math.trunc(
                                                            new Date(
                                                                String(
                                                                    application.dateDue
                                                                ).trim()
                                                            ).getTime() / 1000
                                                        ) || 0,
                                                    isWriteNow:
                                                        Boolean.BOOLEAN_FALSE,
                                                });

                                            applicationPromises.push(
                                                createResponse.mutateAsync(body)
                                            );
                                        } else {
                                            console.error(
                                                `failed to parse due data from proposed application: ${application}`
                                            );
                                            alert(
                                                'There was an issue with submitting one of your forms. Please refresh and try again. If this issue persists, please contact our support!'
                                            );
                                        }
                                    });

                                    Promise.allSettled(
                                        applicationPromises
                                    ).then((results) => {
                                        if (
                                            results.find(
                                                (r) => r.status === 'rejected'
                                            ) === undefined
                                        ) {
                                            // only remove cache if all submissions succeeded!
                                            window.localStorage.removeItem(
                                                `${window.location.href}-form`
                                            );
                                            setFormFinished(true);
                                        }
                                    });
                                },
                            });
                        }
                    },
                    isMobile
                )}
        </div>
    );
}

const FORM_DOCUMENT_KEYS = ['1,5', '15,2,0', '99,3', '99,4', '99,5'];

function splitDocuments(unfilteredDocuments: string[]): {
    documentsMeta: string[];
    documentsRaw: string[];
} {
    const docs = unfilteredDocuments.filter(
        (v: string) =>
            FORM_DOCUMENT_KEYS.find((k) => v.startsWith(k)) !== undefined
    );
    const documentsRaw = docs.map((v) => v.split('FILENAMESPLIT')[1].trim());
    const documentsMeta = docs.map((v) => v.split('FILENAMESPLIT')[0].trim());
    return {
        documentsMeta,
        documentsRaw,
    };
}

export default Intake;
