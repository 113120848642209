import {
    FieldArray,
    FormikErrors,
    FormikProvider,
    FormikValues,
    useField,
    useFormik,
} from 'formik';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextareaAutosize,
    TextField,
    Typography,
    FormGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import './ReffyForm.css';
import {
    Add,
    Delete,
    Edit,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import Cropper from 'react-easy-crop';
import ProfileAvatar from '../profileAvatar/ProfileAvatar';
import { width } from '@mui/system';

// Defines what type a field can be
export enum ReffyFieldType {
    Empty = 1, // input with no type
    ShortAnswer,
    Hidden,
    Paragraph,
    Checkbox,
    DropDown,
    Radio,
    Radio2,
    File,
    Image,
    DatePicker,
    Checkbox2,
    ConfirmCheck,
    Custom,
}

export enum ReffyFormikValidationType {
    None = 1,
    Required,
    LengthMin,
    LengthMax,
    RegexMatch,
    MatchPrevious,
    LimitUploadSize,
}

export interface ReffyFieldValidation {
    type: ReffyFormikValidationType;
    params?: string | number | RegExp;
    errMsg?: string;
}

// Defines the configuration for a form field
export interface ReffyField {
    name: string; // name for formik
    title: string; // the title above the input
    fieldTitle: string; // the title inside the input
    fieldType: ReffyFieldType; // the type of our response
    isRequired: boolean; // if the input is required
    repeat: boolean; // repeat this field
    options?: Array<string>; // response options (i.e checkbox values)
    nextOnOptionIndex?: boolean; // next view = current view index + option index
    default?: string | [];
    validate?: ReffyFieldValidation[];
    category?: string;
    initBoxSize?: string;
    controlNext?: (value: any, currentIdx: number) => number;
    // calls ReffyFormikOnChange to update values
    UI?: (
        formik: any,
        viewName: string,
        viewIndex: number,
        fieldName: string,
        errMsg: string | undefined
    ) => React.ReactNode;
}

// Defines the configuration for a list of form fields
export interface ReffyView {
    name: string; // name for formik
    category: string; // the input category
    subcategory: string; // the input category
    inputs: ReffyField[]; // the list of inputs
    repeat: boolean; // repeat this view
}

// Defines a general struct for initial values that can be generated from a ReffyField
interface ReffyFormOptions {
    [key: string]: any;
}

// Generates "initial values" for Formik using ReffyField.default
/**
 *
 * @param form
 * @param clearOtherOptions
 * clearOtherOptions is set when the user decides to take a different route than the one user decided
 * setting clearOtherOptions = "Application Info" would clear all the data from views that have name "application info(__)"
 * @returns
 */
export function GenerateReffyFormOptions(
    form: ReffyView[],
    clearOtherOptions = ''
): ReffyFormOptions {
    const options: ReffyFormOptions = {};
    for (const view of form) {
        if (view.name.includes(clearOtherOptions)) {
            options[view.name] = [{}];
        }
        for (const field of view.inputs) {
            options[view.name][0][field.name] = field.default || '';
        }
    }
    return options;
}

function GenerateReffyFormValidations(
    form: ReffyView[],
    path: number[]
): (values: FormikValues) => FormikErrors<FormikValues> {
    return (values: FormikValues): FormikErrors<FormikValues> => {
        const errors: FormikErrors<FormikValues> = {};

        for (const viewIndex of path) {
            const view = form[viewIndex];
            for (
                let fIndex = 0;
                fIndex < form[viewIndex].inputs.length;
                fIndex++
            ) {
                const field = form[viewIndex].inputs[fIndex];

                for (let i = 0; i < values[view.name].length; i++) {
                    if (field.validate) {
                        const value = values[view.name][i][field.name];
                        const fieldName = `${view.name}[${i}].${field.name}`;

                        field.validate.forEach((validation) => {
                            if (
                                validation.type ===
                                ReffyFormikValidationType.None
                            ) {
                                return;
                            } else if (
                                validation.type ===
                                ReffyFormikValidationType.Required
                            ) {
                                if (!value || value === 'Other') {
                                    errors[
                                        fieldName
                                    ] = `This field is required`;
                                }
                            }

                            if (value && value !== 'Other') {
                                if (
                                    validation.type ===
                                    ReffyFormikValidationType.LengthMin
                                ) {
                                    if (value.length < validation.params!) {
                                        errors[fieldName] =
                                            validation.errMsg ||
                                            `This field is less than the required min ${validation.params} characters`;
                                    }
                                } else if (
                                    validation.type ===
                                    ReffyFormikValidationType.LengthMax
                                ) {
                                    if (value.length > validation.params!) {
                                        errors[fieldName] =
                                            validation.errMsg ||
                                            `Your response exceeds the maximum length of ${validation.params} characters.`;
                                    }
                                } else if (
                                    validation.type ===
                                    ReffyFormikValidationType.RegexMatch
                                ) {
                                    if (
                                        !(validation.params as RegExp).test(
                                            value
                                        )
                                    ) {
                                        errors[fieldName] =
                                            validation.errMsg ||
                                            `This field does not meet requirements`;
                                    }
                                } else if (
                                    validation.type ===
                                    ReffyFormikValidationType.MatchPrevious
                                ) {
                                    const prevFieldName = `${
                                        view.name
                                    }[${i}].${form[viewIndex].inputs[
                                        Math.max(fIndex - 1, 0)
                                    ]?.name}`;
                                    const prevValue =
                                        values[view.name][i][
                                            form[viewIndex].inputs[
                                                Math.max(fIndex - 1, 0)
                                            ].name
                                        ];
                                    if (prevValue !== value) {
                                        errors[fieldName] =
                                            validation.errMsg ||
                                            `These fields do not match!`;
                                        errors[prevFieldName] =
                                            validation.errMsg ||
                                            `These fields do not match!`;
                                    }
                                } else if (
                                    validation.type ===
                                        ReffyFormikValidationType.LimitUploadSize &&
                                    validation.params
                                ) {
                                    const size = new Blob([value]).size / 1000;
                                    if (size > validation.params) {
                                        console.error(
                                            fieldName,
                                            `Upload size is too large (limit ${validation.params}KB)`,
                                            'got: ',
                                            size,
                                            'KB >',
                                            validation.params
                                        );
                                        errors[
                                            fieldName
                                        ] = `Upload size is too large (limit ${validation.params}KB)`;
                                    }
                                }
                            }
                        });
                    }
                }
            }
        }
        return errors;
    };
}

// Date Picker UI
const ReffyDatePicker = (props: {
    formik: any;
    viewName: string;
    viewIndex: number;
    fieldName: string;
    label: string;
}): JSX.Element => {
    const [field, _, helpers] = useField(props.viewName);
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={props.label}
                inputFormat="MM/DD/YYYY"
                value={field.value[props.viewIndex][props.fieldName]}
                onChange={(val: any) => {
                    const v = Array.from<any>(field.value);
                    if (
                        val &&
                        val.constructor &&
                        val.constructor.name === 'Moment'
                    ) {
                        v[props.viewIndex][props.fieldName] = val._d;
                    } else {
                        v[props.viewIndex][props.fieldName] = val;
                    }
                    props.formik.setFieldTouched(
                        `${props.viewName}[${props.viewIndex}].${props.fieldName}`
                    );
                    helpers.setValue(v);
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};

const ReffyDropDown = (props: {
    formik: any;
    view: ReffyView;
    viewIndex: number;
    field: ReffyField;
    fieldName: string;
}): JSX.Element => {
    const [useOther, setUseOther] = useState({});
    const { formik, view, field, fieldName } = props;

    let currentValue = formik.values[view.name][props.viewIndex][field.name];
    let defaultValue =
        field.options?.findIndex((v) => v === currentValue) !== -1
            ? currentValue
            : 'Other';

    return (
        <div className="reffy-form-field">
            <QuestionTitle field={field} />
            <FormControl variant="standard" fullWidth>
                <InputLabel
                    sx={{ padding: '6px 12px 0px' }}
                    id={`label-${fieldName}`}
                >
                    {field.fieldTitle}
                </InputLabel>
                <Select
                    fullWidth
                    labelId={`label-${fieldName}`}
                    id={fieldName}
                    name={fieldName}
                    value={currentValue === '' ? '' : defaultValue}
                    label={fieldName}
                    variant="filled"
                    MenuProps={{
                        style: { height: '500px' },
                        disableScrollLock: false,
                    }}
                    onChange={(e) => {
                        if (
                            e.target.value !== 'Other' &&
                            !e.target.value.includes(':')
                        ) {
                            ReffyFormikOnChange(
                                formik,
                                view.name,
                                props.viewIndex,
                                field.name,
                                e.target.value
                            );
                            setUseOther(
                                Object.assign({}, useOther, {
                                    [field.name]: false,
                                })
                            );
                        } else {
                            ReffyFormikOnChange(
                                formik,
                                view.name,
                                props.viewIndex,
                                field.name,
                                'Other'
                            );
                            setUseOther(
                                Object.assign({}, useOther, {
                                    [field.name]: true,
                                })
                            );
                        }
                    }}
                    error={
                        wasTouched(
                            formik,
                            view.name,
                            props.viewIndex,
                            field.name
                        ) &&
                        hasError(formik, view.name, props.viewIndex, field.name)
                    }
                >
                    {field.options?.map((option, idx) => {
                        return (
                            <MenuItem
                                key={`dropdown-${fieldName}-${idx}`}
                                value={option}
                            >
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <div style={{ marginTop: '1rem' }}>
                {field.name in useOther && useOther[field.name] && (
                    <TextField
                        fullWidth
                        id={fieldName}
                        name={fieldName}
                        label={field.fieldTitle}
                        onChange={(e) => {
                            ReffyFormikOnChange(
                                formik,
                                view.name,
                                props.viewIndex,
                                field.name,
                                e.currentTarget.value
                            );
                        }}
                    />
                )}
            </div>
            <FormHelperText sx={{ overflowWrap: 'word-break' }} error={true}>
                {wasTouched(formik, view.name, props.viewIndex, field.name) &&
                    getErrorMsg(formik, view.name, props.viewIndex, field.name)}
            </FormHelperText>
        </div>
    );
};

// Reffy Form UI
export function ReffyForm(props: {
    initialValues: FormikValues;
    onSubmit: (values: FormikValues) => void;
    views: ReffyView[];
    padding?: string;
    submitText?: string;
}) {
    const [viewPath, setViewPath] = useState<number[]>([0]);
    const [isSubview, setIsSubview] = useState(false);
    const [subview, setSubview] = useState(0);
    const [pageError, setPageError] = useState('');
    let view = viewPath[viewPath.length - 1];

    const formik = useFormik({
        initialValues: props.initialValues,
        onSubmit: props.onSubmit,
        validate: GenerateReffyFormValidations(props.views, [...viewPath]),
    });

    useEffect(() => {
        const defaults = window.localStorage.getItem(
            `${window.location.href}-form`
        );
        if (defaults) {
            const formikDefaults = JSON.parse(defaults);
            formik.setValues(formikDefaults);
        }
    }, []);

    return (
        <div className="reffy-form">
            <Stack
                direction="column"
                spacing={2}
                alignContent="center"
                justifyContent="center"
                sx={{
                    background: '#ffffff',
                    padding: props.padding ?? '30px 1.5vw',
                    boxSizing: 'border-box',
                    width: '100%',
                }}
            >
                <FormikProvider value={formik}>
                    <Typography
                        margin={'0 10px'}
                        fontSize={{ xs: '28px', sm: '30px', md: '32px' }}
                        color={'#999999'}
                        textAlign="center"
                    >
                        {props.views[view].subcategory}
                    </Typography>
                    {pageError !== '' && (
                        <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography
                                style={{ width: 'fit-content' }}
                                color="#f72c25"
                                fontSize={'17px !important'}
                            >
                                There are error(s) on your responses. Please
                                resolve them before continuing:
                            </Typography>
                            <Typography
                                style={{ width: 'fit-content' }}
                                color="#f72c25"
                                fontSize={'17px !important'}
                            >
                                {pageError}
                            </Typography>
                        </Stack>
                    )}
                    <form onSubmit={formik.handleSubmit}>
                        {props.views[view].repeat && !isSubview && (
                            <ReffyViewTable
                                view={props.views[view]}
                                setSubview={setSubview}
                                setIsSubview={setIsSubview}
                                formik={formik}
                            />
                        )}
                        {props.views[view].repeat && isSubview && (
                            <ReffyFormBuildView
                                view={props.views[view]}
                                viewIndex={subview}
                                formik={formik}
                            />
                        )}
                        {!props.views[view].repeat && !isSubview && (
                            <ReffyFormBuildView
                                view={props.views[view]}
                                viewIndex={subview}
                                formik={formik}
                            />
                        )}
                    </form>
                </FormikProvider>
            </Stack>
            <div
                className="reffy-form-progress-buttons"
                style={{
                    justifyContent:
                        view === 0 ? 'center' : 'space-between',
                }}
            >
                {!isSubview && view !== 0 && (
                    <Button
                        sx={{
                            color: '#444444',
                            backgroundColor: 'white',
                            width: '150px',
                        }}
                        type="button"
                        onClick={() => {
                            viewPath.pop();
                            setPageError('');
                            setViewPath([...viewPath]);
                        }}
                    >
                        Go Back
                    </Button>
                )}
                {/* don't show next button if the intake form page >= 10 */}
                {!isSubview && view < 20 && view !== props.views.length - 1 && (
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        sx={{ width: '150px' }}
                        onClick={async () => {
                            const v = props.views[view];
                            let next: number | undefined = undefined;
                            let hasError: boolean = false;
                            let firstError = '';

                            for (
                                let i = 0;
                                i < formik.values[v.name].length;
                                i++
                            ) {
                                for (const field of v.inputs) {
                                    const name = `${v.name}[${i}].${field.name}`;
                                    const errors = await formik.setFieldTouched(
                                        name,
                                        true,
                                        true
                                    );
                                    if (Boolean(errors[name])) {
                                        hasError = true;
                                        if (firstError === '') {
                                            firstError = `[${field.title}] ${errors[name]}`;
                                        }
                                        console.error(
                                            `Field: ${name} has error: ${
                                                errors[name]
                                            } with value: ${JSON.stringify(
                                                formik.values[v.name][i][
                                                    field.name
                                                ]
                                            )}`
                                        );
                                    } else if (
                                        next === undefined &&
                                        field.controlNext
                                    ) {
                                        // only one control field is allowed
                                        setPageError('');
                                        next = field.controlNext(
                                            formik.values[v.name][i][
                                                field.name
                                            ],
                                            view
                                        );
                                    }
                                }
                            }

                            if (hasError) {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                                setPageError(firstError);
                                return;
                            }

                            // If control-nexted, remove all other view info of the same type
                            setViewPath([...viewPath, next || view + 1]);
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            });
                            window.localStorage.setItem(
                                `${window.location.href}-form`,
                                JSON.stringify(formik.values)
                            );
                        }}
                    >
                        Next Step
                    </Button>
                )}
                {/* Show Submit button for the intake form when view >= 10 */}
                {!isSubview &&
                    (view >= 20 || view === props.views.length - 1) && (
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                let hasError = false;
                                formik.validateForm().then((errors) => {
                                    for (const viewIndex of viewPath) {
                                        const v = props.views[viewIndex];
                                        for (
                                            let i = 0;
                                            i < formik.values[v.name].length;
                                            i++
                                        ) {
                                            for (const field of v.inputs) {
                                                const name = `${v.name}[${i}].${field.name}`;
                                                formik.setFieldTouched(name);
                                                if (Boolean(errors[name])) {
                                                    hasError = true;
                                                    console.error(
                                                        `Validation failed on submit - Field: ${name} has error: ${
                                                            errors[name]
                                                        } with value: ${JSON.stringify(
                                                            formik.values[
                                                                v.name
                                                            ][i][field.name]
                                                        )}`
                                                    );
                                                }
                                            }
                                        }
                                    }

                                    if (hasError) {
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        });
                                        return;
                                    }
                                    props.onSubmit(formik.values);
                                });
                            }}
                        >
                            {props.submitText ? props.submitText : 'Submit'}
                        </Button>
                    )}
                
                {isSubview && (
                    <Button
                        type="button"
                        sx={{ width: "150px", backgroundColor: "white" }}
                        onClick={async () => {
                            setPageError('');
                            setSubview(0);
                            setIsSubview(false);
                        }}
                    >
                        Back
                    </Button>
                )}

                {isSubview && (
                    <Button
                        type="button"
                        sx={{ width: "150px" }}
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            const v = props.views[view];
                            let hasError: boolean = false;
                            let firstError = '';

                            for (
                                let i = 0;
                                i < formik.values[v.name].length;
                                i++
                            ) {
                                for (const field of v.inputs) {
                                    const name = `${v.name}[${i}].${field.name}`;
                                    const errors = await formik.setFieldTouched(
                                        name,
                                        true,
                                        true
                                    );
                                    if (Boolean(errors[name])) {
                                        hasError = true;
                                        if (firstError === '') {
                                            firstError = `[${field.title}] ${errors[name]}`;
                                        }
                                        console.error(
                                            `Field: ${name} has error: ${
                                                errors[name]
                                            } with value: ${JSON.stringify(
                                                formik.values[v.name][i][
                                                    field.name
                                                ]
                                            )}`
                                        );
                                    }
                                }
                            }

                            if (hasError) {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                                setPageError(firstError);
                                return;
                            }

                            setPageError('');
                            setSubview(0);
                            setIsSubview(false);
                        }}
                    >
                        Continue
                    </Button>
                )}
            </div>
        </div>
    );
}

export function ReffyFormikOnChange(
    formik: any,
    viewName: string,
    viewIndex: number,
    fieldName: string,
    v: any
) {
    const c = Array.from<any>(formik.values[viewName]);
    c[viewIndex][fieldName] = v;
    formik.setFieldValue(viewName, c, true);
    const name = `${viewName}[${viewIndex}].${fieldName}`;
    formik.setFieldTouched(name);
}

function wasTouched(
    formik: any,
    viewName: string,
    viewIndex: number,
    fieldName: string
): boolean {
    const t = formik.touched[viewName];
    if (t && t[viewIndex] && t[viewIndex][fieldName]) {
        return Boolean(t[viewIndex][fieldName]);
    }

    return false;
}

function hasError(
    formik: any,
    viewName: string,
    viewIndex: number,
    fieldName: string
): boolean {
    const name = `${viewName}[${viewIndex}].${fieldName}`;
    return Boolean(formik.errors[name]);
}

function getErrorMsg(
    formik: any,
    viewName: string,
    viewIndex: number,
    fieldName: string
): string {
    const name = `${viewName}[${viewIndex}].${fieldName}`;
    return formik.errors[name] || '';
}

const QuestionTitle = ({ field }) => {
    var isRequired = false;
    // Adds a star to the question if it's required
    if (field.validate) {
        field.validate.forEach(
            (validation: { type: ReffyFormikValidationType }) => {
                if (validation.type === ReffyFormikValidationType.Required) {
                    isRequired = true;
                }
            }
        );
    }
    return (
        <Typography
            style={{ width: 'fit-content' }}
            fontWeight="bold"
            fontSize={'21px !important'}
            sx={{ mb: 1 }}
        >
            {field.title}
            {field.title && isRequired && (
                <Typography
                    color="#f72c25"
                    display="inline"
                    component="span"
                    fontWeight="bold"
                    fontSize={'20px !important'}
                >
                    {' *'}
                </Typography>
            )}
        </Typography>
    );
};

function ReffyFormBuildView(props: {
    view: ReffyView;
    viewIndex: number;
    formik: any;
}): JSX.Element {
    const view = props.view;
    const formik = props.formik;
    const [showHidden, setShowHidden] = useState(false);
    const [showImgCrop, setShowImgCrop] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
    const [useOther, setUseOther] = useState({});
    const [useDefault, setUseDefault] = useState(true);

    return (
        <div>
            <FieldArray
                name={view.name}
                render={(_) => {
                    return (
                        <div>
                            {view.inputs.map((field: ReffyField) => {
                                const fieldName = `${view.name}[${props.viewIndex}].${field.name}`;

                                switch (field.fieldType) {
                                    case ReffyFieldType.ShortAnswer:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <TextField
                                                    fullWidth //doesn't work
                                                    id={fieldName}
                                                    name={fieldName}
                                                    label={
                                                        field.fieldTitle !== ''
                                                            ? field.fieldTitle
                                                            : false
                                                    }
                                                    variant="filled"
                                                    size="small"
                                                    type={'text'}
                                                    value={
                                                        formik.values[
                                                            view.name
                                                        ][props.viewIndex][
                                                            field.name
                                                        ] || ''
                                                    }
                                                    onChange={(e) => {
                                                        ReffyFormikOnChange(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name,
                                                            e.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                    error={
                                                        wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                        hasError(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        )
                                                    }
                                                    helperText={
                                                        wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                        getErrorMsg(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    case ReffyFieldType.Hidden:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <TextField
                                                    fullWidth
                                                    id={fieldName}
                                                    name={fieldName}
                                                    label={field.fieldTitle}
                                                    variant="filled"
                                                    size="small"
                                                    value={
                                                        formik.values[
                                                            view.name
                                                        ][props.viewIndex][
                                                            field.name
                                                        ] || ''
                                                    }
                                                    type={
                                                        showHidden
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    onChange={(e) => {
                                                        ReffyFormikOnChange(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name,
                                                            e.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                    error={
                                                        wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                        hasError(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        )
                                                    }
                                                    helperText={
                                                        wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                        getErrorMsg(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        )
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        setShowHidden(
                                                                            !showHidden
                                                                        )
                                                                    }
                                                                >
                                                                    {showHidden ? (
                                                                        <Visibility />
                                                                    ) : (
                                                                        <VisibilityOff />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        );

                                    case ReffyFieldType.DropDown:
                                        return (
                                            <div>
                                                <ReffyDropDown
                                                    formik={formik}
                                                    view={view}
                                                    viewIndex={props.viewIndex}
                                                    field={field}
                                                    fieldName={fieldName}
                                                />
                                            </div>
                                        );

                                    case ReffyFieldType.DatePicker:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <ReffyDatePicker
                                                    formik={formik}
                                                    viewName={view.name}
                                                    viewIndex={props.viewIndex}
                                                    fieldName={field.name}
                                                    label={field.fieldTitle}
                                                />
                                                <FormHelperText error={true}>
                                                    {wasTouched(
                                                        formik,
                                                        view.name,
                                                        props.viewIndex,
                                                        field.name
                                                    ) &&
                                                        getErrorMsg(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        )}
                                                </FormHelperText>
                                            </div>
                                        );

                                    case ReffyFieldType.Paragraph:
                                        let maxLength: any =
                                            field.validate?.find(
                                                (el) =>
                                                    el.type ===
                                                    ReffyFormikValidationType.LengthMax
                                            )?.params;
                                        // Just in case, not sure what's the best way to assume that maxLength is a number on the above line
                                        if (isNaN(maxLength)) {
                                            maxLength = 500;
                                        }
                                        const currLength: number =
                                            formik.values[view.name][
                                                props.viewIndex
                                            ]?.[field.name]?.length ?? 0;
                                        const charLeft = maxLength - currLength;

                                        const errorPresent = hasError(
                                            formik,
                                            view.name,
                                            props.viewIndex,
                                            field.name
                                        );
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <TextField
                                                    fullWidth
                                                    id={fieldName}
                                                    name={fieldName}
                                                    label={field.fieldTitle}
                                                    variant="filled"
                                                    size="medium"
                                                    value={
                                                        formik.values[
                                                            view.name
                                                        ][props.viewIndex][
                                                            field.name
                                                        ] || ''
                                                    }
                                                    onChange={(e) => {
                                                        ReffyFormikOnChange(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name,
                                                            e.currentTarget
                                                                .value
                                                        );
                                                    }}
                                                    error={
                                                        wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) && errorPresent
                                                    }
                                                    helperText={
                                                        wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                        getErrorMsg(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        )
                                                    }
                                                    InputProps={{
                                                        inputComponent:
                                                            TextareaAutosize,
                                                        inputProps: {
                                                            style: {
                                                                resize: 'vertical',
                                                                overflow:
                                                                    'auto',
                                                                minHeight:
                                                                    field.initBoxSize ===
                                                                    'large'
                                                                        ? '15rem'
                                                                        : field.initBoxSize ===
                                                                          'medium'
                                                                        ? '10rem'
                                                                        : '5rem',
                                                                maxHeight:
                                                                    '20rem',
                                                            },
                                                        },
                                                    }}
                                                />
                                                <Stack
                                                    marginTop={
                                                        errorPresent
                                                            ? '-23px'
                                                            : '5px'
                                                    }
                                                    direction="row"
                                                    justifyContent="end"
                                                >
                                                    {charLeft >= 0 && (
                                                        <Typography
                                                            fontSize="0.9642857142857144rem !important"
                                                            color="#999999"
                                                        >
                                                            {currLength}/
                                                            {maxLength}
                                                        </Typography>
                                                    )}
                                                    {errorPresent &&
                                                        charLeft < 0 && (
                                                            <Typography
                                                                fontSize="0.9642857142857144rem !important"
                                                                color="#89023e"
                                                            >
                                                                {currLength}/
                                                                {maxLength}
                                                            </Typography>
                                                        )}
                                                </Stack>
                                            </div>
                                        );

                                    case ReffyFieldType.Radio:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <FormControl>
                                                    <FormLabel
                                                        id={`label-${fieldName}`}
                                                        error={
                                                            wasTouched(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            ) &&
                                                            hasError(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )
                                                        }
                                                    >
                                                        {field.fieldTitle}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby={`label-${fieldName}`}
                                                        defaultValue={
                                                            formik.values[
                                                                view.name
                                                            ][props.viewIndex][
                                                                field.name
                                                            ]
                                                        }
                                                        value={
                                                            formik.values[
                                                                view.name
                                                            ][props.viewIndex][
                                                                field.name
                                                            ]
                                                        }
                                                        onChange={(e) => {
                                                            ReffyFormikOnChange(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name,
                                                                e.target.value
                                                            );
                                                        }}
                                                        name={fieldName}
                                                    >
                                                        {field.options?.map(
                                                            (option) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        value={
                                                                            option
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            option
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </RadioGroup>
                                                    <FormHelperText
                                                        error={true}
                                                    >
                                                        {wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                            getErrorMsg(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )}
                                                    </FormHelperText>
                                                </FormControl>
                                            </div>
                                        );

                                    case ReffyFieldType.Radio2:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <FormControl>
                                                    <FormLabel
                                                        id={`label-${fieldName}`}
                                                        error={
                                                            wasTouched(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            ) &&
                                                            hasError(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )
                                                        }
                                                    >
                                                        {field.fieldTitle}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby={`label-${fieldName}`}
                                                        defaultValue={
                                                            formik.values[
                                                                view.name
                                                            ][props.viewIndex][
                                                                field.name
                                                            ]
                                                        }
                                                        value={
                                                            field.options?.filter(
                                                                (v) =>
                                                                    v ===
                                                                    formik
                                                                        .values[
                                                                        view
                                                                            .name
                                                                    ][
                                                                        props
                                                                            .viewIndex
                                                                    ][
                                                                        field
                                                                            .name
                                                                    ]
                                                            ).length === 0 &&
                                                            field.name in
                                                                useOther &&
                                                            useOther[field.name]
                                                                ? 'Other'
                                                                : field.options?.filter(
                                                                      (v) =>
                                                                          v ===
                                                                          formik
                                                                              .values[
                                                                              view
                                                                                  .name
                                                                          ][
                                                                              props
                                                                                  .viewIndex
                                                                          ][
                                                                              field
                                                                                  .name
                                                                          ]
                                                                  )
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target
                                                                    .value !==
                                                                    'Other' &&
                                                                !e.target.value.includes(
                                                                    ':'
                                                                ) &&
                                                                !e.target.value.includes(
                                                                    'Fill in your own'
                                                                )
                                                            ) {
                                                                ReffyFormikOnChange(
                                                                    formik,
                                                                    view.name,
                                                                    props.viewIndex,
                                                                    field.name,
                                                                    e.target
                                                                        .value
                                                                );
                                                                setUseOther(
                                                                    Object.assign(
                                                                        {},
                                                                        useOther,
                                                                        {
                                                                            [field.name]:
                                                                                false,
                                                                        }
                                                                    )
                                                                );
                                                            } else {
                                                                ReffyFormikOnChange(
                                                                    formik,
                                                                    view.name,
                                                                    props.viewIndex,
                                                                    field.name,
                                                                    'Other'
                                                                );
                                                                setUseOther(
                                                                    Object.assign(
                                                                        {},
                                                                        useOther,
                                                                        {
                                                                            [field.name]:
                                                                                true,
                                                                        }
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                        name={fieldName}
                                                    >
                                                        {field.options?.map(
                                                            (option) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        value={
                                                                            option
                                                                        }
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label={
                                                                            option
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </RadioGroup>
                                                    <FormHelperText
                                                        error={true}
                                                    >
                                                        {wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                            getErrorMsg(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )}
                                                    </FormHelperText>
                                                </FormControl>
                                                <div
                                                    style={{
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    {field.name in useOther &&
                                                        useOther[
                                                            field.name
                                                        ] && (
                                                            <TextField
                                                                fullWidth
                                                                id={fieldName}
                                                                name={fieldName}
                                                                label={
                                                                    field.fieldTitle
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    ReffyFormikOnChange(
                                                                        formik,
                                                                        view.name,
                                                                        props.viewIndex,
                                                                        field.name,
                                                                        e
                                                                            .currentTarget
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        );

                                    case ReffyFieldType.Checkbox:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                >
                                                    <InputLabel
                                                        id={`label-${fieldName}`}
                                                    >
                                                        {field.fieldTitle}
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        labelId={`label-${fieldName}`}
                                                        id={fieldName}
                                                        name={fieldName}
                                                        variant="filled"
                                                        value={
                                                            formik.values[
                                                                view.name
                                                            ][props.viewIndex][
                                                                field.name
                                                            ]
                                                                ?.split(',')
                                                                ?.filter(
                                                                    (
                                                                        v: string
                                                                    ) =>
                                                                        v !== ''
                                                                ) || []
                                                        }
                                                        label={fieldName}
                                                        error={
                                                            wasTouched(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            ) &&
                                                            hasError(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )
                                                        }
                                                        onChange={({
                                                            target: { value },
                                                        }) => {
                                                            const v =
                                                                typeof value ===
                                                                'string'
                                                                    ? value
                                                                    : value.join(
                                                                          ','
                                                                      );
                                                            ReffyFormikOnChange(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name,
                                                                v
                                                            );
                                                        }}
                                                        renderValue={(
                                                            selected
                                                        ) => (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexWrap:
                                                                        'wrap',
                                                                    gap: 0.5,
                                                                }}
                                                            >
                                                                {selected.map(
                                                                    (
                                                                        value: string
                                                                    ) => (
                                                                        <Chip
                                                                            key={`checkbox-chip-${fieldName}-${value}`}
                                                                            label={
                                                                                value
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Box>
                                                        )}
                                                    >
                                                        {field.options?.map(
                                                            (option, idx) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`checkbox-${fieldName}-${idx}`}
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                    <FormHelperText
                                                        error={true}
                                                    >
                                                        {wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                            getErrorMsg(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )}
                                                    </FormHelperText>
                                                </FormControl>
                                            </div>
                                        );
                                    case ReffyFieldType.Checkbox2:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                >
                                                    <InputLabel
                                                        id={`label-${fieldName}`}
                                                    >
                                                        {field.fieldTitle}
                                                    </InputLabel>
                                                    <Select
                                                        // multiple
                                                        labelId={`label-${fieldName}`}
                                                        id={fieldName}
                                                        name={fieldName}
                                                        variant="filled"
                                                        value={
                                                            formik.values[
                                                                view.name
                                                            ][props.viewIndex][
                                                                field.name
                                                            ]
                                                                ?.split(',')
                                                                ?.filter(
                                                                    (
                                                                        v: string
                                                                    ) =>
                                                                        v !== ''
                                                                ) || []
                                                        }
                                                        label={fieldName}
                                                        error={
                                                            wasTouched(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            ) &&
                                                            hasError(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )
                                                        }
                                                        onChange={({
                                                            target: { value },
                                                        }) => {
                                                            const v =
                                                                typeof value ===
                                                                'string'
                                                                    ? value
                                                                    : value.join(
                                                                          ','
                                                                      );
                                                            ReffyFormikOnChange(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name,
                                                                v
                                                            );
                                                        }}
                                                    >
                                                        {field.options?.map(
                                                            (option, idx) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`checkbox-${fieldName}-${idx}`}
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={
                                                                                formik.values[
                                                                                    view
                                                                                        .name
                                                                                ][
                                                                                    props
                                                                                        .viewIndex
                                                                                ][
                                                                                    field
                                                                                        .name
                                                                                ].indexOf(
                                                                                    option
                                                                                ) >
                                                                                -1
                                                                            }
                                                                        />
                                                                        <ListItemText
                                                                            primary={
                                                                                option
                                                                            }
                                                                        />
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}

                                                        {/* {names.map((name) => (
															<MenuItem key={name} value={name}>
																<Checkbox checked={personName.indexOf(name) > -1} />
																<ListItemText primary={name} />
															</MenuItem>
														))} */}
                                                    </Select>
                                                    <FormHelperText
                                                        error={true}
                                                    >
                                                        {wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                            getErrorMsg(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )}
                                                    </FormHelperText>
                                                </FormControl>
                                            </div>
                                        );

                                    case ReffyFieldType.File:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <Stack
                                                    direction="row"
                                                    justifyContent="start"
                                                    gap="20px"
                                                    alignItems="center"
                                                >
                                                    <Button
                                                        component="label"
                                                        color="primary"
                                                        variant="contained"
                                                    >
                                                        UPLOAD
                                                        <input
                                                            className="reffy-form-file-upload-input"
                                                            type="file"
                                                            accept={
                                                                (field.options
                                                                    ?.length &&
                                                                field.options
                                                                    .length > 0
                                                                    ? field
                                                                          .options[0]
                                                                    : false) ||
                                                                'application/pdf'
                                                            }
                                                            id={fieldName}
                                                            name={fieldName}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    event
                                                                        ?.currentTarget
                                                                        ?.files
                                                                ) {
                                                                    ReffyReadFile(
                                                                        formik,
                                                                        view.name,
                                                                        props.viewIndex,
                                                                        field.name,
                                                                        event
                                                                            .currentTarget
                                                                            .files[0]
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                    {formik.values[view.name][
                                                        props.viewIndex
                                                    ][field.name] && (
                                                        <Typography
                                                            display="inline"
                                                            component="span"
                                                        >
                                                            {
                                                                formik.values[
                                                                    view.name
                                                                ][
                                                                    props
                                                                        .viewIndex
                                                                ][
                                                                    field.name
                                                                ].split(
                                                                    'FILENAMESPLIT'
                                                                )[0]
                                                            }
                                                        </Typography>
                                                    )}
                                                </Stack>

                                                <FormHelperText error={true}>
                                                    {wasTouched(
                                                        formik,
                                                        view.name,
                                                        props.viewIndex,
                                                        field.name
                                                    ) &&
                                                        getErrorMsg(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        )}
                                                </FormHelperText>
                                            </div>
                                        );
                                    case ReffyFieldType.Image:
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '1rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        component="label"
                                                    >
                                                        Choose Image
                                                        <input
                                                            hidden
                                                            accept="image/*"
                                                            type="file"
                                                            id={`reffy-form-image-input-${fieldName}`}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    event
                                                                        ?.currentTarget
                                                                        ?.files
                                                                ) {
                                                                    ReffyReadFile(
                                                                        formik,
                                                                        view.name,
                                                                        props.viewIndex,
                                                                        field.name,
                                                                        event
                                                                            .currentTarget
                                                                            .files[0]
                                                                    );
                                                                    setShowImgCrop(
                                                                        true
                                                                    );
                                                                    event.target.value =
                                                                        '';
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                    <Avatar
                                                        alt="profile"
                                                        src={
                                                            formik.values[
                                                                view.name
                                                            ][props.viewIndex][
                                                                field.name
                                                            ].includes(
                                                                'FILENAMESPLIT'
                                                            )
                                                                ? formik.values[
                                                                      view.name
                                                                  ][
                                                                      props
                                                                          .viewIndex
                                                                  ][
                                                                      field.name
                                                                  ].split(
                                                                      'FILENAMESPLIT'
                                                                  )[1]
                                                                : formik.values[
                                                                      view.name
                                                                  ][
                                                                      props
                                                                          .viewIndex
                                                                  ][field.name]
                                                        }
                                                    />
                                                </div>

                                                {showImgCrop && (
                                                    <div
                                                        className="reffy-form-image-crop"
                                                        style={{
                                                            position: 'fixed',
                                                            left: 0,
                                                            top: 0,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'flex-start',
                                                            zIndex: '99999',
                                                            backgroundColor:
                                                                'rgba(0, 0, 0, 0.5)',
                                                        }}
                                                        onClick={(e) => {
                                                            if (
                                                                e.target[
                                                                    'className'
                                                                ] ===
                                                                'reffy-form-image-crop'
                                                            ) {
                                                                ReffyFormikOnChange(
                                                                    formik,
                                                                    view.name,
                                                                    props.viewIndex,
                                                                    field.name,
                                                                    ''
                                                                );
                                                                setShowImgCrop(
                                                                    false
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    'white',
                                                                width: '90vw',
                                                                height: '90vw',
                                                                maxWidth:
                                                                    '500px',
                                                                maxHeight:
                                                                    '500px',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                gap: '1rem',
                                                                marginTop:
                                                                    '6rem',
                                                                padding:
                                                                    '25px 0 25px 0',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position:
                                                                        'relative',
                                                                    backgroundColor:
                                                                        'white',
                                                                    width: '80%',
                                                                    height: '80%',
                                                                }}
                                                            >
                                                                <Cropper
                                                                    image={
                                                                        formik.values[
                                                                            view
                                                                                .name
                                                                        ][
                                                                            props
                                                                                .viewIndex
                                                                        ][
                                                                            field
                                                                                .name
                                                                        ].includes(
                                                                            'FILENAMESPLIT'
                                                                        )
                                                                            ? formik.values[
                                                                                  view
                                                                                      .name
                                                                              ][
                                                                                  props
                                                                                      .viewIndex
                                                                              ][
                                                                                  field
                                                                                      .name
                                                                              ].split(
                                                                                  'FILENAMESPLIT'
                                                                              )[1]
                                                                            : formik
                                                                                  .values[
                                                                                  view
                                                                                      .name
                                                                              ][
                                                                                  props
                                                                                      .viewIndex
                                                                              ][
                                                                                  field
                                                                                      .name
                                                                              ]
                                                                    }
                                                                    crop={crop}
                                                                    zoom={zoom}
                                                                    aspect={1}
                                                                    cropShape="round"
                                                                    showGrid={
                                                                        false
                                                                    }
                                                                    onCropComplete={(
                                                                        _,
                                                                        croppedAreaPixels_
                                                                    ) =>
                                                                        setCroppedAreaPixels(
                                                                            croppedAreaPixels_
                                                                        )
                                                                    }
                                                                    onCropChange={
                                                                        setCrop
                                                                    }
                                                                    onZoomChange={
                                                                        setZoom
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    gap: '1rem',
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    style={{
                                                                        color: 'white',
                                                                        backgroundColor:
                                                                            '#444444',
                                                                    }}
                                                                    onClick={async () => {
                                                                        ReffyFormikOnChange(
                                                                            formik,
                                                                            view.name,
                                                                            props.viewIndex,
                                                                            field.name,
                                                                            ''
                                                                        );
                                                                        setShowImgCrop(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    cancel
                                                                </Button>

                                                                <Button
                                                                    variant="contained"
                                                                    onClick={async () => {
                                                                        await CropImage(
                                                                            formik.values[
                                                                                view
                                                                                    .name
                                                                            ][
                                                                                props
                                                                                    .viewIndex
                                                                            ][
                                                                                field
                                                                                    .name
                                                                            ].includes(
                                                                                'FILENAMESPLIT'
                                                                            )
                                                                                ? formik.values[
                                                                                      view
                                                                                          .name
                                                                                  ][
                                                                                      props
                                                                                          .viewIndex
                                                                                  ][
                                                                                      field
                                                                                          .name
                                                                                  ].split(
                                                                                      'FILENAMESPLIT'
                                                                                  )[0]
                                                                                : '',
                                                                            formik.values[
                                                                                view
                                                                                    .name
                                                                            ][
                                                                                props
                                                                                    .viewIndex
                                                                            ][
                                                                                field
                                                                                    .name
                                                                            ].includes(
                                                                                'FILENAMESPLIT'
                                                                            )
                                                                                ? formik.values[
                                                                                      view
                                                                                          .name
                                                                                  ][
                                                                                      props
                                                                                          .viewIndex
                                                                                  ][
                                                                                      field
                                                                                          .name
                                                                                  ].split(
                                                                                      'FILENAMESPLIT'
                                                                                  )[1]
                                                                                : formik
                                                                                      .values[
                                                                                      view
                                                                                          .name
                                                                                  ][
                                                                                      props
                                                                                          .viewIndex
                                                                                  ][
                                                                                      field
                                                                                          .name
                                                                                  ],
                                                                            croppedAreaPixels,
                                                                            0,
                                                                            {
                                                                                horizontal:
                                                                                    false,
                                                                                vertical:
                                                                                    false,
                                                                            },
                                                                            (
                                                                                v
                                                                            ) => {
                                                                                ReffyFormikOnChange(
                                                                                    formik,
                                                                                    view.name,
                                                                                    props.viewIndex,
                                                                                    field.name,
                                                                                    v
                                                                                );
                                                                            }
                                                                        );
                                                                        setShowImgCrop(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    UPLOAD
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    case ReffyFieldType.ConfirmCheck:
                                        const option = field.options?.find(
                                            (v) => v !== ''
                                        );
                                        return (
                                            <div className="reffy-form-field">
                                                <QuestionTitle field={field} />
                                                <FormGroup>
                                                    <Stack
                                                        width={'100%'}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        useDefault
                                                                            ? field.default
                                                                            : formik
                                                                                  .values[
                                                                                  view
                                                                                      .name
                                                                              ][
                                                                                  props
                                                                                      .viewIndex
                                                                              ][
                                                                                  field
                                                                                      .name
                                                                              ]
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setUseDefault(
                                                                            false
                                                                        );
                                                                        ReffyFormikOnChange(
                                                                            formik,
                                                                            view.name,
                                                                            props.viewIndex,
                                                                            field.name,
                                                                            e
                                                                                .target
                                                                                .checked
                                                                                ? 'Yes'
                                                                                : ''
                                                                        );
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <Typography
                                                                    fontSize={{
                                                                        xs: '16px',
                                                                        md: '18px',
                                                                    }}
                                                                >
                                                                    {option}
                                                                    <>
                                                                        {option!.includes(
                                                                            'affirm'
                                                                        ) && (
                                                                            <Typography
                                                                                color="#f72c25"
                                                                                display="inline"
                                                                                component="span"
                                                                                fontWeight="bold"
                                                                                fontSize={
                                                                                    '20px !important'
                                                                                }
                                                                            >
                                                                                {
                                                                                    ' *'
                                                                                }
                                                                            </Typography>
                                                                        )}
                                                                    </>
                                                                </Typography>
                                                            }
                                                        />
                                                    </Stack>
                                                    <FormHelperText
                                                        sx={{
                                                            marginLeft: '40px',
                                                            overflowWrap:
                                                                'word-break',
                                                        }}
                                                        error={true}
                                                    >
                                                        {wasTouched(
                                                            formik,
                                                            view.name,
                                                            props.viewIndex,
                                                            field.name
                                                        ) &&
                                                            getErrorMsg(
                                                                formik,
                                                                view.name,
                                                                props.viewIndex,
                                                                field.name
                                                            )}
                                                    </FormHelperText>
                                                </FormGroup>
                                            </div>
                                        );
                                    case ReffyFieldType.Custom:
                                        return (
                                            <div className="reffy-form-field">
                                                {field.UI!(
                                                    formik,
                                                    view.name,
                                                    props.viewIndex,
                                                    field.name,
                                                    wasTouched(
                                                        formik,
                                                        view.name,
                                                        props.viewIndex,
                                                        field.name
                                                    )
                                                        ? getErrorMsg(
                                                              formik,
                                                              view.name,
                                                              props.viewIndex,
                                                              field.name
                                                          )
                                                        : undefined
                                                )}
                                            </div>
                                        );
                                    default:
                                        return <></>;
                                }
                            })}
                        </div>
                    );
                }}
            />
        </div>
    );
}
function ReffyViewTable(props: {
    view: ReffyView;
    setSubview: any;
    setIsSubview: any;
    formik: any;
}): JSX.Element {
    const view = props.view;
    const formik = props.formik;
    const setSubview = props.setSubview;
    const setIsSubview = props.setIsSubview;

    return (
        <Stack minWidth="100%">
            <FieldArray
                name={view.name}
                render={(arrayHelper) => {
                    return (
                        <div className="reffy-form-table">
                            <TableHead style={{ minWidth: '100%' }}>
                                <TableRow className="reffy-form-table-names">
                                    <TableCell
                                        sx={{
                                            width: '100%',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        NAME
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        EDIT
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        DELETE
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formik.values[view.name].map(
                                    (v: Object, idx: number) => {
                                        const header = v[view.inputs[0].name];

                                        return (
                                            <TableRow className="reffy-form-table-values">
                                                <TableCell>
                                                    {header?.toString() && (
                                                        <Typography fontSize="20px">
                                                            {header.toString()}
                                                        </Typography>
                                                    )}
                                                    {!header.toString() &&
                                                        view.name !==
                                                            'Professional ExperienceM' &&
                                                        view.name !==
                                                            'Education' && (
                                                            <Typography>
                                                                Application{' '}
                                                                {idx + 1}
                                                                <Typography
                                                                    color="red"
                                                                    display="inline"
                                                                    component="span"
                                                                    fontWeight="bold"
                                                                >
                                                                    {' '}
                                                                    *
                                                                </Typography>
                                                            </Typography>
                                                        )}
                                                    {!header.toString() &&
                                                        view.name ===
                                                            'Professional ExperienceM' && (
                                                            <Typography>
                                                                Professional
                                                                Experience{' '}
                                                                {idx + 1}
                                                                <Typography
                                                                    color="red"
                                                                    display="inline"
                                                                    component="span"
                                                                    fontWeight="bold"
                                                                >
                                                                    {' '}
                                                                    *
                                                                </Typography>
                                                            </Typography>
                                                        )}
                                                    {!header.toString() &&
                                                        view.name ===
                                                            'Education' && (
                                                            <Typography>
                                                                Enter school
                                                                info {idx + 1}
                                                                <Typography
                                                                    color="red"
                                                                    display="inline"
                                                                    component="span"
                                                                    fontWeight="bold"
                                                                >
                                                                    {' '}
                                                                    *
                                                                </Typography>
                                                            </Typography>
                                                        )}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        type="button"
                                                        color="primary"
                                                        onClick={() => {
                                                            setSubview(idx);
                                                            setIsSubview(true);
                                                        }}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        type="button"
                                                        color="error"
                                                        onClick={() => {
                                                            arrayHelper.remove(
                                                                idx
                                                            );
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                            <Button
                                type="button"
                                variant="text"
                                color="primary"
                                sx={{ marginTop: '20px' }}
                                onClick={() => {
                                    const defaults = {};
                                    for (const field of view.inputs) {
                                        defaults[field.name] =
                                            field.default || '';
                                    }
                                    arrayHelper.push(defaults);
                                }}
                            >
                                <Add /> Add another
                            </Button>
                        </div>
                    );
                }}
            />
        </Stack>
    );
}

function ReffyReadFile(
    formik: any,
    viewName: string,
    viewIndex: number,
    fieldName: string,
    file: File
) {
    const fileReader = new FileReader();
    let base64: string = '';

    fileReader.onload = (fileLoadedEvent) => {
        base64 = fileLoadedEvent.target?.result?.toString() || '';
        ReffyFormikOnChange(
            formik,
            viewName,
            viewIndex,
            fieldName,
            `${file.name} FILENAMESPLIT ${base64}`
        );
    };
    // Convert data to base64
    fileReader.readAsDataURL(file);
}

function createImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });
}

function rotateSize(width: number, height: number, rotation: number) {
    const rotRad = (rotation * Math.PI) / 180;

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) +
            Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) +
            Math.abs(Math.cos(rotRad) * height),
    };
}

export async function CropImage(
    imgName: string,
    rawImg: string,
    pixelCrop: any,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
    save: (v: string) => void
) {
    const img = await createImage(rawImg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        console.error('could not obtain ctx for canvas');
        return;
    }

    const rotRad = (rotation * Math.PI) / 180;

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        img.width,
        img.height,
        rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-img.width / 2, -img.height / 2);

    // draw rotated image
    ctx.drawImage(img, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    const croppedImg = canvas.toDataURL('image/jpeg', 0.7);
    save(`${imgName} FILENAMESPLIT ${croppedImg}`);
}
