import { Snackbar, SnackbarContent } from '@mui/material';
import { useState } from 'react';
import './FeedbackPopup.css';

interface PopupOptions {
    message: string;
    type?: number;
    duration?: number;
    onClose?: any;
}

export interface FeedbackPopupMessage {
    isSuccess: boolean;
    message: string;
}

export default function FeedbackPopup(props: PopupOptions) {
    const [open, setOpen] = useState(true);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <Snackbar
            className="popup"
            sx={{ borderRadius: '3000px' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={props.duration ? props.duration : 3000}
            onClose={handleClose}
        >
            <SnackbarContent
                className="popup-message-container"
                style={{
                    backgroundColor: props.type !== 1 ? '#f9d37f' : '#f72c25',
                }}
                message={<span className="popup-message">{props.message}</span>}
            />
        </Snackbar>
    );
}
