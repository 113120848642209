import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../components/form/ReffyForm';
import { useNavigate } from 'react-router-dom';
import {
    Divider,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import './Login.css';
import SignInWithGoogle from './google/SignInWithGoogle';
import { useKratosLoginMutation } from '@cosmogony/common/reffy-hooks/kratos/kratos_hooks';
import { KratosLoginRequest } from '@cosmogony/common/reffy-pb/olympus/pkg/services/kratos/pb/kratos';
import { useEditUserMutation, useUserQuery } from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import { UserStatus } from '@cosmogony/reffy-pb/artemis-svc/pkg/pb/artemis';
import Loading from '../../components/loading/Loading';

export default function SignInSide() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const login = useKratosLoginMutation();
    const user = useUserQuery();

    if (
        login.error &&
        login.error?.toString().includes('verify your account')
    ) {
        navigate(
            `/auth/verify-account/${login.variables?.identifier.toString()}`
        );
    }

    if (user.isSuccess) {
        if (user.data.status === UserStatus.STATUS_UNVERIFIED) {
            navigate(`/auth/verify-account/${user.data.uuid}`);
        } else {
            navigate('/dashboard');
        }
    }

    const form: ReffyView = {
        name: 'login',
        category: 'login',
        subcategory: '',
        repeat: false,
        inputs: [
            {
                name: 'username',
                title: '',
                fieldTitle: 'Email address or username',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: true,
                default: window.localStorage.getItem('_reffy_user') || '',
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'password',
                title: '',
                fieldTitle: 'Password',
                fieldType: ReffyFieldType.Hidden,
                isRequired: true,
                repeat: true,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'rememberMe',
                title: '',
                fieldTitle: '',
                fieldType: ReffyFieldType.ConfirmCheck,
                isRequired: false,
                default:
                    window.localStorage.getItem('_reffy_user') !== null
                        ? 'true'
                        : '',
                category: 'O',
                options: ['Remember me'],
                repeat: false,
            },
        ],
    };

    const view = [form];
    const initialValues = GenerateReffyFormOptions(view);

    return (
        <>
            <div className="login-background"></div>
            <div className="login-page">
                <div className="login-container">
                    <div
                        className="login-form"
                        style={{
                            maxHeight: isMobile ? '100%' : '750px',
                            maxWidth: isMobile ? '500px' : '600px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '.5rem',
                            }}
                        >
                            <img
                                alt="logo"
                                className="login-logo"
                                src="assets/images/logos/reffy_logo.svg"
                            />
                            <Typography
                                color={'#999999'}
                                fontStyle="italic"
                                fontSize={{ md: '20px', xs: '18px' }}
                            >
                                Reference letters made easy
                            </Typography>
                        </div>
                        <Typography
                            color={'#444444'}
                            fontSize={{ md: '20px', xs: '18px' }}
                        >
                            To continue, log in to Reference Builder.
                        </Typography>

                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                }}
                            >
                                <SignInWithGoogle />
                            </div>

                            <div className="login-divider">
                                <Divider
                                    sx={{
                                        width: '40%',
                                        height: '1px',
                                        color: '#444444',
                                    }}
                                />
                                <Typography
                                    color="#999999"
                                    fontWeight="normal"
                                    fontSize={{ xs: '14px', sm: '16px' }}
                                >
                                    OR
                                </Typography>
                                <Divider
                                    sx={{ width: '40%', color: '#444444' }}
                                />
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {!!login.error && (
                                    <Typography
                                        color="error"
                                        fontSize={{ md: '16px', xs: '14px' }}
                                    >
                                        {String(login.error)}
                                    </Typography>
                                )}
                                <ReffyForm
                                    initialValues={initialValues}
                                    views={view}
                                    submitText="Login"
                                    onSubmit={(data) => {
                                        const response = data[view[0].name][0];
                                        const username = response['username'];
                                        const password = response['password'];
                                        const rememberMe =
                                            response['rememberMe'];

                                        if (rememberMe) {
                                            window.localStorage.setItem(
                                                '_reffy_user',
                                                username
                                            );
                                        } else {
                                            window.localStorage.removeItem(
                                                '_reffy_user'
                                            );
                                        }

                                        login.mutate(
                                            KratosLoginRequest.create({
                                                identifier: username,
                                                password: password,
                                            })
                                        );
                                    }}
                                    padding="0px"
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '16px',
                            }}
                        >
                            <Typography
                                color="secondary"
                                fontSize={{ md: '18px', xs: '16px' }}
                            >
                                Don't have an account?{' '}
                                <Link
                                    className="login-subtitle"
                                    href="/sign-up"
                                >
                                    Sign up
                                </Link>
                            </Typography>
                            <Typography
                                color="secondary"
                                fontSize={{ md: '18px', xs: '16px' }}
                            >
                                <Link
                                    sx={{ marginBottom: '2rem' }}
                                    color={'secondary'}
                                    className="login-subtitle"
                                    href="/forgot-password"
                                    underline="hover"
                                >
                                    Forgot your password?
                                </Link>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
