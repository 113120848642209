import {
    Box,
    Button,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import Menu from '@mui/icons-material/Menu';
import './Navbar.css';
import { useNavigate } from 'react-router';
import CreateIcon from '@mui/icons-material/Create';
import LoginIcon from '@mui/icons-material/Login';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

export default function Navbar() {
    const theme = useTheme();
    const navigate = useNavigate();
    const mobile = !useMediaQuery(theme.breakpoints.up('sm'));

    const [open, setOpen] = useState(false);

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpen(open);
        };

    return (
        <div className="navbar">
            <div className="navbar-stack">
                <Link href="/">
                    <img
                        style={{ width: mobile ? '125px' : '150px' }}
                        alt="Reference Builder Logo"
                        className="navbar-logo"
                        src="/assets/images/logos/reffy_logo.svg"
                    />
                </Link>
                <Typography>
                    <div className='navbar-image-text'>
                        by Reffy, Inc
                    </div>
                </Typography>
                {!mobile && (
                    <div className="navbar-buttons">
                        <Button
                            variant="text"
                            color="secondary"
                            href="/sign-up"
                        >
                            SIGN UP
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            href="/login"
                        >
                            LOGIN
                        </Button>
                    </div>
                )}

                {mobile && (
                    <div
                        style={{
                            width: '50px',
                            height: '50px',
                            backgroundColor: open ? '#444444' : 'white',
                            borderRadius: 10000000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1201,
                        }}
                        key="right"
                    >
                        {open ? (
                            <MenuOpenIcon
                                sx={{ zIndex: 1201, color: '#ffffff' }}
                                onClick={() => setOpen(false)}
                            >
                                <Menu />
                            </MenuOpenIcon>
                        ) : (
                            <IconButton
                                sx={{ zIndex: 1201, color: '#444444' }}
                                onClick={() => setOpen(true)}
                            >
                                <Menu />
                            </IconButton>
                        )}

                        <Drawer
                            anchor="right"
                            open={open}
                            onClose={toggleDrawer(false)}
                            variant="temporary"
                            sx={{ opacity: 0.9 }}
                        >
                            <Box
                                sx={{ height: '100%', marginTop: '100px' }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <List>
                                    {[
                                        [
                                            'Sign Up',
                                            'sign-up',
                                            <CreateIcon
                                                sx={{
                                                    width: '25px',
                                                    height: '25px',
                                                    pl: '5px',
                                                }}
                                            />,
                                        ],
                                        [
                                            'Login',
                                            'login',
                                            <LoginIcon
                                                sx={{
                                                    width: '30px',
                                                    height: '30px',
                                                }}
                                            />,
                                        ],
                                    ].map((text, _) => (
                                        <ListItem
                                            key={text[0] as string}
                                            disablePadding
                                            sx={{ padding: '0 5px 0 0' }}
                                        >
                                            <ListItemButton
                                                sx={{ color: '#444444' }}
                                                onClick={() =>
                                                    navigate(
                                                        `/${
                                                            text[1]
                                                                ? text[1]
                                                                : ''
                                                        }`
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <Typography
                                                        sx={{
                                                            color: '#444444',
                                                        }}
                                                    >
                                                        {text[2]}
                                                    </Typography>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={text[0] as string}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Drawer>
                    </div>
                )}
            </div>
        </div>
    );
}
