import * as React from 'react';
import { Link, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import './Support.css';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FeedbackPopup from '../../components/notification/FeedbackPopup';
import { DashboardHelperBoxes } from '../dashboard/dashboard_home/DashboardHome';
import { useSupportMutation } from '@cosmogony/common/reffy-hooks/chronos/chronos_hooks';
import { SupportFormRequest } from '@cosmogony/reffy-pb/chronos-svc/pkg/pb/chronos';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
} from '../../components/form/ReffyForm';
import { Check } from '@mui/icons-material';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Support(props: { link: string }) {
    const [showFeedback, setShowFeedback] = useState(false);
    const support = useSupportMutation();

    useEffect(() => {
        if (showFeedback != false) {
            setTimeout(() => {
                setShowFeedback(false);
            }, 5000);
        }
    }, []);

    // TODO: #863gxgnu0 Create Chronos hooks with request to send email invites/organization form/support (do this in the hooks folder)

    const [expanded, setExpanded] = useState<string | false>('panel1');

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    type Question = [string, JSX.Element];

    const questions1: Question[] = [
        [
            'How does RefBuilder generate personalized letters?',
            <Typography>
                RefBuilder uses the information that you and the applicant provide 
                to create each letter. Our custom software ensures that every letter 
                is unique and tailored to each applicant.
            </Typography>,
        ],
        [
            'What’s included on the Questionnaire?',
            <Typography>
                It depends on the applicant. Your questionnaire automatically adapts 
                to each person and asks questions that are related to their specific 
                goals and relationship with you. For example, it will ask different 
                questions if you are someone’s coach or their professor. To see which 
                questions applicants will see on your questionnaire, try completing it{' '}
                <Link href={`https://${props.link}`} target="_blank" rel="noopener noreferrer">yourself</Link> as if you were in their shoes.
            </Typography>
        ],
        [
            'Can I modify the Questionnaire?',
            <Typography>
                Yes! You can add questions via the “Writer’s Desk” section of your{' '}
                <Link href="/dashboard/account">Manage Account</Link> screen. 
            </Typography>,
        ],
        [
            'Can I request additional documents or files on the Questionnaire?',
            <Typography>
                Sure! You can customize your questionnaire to request a wide range of 
                files via the “Writer’s Desk” section of your{' '}
                <Link href="/dashboard/account">Manage Account</Link> screen.
            </Typography>,
        ],
        [
            'What is the “Write Now” feature?',
            <Typography>
                The Write Now feature allows you to quickly write a personalized letter 
                by answering a few simple questions about the applicant. Click or tap{' '} 
                <Link href="/dashboard/write-now">here</Link> to try it out! 
            </Typography>,
            
        ],
        [
            'Can I edit the letters generated by RefBuilder?',
            <Typography>
                Yep! Just copy and paste the first draft of your letter into a 
                new document to make changes.
            </Typography>,
        ],
        [
            'Are AI-generated reference letters prohibited?',
            <Typography>
                Unlike graded or published writing assignments, it’s almost always 
                okay to use AI for reference letters. Just make sure to make sure 
                your letter is accurate and authentic to your views of the applicant’s 
                qualifications.
            </Typography>,
        ],
        [
            'Can I upload my own letterhead?',
            <Typography>
                Yep! Upload your letterhead in the “Personal Details” section of your 
                {' '}<Link href="/dashboard/account">Manage Account</Link> screen. 
                We’ll keep it handy for you in the “First Draft” section of each applicant’s 
                letter requests
            </Typography>,
        ],
    ];

    const questions2: Question[] = [
        [
            'What happens when I share my Questionnaire?',
            <Typography>
                Sharing your personal questionnaire link lets people request a reference 
                letter and provide details about their qualifications, applications, and 
                relationship with you. They just click the link and answer the questions. 
                Then, we’ll notify you via email when their request is ready to review! 
            </Typography>,
        ],
        [
            'Can I decline someone’s request for a letter? ',
            <Typography>
                Yes. Scroll down to the bottom of the applicant’s profile screen and tap 
                the red “Decline Request” button.
            </Typography>,
        ],
        [
            'How do I submit a letter on behalf of an applicant?',
            <Typography>
                Applicants specify the “Submission Method” for each application in the 
                “Application Information” section of their profile screen. If you need 
                additional guidance or details, contact the applicant. 
            </Typography>,
        ],
        [
            'How do I mark someone’s request as completed?',
            <Typography>
               Scroll down to the bottom of the applicant’s profile screen and tap the 
               yellow “Mark Reference As Completed” button. Note, if an applicant has 
               submitted multiple requests, each request must be separately marked as completed.
            </Typography>,
        ],
        [
            'Where are the old letter requests I received previously?',
            <Typography>
                Right here, in the <Link href="/dashboard/completed-requests">Completed Requests</Link> section of your dashboard. 
            </Typography>,
        ],
    ];

    const questions3: Question[] = [
        [
            'How much does a subscription cost?',
            <Typography>
               RefBuilder Pro subscriptions range from $19.99/mo to $29.99/mo.{' '}
               <Link href="/pricing">Explore subscription options.</Link>
            </Typography>,
        ],
        [
            'Can my entire school or department subscribe to RefBuilder?',
            <Typography>
                Yes, group accounts are available for small, medium, and large 
                institutions. To learn more, email us at sales@refbuilder.com. 
            </Typography>,
        ],
        [
            'How can I cancel my subscription?',
            <Typography>
                Users can cancel their subscription renewal at any time via the 
                {' '}<Link href="/dashboard/account">Manage Account</Link> screen.
                After canceling, you will be able to access and use your account 
                for the remainder of your previous subscription period. 
            </Typography>,
        ],
        [
            'Is there a limit to how many letters I can generate with my subscription?',
            <Typography>
                No.
            </Typography>,
        ],
        [
            'Is my personal data secure?',
            <Typography>
               Yes. We take data security very seriously, and we never sell your personal 
               information to third parties. In addition, our servers are all located in 
               the United States, and user data is not used to train AI models. 
               Here’s a detailed description of our{' '} 
               <Link href="/privacy">Privacy Policy</Link> if you’d like to learn more. 
            </Typography>,
        ],
    ];
    
    const views = [
        {
            name: 'support',
            category: 'Submit feedback and questions',
            subcategory: 'Submit feedback and questions',
            inputs: [
                {
                    name: 'name',
                    title: 'Name',
                    fieldTitle: '',
                    isRequired: true,
                    repeat: false,
                    fieldType: ReffyFieldType.ShortAnswer,
                    validate: [
                        {
                            type: ReffyFormikValidationType.Required,
                        },
                    ],
                },
                {
                    name: 'email',
                    title: 'Email',
                    fieldTitle: '',
                    isRequired: true,
                    repeat: false,
                    fieldType: ReffyFieldType.ShortAnswer,
                    validate: [
                        {
                            type: ReffyFormikValidationType.RegexMatch,
                            params: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            errMsg: "We're looking for an email in the format of john@doe.com",
                        },
                        {
                            type: ReffyFormikValidationType.Required,
                        },
                    ],
                },
                {
                    name: 'question',
                    title: 'Feedback/Question',
                    fieldTitle: '',
                    isRequired: true,
                    repeat: false,
                    fieldType: ReffyFieldType.Paragraph,
                    initBoxSize: 'medium',
                    validate: [
                        {
                            type: ReffyFormikValidationType.Required,
                        },
                    ],
                },
            ],
            repeat: false,
        },
    ];

    const initialValues = GenerateReffyFormOptions(views);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            
                {showFeedback ? (
                    <FeedbackPopup message={'Your form has been received!'} />
                ) : (
                    <></>
                )}
                
                
                
                <div style={{ maxWidth: '800px', marginBottom: '4rem' }}>
                    <Typography
                        display={'flex'}
                        justifyContent={'start'}
                        paddingLeft={'1rem'}
                        marginBottom={'0.5rem'}
                        marginTop={'3rem'}
                        variant="h6"
                        fontWeight="bold"
                        >
                        Quick How-to Video
                    </Typography>
                    <div className="youtube-video-support">
                        <div>
                            <iframe 
                                src="https://www.youtube.com/embed/OtfmjobMIXw?si=PdTqRP8iO8uieHFO" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerPolicy="strict-origin-when-cross-origin" 
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                    <Typography
                        display={'flex'}
                        justifyContent={'start'}
                        paddingLeft={'1rem'}
                        marginBottom={'0.5rem'}
                        marginTop={'3rem'}
                        variant="h6"
                        fontWeight="bold"
                    >
                        How to Write Letters:
                    </Typography>
                    {questions1.map(([q, a]) => {
                        return (
                            <Accordion
                                expanded={expanded === q}
                                onChange={handleChange(q)}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Typography>{q}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{
                                        paddingTop: '2rem',
                                        paddingBottom: '2rem',
                                        paddingLeft: '3rem',
                                        paddingRight: '3rem',
                                    }}
                                >
                                    {a}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    <Typography
                        display={'flex'}
                        justifyContent={'start'}
                        paddingLeft={'1rem'}
                        marginBottom={'0.5rem'}
                        marginTop={'3rem'}
                        variant="h6"
                        fontWeight="bold"
                    >
                        How to Manage and Submit Letters: 
                    </Typography>
                    {questions2.map(([q, a]) => {
                        return (
                            <Accordion
                                expanded={expanded === q}
                                onChange={handleChange(q)}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Typography>{q}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{
                                        paddingTop: '2rem',
                                        paddingBottom: '2rem',
                                        paddingLeft: '3rem',
                                        paddingRight: '3rem',
                                    }}
                                >
                                    {a}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    <Typography
                        display={'flex'}
                        justifyContent={'start'}
                        paddingLeft={'1rem'}
                        marginBottom={'0.5rem'}
                        marginTop={'3rem'}
                        variant="h6"
                        fontWeight="bold"
                    >
                        Subscription and Account Info: 
                    </Typography>
                    {questions3.map(([q, a]) => {
                        return (
                            <Accordion
                                expanded={expanded === q}
                                onChange={handleChange(q)}
                            >
                                <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                >
                                    <Typography>{q}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{
                                        paddingTop: '2rem',
                                        paddingBottom: '2rem',
                                        paddingLeft: '3rem',
                                        paddingRight: '3rem',
                                    }}
                                >
                                    {a}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
                <div
                    style={{
                        paddingTop: '0.5rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        background: 'white',
                        paddingBottom: '3rem',
                        marginBottom: '1rem',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {showFeedback && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '500px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '1rem',
                                    }}
                                >
                                    <Check color="primary" /> Thank you for your
                                    feedback/questions!
                                </Typography>
                            </div>
                        )}
                        {!showFeedback && (
                            <div style={{ maxWidth: '800px', width: '100%' }}>
                                <ReffyForm
                                    initialValues={initialValues}
                                    views={views}
                                    onSubmit={async (response) => {
                                        const data = response['support'][0];
                                        await support
                                            .mutateAsync(
                                                SupportFormRequest.create({
                                                    name: data['name'],
                                                    email: data['email'],
                                                    question: data['question'],
                                                })
                                            )
                                            .then(() => {
                                                setShowFeedback(true);
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    } } />
                            </div>
                        )}
                    </div>
                </div>
            </div>
    );
}
