import { Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate();
    const handlePricingClick = () => {
        navigate('/learn/user', { state: { scrollToPricing: true } });
    };
    return (
        <footer className="footer">
            <div className="footer-links">
                <div className="footer-columns">
                    <div className="footer-column">
                        <Typography
                            variant="subtitle2"
                            className="footer-column-title"
                        >
                            MORE INFO
                        </Typography>
                        <div className="footer-use-links">
                            <Link
                                href="/learn/law-school"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Law Schools
                            </Link>
                            <Link
                                href="/learn/medical-school"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Med Schools
                            </Link>
                            <Link
                                href="/learn/bschool"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Business Schools
                            </Link>
                            <Link
                                href="/learn/schools"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                                sx={{ textWrap: 'nowrap' }}
                            >
                                High Schools
                            </Link>
                            <Link
                                href="/learn/user"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Individual Users
                            </Link>
                        </div>
                    </div>

                    <div className="footer-column">
                        <Typography
                            variant="subtitle2"
                            className="footer-column-title"
                        >
                            HOW IT WORKS
                        </Typography>
                        <div className="footer-use-links">
                            <Link
                                href="/learn/user"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                The Platform
                            </Link>
                            <Link
                                onClick={handlePricingClick}
                                href="/learn/user"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Pricing
                            </Link>
                        </div>
                    </div>

                    <div className="footer-column">
                        <Typography
                            variant="subtitle2"
                            className="footer-column-title"
                        >
                            ACCOUNT
                        </Typography>
                        <div className="footer-use-links">
                            <Link
                                href="/login"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Customer Login
                            </Link>
                            <Link
                                href="/sign-up"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Sign Up
                            </Link>
                            <Link
                                href="/privacy"
                                className="footer-link"
                                color="secondary"
                                underline="hover"
                            >
                                Terms and Privacy
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyFooter />
        </footer>
    );
}

export function PrivacyFooter() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const url = 'https://reffy.ai/'
    return (
        <div className="footer-privacy">
            <div className="footer-privacy-background"></div>
            {!isMobile && (
                <div style={{ marginLeft: '4rem' }}>
                    <Link
                        className="footer-privacy-link"
                        href="/privacy"
                        variant="overline"
                        color="#cccccc"
                        underline="hover"
                    >
                        PRIVACY POLICY
                    </Link>
                    <Link
                        className="footer-privacy-link"
                        href="/terms-of-service"
                        variant="overline"
                        color="#cccccc"
                        underline="hover"
                    >
                        Terms Of Service
                    </Link>

                    <Link
                        className="footer-privacy-link"
                        href="https://reffy.ai"
                        variant="overline"
                        color="#cccccc"
                        underline="hover"
                    >
                       ABOUT US
                    </Link>
                </div>
            )}

            <div style={{ margin: !isMobile ? '0 4rem 0 0' : '0 0 0 2rem' }}>
                <Link
                    className="footer-privacy-link"
                    variant="overline"
                    color="#cccccc"
                    underline="none"
                >
                    COPYRIGHT 2024 REFFY, INC{' '}
                </Link>
                {!isMobile && (
                    <Link
                        className="footer-privacy-link"
                        variant="overline"
                        color="#cccccc"
                        underline="none"
                    >
                        ALL RIGHTS RESERVED
                    </Link>
                )}
            </div>
        </div>
    );
}
