import { useNavigate, useParams } from 'react-router-dom';
import { ReferenceCardProps } from '../../components/dashboard/ReferenceCard';
import Loading from '../../components/loading/Loading';

import DashboardFooter from './DashboardFooter';
import { DashboardNav } from './DashboardNav';
import AllRequests from './all_requests/AllRequests';
import DashboardHome from './dashboard_home/DashboardHome';
import HelpSupport from './help_and_support/HelpSupport';
import ManageAccount from './manage_account/ManageAccount';
import WriteNow from './write_now/WriteNow';
import CompleteAccount from './complete_account/CompleteAccount';

import './Dashboard.css';
import FeedbackPopup from '../../components/notification/FeedbackPopup';
import { useState } from 'react';
import {
	useUserQuery,
	useWriterQuery,
} from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import { useEnterpriseFromUUIDQuery } from '@cosmogony/common/reffy-hooks/apollo/apollo_hooks';
import {
	ResponseInformation,
	useResponsesQuery,
} from '@cosmogony/common/reffy-hooks/litae/litae_hooks';
import {
	UserModel,
	WriterModel,
} from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import { useWriterSubscriptionQuery } from '@cosmogony/common/reffy-hooks/plutus/plutus_hooks';
import { WriterSubscriptionModel } from '@cosmogony/common/reffy-pb/plutus-svc/pkg/pb/plutus';
import { RequestStatus } from '@cosmogony/reffy-pb/litae-svc/pkg/pb/litae';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';

interface ReferenceDueSoon {
	daysLeft: number;
	applicantName: string;
}

export default function Dashboard() {
	const { view } = useParams();
	const navigate = useNavigate();
	const [referenceDue, setReferenceDue] = useState(false);

	let viewIdx = 1;
	switch (view) {
		case 'all-requests':
			viewIdx = 2;
			break;
		case 'completed-requests':
			viewIdx = 3;
			break;
		case 'declined-requests':
			viewIdx = 4;
			break;
		case 'help':
			viewIdx = 5;
			break;
		case 'account':
			viewIdx = 6;
			break;
		case 'write-now':
			viewIdx = 7;
			break;
	}

	const userQuery = useUserQuery();
	const writerQuery = useWriterQuery();
	const enterpriseQuery = useEnterpriseFromUUIDQuery();
	const subscriptionQuery = useWriterSubscriptionQuery();
	const responsesQuery = useResponsesQuery();

	if (
		userQuery.isLoading ||
		responsesQuery.isLoading ||
		writerQuery.isLoading ||
		subscriptionQuery.isLoading ||
		enterpriseQuery.isLoading
	)
		return <Loading />;

	if (userQuery.isError) {
		navigate('/');
		return;
	}

	const user: UserModel = userQuery.data as UserModel;
	const writer: WriterModel = writerQuery.data as WriterModel;
	const responses = responsesQuery.data as ResponseInformation[];
	const requests = processRequests(responses);
	const subscription: WriterSubscriptionModel =
		subscriptionQuery.data as WriterSubscriptionModel;
	const enterprise: EnterpriseModel | undefined = enterpriseQuery.isError
		? undefined
		: (enterpriseQuery.data as EnterpriseModel);

	// check if user status is incomplete
	if (writerQuery.isError) {
		viewIdx = 0;
	} else if (responsesQuery.isError) {
		navigate('/');
	}

	// For showing the due date popup once per session if the writer has an application due in less than a week.
	const refDueSoonNotif: ReferenceDueSoon = getEarliestDueDate(responses);
	if (refDueSoonNotif.daysLeft <= 7 && refDueSoonNotif.daysLeft >= 0) {
		var is_modal_show = sessionStorage.getItem('applicationDueSoon');
		if (is_modal_show !== 'shown') {
			setReferenceDue(true);
			sessionStorage.setItem('applicationDueSoon', 'shown');
		}
	}
	return (
		<div
			style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
		>
			{referenceDue && (
				<FeedbackPopup
					message={`${refDueSoonNotif.applicantName}'s Reference Letter is Due in ${refDueSoonNotif.daysLeft} Days!`}
				/>
			)}
			{window.location.search.includes('success=true') && (
				<FeedbackPopup message={`Welcome to Reference Builder Pro!`} />
			)}
			{window.location.search.includes('canceled=true') && (
				<FeedbackPopup
					duration={4000}
					message={`An error occurred and your payment did not go through. Please try again.`}
				/>
			)}
			{viewIdx > 0 && (
				<DashboardNav user={user} enterprise={enterprise} />
			)}
			<div style={{ flexGrow: 1 }}>
				{viewIdx === 0 && <CompleteAccount user={user} />}
				{viewIdx === 1 && (
					<DashboardHome
						user={user}
						subscription={subscription}
						enterprise={enterprise}
						writer={writer}
						requests={requests}
					/>
				)}
				{viewIdx === 2 && (
					<AllRequests
						status={RequestStatus.REQUEST_OPEN}
						requests={requests}
					/>
				)}
				{viewIdx === 3 && (
					<AllRequests
						status={RequestStatus.REQUEST_COMPLETED}
						requests={requests}
					/>
				)}
				{viewIdx === 4 && (
					<AllRequests
						status={RequestStatus.REQUEST_DECLINED}
						requests={requests}
					/>
				)}
				{viewIdx === 5 && <HelpSupport user={user} writer={writer} />}
				{viewIdx === 6 && (
					<ManageAccount
						user={user}
						subscription={subscription}
						writer={writer}
						enterprise={enterprise}
					/>
				)}
				{viewIdx === 7 && (
					<WriteNow
						user={user}
						subscription={subscription}
						enterprise={enterprise}
						writer={writer}
					/>
				)}
			</div>
			{viewIdx > 0 && <DashboardFooter />}
		</div>
	);
}

function getEarliestDueDate(
	references: ResponseInformation[]
): ReferenceDueSoon {
	let earliestDueDate: number = Infinity;
	let applicantName: string = '';
	for (const reference of references) {
		if (
			reference.dateDue < earliestDueDate &&
			reference.status === RequestStatus.REQUEST_OPEN
		) {
			earliestDueDate = reference.dateDue;
			applicantName = reference.name;
		}
	}
	let timeDiff: number =
		new Date(earliestDueDate * 1000).getTime() - new Date().getTime();
	timeDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
	const reference: ReferenceDueSoon = {
		daysLeft: timeDiff,
		applicantName,
	};
	return reference;
}

function processRequests(references: ResponseInformation[]) {
	const seen: Map<string, ResponseInformation[]> = new Map();

	for (const reference of references) {
		const responses = seen.get(reference.email) || [];
		responses.push(reference);
		seen.set(reference.email, responses);
	}

	const responses: ReferenceCardProps[] = [];

	// metDate should reflect the due date
	seen.forEach((applicant, _) => {
		const props: ReferenceCardProps = {
			name: applicant[0].name,
			picture: applicant[0].pic.url,
			metDate:
				applicant[0].responses
					.find((r: string) => r.includes('When did we first meet'))
					?.split('MAGIC')[2]
					.trim() || 'Invalid Date',
			requests: applicant,
			ruuid: applicant[0].ruuid,
		};

		responses.push(props);
	});
	return responses;
}
