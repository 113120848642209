import { useState, useEffect, useRef } from 'react';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormikValues } from 'formik';
import { useOrganizationMutation } from '@cosmogony/reffy-hooks/chronos/chronos_hooks';
import { OrganizationFormRequest } from '@cosmogony/reffy-pb/chronos-svc/pkg/pb/chronos';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import FeedbackPopup from '../../../components/notification/FeedbackPopup';
import InfoCards from '../../../components/infoCards/InfoCards';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../../components/form/ReffyForm';
import './MedSchoolFunnel.css';

const views: ReffyView[] = [
    {
        name: 'sign-up',
        category: '',
        subcategory: '',
        inputs: [
            {
                name: 'name',
                title: 'Name:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'email',
                title: 'Email Address:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'phone',
                title: 'Phone Number:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'med-school',
                title: 'Medical School Name and Location:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'questions',
                title: 'Questions?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
            },
        ],
        repeat: false,
    },
];

var form = GenerateReffyFormOptions(views);

export default function MedSchoolFunnel() {
    const moreRef = useRef<HTMLDivElement>(null);
    const handleShowMore = () => {
        if (moreRef.current) {
            moreRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [showFeedback, setShowFeedback] = useState(false);
    const medSchool = useOrganizationMutation();

    useEffect(() => {
        if (showFeedback !== false) {
            setTimeout(() => {
                setShowFeedback(false);
            }, 5000);
        }
    }, []);

    const handleSubmit = async (data: FormikValues) => {
        const accountResponse = data['sign-up'][0];
        const name = accountResponse['name'];
        const email = accountResponse['email'];
        const phone = accountResponse['phone'];
        const medSchoolInfo = `med School: ${accountResponse['medical-school']}`;
        const questions = accountResponse['questions'];
        await medSchool
            .mutateAsync(
                OrganizationFormRequest.create({
                    name: name,
                    email: email,
                    phoneNumber: phone,
                    organizationInfo: medSchoolInfo,
                    question: questions,
                })
            )
            .then(() => {
                setShowFeedback(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="home">
            {showFeedback ? (
                <FeedbackPopup message={'Your form has been received!'} />
            ) : (
                <></>
            )}
            <Navbar />
            <div className="med_funnel-1">
                <div className="med_funnel-1-text">
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                        mb={'.5rem'}
                    >
                        Focus on patients, not paperwork
                    </Typography>
                    <Typography fontWeight="normal" color="rgb(153, 153, 153)">
                    RefBuilder streamlines traditional letters 
                    of recommendation and the written comments 
                    sections within standardized letters of evaluation
                    (SLOE) while ensuring that every student, resident,
                    and fellow receives detailed and accurate references. {' '}
                    </Typography>
                    <div className="med_funnel-1-buttons">
                        <Button
                            style={{
                                marginBottom: isMobile ? '1rem' : '0',
                                width: isMobile ? '100%' : 'auto',
                                marginRight: '1rem',
                            }}
                            color="primary"
                            variant="contained"
                            onClick={handleShowMore}
                            className="med_funnel-1-button"
                        >
                            LEARN MORE
                        </Button>
                        <Typography
                            variant="body2"
                            style={{ color: '#999999' }}
                        >
                            Our team will reach out
                        </Typography>
                    </div>
                </div>
                <div className="med_funnel-1-image">
                    <img
                        alt="medyer smiling"
                        className="med_funnel-1-image-img"
                        src="/assets/images/medschool_funnel/medschool_funnel_hero.jpg"
                    />
                </div>
            </div>

            <div className="med_funnel-3">
                <div className="med_funnel-3-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'.5rem'}
                    >
                        SIMPLIFY THE PROCESS
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                    Recommendation letters play an important role in 
                    medical education and residency matches. 
                    Why not make them easier to manage?{' '}
                    </Typography>
                </div>
            </div>

            <div className="med_funnel-4">
                <div className="med_funnel-4-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'.5rem'}
                    >
                        STOP CUTTING CORNERS
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        Students use RefBuilder to provide all of the 
                        important details about their qualifications 
                        and experience with each professor or supervisor, 
                        taking the guesswork out of evaluating their performance. 
                    </Typography>
                </div>
                <InfoCards
                    firstTitle="ACCURATE EVALUATIONS"
                    firstText="RefBuilder takes the stress out of 
                    recommendation letters and student evaluations,
                    making a complicated process more efficient and simple. "

                    secondTitle="SAVE TIME"
                    secondText="RefBuilder AI automatically crafts a first
                    draft of each letter, saving faculty up to two hours per letter."

                    thirdTitle="EASY INTEGRATION"
                    thirdText="RefBuilder can be instantly integrated into 
                    existing workflows. It will improve your current 
                    processes without needing to replace them. "
                />
            </div>
            <div className="youtube-video-med">
                <div>
                    <iframe 
                        src="https://www.youtube.com/embed/OtfmjobMIXw?si=2QFWvnNZhRjOhzDy" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerPolicy="strict-origin-when-cross-origin" 
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="med_funnel-5">
                <div className="med_funnel-5-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                        mb={'.5rem'}
                    >
                        Say no to the status quo.
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                    >
                        No more telling students to write their own 
                        letters. No more guesswork or cookie-cutter 
                        letter templates. And no more wasting valuable time. 
                    </Typography>
                </div>
            </div>

            <div ref={moreRef} className="med_funnel-6">
                <div className="med_funnel-6-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                        mb={'.5rem'}
                    >
                        Need more information?  
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                    >
                        Please fill out this form to share your 
                        inquiries and specify the information you 
                        need to determine if RefBuilder makes sense 
                        for your school.

                    </Typography>
                </div>
                <div className="med_funnel-6-form">
                    <ReffyForm
                        initialValues={form}
                        views={views}
                        onSubmit={(data) => {
                            handleSubmit(data);
                        }}
                        padding="0px"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}
