import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import { DocumentRecord } from '../../reffy-pb/common/pb/common';
import {
    CreateResponseRequest,
    EditResponseRequest,
    GetResponseRequest,
    GetWritersResponsesResponse,
    ResponseModel,
} from '../../reffy-pb/litae-svc/pkg/pb/litae';

// Get's a WriterModel using either the authenticated JWT or provided identifier
export function useCreateResponseMutation() {
    return useMutation({
        mutationFn: async (req: CreateResponseRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/litae/response',
                    CreateResponseRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);
                }
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

// Edit the response by passing any ResponseModel owned attrs
export function useEditResponseMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['edit-response'],
        mutationFn: (req: EditResponseRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/litae/response/edit',
                    EditResponseRequest.toJSON(req) as Object
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    resolve(response.payload);

                    await queryClient.cancelQueries({
                        queryKey: ['responses'],
                    });
                    await queryClient.cancelQueries({
                        queryKey: [`response-${req.ruuid}`],
                    });

                    queryClient.invalidateQueries({ queryKey: ['responses'] });
                    queryClient.invalidateQueries({
                        queryKey: [`response-${req.ruuid}`],
                    });
                }
            });
        },
        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}

// Get a single owned response query
export function useResponseQuery(req: GetResponseRequest) {
    return useQuery({
        queryKey: [`response-${req.ruuid}`],
        queryFn: async () => {
            return new Promise<ResponseInformation>(async (resolve, reject) => {
                const response = await genericHandler(
                    'GET',
                    'include',
                    `/litae/response/${req.ruuid}`
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    const r = ResponseModel.fromJSON(response.payload);
                    resolve(parseResponse(r));
                }
            });
        },

        retry: 0,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,

        onError: (err) => {
            console.error(err);
        },
    });
}

// Get a all user-owned responses query
export function useResponsesQuery(identifier?: string) {
    return useQuery({
        queryKey: ['responses'],
        queryFn: async () => {
            return new Promise<ResponseInformation[]>(
                async (resolve, reject) => {
                    const response = await genericHandler(
                        'GET',
                        'include',
                        identifier ? `/litae/responses?uuid=${identifier}` : '/litae/responses/'
                    );

                    if (!response || response.error) {
                        reject(
                            response?.payload ?? 'unable to fulfill request'
                        );
                    } else {
                        const r = GetWritersResponsesResponse.fromJSON(
                            response.payload
                        );
                        resolve(r.responses.map((v) => parseResponse(v)));
                    }
                }
            );
        },

        retry: 0,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,

        onError: (err) => {
            console.error(err);
        },
    });
}

export interface ResponseInformation extends ResponseModel {
    name: string;
    email: string;
    linkedin: string;
    bio: string;
    phoneNumber: string;
    resume: DocumentRecord;
    pic: DocumentRecord;
    title: string;
    pronouns: string;
}

function parseResponse(response: ResponseModel): ResponseInformation {
    let picture = response.documents.find((el: DocumentRecord) =>
        el.name.startsWith('1,5')
    );

    const info: ResponseInformation = {
        ...response,
        name:
            response.responses
                .find(
                    (el: string) =>
                        el.startsWith('1,1') || el.startsWith('50,1')
                )
                ?.split('MAGIC')[1] || 'Default Name',
        pronouns:
            response.responses
                .find((el: string) => el.startsWith('1,4'))
                ?.split('MAGIC')[1] || '',
        email:
            response.responses
                .find((el: string) => el.startsWith('1,2'))
                ?.split('MAGIC')[1] || String(Math.random() * 1000),
        linkedin:
            response.responses
                .find((el: string) => el.startsWith('15,1'))
                ?.split('MAGIC')[1] || '',
        bio:
            response.responses
                .find((el: string) => el.startsWith('1,6'))
                ?.split('MAGIC')[1] || '',
        resume:
            response.documents.find((el: DocumentRecord) =>
                el.name.startsWith('15,2')
            ) || DocumentRecord.create({}),
        phoneNumber:
            response.responses
                .find((el: string) => el.startsWith('1,3'))
                ?.split('MAGIC')[1] || '',
        pic: picture || DocumentRecord.create({}),
        title:
            response.responses
                .find(
                    (el: string) =>
                        el.startsWith('8,1') ||
                        el.startsWith('9,1') ||
                        el.startsWith('10,1')
                )
                ?.split('MAGIC')[1] || '',
    };

    return info;
}

// Splits responses into the current response noted by currentRuuid & returns all other responses potentially associated with the RUUID user
export function splitResponses(
    currentRuuid: string,
    responses: ResponseInformation[]
): { current: ResponseInformation | undefined; other: ResponseInformation[] } {
    let current: ResponseInformation | undefined = undefined;
    let other: ResponseInformation[] = [];

    for (let response of responses) {
        if (response.ruuid === currentRuuid) {
            current = response;
            break;
        }
    }

    if (current) {
        for (let response of responses) {
            if (
                current.email === response.email &&
                current.ruuid !== response.ruuid
            ) {
                other.push(response);
            }
        }
    }

    return { current, other };
}
