import Link from '@mui/material/Link';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../components/form/ReffyForm';
import Loading from '../../components/loading/Loading';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useKratosForgotMutation } from '@cosmogony/common/reffy-hooks/kratos/kratos_hooks';
import { KratosForgotPasswordRequest } from '@cosmogony/common/reffy-pb/olympus/pkg/services/kratos/pb/kratos';

export default function ForgotPassword() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const forgot = useKratosForgotMutation();
    const form: ReffyView = {
        name: 'fp',
        category: 'fp',
        subcategory: '',
        repeat: false,
        inputs: [
            {
                name: 'identifier',
                title: '',
                fieldTitle: 'Email or Username',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
        ],
    };

    const view = [form];
    const initialValues = GenerateReffyFormOptions(view);

    return (
        <div className="signup-page">
            <div className="signup-container">
                <div
                    className="signup-form"
                    style={{
                        maxHeight: isMobile ? '100%' : '750px',
                        maxWidth: isMobile ? '500px' : '600px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            alt="logo"
                            className="intake-logo"
                            src="/assets/images/logos/reffy_logo.svg"
                        />
                        <Typography
                            marginX={'1rem'}
                            color={'#999999'}
                            fontStyle="italic"
                            fontSize={{ md: '18px', xs: '16px' }}
                        >
                            Reference letters made easy
                        </Typography>
                    </div>
                    <Typography
                        color={'#444444'}
                        fontSize={{ md: '18px', xs: '16px' }}
                    >
                        Reset Your Password
                    </Typography>

                    {forgot.isError && (
                        <Typography className="login-err">{`Error: ${forgot.error}`}</Typography>
                    )}
                    {forgot.isLoading && <Loading />}
                    <div
                        style={{
                            flexGrow: 1,
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    >
                        {!forgot.isLoading && !forgot.isSuccess && (
                            <ReffyForm
                                initialValues={initialValues}
                                views={view}
                                onSubmit={(results) => {
                                    const response = results[view[0].name][0];
                                    const identifier = response['identifier'];
                                    console.log(identifier);
                                    forgot.mutate(
                                        KratosForgotPasswordRequest.create({
                                            identifier: identifier,
                                        })
                                    );
                                }}
                                padding="0px"
                            />
                        )}

                        {!forgot.isLoading && forgot.isSuccess && (
                            <Typography fontSize="32px" textAlign={'center'}>
                                If the email you entered is valid, you will
                                receive a link to reset your password.
                            </Typography>
                        )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '16px',
                        }}
                    >
                        <Typography
                            fontSize={{ md: '18px', xs: '16px' }}
                            mx={'1rem'}
                        >
                            Don't have an account?{' '}
                            <Link
                                className="login-subtitle"
                                href="/sign-up"
                                underline="hover"
                                color="secondary"
                                marginTop={2}
                            >
                                <span
                                    style={{
                                        color: '#f9d37f',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {' '}
                                    Sign up for Free
                                </span>
                            </Link>
                        </Typography>
                        <Typography
                            fontSize={{ md: '18px', xs: '16px' }}
                            mx={'1rem'}
                        >
                            Already have an account?{' '}
                            <Link
                                className="login-subtitle"
                                href="/login"
                                underline="hover"
                                color="secondary"
                            >
                                <span
                                    style={{
                                        color: '#f9d37f',
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Login
                                </span>
                            </Link>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
