import { Check } from '@mui/icons-material';
import {
    Button,
    List,
    ListItem,
    ListItemIcon,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../../components/form/ReffyForm';
import Loading from '../../../components/loading/Loading';
import {
    useEditUserMutation,
    useEditWriterMutation,
} from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import {
    UserModel,
    WriterModel,
} from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import ProfileAvatar from '../../../components/profileAvatar/ProfileAvatar';
import { WriterSubscriptionModel } from '@cosmogony/common/reffy-pb/plutus-svc/pkg/pb/plutus';
import { useCreateResponseMutation } from '@cosmogony/reffy-hooks';
import { Stack } from '@mui/system';
import { CreateResponseRequest } from '@cosmogony/reffy-pb/litae-svc/pkg/pb/litae';
import { Boolean } from '@cosmogony/reffy-pb/common/pb/common';
import { useQueryClient } from '@tanstack/react-query';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import { isPro } from '../../../api/api';

export default function WriteNow(props: {
    user: UserModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    writer: WriterModel;
}) {
    const { user } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const editUser = useEditUserMutation();
    const editWriter = useEditWriterMutation();
    const createResponse = useCreateResponseMutation();
    const queryClient = useQueryClient();

    if (editUser.isLoading || editWriter.isLoading) {
        return <Loading />;
    }

    function RenderForm(
        views: Array<ReffyView>,
        introFinished: boolean,
        setIntroFinished: React.Dispatch<React.SetStateAction<boolean>>,
        formFinished: boolean,
        formOwner: UserModel,
        mutation: any,
        isMobile: boolean
    ): React.ReactElement {
        const initialValues = GenerateReffyFormOptions(views);
        return (
            <Stack
                maxWidth="100%"
                minWidth="100%"
                className="intake-form"
                direction="column"
                justifyContent="center"
                height="100%"
                alignItems="center"
            >
                <Stack
                    className="intake"
                    width={isMobile ? '90%' : '60%'}
                    marginTop="6.5rem"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    {!introFinished &&
                        RenderFormIntroduction(
                            formOwner,
                            props.subscription,
                            props.enterprise,
                            setIntroFinished,
                            navigate
                        )}
                    {introFinished && !formFinished && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '15%',
                                minWidth: '100%',
                                marginTop: '3rem',
                            }}
                        >
                            <ReffyForm
                                submitText="Create Letter"
                                initialValues={initialValues}
                                views={views}
                                onSubmit={(results) => {
                                    mutation(results);
                                }}
                            />
                        </div>
                    )}
                    {formFinished && RenderFormSubmission(navigate)}
                </Stack>
            </Stack>
        );
    }

    const form: ReffyView = {
        name: 'Write Now',
        category: 'Write Now',
        subcategory:
            ' Your insights + RefBuilder AI = Highly Personalized References',
        repeat: false,
        inputs: [
            {
                name: '50,1',
                title: 'Applicant Name (First Last):',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: true,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: '50,2',
                title: 'What is the specific opportunity, objective, or circumstance for which the applicant needs a reference letter? (Describe the opportunity and/or paste the job description, etc. here.)',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: true,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1500,
                    },
                ],
            },

            {
                name: '50,3',
                title: 'How long have you known the applicant, and what is the nature of your relationship?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: true,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
            },

            {
                name: '50,4',
                title: "Which of the applicant's personal qualities, skills, or experiences make them stand out in relation to this opportunity?",
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: true,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1000,
                    },
                ],
            },
            {
                name: '50,5',
                title: "Can you recall specific instances where you collaborated or observed the applicant's qualifications in action?",
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: true,
                validate: [
                    { type: ReffyFormikValidationType.Required },
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 1500,
                    },
                ],
            },
            {
                name: '50,6',
                title: 'Is there anything else you would like to add that would help in crafting a comprehensive reference letter?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: true,
                repeat: true,
                validate: [
                    {
                        type: ReffyFormikValidationType.LengthMax,
                        params: 750,
                    },
                ],
            },
        ],
    };

    const view = [form];

    const [introFinished, setIntroFinished] = useState(false);
    const [formFinished, setFormFinished] = useState(false);

    return (
        <>
            {RenderForm(
                view,
                introFinished,
                setIntroFinished,
                formFinished,
                user,
                (values: { [x: string]: any }) => {
                    const writeNow = values['Write Now'][0];
                    const response: string[] = [];
                    for (const view of [writeNow]) {
                        for (const question of Object.keys(view)) {
                            response.push(
                                `${question} MAGIC ${view[question]}`
                            );
                        }
                    }

                    response.push(
                        `50,7 MAGIC ${Math.trunc(new Date().getTime() / 1000)}`
                    );

                    type Application = {
                        responses: string[];
                        dateDue: string;
                    };
                    const applications: Application[] = [];
                    const application = [...response];

                    applications.push({
                        responses: application,
                        dateDue: (new Date().getTime() / 1000).toString(),
                    });

                    const applicationPromises: Promise<unknown>[] = [];

                    applications.map((application) => {
                        const body = CreateResponseRequest.create({
                            ouuid: user?.uuid,
                            responses: application.responses,
                            documents: [],
                            dateDue:
                                Math.trunc(Number(application.dateDue)) || 0,
                            isWriteNow: Boolean.BOOLEAN_TRUE,
                        });

                        applicationPromises.push(
                            createResponse.mutateAsync(body)
                        );
                    });

                    Promise.allSettled(applicationPromises).then((results) => {
                        if (
                            results.find((r) => r.status === 'rejected') ===
                            undefined
                        ) {
                            queryClient.invalidateQueries({
                                queryKey: ['responses'],
                            });
                            setFormFinished(true);
                        }
                    });
                },
                isMobile
            )}
        </>
    );
}

function RenderFormIntroduction(
    formOwner: UserModel,
    subscription: WriterSubscriptionModel,
    enterprise: EnterpriseModel | undefined,
    finishForm: React.Dispatch<React.SetStateAction<boolean>>,
    navigate: NavigateFunction
): React.ReactElement {
    const isProUser: boolean = isPro(subscription, enterprise);
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                alignContent="center"
                justifyContent="center"
                sx={{
                    background: '#ffffff',
                    padding: '110px 5vw 1rem 5vw',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >
                <ProfileAvatar
                    className="reference-card-pfp"
                    sx={{
                        height: 200,
                        width: 200,
                        borderRadius: '50%',
                        border: '5px solid #ffffff',
                        alignSelf: 'center',
                        maxHeight: { xs: 233, md: 233 },
                        maxWidth: { xs: 350, md: 350 },
                        position: 'absolute',
                        top: '-100px',
                    }}
                    url={'/assets/images/writenow.png'}
                    name={formOwner.name}
                    fontSize={{ xs: '35px' }}
                />
                <Typography
                    textAlign={'left'}
                    fontSize={'18px !important'}
                    color={'#444444'}
                >
                    Don’t need to collect info from an applicant? Write Now
                    helps you create a personalized reference with AI, right
                    now.
                </Typography>

                <List style={{ flexGrow: '1' }}>
                    <ListItem
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    >
                        <ListItemIcon
                            sx={{ alignSelf: 'flex-start', minWidth: '24px' }}
                        >
                            <Check sx={{ width: '20px', color: '#888B8D' }} />
                        </ListItemIcon>
                        <div>
                            <Typography
                                color="#444444"
                                fontSize={'18px !important'}
                                lineHeight={'1.6'}
                            >
                                Answer a few questions about the applicant
                            </Typography>
                        </div>
                    </ListItem>
                    <ListItem
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    >
                        <ListItemIcon
                            sx={{ alignSelf: 'flex-start', minWidth: '24px' }}
                        >
                            <Check sx={{ width: '20px', color: '#888B8D' }} />
                        </ListItemIcon>
                        <div>
                            <Typography
                                color="#444444"
                                fontSize={'18px !important'}
                                lineHeight={'1.6'}
                            >
                                Click submit
                            </Typography>
                        </div>
                    </ListItem>
                    <ListItem
                        style={{ paddingBottom: '0px', paddingTop: '0px' }}
                    >
                        <ListItemIcon
                            sx={{ alignSelf: 'flex-start', minWidth: '24px' }}
                        >
                            <Check sx={{ width: '20px', color: '#888B8D' }} />
                        </ListItemIcon>
                        <div>
                            <Typography
                                color="#444444"
                                fontSize={'18px !important'}
                                lineHeight={'1.6'}
                            >
                                All done!
                            </Typography>
                        </div>
                    </ListItem>
                </List>

                <Typography
                    textAlign={'left'}
                    fontSize={'18px !important'}
                    color={'#444444'}
                >
                    Remember: More details = Better letters.
                </Typography>

                <Typography
                    textAlign={'left'}
                    fontSize={'18px !important'}
                    color={'#444444'}
                >
                    After you’re done, your letter will appear in the “Completed
                    Requests” section of your dashboard.
                </Typography>

                <Button
                    variant="contained"
                    sx={{
                        alignSelf: 'center',
                        width: 'fit-content',
                        height: 'fit-content',
                    }}
                    onClick={() => {
                        if (isProUser) {
                            finishForm(true);
                        } else {
                            navigate('/pricing');
                        }
                    }}
                >
                    {isProUser ? <>Continue</> : <>Upgrade to Pro</>}
                </Button>
            </Stack>
        </div>
    );
}

function RenderFormSubmission(navigate: NavigateFunction): React.ReactElement {
    const finalMessage = `Your letter will be generated promptly.`;

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                alignContent="center"
                justifyContent="center"
                sx={{
                    background: '#ffffff',
                    padding: '50px',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    position: 'relative',
                }}
            >
                <Typography
                    fontSize={{ xs: '28px', sm: '30px', md: '32px' }}
                    color={'#999999'}
                >
                    {finalMessage}
                </Typography>
                <Typography
                    fontSize={{ xs: '28px', sm: '30px', md: '32px' }}
                    color={'#999999'}
                >
                    Please return to your
                    <Button
                        variant="contained"
                        sx={{
                            alignSelf: 'center',
                            width: 'fit-content',
                            height: 'fit-content',
                            marginLeft: '1rem',
                        }}
                        onClick={() => navigate('/dashboard')}
                    >
                        Dashboard
                    </Button>
                </Typography>
            </Stack>
        </div>
    );
}
