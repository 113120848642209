import {
    Circle,
    ExpandMore,
    Layers,
    NavigateBefore,
    ContentCopy,
    LockOutlined,
    FileDownloadOutlined,
    InfoOutlined,
    Undo,
    CheckBoxOutlined,
    ArrowBack,
    NotInterested,
} from '@mui/icons-material';
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    Container,
    Divider,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    Stack,
    styled,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import {
    useUserQuery,
    useWriterQuery,
} from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import { useEnterpriseFromUUIDQuery } from '@cosmogony/common/reffy-hooks/apollo/apollo_hooks';
import {
    ResponseInformation,
    splitResponses,
    useEditResponseMutation,
    useResponsesQuery,
} from '@cosmogony/common/reffy-hooks/litae/litae_hooks';
import { useLetterQuery, useRegenerateLetterMutation } from '@cosmogony/common/reffy-hooks/hephaestus/hephaestus_hooks';
import {
    UserModel,
    WriterModel,
} from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import { Boolean } from '@cosmogony/common/reffy-pb/common/pb/common';
import {
    EditResponseRequest,
    RequestStatus,
} from '@cosmogony/common/reffy-pb/litae-svc/pkg/pb/litae';
import DashboardFooter from '../dashboard/DashboardFooter';
import { DashboardNav } from '../dashboard/DashboardNav';
import './applicant_profile.css';
import ProfileAvatar from '../../components/profileAvatar/ProfileAvatar';
import { useWriterSubscriptionQuery } from '@cosmogony/common/reffy-hooks/plutus/plutus_hooks';
import { WriterSubscriptionModel } from '@cosmogony/common/reffy-pb/plutus-svc/pkg/pb/plutus';
import { LetterStatus } from '@cosmogony/reffy-pb/hephaestus-svc/pkg/pb/hephaestus';
import GradeIcon from '@mui/icons-material/Grade';
import DeclineRefModal from '../../components/declineModal/DeclineRefModal';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';
import { isPro } from '../../api/api';
import { fontSize } from '@mui/system';

export function ApplicantProfile() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    let { ruuid } = useParams<string>();
    const navigate = useNavigate();
    const userQuery = useUserQuery();
    const enterpriseQuery = useEnterpriseFromUUIDQuery();
    const writerQuery = useWriterQuery();
    const subscriptionQuery = useWriterSubscriptionQuery();
    const responsesQuery = useResponsesQuery();
    const editResponse = useEditResponseMutation();
    const [showDeclineModal, setShowDeclineModal] = useState(false);

    if (ruuid === undefined) {
        navigate('/dashboard');
    }

    if (
        userQuery.isLoading ||
        writerQuery.isLoading ||
        subscriptionQuery.isLoading ||
        (responsesQuery.isLoading && !responsesQuery.isRefetching) ||
        enterpriseQuery.isLoading
    )
        return <Loading />;

    if (
        userQuery.isError ||
        writerQuery.isError ||
        subscriptionQuery.isError ||
        userQuery.data === undefined ||
        writerQuery.data === undefined
    ) {
        navigate('/');
    }

    if (responsesQuery.isError || !responsesQuery.data) {
        navigate('/');
    }

    const user = userQuery.data as UserModel;
    const writer = writerQuery.data as WriterModel;
    const subscription = subscriptionQuery.data as WriterSubscriptionModel;
	const enterprise: EnterpriseModel | undefined = enterpriseQuery.isError
		? undefined
		: (enterpriseQuery.data as EnterpriseModel);
    const { current, other: otherResponses } = splitResponses(
        ruuid as string,
        responsesQuery.data!
    );

    if (current === undefined) {
        navigate('/dashboard');
    }
    const response = current as ResponseInformation;
    const isWriteNow = response.isWriteNow === Boolean.BOOLEAN_TRUE;

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <DashboardNav user={user} enterprise={enterprise} />

            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    margin: isMobile
                        ? '20px 0px 50px 0px'
                        : '20px 50px 50px 50px',
                }}
            >
                <div className="magage-top-row">
                    <Typography
                        fontSize={{ md: '18px', xs: '12px' }}
                        color="error"
                    ></Typography>
                    <Button
                        sx={{
                            marginRight: 'auto',
                            marginLeft: '1rem',
                            padding: '0px',
                        }}
                        variant="text"
                        color="secondary"
                        startIcon={<ArrowBack />}
                        onClick={() => navigate('/dashboard')}
                    >
                        Dashboard
                    </Button>
                </div>
                {!isWriteNow && (
                    <RefbuilderApplicantContent
                        mobile={isMobile}
                        user={user}
                        writer={writer}
                        subscription={subscription}
                        enterprise={enterprise}
                        response={response}
                        otherResponses={otherResponses}
                    />
                )}

                {isWriteNow && (
                    <WriteNowApplicantContent
                        mobile={isMobile}
                        user={user}
                        writer={writer}
                        subscription={subscription}
                        enterprise={enterprise}
                        response={response}
                    />
                )}

                <div
                    style={{
                        marginTop: isMobile ? 0 : '50px',
                        display: 'flex',
                        gap: isMobile ? '1rem' : '',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="text"
                        color="secondary"
                        startIcon={<NavigateBefore />}
                        onClick={() => navigate('/dashboard')}
                    >
                        BACK TO DASHBOARD
                    </Button>

                    {!isWriteNow && (
                        <>
                            {isSmall ? (
                                <>
                                    {(response.status ===
                                        RequestStatus.REQUEST_OPEN ||
                                        response.status ===
                                        RequestStatus.REQUEST_DECLINED) && (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setShowDeclineModal(true);
                                                }}
                                                sx={{
                                                    backgroundColor: '#ff6961',
                                                    ':hover': {
                                                        backgroundColor: '#ff6961',
                                                    },
                                                }}
                                                startIcon={
                                                    response.status ===
                                                        RequestStatus.REQUEST_OPEN ? (
                                                        <NotInterested />
                                                    ) : (
                                                        <Undo />
                                                    )
                                                }
                                            >
                                                {response.status ===
                                                    RequestStatus.REQUEST_OPEN
                                                    ? 'Decline Reference'
                                                    : 'Reopen Declined Reference'}
                                            </Button>
                                        )}

                                    {(response.status ===
                                        RequestStatus.REQUEST_OPEN ||
                                        response.status ===
                                        RequestStatus.REQUEST_COMPLETED) && (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    editResponse.mutate(
                                                        EditResponseRequest.create({
                                                            ruuid,
                                                            luuid: response.luuid,
                                                            status:
                                                                response.status ===
                                                                    RequestStatus.REQUEST_OPEN
                                                                    ? RequestStatus.REQUEST_COMPLETED
                                                                    : RequestStatus.REQUEST_OPEN,
                                                        })
                                                    );
                                                }}
                                                sx={{
                                                    backgroundColor:
                                                        response.status ===
                                                            RequestStatus.REQUEST_COMPLETED
                                                            ? '#e9e9e9'
                                                            : 'primary',
                                                    ':hover': {
                                                        backgroundColor:
                                                            response.status ===
                                                                RequestStatus.REQUEST_COMPLETED
                                                                ? '#ff6961'
                                                                : 'primary',
                                                    },
                                                }}
                                                startIcon={
                                                    response.status ===
                                                        RequestStatus.REQUEST_OPEN ? (
                                                        <CheckBoxOutlined />
                                                    ) : (
                                                        <Undo />
                                                    )
                                                }
                                            >
                                                {response.status ===
                                                    RequestStatus.REQUEST_OPEN
                                                    ? 'MARK REFERENCE AS COMPLETED'
                                                    : 'UNMARK REFERENCE AS COMPLETED'}
                                            </Button>
                                        )}
                                </>
                            ) : (
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    {(response.status ===
                                        RequestStatus.REQUEST_OPEN ||
                                        response.status ===
                                        RequestStatus.REQUEST_DECLINED) && (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setShowDeclineModal(true);
                                                }}
                                                sx={{
                                                    backgroundColor: '#ff6961',
                                                    ':hover': {
                                                        backgroundColor: '#ff6961',
                                                    },
                                                }}
                                                startIcon={
                                                    response.status ===
                                                        RequestStatus.REQUEST_OPEN ? (
                                                        <NotInterested />
                                                    ) : (
                                                        <Undo />
                                                    )
                                                }
                                            >
                                                {response.status ===
                                                    RequestStatus.REQUEST_OPEN
                                                    ? 'Decline Reference'
                                                    : 'Reopen Declined Reference'}
                                            </Button>
                                        )}

                                    {(response.status ===
                                        RequestStatus.REQUEST_OPEN ||
                                        response.status ===
                                        RequestStatus.REQUEST_COMPLETED) && (
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    editResponse.mutate(
                                                        EditResponseRequest.create({
                                                            ruuid,
                                                            luuid: response.luuid,
                                                            status:
                                                                response.status ===
                                                                    RequestStatus.REQUEST_OPEN
                                                                    ? RequestStatus.REQUEST_COMPLETED
                                                                    : RequestStatus.REQUEST_OPEN,
                                                        })
                                                    );
                                                }}
                                                sx={{
                                                    backgroundColor:
                                                        response.status ===
                                                            RequestStatus.REQUEST_COMPLETED
                                                            ? '#e9e9e9'
                                                            : 'primary',
                                                    ':hover': {
                                                        backgroundColor:
                                                            response.status ===
                                                                RequestStatus.REQUEST_COMPLETED
                                                                ? '#ff6961'
                                                                : 'primary',
                                                    },
                                                }}
                                                startIcon={
                                                    response.status ===
                                                        RequestStatus.REQUEST_OPEN ? (
                                                        <CheckBoxOutlined />
                                                    ) : (
                                                        <Undo />
                                                    )
                                                }
                                            >
                                                {response.status ===
                                                    RequestStatus.REQUEST_OPEN
                                                    ? 'MARK REFERENCE AS COMPLETED'
                                                    : 'UNMARK REFERENCE AS COMPLETED'}
                                            </Button>
                                        )}
                                </div>
                            )}
                            {showDeclineModal && current && otherResponses && (
                                <DeclineRefModal
                                    open={showDeclineModal}
                                    setOpen={(isOpen: boolean) =>
                                        setShowDeclineModal(isOpen)
                                    }
                                    currentReference={current}
                                    otherReferences={
                                        response.status ===
                                            RequestStatus.REQUEST_OPEN
                                            ? otherResponses.filter(
                                                (res) =>
                                                    res.status ===
                                                    RequestStatus.REQUEST_OPEN
                                            )
                                            : otherResponses.filter(
                                                (res) =>
                                                    res.status ===
                                                    RequestStatus.REQUEST_DECLINED
                                            )
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            <DashboardFooter />
        </div>
    );
}

function RefbuilderApplicantContent(props: {
    mobile: boolean;
    user: UserModel;
    writer: WriterModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    response: ResponseInformation;
    otherResponses: ResponseInformation[];
}) {
    const {
        mobile,
        user,
        writer,
        subscription,
        enterprise,
        response,
        otherResponses,
    } = props;
    return (
        <>
            <ApplicantHeader
                mobile={mobile}
                user={user}
                writer={writer}
                subscription={subscription}
                enterprise={enterprise}
                response={response}
            />
            <ApplicationInformation
                mobile={mobile}
                user={user}
                response={response}
                otherResponses={otherResponses}
            />
            <ChatGPTLetter
                mobile={mobile}
                user={user}
                writer={writer}
                subscription={subscription}
                enterprise={enterprise}
                response={response}
            />
            <ApplicationRelationship
                mobile={mobile}
                user={user}
                response={response}
            />
            <ApplicationInterviewQuestions
                user={user}
                subscription={subscription}
                response={response}
            />
            <ApplicationStrengthsSkills
                mobile={mobile}
                user={user}
                response={response}
            />
            <ApplicantExperience mobile={mobile} response={response} />
            {response.responses.find(
                (el: string) => el.startsWith('14,') || el.startsWith('15,')
            ) && (
                    <ApplicantExperience
                        mobile={mobile}
                        response={response}
                        isWorkExperience
                    />
                )}
        </>
    );
}

function WriteNowApplicantContent(props: {
    mobile: boolean;
    user: UserModel;
    writer: WriterModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    response: ResponseInformation;
}) {
    const { mobile, user, writer, subscription, enterprise, response } = props;
    return (
        <>
            <WriteNowHeader
                mobile={mobile}
                user={user}
                writer={writer}
                subscription={subscription}
                enterprise={enterprise}
                response={response}
            />

            <WriteNowStrengthsSkills
                mobile={mobile}
                user={user}
                response={response}
            />
            <ChatGPTLetter
                mobile={mobile}
                user={user}
                writer={writer}
                subscription={subscription}
                enterprise={enterprise}
                response={response}
            />
        </>
    );
}

function ApplicantHeader(props: {
    mobile: boolean;
    user: UserModel;
    writer: WriterModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    response: ResponseInformation;
}) {
    const { mobile, response } = props;
    const isProUser: boolean = isPro(props.subscription, props.enterprise);
    const { letterHead, docName } = extractLetterHead(response, props.writer);

    return (
        <div
            style={{
                maxHeight: mobile ? '' : '277px',
                minHeight: mobile ? '' : '277px',
                width: '100%',
                display: 'flex',
                flexDirection: mobile ? 'column' : 'row',
                boxSizing: 'border-box',
            }}
        >
            <ProfileAvatar
                sx={{
                    width: 200,
                    height: 200,
                    margin: 'auto',
                    border: '5px solid white',
                }}
                url={response.pic.url || ''}
                name={props.user.name}
                fontSize={{ xs: '35px' }}
            />
            <div
                style={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    marginLeft: mobile ? 0 : '-100px',
                    marginTop: mobile ? '-100px' : 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: mobile ? '30px' : '',
                    boxSizing: 'border-box',
                }}
            >
                <Grid
                    container
                    sx={{
                        marginLeft: mobile ? 0 : '150px',
                        marginTop: mobile ? '100px' : 0,
                    }}
                    gap={mobile ? 4 : 0}
                >
                    <Grid item xs={12} md={6}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px',
                                paddingRight: mobile ? 0 : '1rem',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '11.5px',
                                }}
                            >
                                <Typography
                                    fontSize={'18px'}
                                    fontWeight="bold"
                                    color="#444444"
                                >
                                    {response.name}
                                </Typography>
                                <Typography
                                    fontSize={'14px !important'}
                                    color="#999999"
                                    fontStyle="oblique"
                                >
                                    {response.pronouns
                                        .split('/')
                                        .filter((_, i) => i !== 2)
                                        .join('/')}
                                </Typography>
                            </div>
                            <Link
                                color="primary"
                                href={`mailto:${response.email}`}
                                underline="hover"
                            >
                                {response.email}
                            </Link>
                            <Link
                                color="primary"
                                href={`tel:${response.phoneNumber}`}
                                underline="hover"
                            >
                                {response.phoneNumber}
                            </Link>

                            <Typography
                                color="#999999"
                                fontSize={'18px !important'}
                            >
                                {response.bio}
                            </Typography>
                        </div>
                    </Grid>
                    {!mobile && (
                        <Grid item xs={12} md={6}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    fontSize={'18px'}
                                    color="#444444"
                                    fontWeight="bold"
                                >
                                    QUICK ACCESS LETTER TEMPLATES
                                </Typography>
                                {!isProUser ? (
                                    <Link
                                        href={letterHead}
                                        download={docName}
                                        color="primary"
                                        style={{
                                            pointerEvents: 'none',
                                            color: 'lightgrey',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        - My Letterhead (Pro Users)
                                    </Link>
                                ) : (
                                    <Link
                                        href={letterHead}
                                        download={docName}
                                        color="primary"
                                    >
                                        - My Letterhead (Pro Users)
                                    </Link>
                                )}
                                <Link
                                    href={`/assets/templates/basic.docx`}
                                    download={docName}
                                    color="primary"
                                >
                                    - Basic Letter Template (doc)
                                </Link>
                                <Link
                                    href={`/assets/templates/detailed.docx`}
                                    download={docName}
                                    color="primary"
                                >
                                    - Detailed Letter Template (doc)
                                </Link>
                                <Link
                                    href={`/assets/templates/blank.docx`}
                                    download={docName}
                                    color="primary"
                                >
                                    - Blank Word Template (doc)
                                </Link>
                                <br />
                                <Typography
                                    fontSize={'18px'}
                                    color="#444444"
                                    fontWeight="bold"
                                >
                                    NOT SURE WHAT TO WRITE?
                                </Typography>
                                <Link
                                    href={'#'}
                                    target="_blank"
                                    color="primary"
                                >
                                    - Try RefBuilder AI! (Pro Users)
                                </Link>
                                <Link
                                    href={
                                        '/assets/docs/Reference_Builder_Quick_Guide_to_Reference_Letters.pdf'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    color="primary"
                                >
                                    - Download our free writing guide (pdf)
                                </Link>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
}

function extractLetterHead(response: ResponseInformation, writer: WriterModel) {
    const appType = response.responses.find((v: string) => v.startsWith('2,1'));
    const docName = `${response.name.trim()}_${response.title.trim()}.docx`;
    let templateUrl = 'general.docx';
    if (appType === 'Education') {
        templateUrl = 'higher_ed.docx';
    } else if (appType === 'Job applications') {
        templateUrl = 'employment.docx';
    }

    let letterHead = `/assets/templates/${templateUrl}`;
    if (writer.letterHead?.url != null && writer.letterHead?.url.length > 0) {
        letterHead = writer.letterHead?.url;
    }
    return { letterHead, docName };
}

function ChatGPTLetter(props: {
    mobile: boolean;
    user: UserModel;
    writer: WriterModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    response: ResponseInformation;
}) {
    const [expanded, setExpanded] = useState(false);
    const [copiedLetter, setCopiedLetter] = useState(false);
    const letterQuery = useLetterQuery(props.response.ruuid);
    const { mobile } = props;
    const isProUser: boolean = isPro(props.subscription, props.enterprise);
    let rawLetter = letterQuery.data?.letter || '';

    if (
        props.response.isWriteNow === Boolean.BOOLEAN_TRUE &&
        letterQuery.isSuccess &&
        letterQuery.data.status === LetterStatus.STATUS_CREATED
    ) {
        const content =
            letterQuery.data?.letter.replace(/\\"/g, '"') || `{"letter": ""}`;
        try {
            rawLetter =
                JSON.parse(content)?.letter ||
                'Failed to load AI Generated letter at this time!';
        } catch (e) {
            console.error(content, e);
            rawLetter = 'Failed to load AI Generated letter at this time!';
        }
    }

    if (letterQuery.data?.status === LetterStatus.STATUS_FAILED) {
        rawLetter =
            "Whoops!\nWe can't seem to find your generated letter!\nPlease visit Help/FAQ to contact support!";
    } else if (!isProUser) {
        rawLetter +=
            '... (upgrade to get full letter)\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n we are hiring security engineers';
    }

    const letter = rawLetter?.split('\n') || [];

    const { letterHead, docName } = extractLetterHead(
        props.response,
        props.writer
    );

    const isCustomLetterHead = letterHead === props.writer.letterHead?.url;

    if (
        letterQuery.isLoading ||
        letterQuery.data?.status === LetterStatus.STATUS_LOADING
    ) {
        return (
          <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <Loading />
            <Typography color="#cccccc">Searching the drawers for your letter...</Typography>
          </div>
        );
    }
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingTop: isProUser ? '1rem' : '2rem',
                paddingBottom: isProUser ? '1rem' : '2rem',
            }}
        >
            {!isProUser && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: '1',
                        boxSizing: 'border-box',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            background: 'rgba(245, 245, 245, 0.5)',
                        }}
                    >
                        <div
                            style={{
                                width: '350px',
                                aspectRatio: '2/1',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '1rem',
                                background: 'white',
                                boxShadow:
                                    '0px 2px 40px 0px rgba(0, 0, 0, 0.13)',
                            }}
                        >
                            <LockOutlined
                                color="secondary"
                                style={{ width: 64, height: 64 }}
                            />
                            <Typography
                                color="#999999"
                                fontSize={'24px'}
                                textAlign="center"
                            >
                                The ultimate time saver.
                            </Typography>
                            <Typography
                                marginBottom={'1rem'}
                                lineHeight={1.3}
                                fontSize={'18px'}
                                textAlign="center"
                            >
                                Instantly access a completed first draft with
                                RefBuilder Pro.
                            </Typography>
                            <Button href="/pricing" variant="contained">
                                UNLOCK PRO FEATURES
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <Card
                style={{
                    width: '100%',
                    minHeight: '186px',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    borderRadius: 0,
                    alignItems: 'center',
                }}
            >
                {!mobile ? (
                    <div className="applicant_ribbon">
                        <Typography
                            ml={'1.5rem'}
                            display={'flex'}
                            gap={'1rem'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            fontSize={{ xs: '16px' }}
                            fontWeight={'bold'}
                            color={'#fff'}
                        >
                            HERE'S YOUR FIRST DRAFT
                        </Typography>
                        <GradeIcon
                            sx={{
                                position: 'absolute',
                                right: '10px',
                                color: '#f9d37f',
                                fontSize: '25px',
                            }}
                        />
                    </div>
                ) : (
                    <div style={{ width: 'fit-content' }}>
                        <Button
                            variant="text"
                            style={{
                                marginBottom: '0',
                                width: mobile ? '100%' : '250px',
                                height: mobile ? '20px' : '38px',
                                fontSize: '16px',
                            }}
                            color="primary"
                        >
                            First Draft of Letter
                        </Button>
                    </div>
                )}
                {!mobile && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '3rem',
                            right: '2rem',
                        }}
                    >
                        <ExpandMoreComponent
                            expand={expanded}
                            onClick={() => setExpanded(!expanded)}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMore />
                        </ExpandMoreComponent>
                    </div>
                )}
                <CardContent sx={{ padding: '0', maxHeight: '230px' }}>
                    <Grid
                        container
                        rowSpacing={2}
                        sx={{ padding: mobile ? '0px' : '30px' }}
                    >
                        <Grid item xs={12} md={3} textAlign="left">
                            {mobile && (
                                <div
                                    style={{
                                        padding: 0,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        top: '3rem',
                                        right: '1rem',
                                    }}
                                >
                                    <ExpandMoreComponent
                                        expand={expanded}
                                        onClick={() => setExpanded(!expanded)}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore />
                                    </ExpandMoreComponent>
                                </div>
                            )}
                        </Grid>
                        {!expanded && (
                            <Grid
                                style={{ paddingTop: mobile ? 0 : '1rem' }}
                                item
                                xs={12}
                                md={9}
                                container
                                rowSpacing={2}
                                justifyContent={
                                    mobile ? 'center' : 'flex-start'
                                }
                            >
                                <Grid
                                    style={{
                                        paddingTop: mobile ? '1rem' : '1rem',
                                    }}
                                    item
                                    xs={mobile ? 10 : 11}
                                    justifyContent={
                                        mobile ? 'center' : 'flex-start'
                                    }
                                >
                                    <Typography
                                        color="#999999"
                                        fontSize={{ md: '18px', xs: '16px' }}
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        whiteSpace="pre-wrap"
                                    >
                                        {rawLetter}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent
                        style={{
                            paddingTop: mobile ? 0 : '1rem',
                            marginTop: mobile ? 0 : '5rem',
                        }}
                    >
                        <Grid
                            style={{ paddingTop: mobile ? 0 : '1rem' }}
                            container
                            rowSpacing={2}
                            justifyContent={'center'}
                        >
                            <Grid item justifyContent={'flex-start'} xs={11}>
                                <Typography
                                    color="black"
                                    fontSize={{ md: '18px', xs: '16px' }}
                                    fontWeight={'bold'}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="pre-wrap"
                                    mb={2}
                                >
                                    You're almost done!
                                </Typography>
                                <Stack
                                    spacing={2}
                                    style={{ paddingLeft: '1em' }}
                                >
                                    <Stack
                                        direction={'row'}
                                        alignItems={'baseline'}
                                        spacing={1}
                                    >
                                        <Typography
                                            color="#999"
                                            fontSize={{
                                                md: '18px',
                                                xs: '16px',
                                            }}
                                            whiteSpace="nowrap"
                                            fontWeight={'bold'}
                                        >
                                            {'Step 1: '}
                                        </Typography>
                                        <Typography
                                            color="#999"
                                            fontSize={{
                                                md: '18px',
                                                xs: '16px',
                                            }}
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            whiteSpace="pre-wrap"
                                            display={'inline'}
                                        >
                                            Open a new document:{' '}
                                            <Stack
                                                direction={'row'}
                                                display={'inline-flex'}
                                                whiteSpace={'normal'}
                                            >
                                                {isCustomLetterHead ? (
                                                    <Link
                                                        href={letterHead}
                                                        download={docName}
                                                        color={
                                                            isCustomLetterHead
                                                                ? 'primary'
                                                                : 'inherit'
                                                        }
                                                        style={{
                                                            display:
                                                                'inline-flex',
                                                        }}
                                                    >
                                                        My letterhead{' '}
                                                        <FileDownloadOutlined fontSize="small" />
                                                    </Link>
                                                ) : (
                                                    <>
                                                        <Typography
                                                            color="#999"
                                                            fontSize={{
                                                                md: '18px',
                                                                xs: '16px',
                                                            }}
                                                        >
                                                            My letterhead{' '}
                                                        </Typography>
                                                        <Tooltip
                                                            title={
                                                                'To upload and use your personal letterhead, go to Manage > Personal Details > My Letterhead'
                                                            }
                                                        >
                                                            <InfoOutlined
                                                                fontSize="small"
                                                                style={{
                                                                    margin: '0 2px',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </>
                                                )}
                                                /
                                                <Link
                                                    href={
                                                        '/assets/templates/basic_letterhead.docx'
                                                    }
                                                    download={docName}
                                                    color="primary"
                                                    style={{
                                                        display: 'inline-flex',
                                                    }}
                                                    ml={0.5}
                                                >
                                                    Basic letterhead{' '}
                                                    <FileDownloadOutlined fontSize="small" />
                                                </Link>
                                            </Stack>
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        alignItems={'baseline'}
                                        spacing={1}
                                    >
                                        <Typography
                                            color="#999"
                                            fontSize={{
                                                md: '18px',
                                                xs: '16px',
                                            }}
                                            whiteSpace="nowrap"
                                            fontWeight={'bold'}
                                        >
                                            {'Step 2: '}
                                        </Typography>
                                        <Typography
                                            color="#999"
                                            fontSize={{
                                                md: '18px',
                                                xs: '16px',
                                            }}
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            whiteSpace="pre-wrap"
                                        >
                                            Copy and paste your letter into the
                                            new document, then proofread and add
                                            your personal touch before sending.
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction={'row'}
                                        alignItems={'baseline'}
                                        spacing={1}
                                    >
                                        <Typography
                                            color="#999"
                                            fontSize={{
                                                md: '18px',
                                                xs: '16px',
                                            }}
                                            whiteSpace="nowrap"
                                            fontWeight={'bold'}
                                        >
                                            {'Step 3: '}
                                        </Typography>

                                        {props.response.isWriteNow ===
                                            Boolean.BOOLEAN_FALSE && (
                                            <Typography
                                                color="#999"
                                                fontSize={{
                                                    md: '18px',
                                                    xs: '16px',
                                                }}
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                                whiteSpace="pre-wrap"
                                            >
                                                Check the "Application
                                                Information" section above for
                                                instructions to submit your
                                                letter, then mark this request
                                                as complete using the button at
                                                the bottom of the page. Great
                                                job!
                                            </Typography>
                                        )}
                                    </Stack>
                                </Stack>
                                <div style={{margin: '2em 0'}}>
                                    <CustomizeLetter mobile={mobile} response={props.response} writer={props.writer} user={props.user} />
                                </div>
                                <Typography
                                    color="black"
                                    fontSize={{ md: '18px', xs: '16px' }}
                                    fontWeight={'bold'}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="pre-wrap"
                                >
                                    Generated Letter
                                </Typography>
                                <Button
                                    variant="text"
                                    sx={{ color: '#f9d37f', padding: 0 }}
                                    onClick={() => {
                                        if (!copiedLetter) {
                                            setTimeout(
                                                () => setCopiedLetter(false),
                                                5 * 1000
                                            );
                                            setCopiedLetter(true);
                                        }
                                        navigator.clipboard.writeText(
                                            rawLetter || ''
                                        );
                                    }}
                                >
                                    <ContentCopy
                                        style={{
                                            margin: '0',
                                            fontSize: '18px',
                                        }}
                                    />
                                    <Typography
                                        marginLeft={'5px'}
                                        textTransform={'none'}
                                        fontSize={{ md: '18px', xs: '18px' }}
                                        color={'#f9d37f'}
                                        className=""
                                    >
                                        {copiedLetter
                                            ? 'Letter copied'
                                            : 'Copy letter to clipboard'}
                                    </Typography>
                                </Button>
                            </Grid>

                            {letter.map((t: string) => {
                                if (t.length > 0) {
                                    return (
                                        <Grid
                                            item
                                            xs={11}
                                            justifyContent={
                                                mobile ? 'center' : 'flex-start'
                                            }
                                        >
                                            {
                                                <Typography
                                                    color="#999999"
                                                    fontSize={{
                                                        md: '18px',
                                                        xs: '16px',
                                                    }}
                                                >
                                                    {t}
                                                </Typography>
                                            }
                                        </Grid>
                                    );
                                }
                            })}
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    );
}

enum Boost {
    Recommend = 'recommend',
    Strongly_Recommend = 'strongly recommend',
    Highly_Recommend = 'highly recommend'
}

function CustomizeLetter(props: {mobile: boolean, response: ResponseInformation, writer: WriterModel, user: UserModel}) {
    const [expanded, setExpanded] = useState(false);
    const [boost, setBoost] = useState('');
    const [comments, setComments] = useState('');
    const [instructions, setInstructions] = useState('');
    const regenerateLetterRequest = useRegenerateLetterMutation(props.response.ruuid);

    if (regenerateLetterRequest.isLoading) {
        return <Loading />;
    }

    const regenerateLetter = () => {
        regenerateLetterRequest.mutate({
            wuuid: props.writer.uuid,
            ruuid: props.response.ruuid,
            writerName: props.user.name,
            responses: props.response.responses,
            isWriteNow: props.response.isWriteNow,
            luuid: props.response.luuid,
            boost: boost,
            comments: comments,
            instructions: instructions
        })
    }

    return (
        <Card
            style={{
                width: '100%',
                backgroundColor: 'white',
                boxShadow: 'none',
                borderRadius: 0,
                alignItems: 'center',
                marginBottom: '15px',
            }}
        >
            <CardHeader title="Advanced Letter Customization" sx={{
                '& .MuiCardHeader-title': {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    // color: '#f9d37f'
                },

                paddingLeft: 0,
                borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                borderTop: '1px solid rgba(0, 0, 0, 0.2)'
            }} 
            action={
                <ExpandMoreComponent
                    expand={expanded}
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label="show more"
                    size="small"
                >
                    <ExpandMore />
                </ExpandMoreComponent>
            }>
            </CardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent sx={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1em', borderBottom: '1px solid rgba(0, 0, 0, 0.2)', color: '#999', '& .MuiTypography-root': {color: '#999'}}}>
                    <Typography fontSize={{md: '18px', xs: '16px'}}>Use this tool to add additional insights and generate a new letter.</Typography>
                    <div>
                        <Typography fontSize={{md: '18px', xs: '16px'}}>Boost your recommendation:</Typography>
                        <ButtonGroup sx={{paddingBottom: '1em'}} size='small'>
                            <Button variant={boost === Boost.Recommend ? "contained" : "outlined"} onClick={() => setBoost(Boost.Recommend)}>Recommend</Button>
                            <Button variant={boost === Boost.Strongly_Recommend ? "contained" : "outlined"} onClick={() => setBoost(Boost.Strongly_Recommend)}>Strongly Recommend</Button>
                            <Button variant={boost === Boost.Highly_Recommend ? "contained" : "outlined"} onClick={() => setBoost(Boost.Highly_Recommend)}>Highly Recommend</Button>
                        </ButtonGroup>
                    </div>
                    <div>
                        <Typography fontSize={{md: '18px', xs: '16px'}}>Describe specific qualifications, stories, and other insights that you would like to emphasize in this letter.</Typography>
                        <TextField onChange={(e) => setComments(e.target.value)} sx={{'& input::placeholder': {fontSize: '16px'}}} placeholder="More details = better letters..."/>
                    </div>
                    <div>
                        <Typography fontSize={{md: '18px', xs: '16px'}}>Do you have any other suggestions on how to make this letter authentic to you?</Typography>
                        <TextField onChange={(e) => setInstructions(e.target.value)} sx={{'& input::placeholder': {fontSize: '16px'}}} placeholder="E.g. 'use a friendly tone' or 'Make it about two pages long'" />
                    </div>
                    <Typography fontSize={{md: '18px', xs: '16px'}}>Note, generating a new letter will overwrite the existing letter!</Typography>
                    <Button onClick={() => {
                        if (boost !== '') {
                            regenerateLetter()
                        }
                    }} variant="contained" sx={{width: 'fit-content'}}>Generate New Letter</Button>
                </CardContent>
            </Collapse>
        </Card>
    )
}

function ApplicationInformation(props: {
    mobile: boolean;
    user: UserModel;
    response: ResponseInformation;
    otherResponses: ResponseInformation[];
}) {
    const [expanded, setExpanded] = useState(false);
    const { mobile, response, otherResponses } = props;

    const [modalExpanded, setModalExpanded] = useState(false);

    let header = (
        <div style={{ width: '100%', backgroundColor: 'white' }}>
            <Grid container padding="30px 30px 30px 30px">
                <Grid item xs={12} md={3}>
                    <Typography
                        fontSize={'18px !important'}
                        color="#444444"
                        fontWeight="bold"
                    >
                        APPLYING TO:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography fontSize={'20px !important'} color="#444444">
                        {response.title}
                        {response.status === RequestStatus.REQUEST_COMPLETED &&
                            ' (Completed)'}
                        {response.status === RequestStatus.REQUEST_DECLINED &&
                            ' (Declined)'}
                    </Typography>
                </Grid>
            </Grid>
            <Divider />
        </div>
    );

    if (otherResponses.length > 0) {
        header = (
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#f9d37f',
                    position: 'relative',
                }}
            >
                <Grid
                    container
                    padding={
                        modalExpanded
                            ? '30px 30px 0 30px'
                            : '30px 30px 30px 30px'
                    }
                >
                    <Grid item xs={12} md={3}>
                        <Typography
                            fontSize={'20px !important'}
                            color="secondary"
                            fontWeight="bold"
                        >
                            APPLYING TO:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className="applicant-dropdown">
                        <Typography
                            fontSize={'20px !important'}
                            color="secondary"
                        >
                            {response.title}
                            {response.status ===
                                RequestStatus.REQUEST_COMPLETED &&
                                ' (Completed)'}
                            {response.status ===
                                RequestStatus.REQUEST_DECLINED && ' (Declined)'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Chip
                                label={
                                    modalExpanded
                                        ? 'Show fewer'
                                        : `+ ${otherResponses.length} more`
                                }
                                deleteIcon={
                                    <Layers
                                        sx={{ color: '#444444 !important' }}
                                    />
                                }
                                onClick={() => setModalExpanded(!modalExpanded)}
                                onDelete={() =>
                                    setModalExpanded(!modalExpanded)
                                }
                                sx={{
                                    marginLeft: 'auto',
                                    marginRight: '1rem',
                                    backgroundColor: 'white',
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div
                    style={{
                        width: '100%',
                        backgroundColor: '#f9d37f',
                        position: 'absolute',
                        zIndex: 1200,
                        boxShadow: '0px 20px 10px 0px rgba(0, 0, 0, 0.19)',
                    }}
                >
                    <Collapse in={modalExpanded} timeout="auto" unmountOnExit>
                        <Grid container padding="25px 46px 10px 46px">
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container>
                                    {otherResponses
                                        .sort((a, b) => {
                                            if (
                                                a['status'] ===
                                                RequestStatus.REQUEST_COMPLETED
                                            )
                                                return 1;
                                            if (a['status'] === b['status'])
                                                return 0;
                                            return -1;
                                        })
                                        .map((r) => {
                                            return (
                                                <Grid item xs={12}>
                                                    <Divider />
                                                    <Link
                                                        href={`/dashboard/requestor/${r.ruuid}`}
                                                        underline="hover"
                                                        color="secondary"
                                                    >
                                                        <Typography
                                                            marginTop="20px"
                                                            marginBottom="20px"
                                                            fontSize={
                                                                '20px !important'
                                                            }
                                                            color="secondary"
                                                        >
                                                            {r.title}
                                                            {r.status ===
                                                                RequestStatus.REQUEST_COMPLETED &&
                                                                ' (Completed)'}
                                                            {r.status ===
                                                                RequestStatus.REQUEST_DECLINED &&
                                                                ' (Declined)'}
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>
            </div>
        );
    }

    return (
        <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
            {header}
            <Card
                style={{
                    width: '100%',
                    minHeight: '186px',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    borderRadius: 0,
                    position: 'relative',
                }}
            >
                {!mobile && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                        }}
                    >
                        <ExpandMoreComponent
                            expand={expanded}
                            onClick={() => setExpanded(!expanded)}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMore />
                        </ExpandMoreComponent>
                    </div>
                )}
                <CardContent>
                    <Grid
                        container
                        rowSpacing={2}
                        sx={{ padding: mobile ? '15px' : '30px' }}
                    >
                        <Grid item xs={12} md={3} textAlign="left">
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    fontSize={'18px !important'}
                                    color="#444444"
                                    fontWeight="bold"
                                >
                                    APPLICATION INFORMATION:
                                </Typography>
                                {mobile && (
                                    <ExpandMoreComponent
                                        expand={expanded}
                                        onClick={() => setExpanded(!expanded)}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore />
                                    </ExpandMoreComponent>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography
                                            fontSize={'18px !important'}
                                            fontWeight="bold"
                                            color="#424242"
                                        >
                                            Due Date:
                                        </Typography>
                                        <Typography
                                            fontSize={'18px !important'}
                                            color="#999999"
                                        >
                                            {new Date(
                                                response.dateDue * 1000
                                            ).toLocaleDateString('en-us', {
                                                weekday: 'long',
                                                month: 'long',
                                                day: 'numeric',
                                                year: 'numeric',
                                            })}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography
                                            fontSize={'18px !important'}
                                            fontWeight="bold"
                                            color="#424242"
                                        >
                                            Submission Method:
                                        </Typography>
                                        <Typography
                                            fontSize={'18px !important'}
                                            color="#999999"
                                        >
                                            {response.responses
                                                .find(
                                                    (el: string) =>
                                                        el.startsWith('8,5') ||
                                                        el.startsWith('9,5') ||
                                                        el.startsWith('10,4')
                                                )
                                                ?.split('MAGIC')
                                                ?.at(1) || ''}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <Typography
                                            fontSize={'18px !important'}
                                            fontWeight="bold"
                                            color="#424242"
                                        >
                                            Website:
                                        </Typography>
                                        <Typography
                                            color="#999999"
                                            fontSize={'18px !important'}
                                        >
                                            {response.responses
                                                .find(
                                                    (el: string) =>
                                                        el.startsWith('8,2') ||
                                                        el.startsWith('9,2')
                                                )
                                                ?.split('MAGIC')[1] || 'None'}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography
                                            fontSize={'18px !important'}
                                            fontWeight="bold"
                                            color="#424242"
                                        >
                                            {response.responses.find(
                                                (el: string) =>
                                                    el.startsWith('8,3')
                                            )
                                                ? 'Expected Degree:'
                                                : response.responses.find(
                                                    (el: string) =>
                                                        el.startsWith('9,3')
                                                )
                                                    ? 'Job Title/Role:'
                                                    : 'Goal/Opportunity:'}
                                        </Typography>
                                        <Typography
                                            fontSize={'18px !important'}
                                            color="#999999"
                                        >
                                            {
                                                response.responses
                                                    .find(
                                                        (el: string) =>
                                                            el.startsWith(
                                                                '8,3'
                                                            ) ||
                                                            el.startsWith(
                                                                '9,3'
                                                            ) ||
                                                            el.startsWith(
                                                                '10,2'
                                                            )
                                                    )
                                                    ?.split('MAGIC')[1]
                                            }
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Grid
                            container
                            rowSpacing={2}
                            sx={{ padding: '0 15px 0 15px' }}
                        >
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container rowSpacing={2}>
                                    {response.responses
                                        .find((el: string) =>
                                            el.startsWith('2,1')
                                        )
                                        ?.split('MAGIC')[1] === 'Other' && (
                                            <Grid item xs={12}>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    fontSize={'18px !important'}
                                                    color="secondary"
                                                >
                                                    What type of application is
                                                    this?
                                                </Typography>
                                                <Typography
                                                    fontSize={'18px !important'}
                                                    color="#999999"
                                                >
                                                    {
                                                        response.responses
                                                            .find((el: string) =>
                                                                el.startsWith(
                                                                    `10,1`
                                                                )
                                                            )
                                                            ?.split('MAGIC')[1]
                                                    }
                                                </Typography>

                                                <Typography
                                                    fontWeight={'bold'}
                                                    fontSize={'18px !important'}
                                                    color="secondary"
                                                >
                                                    Which goal or opportunity are
                                                    you applying for?
                                                </Typography>
                                                <Typography
                                                    fontSize={'18px !important'}
                                                    color="#999999"
                                                >
                                                    {
                                                        response.responses
                                                            .find((el: string) =>
                                                                el.startsWith(
                                                                    '10,2'
                                                                )
                                                            )
                                                            ?.split('MAGIC')[1]
                                                    }
                                                </Typography>

                                                <Typography
                                                    fontWeight={'bold'}
                                                    fontSize={'18px !important'}
                                                    color="secondary"
                                                >
                                                    Which information or details do
                                                    you want me to know about this
                                                    opportunity?
                                                </Typography>
                                                <Typography
                                                    fontSize={'18px !important'}
                                                    color="#999999"
                                                >
                                                    {
                                                        response.responses
                                                            .find((el: string) =>
                                                                el.startsWith(
                                                                    '10,3'
                                                                )
                                                            )
                                                            ?.split('MAGIC')[1]
                                                    }
                                                </Typography>
                                            </Grid>
                                        )}

                                    {response.responses
                                        .find((el: string) =>
                                            el.startsWith('2,1')
                                        )
                                        ?.split('MAGIC')[1]
                                        ?.trim() === 'Job applications' && (
                                            <Grid item xs={12}>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    fontSize={'18px !important'}
                                                    color="secondary"
                                                >
                                                    Job Description
                                                </Typography>
                                                <Typography
                                                    fontSize={'18px !important'}
                                                    color="#999999"
                                                >
                                                    {
                                                        response.responses
                                                            .find((el: string) =>
                                                                el.startsWith(`9,4`)
                                                            )
                                                            ?.split('MAGIC')[1]
                                                    }
                                                </Typography>
                                            </Grid>
                                        )}
                                    <Grid item xs={12}>
                                        <Typography
                                            fontWeight="bold"
                                            fontSize={'18px !important'}
                                            color="secondary"
                                        >
                                            What is the most important thing(s)
                                            for me to know about this school or
                                            application when I write your
                                            reference letter?
                                        </Typography>
                                        <Typography
                                            fontSize={'18px !important'}
                                            color="#999999"
                                        >
                                            {
                                                response.responses
                                                    .find(
                                                        (el: string) =>
                                                            el.startsWith(
                                                                '8,7'
                                                            ) ||
                                                            el.startsWith(
                                                                '9,8'
                                                            ) ||
                                                            el.startsWith(
                                                                '10,6'
                                                            )
                                                    )
                                                    ?.split('MAGIC')[1]
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    );
}

function ApplicationRelationship(props: {
    mobile: boolean;
    user: UserModel;
    response: ResponseInformation;
}) {
    const { mobile, user, response } = props;
    const firstName = response.name.trim().split(' ')[0];
    const [expanded, setExpanded] = useState(false);

    return (
        <div
            style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Card
                style={{
                    width: '100%',
                    minHeight: '186px',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    borderRadius: 0,
                    position: 'relative',
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        container
                        sx={{ padding: mobile ? '15px' : '15px' }}
                        gap={mobile ? 2 : 0}
                    >
                        <Grid item xs={12} md={3}>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    fontSize={'18px !important'}
                                    fontWeight="bold"
                                >
                                    {`HOW YOU KNOW ${firstName}:`.toUpperCase()}
                                </Typography>
                                {mobile && (
                                    <ExpandMoreComponent
                                        expand={expanded}
                                        onClick={() => setExpanded(!expanded)}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore />
                                    </ExpandMoreComponent>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <List>
                                <ListItem>
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Circle
                                            sx={{
                                                width: '8px',
                                                color: '#d8d8d8',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={'18px !important'}
                                        >
                                            You were {firstName}'s{' '}
                                            {response.responses
                                                .find((el: string) =>
                                                    el.startsWith('17,1')
                                                )
                                                ?.split('MAGIC')[1]
                                                .toLowerCase()}
                                            .
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon
                                        sx={{
                                            alignSelf: 'flex-start',
                                            minWidth: '24px',
                                        }}
                                    >
                                        <Circle
                                            sx={{
                                                width: '8px',
                                                color: '#d8d8d8',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <div>
                                        <Typography
                                            color="#999999"
                                            fontSize={'18px !important'}
                                        >
                                            You met in{' '}
                                            {
                                                response.responses
                                                    .find((el: string) =>
                                                        el.startsWith('17,2')
                                                    )
                                                    ?.split('MAGIC')[1]
                                            }
                                            .
                                        </Typography>
                                    </div>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    {!mobile && (
                        <div style={{ width: '64px', height: '64px' }}>
                            <ExpandMoreComponent
                                expand={expanded}
                                onClick={() => setExpanded(!expanded)}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMore />
                            </ExpandMoreComponent>
                        </div>
                    )}
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ padding: 0 }}>
                        <Grid container sx={{ padding: '0 30px 0 30px' }}>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container gap={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            color="secondary"
                                            fontWeight="bold"
                                            fontSize={'18px !important'}
                                        >
                                            How did we meet?
                                        </Typography>
                                        <Typography
                                            color="#999999"
                                            fontSize={'18px !important'}
                                        >
                                            {
                                                response.responses
                                                    .find((el: string) =>
                                                        el.startsWith('17,3')
                                                    )
                                                    ?.split('MAGIC')[1]
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            fontWeight="bold"
                                            fontSize={'18px !important'}
                                            color="secondary"
                                        >
                                            {response.responses.find(
                                                (el: string) =>
                                                    el.startsWith('3,1')
                                            ) &&
                                                'Which of my classes have you taken?'}
                                            {response.responses.find(
                                                (el: string) =>
                                                    el.startsWith('4,1')
                                            ) &&
                                                'How have I been a mentor to you?'}
                                            {response.responses.find(
                                                (el: string) =>
                                                    el.startsWith('5,1')
                                            ) &&
                                                'In which sports have I coached you?'}
                                            {response.responses.find(
                                                (el: string) =>
                                                    el.startsWith('6,1')
                                            ) &&
                                                'How long have you been involved in our religious community, and how have you participated in various activities?'}
                                            {response.responses.find(
                                                (el: string) =>
                                                    el.startsWith('7,1')
                                            ) &&
                                                'How would you describe our relationship, and why do you think I am a good person to write a reference letter on your behalf?'}
                                        </Typography>
                                        <Typography
                                            fontSize={'18px !important'}
                                            color="#999999"
                                        >
                                            {
                                                response.responses
                                                    .find(
                                                        (el: string) =>
                                                            [
                                                                3, 4, 5, 6, 7,
                                                            ].find((i) =>
                                                                el.startsWith(
                                                                    `${i},1`
                                                                )
                                                            ) !== undefined
                                                    )
                                                    ?.split('MAGIC')[1]
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            fontWeight={'bold'}
                                            fontSize={'18px !important'}
                                            color="secondary"
                                        >
                                            {response.responses.map(
                                                (el: string) => {
                                                    if (el.startsWith('3,2')) {
                                                        return 'Are there any highlights or noteworthy accomplishments from your time as my student that you would like me to mention in my letter?';
                                                    } else if (
                                                        el.startsWith('4,2')
                                                    ) {
                                                        return "What are some specific examples of achievements or activities you've completed either directly with me or under my supervision that demonstrate your qualifications for this opportunity?";
                                                    } else if (
                                                        el.startsWith('5,2')
                                                    ) {
                                                        return 'Are there any highlights or noteworthy accomplishments from the time that I’ve coached you that you would like me to mention in my letter?';
                                                    } else if (
                                                        el.startsWith('6,2')
                                                    ) {
                                                        return 'Are there any highlights or noteworthy accomplishments from the time that I’ve known you that you would like me to mention in my letter?';
                                                    } else if (
                                                        el.startsWith('7,2')
                                                    ) {
                                                        return 'What are some key achievements or standout moments from our mentor-mentee relationship that I can include in my recommendation letter for you?';
                                                    }
                                                    return undefined;
                                                }
                                            )}
                                        </Typography>
                                        <Typography
                                            fontSize={'18px !important'}
                                            color="#999999"
                                        >
                                            {
                                                response.responses
                                                    .find(
                                                        (el: string) =>
                                                            [
                                                                3, 4, 5, 6, 7,
                                                            ].find((i) =>
                                                                el.startsWith(
                                                                    `${i},2`
                                                                )
                                                            ) !== undefined
                                                    )
                                                    ?.split('MAGIC')[1]
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    );
}

function ApplicationInterviewQuestions(props: {
    user: UserModel;
    subscription: WriterSubscriptionModel;
    response: ResponseInformation;
}) {
    const { user, subscription, response } = props;

    let interviewAnswers: JSX.Element[] = [];
    if (response.responses.find((el) => el.startsWith('16,1'))) {
        interviewAnswers.push(
            <Grid item xs={12} key={1}>
                <Typography
                    fontSize={'18px !important'}
                    color="secondary"
                    fontWeight="bold"
                >
                    What are you most proud of, and why?
                </Typography>
                <Typography fontSize={'18px !important'} color="#999999">
                    {
                        response.responses
                            .find((el) => el.startsWith('16,1'))
                            ?.split('MAGIC')[1]
                    }
                </Typography>
            </Grid>
        );
    }

    interviewAnswers.push(
        <Grid item xs={12}>
            <Typography
                fontSize={'18px !important'}
                color="secondary"
                fontWeight="bold"
            >
                Is there anything else you would like me to know before you
                submit this form?
            </Typography>
            <Typography fontSize={'18px !important'} color="#999999">
                {
                    response.responses
                        .find((el: string) => el.startsWith('16,2'))
                        ?.split('MAGIC')[1]
                }
            </Typography>
        </Grid>
    );

    if (subscription.active === Boolean.BOOLEAN_TRUE) {
        const proKeys = ['99,0', '99,1', '99,2'];
        const proAnswers = response.responses
            .filter(
                (el: string) =>
                    proKeys.find((k) => el.startsWith(k)) !== undefined
            )
            .map((el: string) => {
                const v = el.split('MAGIC')[1].split('TITLESPLIT');
                const title = v[0].trim();
                const r = v[1].trim();

                return (
                    <Grid item xs={12}>
                        <Typography
                            fontSize={'18px !important'}
                            color="secondary"
                            fontWeight="bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            fontSize={'18px !important'}
                            color="#999999"
                        >
                            {r}
                        </Typography>
                    </Grid>
                );
            });

        const proDocumentKeys = ['99,3', '99,4', '99,5'];
        const proDocumentAnswers = response.responses
            .filter(
                (el: string) =>
                    proDocumentKeys.find((k) => el.startsWith(k)) !== undefined
            )
            .map((el: string) => {
                const meta = el.split('MAGIC')[1].split('TITLESPLIT');

                // this is a link upload
                if (
                    meta !== undefined &&
                    meta.length === 2 &&
                    meta[1].indexOf('FILENAMESPLIT') === -1
                ) {
                    if (meta[1].trim() !== '') {
                        return (
                            <Grid item xs={12}>
                                <Typography
                                    fontSize={'18px !important'}
                                    color="secondary"
                                    fontWeight="bold"
                                >
                                    {meta[0].trim()}
                                </Typography>
                                <Typography
                                    fontSize={'18px !important'}
                                    color="#999999"
                                >
                                    <Link
                                        href={meta[1].trim()}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {meta[1].trim()}
                                    </Link>
                                </Typography>
                            </Grid>
                        );
                    } else {
                        return;
                    }
                }

                if (meta && meta.length === 2) {
                    const nameTokens = meta[1].split('FILENAMESPLIT');
                    if (nameTokens.length === 2) {
                        const name = nameTokens[0].trim();
                        const content = nameTokens[1].trim();

                        return (
                            <Grid item xs={12}>
                                <Typography
                                    fontSize={'18px !important'}
                                    color="secondary"
                                    fontWeight="bold"
                                >
                                    {meta[0]}
                                </Typography>
                                <Link
                                    href={content}
                                    download={name}
                                    underline="hover"
                                    color="primary"
                                >
                                    {name}
                                </Link>
                            </Grid>
                        );
                    }
                }
            })
            .filter((v) => v !== undefined) as JSX.Element[];

        if (proAnswers.length > 0) {
            interviewAnswers = [
                ...interviewAnswers,
                ...proAnswers,
                ...proDocumentAnswers,
            ];
        }
    }

    return (
        <div style={{ backgroundColor: 'white' }}>
            <Grid
                container
                sx={{ padding: '30px', boxSizing: 'border-box' }}
                rowSpacing={2}
            >
                <Grid item xs={12} md={3}>
                    <Typography
                        fontSize={'18px !important'}
                        color="secondary"
                        fontWeight="bold"
                    >
                        INTERVIEW QUESTIONS:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                fontSize={'18px !important'}
                                color="secondary"
                                fontWeight="bold"
                            >
                                What are your personal or professional goals,
                                and how will this application(s) help you
                                achieve those goals?{' '}
                            </Typography>
                            <Typography
                                fontSize={'18px !important'}
                                color="#999999"
                            >
                                {
                                    response.responses
                                        .find((el: string) =>
                                            el.startsWith('2,2')
                                        )
                                        ?.split('MAGIC')[1]
                                }
                            </Typography>
                        </Grid>
                        {interviewAnswers}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

function ApplicationStrengthsSkills(props: {
    mobile: boolean;
    user: UserModel;
    response: ResponseInformation;
}) {
    const [expanded, setExpanded] = useState(false);
    const { mobile, response } = props;

    return (
        <div
            style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Card
                style={{
                    width: '100%',
                    minHeight: '186px',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    borderRadius: 0,
                    position: 'relative',
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        container
                        sx={{ padding: mobile ? '14px' : '14px' }}
                        gap={mobile ? 2 : 0}
                    >
                        <Grid item xs={12} md={3}>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    fontSize={'18px !important'}
                                    fontWeight="bold"
                                >
                                    STRENGTHS AND SKILLS:
                                </Typography>
                                {mobile && (
                                    <ExpandMoreComponent
                                        expand={expanded}
                                        onClick={() => setExpanded(!expanded)}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore />
                                    </ExpandMoreComponent>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <div style={{ display: 'flex', gap: '1 rem' }}>
                                <div style={{ width: '100%' }}>
                                    {expanded && (
                                        <Typography
                                            fontSize={'18px !important'}
                                            fontWeight="bold"
                                        >
                                            Which of your personal strengths,
                                            skills, or character traits would
                                            you like me to emphasize in my
                                            letter?
                                        </Typography>
                                    )}
                                    <Typography
                                        fontSize={'18px !important'}
                                        color="#999999"
                                    >
                                        {
                                            response.responses
                                                .find((el: string) =>
                                                    el.startsWith('11,1')
                                                )
                                                ?.split('MAGIC')[1]
                                        }
                                    </Typography>
                                </div>
                                {!mobile && (
                                    <div
                                        style={{
                                            width: '64px',
                                            height: '64px',
                                        }}
                                    >
                                        <ExpandMoreComponent
                                            expand={expanded}
                                            onClick={() =>
                                                setExpanded(!expanded)
                                            }
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMore />
                                        </ExpandMoreComponent>
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ padding: 0 }}>
                        <Grid container sx={{ padding: '0 30px 0 30px' }}>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9}>
                                <div
                                    style={{
                                        paddingRight: !mobile ? '64px' : '',
                                    }}
                                >
                                    <Typography
                                        color="secondary"
                                        fontWeight="bold"
                                        fontSize={'18px !important'}
                                    >
                                        Can you think of any specific examples
                                        or times when you demonstrated these
                                        skills and strengths during the time
                                        we’ve known each other?
                                    </Typography>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                    >
                                        {
                                            response.responses
                                                .find((el: string) =>
                                                    el.startsWith('11,2')
                                                )
                                                ?.split('MAGIC')[1]
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    );
}

function ApplicantExperience(props: {
    mobile: boolean;
    response: ResponseInformation;
    isWorkExperience?: boolean;
}) {
    const [expanded, setExpanded] = useState([false, false, false]);
    const { mobile, response, isWorkExperience } = props;
    const title = isWorkExperience ? 'Experience:' : 'Education:';
    const idx = isWorkExperience ? 14 : 12;

    const experiences = {};
    for (const r of response.responses.filter((el: string) =>
        el.startsWith(`${idx},`)
    )) {
        const id: string = r.split('MAGIC')[0].split(',')[2].trim();
        experiences[id] = [...(experiences[id] || []), r];
    }

    return (
        <div
            style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
        >
            <Grid container sx={{ padding: '30px' }}>
                <Grid item xs={12} md={3}>
                    <Typography fontWeight="bold" fontSize={'18px !important'}>
                        {title.toUpperCase()}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                        {isWorkExperience &&
                            response.responses.find((el: string) =>
                                el.startsWith('15,3')
                            ) && (
                                <Grid item xs={12} gap={2}>
                                    <Grid container gap={2}>
                                        <Grid item xs={12}>
                                            {response.resume.url && (
                                                <a
                                                    href={response.resume.url}
                                                    download={`${response.name}_Resume.pdf`.trim()}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{
                                                        color: '#f9d37f',
                                                        display: response.resume
                                                            .url
                                                            ? ''
                                                            : 'none',
                                                    }}
                                                >
                                                    - Download résumé or CV
                                                </a>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {response.linkedin && (
                                                <Link
                                                    href={response.linkedin}
                                                    target="_blank"
                                                    underline="hover"
                                                    color="primary"
                                                    display={
                                                        response.linkedin
                                                            ? ''
                                                            : 'none'
                                                    }
                                                >
                                                    - View LinkedIn Profile
                                                </Link>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                color="secondary"
                                                fontWeight="bold"
                                                fontSize={'18px !important'}
                                            >
                                                What is the most important
                                                thing(s) for me to know about
                                                your professional background?{' '}
                                            </Typography>
                                            <Typography
                                                color="#999999"
                                                fontSize={'18px !important'}
                                            >
                                                {
                                                    response.responses
                                                        .find((el: string) =>
                                                            el.startsWith(
                                                                '15,3'
                                                            )
                                                        )
                                                        ?.split('MAGIC')[1]
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                color="secondary"
                                                fontWeight="bold"
                                                fontSize={'18px !important'}
                                            >
                                                Is there anything else you want
                                                to tell me about your work
                                                experience?
                                            </Typography>
                                            <Typography
                                                color="#999999"
                                                fontSize={'18px !important'}
                                            >
                                                {
                                                    response.responses
                                                        .find((el: string) =>
                                                            el.startsWith(
                                                                '15,3'
                                                            )
                                                        )
                                                        ?.split('MAGIC')[1]
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        {Object.keys(experiences).map((id) => {
                            const experience = experiences[id];
                            return (
                                <Grid item xs={12}>
                                    <div className="applicant-experience">
                                        <div className="applicant-experience-header">
                                            <div>
                                                <div className="applicant-experience-header-name">
                                                    <Typography
                                                        fontWeight="bold"
                                                        fontSize={
                                                            '18px !important'
                                                        }
                                                    >
                                                        {
                                                            experience
                                                                .find(
                                                                    (
                                                                        el: string
                                                                    ) =>
                                                                        el.startsWith(
                                                                            `${idx},1`
                                                                        )
                                                                )
                                                                ?.split(
                                                                    'MAGIC'
                                                                )[1]
                                                        }
                                                    </Typography>
                                                    {!mobile && (
                                                        <Typography
                                                            color="#999999"
                                                            fontSize={
                                                                '18px !important'
                                                            }
                                                        >
                                                            {
                                                                experience
                                                                    .find(
                                                                        (
                                                                            el: string
                                                                        ) =>
                                                                            el.startsWith(
                                                                                `${idx},2`
                                                                            )
                                                                    )
                                                                    ?.split(
                                                                        'MAGIC'
                                                                    )[1]
                                                            }
                                                        </Typography>
                                                    )}
                                                </div>
                                                <Typography
                                                    color="#999999"
                                                    fontSize={'18px !important'}
                                                >
                                                    {
                                                        experience
                                                            .find(
                                                                (el: string) =>
                                                                    el.startsWith(
                                                                        `${idx},4`
                                                                    )
                                                            )
                                                            ?.split('MAGIC')[1]
                                                    }{' '}
                                                    -{' '}
                                                    {!isWorkExperience &&
                                                        Number(
                                                            response.responses
                                                                .find(
                                                                    (
                                                                        el: string
                                                                    ) =>
                                                                        el.startsWith(
                                                                            `${idx},3`
                                                                        )
                                                                )
                                                                ?.split(
                                                                    'MAGIC'
                                                                )[1]
                                                        ).toFixed(1)}
                                                    {isWorkExperience &&
                                                        response.responses
                                                            .find(
                                                                (el: string) =>
                                                                    el.startsWith(
                                                                        `${idx},3`
                                                                    )
                                                            )
                                                            ?.split('MAGIC')[1]}
                                                </Typography>
                                                {mobile && (
                                                    <Typography
                                                        color="#999999"
                                                        fontSize={
                                                            '18px !important'
                                                        }
                                                    >
                                                        {
                                                            response.responses
                                                                .find(
                                                                    (
                                                                        el: string
                                                                    ) =>
                                                                        el.startsWith(
                                                                            `${idx},2`
                                                                        )
                                                                )
                                                                ?.split(
                                                                    'MAGIC'
                                                                )[1]
                                                        }
                                                    </Typography>
                                                )}
                                            </div>

                                            <ExpandMoreComponent
                                                expand={expanded[id]}
                                                onClick={() =>
                                                    setExpanded(
                                                        expanded.map(
                                                            (v, new_id) =>
                                                                id ===
                                                                    `${new_id}`
                                                                    ? !v
                                                                    : v
                                                        )
                                                    )
                                                }
                                                aria-expanded={expanded[id]}
                                                aria-label="show more"
                                            >
                                                <ExpandMore />
                                            </ExpandMoreComponent>
                                        </div>
                                        <Collapse
                                            in={expanded[id]}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon
                                                        sx={{
                                                            alignSelf:
                                                                'flex-start',
                                                            minWidth: '24px',
                                                        }}
                                                    >
                                                        <Circle
                                                            sx={{
                                                                width: '8px',
                                                                color: '#d8d8d8',
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <div>
                                                        <Typography
                                                            fontWeight="bold"
                                                            fontSize={
                                                                '18px !important'
                                                            }
                                                        >
                                                            {isWorkExperience
                                                                ? 'Skills used:'
                                                                : 'Extracurricular activities:'}
                                                        </Typography>
                                                        <Typography
                                                            color="#999999"
                                                            fontSize={
                                                                '18px !important'
                                                            }
                                                        >
                                                            {
                                                                experience
                                                                    .find(
                                                                        (
                                                                            el: string
                                                                        ) =>
                                                                            el.startsWith(
                                                                                `${idx},5`
                                                                            )
                                                                    )
                                                                    ?.split(
                                                                        'MAGIC'
                                                                    )[1]
                                                            }
                                                        </Typography>
                                                    </div>
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon
                                                        sx={{
                                                            alignSelf:
                                                                'flex-start',
                                                            minWidth: '24px',
                                                        }}
                                                    >
                                                        <Circle
                                                            sx={{
                                                                width: '8px',
                                                                color: '#d8d8d8',
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <div>
                                                        <Typography
                                                            fontWeight="bold"
                                                            fontSize={
                                                                '18px !important'
                                                            }
                                                        >
                                                            {isWorkExperience
                                                                ? 'Achievements:'
                                                                : 'Awards and achievements:'}
                                                        </Typography>
                                                        <Typography
                                                            color="#999999"
                                                            fontSize={
                                                                '18px !important'
                                                            }
                                                        >
                                                            {
                                                                experience
                                                                    .find(
                                                                        (
                                                                            el: string
                                                                        ) =>
                                                                            el.startsWith(
                                                                                `${idx},6`
                                                                            )
                                                                    )
                                                                    ?.split(
                                                                        'MAGIC'
                                                                    )[1]
                                                            }
                                                        </Typography>
                                                    </div>
                                                </ListItem>
                                                {!isWorkExperience && (
                                                    <ListItem>
                                                        <ListItemIcon
                                                            sx={{
                                                                alignSelf:
                                                                    'flex-start',
                                                                minWidth:
                                                                    '24px',
                                                            }}
                                                        >
                                                            <Circle
                                                                sx={{
                                                                    width: '8px',
                                                                    color: '#d8d8d8',
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <div>
                                                            <Typography
                                                                fontWeight="bold"
                                                                fontSize={
                                                                    '18px !important'
                                                                }
                                                            >
                                                                Degree earned or
                                                                expected
                                                            </Typography>
                                                            <Typography
                                                                color="#999999"
                                                                fontSize={
                                                                    '18px !important'
                                                                }
                                                            >
                                                                {
                                                                    experience
                                                                        .find(
                                                                            (
                                                                                el: string
                                                                            ) =>
                                                                                el.startsWith(
                                                                                    `${idx},7`
                                                                                )
                                                                        )
                                                                        ?.split(
                                                                            'MAGIC'
                                                                        )[1]
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Collapse>
                                    </div>
                                    {id !== `2` && (
                                        <Divider sx={{ marginTop: '1rem' }} />
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

const ExpandMoreComponent = styled((props: any) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    backgroundColor: '#f5f5f5',
    ':hover': { backgroundColor: '#f1f1f1' },
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export function GetDays(seconds: number): number {
    return (seconds - Date.now()) / (1000 * 3600 * 24);
}

function WriteNowHeader(props: {
    mobile: boolean;
    user: UserModel;
    writer: WriterModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    response: ResponseInformation;
}) {
    const { mobile, response } = props;
    const isProUser: boolean = isPro(props.subscription, props.enterprise);
    const letterQuery = useLetterQuery(props.response.ruuid);

    let summary = '';

    if (
        letterQuery.isLoading ||
        letterQuery.data?.status === LetterStatus.STATUS_LOADING
    ) {
        summary = 'Loading AI Generated Summary...';
    } else if (letterQuery.isError) {
        summary = 'Failed to load AI Generated Summary at this time!';
    } else if (
        letterQuery.isSuccess &&
        letterQuery.data.status === LetterStatus.STATUS_CREATED
    ) {
        const content = letterQuery.data.letter.replace(/\\"/g, '"');
        try {
            summary =
                JSON.parse(content)?.summary ||
                'Failed to load AI Generated Summary at this time!';
        } catch (e) {
            console.error(content, e);
            summary = 'Failed to load AI Generated Summary at this time!';
        }
    }

    const { letterHead, docName } = extractLetterHead(response, props.writer);
    const name =
        response.responses
            .find((v: string) => v.startsWith('50,1'))
            ?.split('MAGIC')[1] || '';
    return (
        <div
            style={{
                maxHeight: mobile ? '' : '277px',
                minHeight: mobile ? '' : '277px',
                width: '100%',
                display: 'flex',
                flexDirection: mobile ? 'column' : 'row',
                boxSizing: 'border-box',
            }}
        >
            <ProfileAvatar
                sx={{
                    width: 200,
                    height: 200,
                    margin: 'auto',
                    border: '5px solid white',
                }}
                url={'/assets/images/writenow.png'}
                name={name || ''}
                fontSize={{ xs: '35px' }}
            />
            <div
                style={{
                    flexGrow: 1,
                    backgroundColor: 'white',
                    marginLeft: mobile ? 0 : '-100px',
                    marginTop: mobile ? '-100px' : 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: mobile ? '30px' : '',
                    boxSizing: 'border-box',
                }}
            >
                <Grid
                    container
                    sx={{
                        marginLeft: mobile ? 0 : '150px',
                        marginTop: mobile ? '100px' : 0,
                    }}
                    gap={mobile ? 4 : 0}
                >
                    <Grid item xs={12} md={6}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px',
                                paddingRight: mobile ? 0 : '1rem',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '11.5px',
                                }}
                            >
                                <Typography
                                    fontSize={'18px'}
                                    fontWeight="bold"
                                    color="#444444"
                                >
                                    {name || 'Write Now Letter'}
                                </Typography>
                                <Typography
                                    fontSize={'14px !important'}
                                    color="#999999"
                                    fontStyle="oblique"
                                >
                                    {response.pronouns
                                        .split('/')
                                        .filter((_, i) => i !== 2)
                                        .join('/')}
                                </Typography>
                            </div>
                            <Typography
                                color="#999999"
                                fontSize={'18px !important'}
                            >
                                {summary}
                            </Typography>
                        </div>
                    </Grid>
                    {!mobile && (
                        <Grid item xs={12} md={6}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    fontSize={'18px'}
                                    color="#444444"
                                    fontWeight="bold"
                                >
                                    QUICK ACCESS LETTER TEMPLATES
                                </Typography>
                                {!isProUser ? (
                                    <Link
                                        href={letterHead}
                                        download={docName}
                                        color="primary"
                                        style={{
                                            pointerEvents: 'none',
                                            color: 'lightgrey',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        - My Letterhead (Pro Users)
                                    </Link>
                                ) : (
                                    <Link
                                        href={letterHead}
                                        download={docName}
                                        color="primary"
                                    >
                                        - My Letterhead (Pro Users)
                                    </Link>
                                )}
                                <Link
                                    href={`/assets/templates/basic.docx`}
                                    download={docName}
                                    color="primary"
                                >
                                    - Basic Letter Template (doc)
                                </Link>
                                <Link
                                    href={`/assets/templates/detailed.docx`}
                                    download={docName}
                                    color="primary"
                                >
                                    - Detailed Letter Template (doc)
                                </Link>
                                <Link
                                    href={`/assets/templates/blank.docx`}
                                    download={docName}
                                    color="primary"
                                >
                                    - Blank Word Template (doc)
                                </Link>
                                <br />
                                <Typography
                                    fontSize={'18px'}
                                    color="#444444"
                                    fontWeight="bold"
                                >
                                    NOT SURE WHAT TO WRITE?
                                </Typography>
                                <Link
                                    href={'#'}
                                    target="_blank"
                                    color="primary"
                                >
                                    - Try RefBuilder AI! (Pro Users)
                                </Link>
                                <Link
                                    href={
                                        '/assets/docs/Reference_Builder_Quick_Guide_to_Reference_Letters.pdf'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    color="primary"
                                >
                                    - Download our free writing guide (pdf)
                                </Link>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
}

function WriteNowStrengthsSkills(props: {
    mobile: boolean;
    user: UserModel;
    response: ResponseInformation;
}) {
    const [expanded, setExpanded] = useState(false);
    const { mobile, response } = props;
    return (
        <div
            style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Card
                style={{
                    width: '100%',
                    minHeight: '186px',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    boxShadow: 'none',
                    borderRadius: 0,
                    position: 'relative',
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        container
                        sx={{ padding: mobile ? '14px' : '14px' }}
                        gap={mobile ? 2 : 0}
                    >
                        <Grid item xs={12} md={3}>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    fontSize={'18px !important'}
                                    fontWeight="bold"
                                >
                                    Applicant Information
                                </Typography>
                                {mobile && (
                                    <ExpandMoreComponent
                                        expand={expanded}
                                        onClick={() => setExpanded(!expanded)}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore />
                                    </ExpandMoreComponent>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <div style={{ display: 'flex', gap: '1 rem' }}>
                                <div style={{ width: '100%' }}>
                                    {/* {expanded && ( */}
                                    <Typography
                                        fontSize={'18px !important'}
                                        fontWeight="bold"
                                    >
                                        What is the specific opportunity,
                                        objective, or circumstance for which the
                                        applicant needs a reference letter?
                                        (Describe the opportunity and/or paste
                                        the job description, etc. here.)
                                    </Typography>
                                    {/* )} */}
                                    <Typography
                                        fontSize={'18px !important'}
                                        color="#999999"
                                    >
                                        {
                                            response.responses
                                                .find((el: string) =>
                                                    el.startsWith('50,2')
                                                )
                                                ?.split('MAGIC')[1]
                                        }
                                    </Typography>
                                </div>
                                {!mobile && (
                                    <div
                                        style={{
                                            width: '64px',
                                            height: '64px',
                                        }}
                                    >
                                        <ExpandMoreComponent
                                            expand={expanded}
                                            onClick={() =>
                                                setExpanded(!expanded)
                                            }
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <ExpandMore />
                                        </ExpandMoreComponent>
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent sx={{ padding: 0 }}>
                    <Grid container sx={{ padding: '0 30px 2rem 30px' }}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={9}>
                            <div
                                style={{
                                    paddingRight: !mobile ? '64px' : '',
                                }}
                            >
                                <Typography
                                    color="secondary"
                                    fontWeight="bold"
                                    fontSize={'18px !important'}
                                >
                                    How long have you known the applicant, and
                                    what is the nature of your relationship?
                                </Typography>
                                <Typography
                                    color="#999999"
                                    fontSize={'18px !important'}
                                >
                                    {
                                        response.responses
                                            .find((el: string) =>
                                                el.startsWith('50,3')
                                            )
                                            ?.split('MAGIC')[1]
                                    }
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ padding: 0 }}>
                        <Grid container sx={{ padding: '0 30px 2rem 30px' }}>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9}>
                                <div
                                    style={{
                                        paddingRight: !mobile ? '64px' : '',
                                    }}
                                >
                                    <Typography
                                        color="secondary"
                                        fontWeight="bold"
                                        fontSize={'18px !important'}
                                    >
                                        Which of the applicant's personal
                                        qualities, skills, or experiences make
                                        them stand out in relation to this
                                        opportunity?
                                    </Typography>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                    >
                                        {
                                            response.responses
                                                .find((el: string) =>
                                                    el.startsWith('50,4')
                                                )
                                                ?.split('MAGIC')[1]
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent sx={{ padding: 0 }}>
                        <Grid container sx={{ padding: '0 30px 2rem 30px' }}>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9}>
                                <div
                                    style={{
                                        paddingRight: !mobile ? '64px' : '',
                                    }}
                                >
                                    <Typography
                                        color="secondary"
                                        fontWeight="bold"
                                        fontSize={'18px !important'}
                                    >
                                        Can you recall specific instances where
                                        you collaborated or observed the
                                        applicant's qualifications in action?
                                    </Typography>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                    >
                                        {
                                            response.responses
                                                .find((el: string) =>
                                                    el.startsWith('50,5')
                                                )
                                                ?.split('MAGIC')[1]
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent sx={{ padding: 0 }}>
                        <Grid container sx={{ padding: '0 30px 0 30px' }}>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9}>
                                <div
                                    style={{
                                        paddingRight: !mobile ? '64px' : '',
                                    }}
                                >
                                    <Typography
                                        color="secondary"
                                        fontWeight="bold"
                                        fontSize={'18px !important'}
                                    >
                                        Is there anything else you would like to
                                        add that would help in crafting a
                                        comprehensive reference letter?
                                    </Typography>
                                    <Typography
                                        color="#999999"
                                        fontSize={'18px !important'}
                                    >
                                        {
                                            response.responses
                                                .find((el: string) =>
                                                    el.startsWith('50,6')
                                                )
                                                ?.split('MAGIC')[1]
                                        }
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    );
}
