import { Typography } from '@mui/material';
import { PrivacyFooter } from '../../components/footer/Footer';
import './NotFound.css';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
            }}
        >
            <div className="not-found-main">
                <img
                    alt="Reference Builder Logo"
                    className="intake-logo"
                    src="/assets/images/logos/reffy_logo.svg"
                    style={{ marginTop: '2rem', marginBottom: '0px' }}
                />
                <Typography marginTop={'40px'} fontSize={'40px !important'}>
                    {' '}
                    404 Error
                </Typography>
                <Typography
                    textAlign={'center'}
                    marginX={'10px'}
                    marginTop={'30px'}
                    fontSize={{ sm: '20px', xs: '15px' }}
                >
                    {' '}
                    Uh Ohh... this page does not seem to be working.
                </Typography>
                <Typography
                    textAlign={'center'}
                    marginX={'10px'}
                    marginBottom={'40px'}
                    fontSize={{ sm: '20px', xs: '15px' }}
                >
                    {' '}
                    Click on this button to start over!
                </Typography>
                <button
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        navigate('/');
                    }}
                    className="not-found-button"
                >
                    <Typography fontSize={'10px !important'} color={'#444444'}>
                        {' '}
                        REFFY
                    </Typography>
                </button>
            </div>
            <div className="not-found-footer">
                <PrivacyFooter />
            </div>
        </div>
    );
}
