import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { genericHandler } from '../../reffy-api';
import {
    LetterModel,
    LetterStatus,
    RegenerateLetterRequest,
} from '../../reffy-pb/hephaestus-svc/pkg/pb/hephaestus';

// Get a letter query
export function useLetterQuery(identifier?: string) {
    return useQuery({
        queryKey: [`letter-${identifier}`],
        queryFn: async () => {
            return new Promise<LetterModel>(async (resolve, reject) => {
                const response = await genericHandler(
                    'GET',
                    'include',
                    `/hephaestus/letter/${identifier}`
                );
                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    const letter = LetterModel.fromJSON(response.payload);
                    if (letter.status === LetterStatus.STATUS_LOADING) {
                        reject('loading');
                    } else {
                        resolve(letter);
                    }
                }
            });
        },
        retry: (_, err) => {
            return err === 'loading';
        },
        retryDelay: 10000,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,

        onError: (err) => {
            console.error(err);
        },
    });
}

export function useRegenerateLetterMutation(
    identifier: string
) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [`regenerate-letter-${identifier}`],
        mutationFn: async (req: RegenerateLetterRequest) => {
            return new Promise(async (resolve, reject) => {
                const response = await genericHandler(
                    'POST',
                    'include',
                    '/hephaestus/letter/regenerate',
                    RegenerateLetterRequest.toJSON(req) as Object,
                );

                if (!response || response.error) {
                    reject(response?.payload ?? 'unable to fulfill request');
                } else {
                    queryClient
                        .invalidateQueries([`letter-${identifier}`])
                        .then(() => {
                            resolve(response.payload);
                        });
                }
            });
        },

        retry: 0,
        onError: (err) => {
            console.error(err);
        },
    });
}
