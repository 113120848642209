import { Grid, Typography } from '@mui/material';
import {
    MiniReferenceCard,
    ReferenceCardProps,
} from '../../../components/dashboard/ReferenceCard';
import { RequestStatus } from '@cosmogony/reffy-pb/litae-svc/pkg/pb/litae';

export default function AllRequests(props: {
    status: RequestStatus;
    requests: ReferenceCardProps[];
}) {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography
                fontSize={{ xs: '18px', sm: '20px', md: '24px' }}
                mt={2}
                mb={2}
                color="#999999"
            >
                {props.status === RequestStatus.REQUEST_COMPLETED &&
                    'Completed Reference Requests'}
                {props.status === RequestStatus.REQUEST_DECLINED &&
                    'Declined Reference Requests'}
                {props.status === RequestStatus.REQUEST_OPEN &&
                    'Open Reference Requests'}
            </Typography>
            <div className="dashboard-completed-request-cards">
                <Grid container spacing={2} mb={1}>
                    {props.requests
                        .filter(
                            (response) =>
                                response.requests.find((r) => {
                                    if (
                                        r['status'] ===
                                        RequestStatus.UNRECOGNIZED
                                    )
                                        return RequestStatus.REQUEST_OPEN;
                                    return r['status'] === props.status;
                                }) !== undefined
                        )
                        .sort(
                            (
                                a,
                                b // Sort in descending order by the largest updated at time per applicant
                            ) =>
                                a.requests.reduce(
                                    (x, y) => Math.max(x, y.updatedAt),
                                    -Infinity
                                ) <
                                b.requests.reduce(
                                    (x, y) => Math.max(x, y.updatedAt),
                                    -Infinity
                                )
                                    ? 1
                                    : -1
                        )
                        .map((response: ReferenceCardProps) => {
                            return (
                                <Grid
                                    display={'flex'}
                                    justifyContent={'center'}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                >
                                    <MiniReferenceCard
                                        props={response}
                                        status={props.status}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </div>
        </div>
    );
}
