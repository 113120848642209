import { useNavigate } from 'react-router-dom';
import { UserModel } from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import {
    AccountCircle,
    ArrowBack,
    ArrowBackIos,
    Check,
    CloseSharp,
    ContentCopy,
    ForwardToInbox,
    Groups,
    HelpSharp,
    Home,
    HowToReg,
    QrCode2,
    Description,
    SupervisorAccount,
} from '@mui/icons-material';
import Menu from '@mui/icons-material/Menu';
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import ProfileAvatar from '../../components/profileAvatar/ProfileAvatar';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useInviteMutation } from '@cosmogony/reffy-hooks/chronos/chronos_hooks';
import { InviteApplicantRequest } from '@cosmogony/common/reffy-pb/chronos-svc/pkg/pb/chronos';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';

export function DashboardNav(props: {
    user: UserModel;
    enterprise: EnterpriseModel | undefined;
}) {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (!isMobile)
        return (
            <DesktopDashboardNav
                user={props.user}
                enterprise={props.enterprise}
            />
        );
    else
        return (
            <MobileDashboardNav
                user={props.user}
                enterprise={props.enterprise}
            />
        );
}

export function MobileDashboardNav(props: {
    user: UserModel;
    enterprise: EnterpriseModel | undefined;
}) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [usingQRCode, setUsingQRCode] = useState(false);
    const [sendEmail, setSendEmail] = useState('');
    const [isvalidEmail, setValidEmail] = useState(false);
    const [moreOptions, setMoreOptions] = useState(false);
    const [copyMessage, setCopyMessage] = useState('COPY MESSAGE WITH LINK');
    const [copyLink, setCopyLink] = useState('COPY LINK');
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const invite = useInviteMutation();
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleEmailValidation = (e) => {
        const reg: RegExp =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setValidEmail(reg.test(e.target.value));
        setSendEmail(e.target.value);
    };

    const handleEmailSend = async () => {
        if (isvalidEmail === true) {
            await invite
                .mutateAsync(
                    InviteApplicantRequest.create({
                        to: sendEmail,
                        name: props.user.name,
                        username: props.user.username,
                    })
                )
                .then(() => {
                    setSendEmail('');
                    setValidEmail(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const link = `refbuilder.com/intake/${props.user.username}`;
    const argonLink = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
        ? 'http://localhost:3003'
        : `https://enterprise.refbuilder.com`;

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpen(open);
        };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '1rem',
                    boxSizing: 'border-box',
                    marginLeft: '5%',
                    marginRight: '5%',
                }}
            >
                <img
                    style={{ width: '125px' }}
                    src="/assets/images/logos/reffy_logo.svg"
                    alt="reffy logo"
                    onClick={() => navigate('/dashboard')}
                />
                <div
                    style={{
                        width: '50px',
                        height: '50px',
                        backgroundColor: open ? '#444444' : 'white',
                        borderRadius: 10000000,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1201,
                    }}
                    key="right"
                >
                    {open ? (
                        <MenuOpenIcon
                            sx={{ zIndex: 1201, color: '#ffffff' }}
                            onClick={() => setOpen(false)}
                        >
                            <Menu />
                        </MenuOpenIcon>
                    ) : (
                        <IconButton
                            sx={{ zIndex: 1201, color: '#444444' }}
                            onClick={() => setOpen(true)}
                        >
                            <Menu />
                        </IconButton>
                    )}
                    <Drawer
                        anchor="right"
                        open={open}
                        onClose={toggleDrawer(false)}
                        variant="temporary"
                        sx={{ opacity: 0.9 }}
                    >
                        <Box
                            sx={{ height: '100%', marginTop: '100px' }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            <List>
                                {(props.user.uuid === props.enterprise?.owner ||
                                    props.enterprise?.admins.includes(
                                        props.user.uuid
                                    )) && (
                                    <ListItem key={'admin'} disablePadding>
                                        <ListItemButton href={argonLink}>
                                            <ListItemIcon>
                                                <SupervisorAccount />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Admin Dashboard'}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                )}
                                {[
                                    ['Dashboard', '', <Home />],
                                    [
                                        'Write Now',
                                        'write-now',
                                        <img
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '100px',
                                            }}
                                            alt="writing on paper"
                                            className="user-info-1-image-img"
                                            src="/assets/images/writenow.png"
                                        />,
                                    ],
                                    [
                                        'All Open Requests',
                                        'all-requests',
                                        <Groups />,
                                    ],
                                    [
                                        'Completed Requests',
                                        'completed-requests',
                                        <HowToReg />,
                                    ],
                                    ['Help', 'help', <HelpSharp />],
                                    [
                                        'Manage',
                                        'account',
                                        <Description />,
                                        'manage',
                                    ],
                                    [
                                        'Account Details',
                                        'account',
                                        <AccountCircle />,
                                        'details',
                                    ],
                                ].map((text, _) => (
                                    <ListItem
                                        key={text[0] as string}
                                        disablePadding
                                    >
                                        <ListItemButton
                                            onClick={() =>
                                                navigate(
                                                    `/dashboard${text[1]
                                                        ? '/' + text[1]
                                                        : ''
                                                    }`,
                                                    text[3]
                                                        ? {
                                                            state: {
                                                                scroll: text[3],
                                                            },
                                                        }
                                                        : undefined
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                {text[2]}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={text[0] as string}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Drawer>
                </div>
            </div>
            <div className="dashboard-nav-reflink">
                <Button
                    color="primary"
                    variant="contained"
                    className="dashboard-nav-reflink-button"
                    fullWidth
                    onClick={() => setMoreOptions(!moreOptions)}
                    style={{ height: '50px' }}
                >
                    SHARE MY APPLICANT QUESTIONNAIRE
                </Button>
                <div
                    className="dashboard-nav-reflink-textfield"
                    style={{ width: '100%' }}
                >
                    <Typography className="dashboard-nav-reflink-textfield-text">
                        {copiedUrl ? 'Link copied' : link}
                    </Typography>
                    <IconButton
                        onClick={() => {
                            if (!copiedUrl) {
                                setTimeout(() => setCopiedUrl(false), 5 * 1000);
                                setCopiedUrl(true);
                            }
                            navigator.clipboard.writeText(`https://${link}`);
                        }}
                        className="dashboard-nav-reflink-copy"
                    >
                        <ContentCopy color="primary" />
                    </IconButton>
                </div>
            </div>
            <div
                className="dashboard-more-options-popup"
                style={{ display: moreOptions ? 'flex' : 'none' }}
                onClick={(e) => {
                    if (
                        e.target['className'] === 'dashboard-more-options-popup'
                    ) {
                        setUsingQRCode(false);
                        setMoreOptions(false);
                    }
                }}
            >
                <div className="dashboard-more-options-popup-window">
                    <Typography color={'#999999'} fontSize={'32px !important'}>
                        Share Options
                    </Typography>
                    {usingQRCode && (
                        <div
                            className="dashboard-more-options-popup-window-methods"
                            style={{ width: '100%', height: '100%' }}
                        >
                            <Button
                                onClick={() => setUsingQRCode(false)}
                                sx={{ fontSize: '24px', color: '#444444' }}
                                variant="text"
                                startIcon={<ArrowBackIos />}
                            >
                                {' '}
                                BACK
                            </Button>
                            <QRCode value={`https://${link}`} />
                        </div>
                    )}
                    {!usingQRCode && (
                        <>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100px !important',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'rgb(245, 245, 245)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 50,
                                        boxSizing: 'border-box',
                                        paddingLeft: '1rem',
                                    }}
                                >
                                    <Typography
                                        textAlign={'center'}
                                        color={'#999999'}
                                    >
                                        {link}
                                    </Typography>
                                </div>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        width: 'auto',
                                        color: '#e6b556',
                                        paddingTop: '0',
                                        marginTop: '0',
                                    }}
                                    onClick={() => {
                                        if (copyLink === 'COPY LINK') {
                                            setTimeout(
                                                () => setCopyLink('COPY LINK'),
                                                3 * 1000
                                            );
                                            setCopyLink('LINK COPIED');
                                        }
                                        navigator.clipboard.writeText(
                                            `https://${link}`
                                        );
                                    }}
                                >
                                    {copyLink}
                                    {copyLink === 'COPY LINK' ? (
                                        <ContentCopy
                                            color="primary"
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        />
                                    ) : (
                                        <Check
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </div>
                            <div style={{ width: '100%', maxWidth: '100%' }}>
                                <div
                                    style={{
                                        backgroundColor: '#f5f5f5',
                                        padding: '1rem',
                                    }}
                                >
                                    <Typography
                                        textAlign={'center'}
                                        color={'#999999'}
                                        variant="body1"
                                    >
                                        Hi there! Please fill out the form at
                                        the following link to help me write you
                                        a great reference!
                                    </Typography>
                                    <Typography
                                        textAlign={'center'}
                                        color={'#999999'}
                                        variant="body1"
                                        fontStyle="italic"
                                    >
                                        https://{link}
                                    </Typography>
                                </div>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    sx={{ color: '#e6b556' }}
                                    onClick={() => {
                                        if (
                                            copyMessage ===
                                            'COPY MESSAGE WITH LINK'
                                        ) {
                                            setTimeout(
                                                () =>
                                                    setCopyMessage(
                                                        'COPY MESSAGE WITH LINK'
                                                    ),
                                                3 * 1000
                                            );
                                            setCopyMessage('MESSAGE COPIED');
                                        }
                                        navigator.clipboard.writeText(
                                            `Hi there!\nPlease fill out the form at the following link to help me write you a great reference!\nhttps://${link}`
                                        );
                                    }}
                                >
                                    {copyMessage}
                                    {copyMessage ===
                                        'COPY MESSAGE WITH LINK' ? (
                                        <ContentCopy
                                            color="primary"
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        />
                                    ) : (
                                        <Check
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </div>
                            <Button
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    width: '100%',
                                }}
                                variant="contained"
                                onClick={() => setUsingQRCode(true)}
                                startIcon={
                                    <QrCode2
                                        sx={{ fontSize: '32px !important' }}
                                    />
                                }
                            >
                                SHARE YOUR QR CODE
                            </Button>
                            <div
                                className="dashboard-more-options-popup-email"
                                style={{ alignItems: 'center' }}
                            >
                                <TextField
                                    error={!isvalidEmail && sendEmail !== ''}
                                    helperText={
                                        !isvalidEmail && sendEmail !== ''
                                            ? 'Please Enter a valid email'
                                            : ''
                                    }
                                    value={sendEmail}
                                    onChange={handleEmailValidation}
                                    id=""
                                    variant="filled"
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputLabel-root': {
                                            width: '100%',
                                            textAlign: 'center',
                                            display:
                                                sendEmail || isFocused
                                                    ? 'none'
                                                    : 'block',
                                            lineHeight: 'normal',
                                        },
                                        '& input': {
                                            textAlign: 'center',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            lineHeight: 'normal',
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:before': {
                                                borderBottom: 'none',
                                            },
                                            '&:after': {
                                                borderBottom: 'none',
                                            },
                                            '&:hover:before': {
                                                borderBottom: 'none',
                                            },
                                        },
                                    }}
                                    label="Enter an email here, then SEND"
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                                <Button
                                    fullWidth
                                    sx={{ color: '#e6b556' }}
                                    onClick={handleEmailSend}
                                    variant="outlined"
                                >
                                    SEND LINK TO EMAIL
                                    <ForwardToInbox
                                        sx={{
                                            marginLeft: '10px',
                                            color: '#e6b556',
                                            fontSize: '22px',
                                        }}
                                    />
                                </Button>
                            </div>
                        </>
                    )}
                    <Button
                        fullWidth
                        onClick={() => {
                            setUsingQRCode(false);
                            setMoreOptions(false);
                        }}
                        variant="contained"
                        sx={{ marginTop: '1rem', padding: '0.3rem' }}
                        endIcon={
                            <CloseSharp
                                sx={{ color: '#444444', fontSize: '22px' }}
                            />
                        }
                    >
                        CLOSE
                    </Button>
                </div>
            </div>
        </div>
    );
}

function DesktopDashboardNav(props: {
    user: UserModel;
    enterprise: EnterpriseModel | undefined;
}) {
    const navigate = useNavigate();
    const [moreOptions, setMoreOptions] = useState(false);
    const [usingQRCode, setUsingQRCode] = useState(false);
    const [sendEmail, setSendEmail] = useState('');
    const [isvalidEmail, setValidEmail] = useState(false);
    const link = `refbuilder.com/intake/${props.user.username}`;
    const [copyMessage, setCopyMessage] = useState('COPY MESSAGE WITH LINK');
    const [copyLink, setCopyLink] = useState('COPY LINK');
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const invite = useInviteMutation();
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleEmailValidation = (e) => {
        const reg: RegExp =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setValidEmail(reg.test(e.target.value));
        setSendEmail(e.target.value);
    };
    const handleEmailSend = async () => {
        if (isvalidEmail === true) {
            await invite
                .mutateAsync(
                    InviteApplicantRequest.create({
                        to: sendEmail,
                        name: props.user.name,
                        username: props.user.username,
                    })
                )
                .then(() => {
                    setSendEmail('');
                    setValidEmail(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
    const argonLink = window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
        ? 'http://localhost:3003'
        : `https://enterprise.refbuilder.com`;

    return (
        <>
            <div className="dashboard-nav">
                <img
                    className="dashboard-nav-logo"
                    src="/assets/images/logos/reffy_logo.svg"
                    alt="reffy logo"
                    onClick={() => navigate('/dashboard')}
                />

                {(props.user.uuid === props.enterprise?.owner ||
                        props.enterprise?.admins.includes(props.user.uuid)) && (
                        <Button
                            variant="text"
                            className="dashboard-nav-account-button-write-now"
                            href={argonLink}
                            style={{
                                whiteSpace: 'nowrap',
                                marginRight: '2rem',
                            }}
                        >
                            Admin Dashboard
                        </Button>
                    )}

                <div className="dashboard-nav-reflink">
                    <Button
                        color="primary"
                        variant="contained"
                        className="dashboard-nav-reflink-button"
                        fullWidth
                        onClick={() => setMoreOptions(!moreOptions)}
                        style={{ height: '50px' }}
                    >
                        SHARE MY APPLICANT QUESTIONNAIRE
                    </Button>
                    <div className="dashboard-nav-reflink-textfield">
                        <Typography className="dashboard-nav-reflink-textfield-text">
                            {copiedUrl ? 'Link copied' : link}
                        </Typography>
                        <IconButton
                            disabled={copiedUrl}
                            onClick={() => {
                                if (!copiedUrl) {
                                    setTimeout(
                                        () => setCopiedUrl(false),
                                        5 * 1000
                                    );
                                    setCopiedUrl(true);
                                }
                                navigator.clipboard.writeText(
                                    `https://${link}`
                                );
                            }}
                            className="dashboard-nav-reflink-copy"
                        >
                            <ContentCopy color="primary" />
                        </IconButton>
                    </div>
                </div>

                <Button
                    variant="text"
                    className="dashboard-nav-account-button-write-now"
                    onClick={() => navigate('/dashboard/write-now')}
                >
                    Write Now
                </Button>

                <Button
                    variant="text"
                    className="dashboard-nav-account-button-help"
                    onClick={() => navigate('/dashboard/help')}
                >
                    Help/FAQ
                </Button>
                <div>
                    <Button
                        variant="text"
                        className="dashboard-nav-account-button-account"
                        onClick={() => {
                            navigate('/dashboard/account', {
                                state: { scroll: 'manage' },
                            });
                        }}
                    >
                        <Typography
                            color={'#999999'}
                            fontSize={'16px !important'}
                            fontWeight="bold"
                        >
                            Manage
                        </Typography>
                    </Button>
                    <Button
                        variant="text"
                        style={{ minWidth: 'auto', padding: '0' }}
                        onClick={() => {
                            navigate('/dashboard/account', {
                                state: { scroll: 'details' },
                            });
                        }}
                    >
                        <ProfileAvatar
                            sx={{ width: 60, height: 60 }}
                            url={props.user.pfp?.url || ''}
                            name={props.user.name}
                        />
                    </Button>
                </div>
            </div >

            <div
                className="dashboard-more-options-popup"
                style={{ display: moreOptions ? 'flex' : 'none' }}
                onClick={(e) => {
                    if (
                        e.target['className'] === 'dashboard-more-options-popup'
                    ) {
                        setUsingQRCode(false);
                        setMoreOptions(false);
                    }
                }}
            >
                <div className="dashboard-more-options-popup-window">
                    <IconButton
                        className="dashboard-more-options-popup-close"
                        onClick={() => {
                            setUsingQRCode(false);
                            setMoreOptions(false);
                        }}
                        sx={{
                            backgroundColor: '#f9d37f',
                            marginTop: '-20px',
                            marginRight: '-50px',
                            ':hover': {
                                backgroundColor: '#FFD069',
                            },
                        }}
                    >
                        <CloseSharp sx={{ color: '#444444' }} />
                    </IconButton>

                    <Typography
                        color={'#999999'}
                        fontSize={'32px !important'}
                        marginBottom="2rem"
                    >
                        Share Options
                    </Typography>
                    {usingQRCode && (
                        <div
                            className="dashboard-more-options-popup-window-methods"
                            style={{ width: '500px', height: '60%' }}
                        >
                            <QRCode value={`https://${link}`} />
                            <Button
                                onClick={() => setUsingQRCode(false)}
                                sx={{ fontSize: '24px', color: '#444444' }}
                                variant="text"
                                startIcon={<ArrowBack />}
                            >
                                {' '}
                                BACK
                            </Button>
                        </div>
                    )}
                    {!usingQRCode && (
                        <div
                            className="dashboard-more-options-popup-window-methods"
                            style={{ width: '500px', height: '100%' }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    height: '100px !important',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        paddingLeft: '1rem',
                                    }}
                                >
                                    <Typography
                                        fontSize={{ sm: '20px' }}
                                        fontStyle="italic"
                                        color={'#999999'}
                                    >
                                        {link}
                                    </Typography>
                                </div>
                                <Button
                                    color="primary"
                                    variant="text"
                                    sx={{
                                        width: 'auto',
                                        color: '#e6b556',
                                        paddingTop: '0',
                                        marginTop: '0',
                                    }}
                                    onClick={() => {
                                        if (copyLink === 'COPY LINK') {
                                            setTimeout(
                                                () => setCopyLink('COPY LINK'),
                                                3 * 1000
                                            );
                                            setCopyLink('LINK COPIED');
                                        }
                                        navigator.clipboard.writeText(
                                            `https://${link}`
                                        );
                                    }}
                                >
                                    {copyLink}
                                    {copyLink === 'COPY LINK' ? (
                                        <ContentCopy
                                            color="primary"
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        />
                                    ) : (
                                        <Check
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </div>
                            <Divider sx={{ width: '100%' }} />
                            <div
                                style={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    <Typography
                                        fontSize={{ sm: '20px' }}
                                        textAlign={'center'}
                                        color={'#999999'}
                                        variant="body1"
                                    >
                                        Hi there! Please fill out the form at
                                        the following link to help me write you
                                        a great reference!
                                    </Typography>
                                    <Typography
                                        textAlign={'center'}
                                        overflow={'hidden'}
                                        color={'#999999'}
                                        variant="body1"
                                        fontStyle="oblique"
                                        sx={{ textDecoration: 'underline' }}
                                        fontSize={{ sm: '20px' }}
                                    >
                                        https://{link}
                                    </Typography>
                                </div>
                                <Button
                                    variant="text"
                                    fullWidth
                                    sx={{ width: 'auto', color: '#e6b556' }}
                                    onClick={() => {
                                        if (
                                            copyMessage ===
                                            'COPY MESSAGE WITH LINK'
                                        ) {
                                            setTimeout(
                                                () =>
                                                    setCopyMessage(
                                                        'COPY MESSAGE WITH LINK'
                                                    ),
                                                3 * 1000
                                            );
                                            setCopyMessage('MESSAGE COPIED');
                                        }
                                        navigator.clipboard.writeText(
                                            `Hi there!\nPlease fill out the form at the following link to help me write you a great reference!\nhttps://${link}`
                                        );
                                    }}
                                >
                                    {copyMessage}
                                    {copyMessage ===
                                        'COPY MESSAGE WITH LINK' ? (
                                        <ContentCopy
                                            color="primary"
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        ></ContentCopy>
                                    ) : (
                                        <Check
                                            sx={{
                                                marginLeft: '10px',
                                                color: '#e6b556',
                                                fontSize: '22px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </div>
                            <Divider sx={{ width: '100%' }} />
                            <Button
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    width: '100%',
                                }}
                                variant="contained"
                                onClick={() => setUsingQRCode(true)}
                                startIcon={
                                    <QrCode2
                                        sx={{ fontSize: '32px !important' }}
                                    />
                                }
                            >
                                SHARE YOUR QR CODE
                            </Button>

                            <Divider sx={{ width: '100%' }} />
                            <div
                                className="dashboard-more-options-popup-email"
                                style={{ alignItems: 'center' }}
                            >
                                <TextField
                                    error={!isvalidEmail && sendEmail !== ''}
                                    helperText={
                                        !isvalidEmail && sendEmail !== ''
                                            ? 'Please Enter a valid email'
                                            : ''
                                    }
                                    value={sendEmail}
                                    onChange={handleEmailValidation}
                                    id=""
                                    variant="filled"
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputLabel-root': {
                                            width: '100%',
                                            textAlign: 'center',
                                            display:
                                                sendEmail || isFocused
                                                    ? 'none'
                                                    : 'block',
                                            lineHeight: 'normal',
                                        },
                                        '& input': {
                                            textAlign: 'center',
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            lineHeight: 'normal',
                                        },
                                        '& .MuiInputBase-root': {
                                            '&:before': {
                                                borderBottom: 'none',
                                            },
                                            '&:after': {
                                                borderBottom: 'none',
                                            },
                                            '&:hover:before': {
                                                borderBottom: 'none',
                                            },
                                        },
                                        marginBottom: '.5rem',
                                    }}
                                    label="Enter an email here, then SEND"
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                                <Button
                                    sx={{ width: 'fit-content' }}
                                    onClick={handleEmailSend}
                                    variant="contained"
                                >
                                    SEND LINK TO EMAIL
                                    <ForwardToInbox
                                        sx={{
                                            marginLeft: '10px',
                                            color: '#444444',
                                            fontSize: '22px',
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
