import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormikValues } from 'formik';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import './OrganizationFunnel.css';
import FeedbackPopup from '../../../components/notification/FeedbackPopup';
import {
    GenerateReffyFormOptions,
    ReffyFieldType,
    ReffyForm,
    ReffyFormikValidationType,
    ReffyView,
} from '../../../components/form/ReffyForm';
import { useState, useEffect, useRef } from 'react';
import InfoCards from '../../../components/infoCards/InfoCards';
import { useOrganizationMutation } from '@cosmogony/reffy-hooks/chronos/chronos_hooks';
import { OrganizationFormRequest } from '@cosmogony/reffy-pb/chronos-svc/pkg/pb/chronos';
const views: ReffyView[] = [
    {
        name: 'sign-up',
        category: '',
        subcategory: '',
        inputs: [
            {
                name: 'name',
                title: 'Name:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'email',
                title: 'Email Address:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'phone',
                title: 'Phone Number:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'organization',
                title: 'Organization Name and Location:',
                fieldTitle: '',
                fieldType: ReffyFieldType.ShortAnswer,
                isRequired: true,
                repeat: false,
                validate: [{ type: ReffyFormikValidationType.Required }],
            },
            {
                name: 'questions',
                title: 'Questions?',
                fieldTitle: '',
                fieldType: ReffyFieldType.Paragraph,
                isRequired: false,
                repeat: false,
            },
        ],
        repeat: false,
    },
];

var form = GenerateReffyFormOptions(views);

export default function OrganizationFunnel() {
    const moreRef = useRef<HTMLDivElement>(null);
    const handleShowMore = () => {
        if (moreRef.current) {
            moreRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [showFeedback, setShowFeedback] = useState(false);
    const organization = useOrganizationMutation();

    useEffect(() => {
        if (showFeedback !== false) {
            setTimeout(() => {
                setShowFeedback(false);
            }, 5000);
        }
    }, []);

    const handleSubmit = async (data: FormikValues) => {
        const accountResponse = data['sign-up'][0];
        const name = accountResponse['name'];
        const email = accountResponse['email'];
        const phone = accountResponse['phone'];
        const organization_info = accountResponse['organization'];
        const questions = accountResponse['questions'];
        await organization
            .mutateAsync(
                OrganizationFormRequest.create({
                    name: name,
                    email: email,
                    phoneNumber: phone,
                    organizationInfo: organization_info,
                    question: questions,
                })
            )
            .then(() => {
                setShowFeedback(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="home">
            {showFeedback ? (
                <FeedbackPopup message={'Your form has been received!'} />
            ) : (
                <></>
            )}
            <Navbar />
            <div className="organization_funnel-1">
                <div className="organization_funnel-1-text">
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', xs: '24px' }}
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                        mb={'.5rem'}
                    >
                        Yep, it’s that easy.
                    </Typography>
                    <Typography
                        variant="h5"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                        mb={'.5rem'}
                    >
                        Reference Builder is designed by educators, for
                        educators.
                    </Typography>
                    <Typography fontWeight="normal" color="rgb(153, 153, 153)">
                        It is the easiest way to give your students a leg up in
                        their applications, and to help your faculty write
                        personalized letters more quickly and easily.{' '}
                    </Typography>{' '}
                    <div className="organization_funnel-1-buttons">
                        <Button
                            style={{
                                marginBottom: isMobile ? '1rem' : '0',
                                width: isMobile ? '100%' : 'auto',
                                marginRight: '1rem',
                            }}
                            color="primary"
                            variant="contained"
                            onClick={handleShowMore}
                            className="organization_funnel-1-button"
                        >
                            REQUEST A QUOTE
                        </Button>
                        <Typography
                            variant="body2"
                            style={{ color: '#999999' }}
                        >
                            Our team will reach out
                        </Typography>
                    </div>
                </div>
                <div className="organization_funnel-1-image">
                    <img
                        alt="writing on paper"
                        className="organization_funnel-1-image-img"
                        src="/assets/images/organization_funnel/organization_funnel_hero.jpeg"
                    />
                </div>
            </div>

            <div className="organization_funnel-3">
                <div className="organization_funnel-3-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'.5rem'}
                    >
                        EDUCATORS WRITE LOTS OF RECOMMENDATION LETTERS.
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        We care about our students and want them to succeed. But
                        it’s tough to manage so many requests – especially
                        during busy times of the semester.
                    </Typography>
                </div>
            </div>

            <div className="organization_funnel-4">
                <div className="organization_funnel-4-text">
                    <Typography
                        fontSize={{ sm: '18px', xs: '16px' }}
                        fontWeight="bold"
                        mb={'.5rem'}
                    >
                        HOW DOES IT WORK?
                    </Typography>
                    <Typography fontSize={{ sm: '24px', xs: '18px' }}>
                        Reference Builder automatically organizes requests,
                        collects applicant information, and writes a
                        personalized first draft of every letter with RefBuilder
                        AI. When a student asks for a recommendation, just send
                        them the link to your applicant questionnaire, wait for
                        their response, and voilà! Giving your students a leg up
                        has never been easier.
                    </Typography>
                </div>
                <InfoCards />
            </div>
            <div className="youtube-video-organization">
                <div>
                    <iframe 
                        src="https://www.youtube.com/embed/OtfmjobMIXw?si=2QFWvnNZhRjOhzDy" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        referrerPolicy="strict-origin-when-cross-origin" 
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className="organization_funnel-5">
                <div className="organization_funnel-5-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                        mb={'.5rem'}
                    >
                        Say no to the status quo.
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                    >
                        No more clunky forms from the counselor’s office. No
                        more staring at a blank screen wondering what to write.
                        No more back-and-forth emails trying to schedule office
                        meetings and collect information from students. And no
                        more swapping out names in generic letters that hinder
                        your students’ chances at success.
                    </Typography>
                </div>
            </div>

            <div ref={moreRef} className="organization_funnel-6">
                <div className="organization_funnel-6-text">
                    <Typography
                        fontSize={{ md: '32px', xs: '24px' }}
                        variant="subtitle2"
                        fontWeight="normal"
                        mb={'.5rem'}
                    >
                        Elevate Your Students' Success
                    </Typography>
                    <Typography
                        variant="body2"
                        fontSize={{ md: '32px', sm: '24px', xs: '18px' }}
                        fontWeight="normal"
                        color="rgb(153, 153, 153)"
                    >
                        Join other top high schools in improving student
                        outcomes with RefBuilder. Submit your details and
                        questions below, and we’ll help you determine if
                        RefBuilder is a good fit for your institution.
                    </Typography>
                </div>
                <div className="organization_funnel-6-form">
                    <ReffyForm
                        initialValues={form}
                        views={views}
                        onSubmit={(data) => {
                            handleSubmit(data);
                        }}
                        padding="0px"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}
