import { useMemo, useState } from 'react';
import {
    Button,
    FormControlLabel,
    Checkbox,
    TextField,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Alert,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import {
    ResponseInformation,
    useEditResponseMutation,
} from '@cosmogony/reffy-hooks';
import {
    EditResponseRequest,
    RequestStatus,
} from '@cosmogony/reffy-pb/litae-svc/pkg/pb/litae';

interface DeclineRefModalProps {
    open: boolean;
    setOpen: (isOpen: boolean) => void;
    currentReference: ResponseInformation;
    otherReferences: ResponseInformation[];
}

export default function DeclineRefModal({
    open,
    setOpen,
    currentReference,
    otherReferences,
}: DeclineRefModalProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const editResponse = useEditResponseMutation();
    const [writerMessage, setWriterMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [hasError, setHasError] = useState(true);
    const [isDeclineModal, setIsDeclineModal] = useState(
        () => currentReference.status === RequestStatus.REQUEST_OPEN
    );

    const selectedRefs = {
        [currentReference.ruuid]: false,
    };

    otherReferences.forEach((ref) => {
        selectedRefs[ref.ruuid] = false;
    });

    const [refMap, setRefMap] = useState(selectedRefs);

    function updateSelectedRef(
        ruuid: string,
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        setRefMap((prevMap) => {
            const newMap = {
                ...prevMap,
                [ruuid]: e.target.checked,
            };
            const noneSelected = !Object.values(newMap).some(
                (val) => val === true
            );
            setHasError(noneSelected);
            return newMap;
        });
    }

    function editRequestFn(response: ResponseInformation) {
        editResponse.mutate(
            EditResponseRequest.create({
                ruuid: response.ruuid,
                luuid: response.luuid,
                status: isDeclineModal
                    ? RequestStatus.REQUEST_DECLINED
                    : RequestStatus.REQUEST_OPEN,
                writerMessage: !!writerMessage ? writerMessage : undefined,
            })
        );
    }

    const firstNameOfApplicant = currentReference.name.trim().split(' ')[0];

    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: !isMobile
                    ? { padding: '0.5rem 1rem', overflowX: 'hidden' }
                    : {},
                square: true,
            }}
        >
            {showNotification && (
                <Alert
                    sx={
                        !isMobile
                            ? { margin: '-0.5rem 0 0 -1rem', width: '100%' }
                            : {}
                    }
                    severity="success"
                >
                    An email is being sent to {currentReference.name}{' '}
                    {'<' + currentReference.email.trim() + '>'}
                </Alert>
            )}
            <DialogTitle sx={{ margin: 'auto' }}>
                {isDeclineModal ? 'Decline' : 'Reopen'} Reference Requests
            </DialogTitle>
            <DialogContent>
                <FormControlLabel
                    sx={{
                        '& .MuiTypography-root': {
                            color: refMap[currentReference.ruuid] ? '' : 'gray',
                        },
                    }}
                    key={currentReference.ruuid}
                    control={
                        <Checkbox
                            disabled={showNotification}
                            checked={refMap[currentReference.ruuid]}
                            onChange={(e) =>
                                updateSelectedRef(currentReference.ruuid, e)
                            }
                        />
                    }
                    label={
                        currentReference.title +
                        ', due ' +
                        new Date(currentReference.dateDue * 1000).toDateString()
                    }
                />
                {otherReferences.map((ref) => {
                    return (
                        <FormControlLabel
                            sx={{
                                '& .MuiTypography-root': {
                                    color: refMap[currentReference.ruuid]
                                        ? ''
                                        : 'gray',
                                },
                            }}
                            key={ref.ruuid}
                            control={
                                <Checkbox
                                    disabled={showNotification}
                                    checked={refMap[ref.ruuid]}
                                    onChange={(e) =>
                                        updateSelectedRef(ref.ruuid, e)
                                    }
                                />
                            }
                            label={
                                ref.title +
                                ', due ' +
                                new Date(ref.dateDue * 1000).toDateString()
                            }
                        />
                    );
                })}
            </DialogContent>
            {isDeclineModal && (
                <>
                    <DialogTitle>
                        Tell {currentReference.name} why (optional)
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This message will be added to an email sent to the
                            applicant.
                        </DialogContentText>
                        <TextField
                            disabled={showNotification}
                            multiline
                            rows={2}
                            value={writerMessage}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setWriterMessage(e.target.value);
                            }}
                        />
                    </DialogContent>
                </>
            )}
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                }}
            >
                <Button
                    disabled={showNotification || hasError}
                    variant="contained"
                    onClick={() => {
                        if (refMap[currentReference.ruuid] === true) {
                            editRequestFn(currentReference);
                        }

                        otherReferences
                            .filter((ref) => refMap[ref.ruuid] === true)
                            .forEach((ref) => editRequestFn(ref));

                        if (isDeclineModal) {
                            setShowNotification(true);

                            setTimeout(() => {
                                setShowNotification(false);
                                setOpen(false);
                            }, 2500);
                        } else {
                            setOpen(false);
                        }
                    }}
                >
                    {isDeclineModal
                        ? 'Decline and notify ' + firstNameOfApplicant
                        : 'Reopen'}
                </Button>
                <Button
                    disabled={showNotification}
                    variant="contained"
                    style={{ background: 'none' }}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
}
