import { unstable_useBlocker as useBlocker } from 'react-router-dom';

function Prompt(props: { when: any; message: string | undefined }) {
    const block = props.when;

    useBlocker(() => {
        if (block) {
            return !window.confirm(props.message);
        }
        return false;
    });

    return <div key={block} />;
}

export default Prompt;
