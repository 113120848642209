import {
    Button,
    Divider,
    IconButton,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import {
    MiniReferenceCard,
    ReferenceCard,
    ReferenceCardProps,
} from '../../../components/dashboard/ReferenceCard';
import Support from '../../support/Support';
import {
    UserModel,
    WriterModel,
} from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import { useNavigate } from 'react-router-dom';
import { WriterSubscriptionModel } from '@cosmogony/common/reffy-pb/plutus-svc/pkg/pb/plutus';
import { ContentCopy } from '@mui/icons-material';
import './DashboardHome.css';
import { RequestStatus } from '@cosmogony/reffy-pb/litae-svc/pkg/pb/litae';
import { isPro } from '../../../api/api';
import { EnterpriseModel } from '@cosmogony/reffy-pb/apollo-svc/pkg/pb/apollo';

function capitalize(str: String): string {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function DashboardHelperBoxes(props: { link: String }) {
    const [isCopied, setIsCopied] = useState(false);
    return (
        <div className="dashboard-intro-prompt-helper-boxes">
            <div className="dashboard-intro-prompt-helper-box">
                <Typography
                    fontSize={{ sm: '100px', md: '120px', xs: '60px' }}
                    color="primary"
                    variant="h1"
                >
                    01.
                </Typography>
                <Typography
                    fontSize={{ sm: '18px', xs: '16px' }}
                    color={'#444444'}
                    fontWeight="bold"
                >
                    SHARE YOUR APPLICANT QUESTIONNAIRE
                </Typography>
                <Typography
                    fontSize={{ sm: '20px', xs: '18px' }}
                    color={'#999999'}
                >
                    Send this link to the person who requested a letter. We'll
                    collect their application info, then use it to help you
                    write your letter!
                </Typography>

                <div className="dashboard-home-copy-link">
                    <IconButton
                        sx={{ paddingLeft: 0, color: '#f9d37f' }}
                        onClick={() => {
                            if (!isCopied) {
                                setIsCopied(true);
                                setTimeout(() => setIsCopied(false), 5 * 1000);
                            }

                            navigator.clipboard.writeText(
                                `https://${props.link}`
                            );
                        }}
                    >
                        <ContentCopy />
                    </IconButton>
                    <Typography
                        mt={'.5rem'}
                        overflow={'hidden'}
                        noWrap
                        textOverflow={'ellipsis'}
                        fontSize={{ sm: '20px', xs: '18px' }}
                    >
                        {isCopied ? 'Link Copied' : `${capitalize(props.link)}`}
                    </Typography>
                </div>
            </div>

            <div className="dashboard-intro-prompt-helper-box">
                <Typography
                    fontSize={{ sm: '100px', md: '120px', xs: '60px' }}
                    color="primary"
                    variant="h1"
                >
                    02.
                </Typography>
                <Typography
                    fontSize={{ sm: '18px', xs: '16px' }}
                    color={'#444444'}
                    fontWeight="bold"
                >
                    GET INFORMATION BACK
                </Typography>
                <Typography
                    fontSize={{ sm: '20px', xs: '18px' }}
                    color={'#999999'}
                >
                    We’ll organize all of your reference requests here on your
                    dashboard for easy access.
                </Typography>
            </div>

            <div className="dashboard-intro-prompt-helper-box">
                <Typography
                    fontSize={{ sm: '100px', md: '120px', xs: '60px' }}
                    color="primary"
                    variant="h1"
                >
                    03.
                </Typography>
                <Typography
                    fontSize={{ sm: '18px', xs: '16px' }}
                    color={'#444444'}
                    fontWeight="bold"
                >
                    COMPLETE YOUR LETTER
                </Typography>
                <Typography
                    fontSize={{ sm: '20px', xs: '18px' }}
                    color={'#999999'}
                >
                    Write an original letter with our easy-to-use templates, or
                    use the custom draft written by RefBuilder AI!
                </Typography>
            </div>
        </div>
    );
}

export default function DashboardHome(props: {
    user: UserModel;
    writer: WriterModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    requests: ReferenceCardProps[];
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const user = props.user;
    const requests = props.requests;
    const subscription = props.subscription;
    const enterprise = props.enterprise;
    const navigate = useNavigate();

    const completedRequests = requests.filter((request) =>
        request.requests.find(
            (application) =>
                application['status'] === RequestStatus.REQUEST_COMPLETED
        )
    );

    const completedRequestsToShow = completedRequests
        .filter((_, index) => index < (isMobile ? 3 : 9))
        .sort(
            (
                a,
                b // Sort in descending order by the latest updated at time per applicant
            ) =>
                a.requests.reduce(
                    (x, y) => Math.max(x, y.updatedAt),
                    -Infinity
                ) <
                b.requests.reduce((x, y) => Math.max(x, y.updatedAt), -Infinity)
                    ? 1
                    : -1
        )
        .map((request) => {
            return (
                <Grid
                    className="dashboard-completed-request-card"
                    item
                    display={'flex'}
                    justifyContent={'center'}
                    xs={12}
                    sm={6}
                    md={4}
                >
                    <MiniReferenceCard
                        props={request}
                        status={RequestStatus.REQUEST_COMPLETED}
                    />
                </Grid>
            );
        });

    const declinedRequests = requests.filter((request) =>
        request.requests.find(
            (application) =>
                application['status'] === RequestStatus.REQUEST_DECLINED
        )
    );

    const declinedRequestsToShow = declinedRequests
        .filter((_, index) => index < (isMobile ? 3 : 9))
        .map((request) => {
            return (
                <Grid
                    className="dashboard-completed-request-card"
                    item
                    display={'flex'}
                    justifyContent={'center'}
                    xs={12}
                    sm={6}
                    md={4}
                >
                    <MiniReferenceCard
                        props={request}
                        status={RequestStatus.REQUEST_DECLINED}
                    />
                </Grid>
            );
        });

    const isProUser: boolean = isPro(subscription, enterprise);

    return (
        <>
            <IntroPrompt user={user} requests={requests} />
            {!isProUser ? (
                <div className="home-7">
                    <div className="home-7-overlay">
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                width="80%"
                                variant="h5"
                                color="#FFFFFF"
                            >
                                Be someone's hero. Write amazing letters in a
                                flash.
                            </Typography>
                            <Button
                                sx={{ marginTop: '1rem' }}
                                variant="contained"
                                color="primary"
                                onClick={() => navigate('/pricing')}
                            >
                                UPGRADE TO REFERENCE BUILDER PRO
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}

            {completedRequestsToShow.length > 0 && (
                <>
                    {isProUser ? (
                        <div className="dashboard-divider-container">
                            <Divider className="dashboard-divider" />
                            <Typography
                                noWrap
                                color="#999999"
                                fontWeight="normal"
                                fontSize={{
                                    xs: '14px',
                                    sm: '16px',
                                    md: '18px',
                                }}
                            >
                                COMPLETED REQUESTS
                            </Typography>
                            <Divider className="dashboard-divider" />
                        </div>
                    ) : (
                        <Typography
                            fontWeight="bold"
                            sx={{
                                marginLeft: isMobile ? '1rem' : '152px',
                                marginTop: isMobile ? '1rem' : '27px',
                            }}
                        >
                            COMPLETED REQUESTS:
                        </Typography>
                    )}
                    <div className="dashboard-completed-request-cards">
                        <Grid container spacing={2}>
                            {completedRequestsToShow}
                        </Grid>
                    </div>

                    {completedRequests.length >
                        completedRequestsToShow.length && (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                                marginBottom: '56px',
                            }}
                        >
                            <Button
                                sx={{ color: '#444444' }}
                                variant="text"
                                onClick={() =>
                                    navigate('/dashboard/completed-requests')
                                }
                            >
                                VIEW ALL COMPLETED REQUESTS
                            </Button>
                        </div>
                    )}
                </>
            )}

            {declinedRequestsToShow.length > 0 && (
                <>
                    {isProUser ? (
                        <div className="dashboard-divider-container">
                            <Divider className="dashboard-divider" />
                            <Typography
                                noWrap
                                color="#999999"
                                fontWeight="normal"
                                fontSize={{
                                    xs: '14px',
                                    sm: '16px',
                                    md: '18px',
                                }}
                            >
                                DECLINED REQUESTS
                            </Typography>
                            <Divider className="dashboard-divider" />
                        </div>
                    ) : (
                        <Typography
                            fontWeight="bold"
                            sx={{
                                marginLeft: isMobile ? '1rem' : '152px',
                                marginTop: isMobile ? '1rem' : '27px',
                            }}
                        >
                            DECLINED REQUESTS:
                        </Typography>
                    )}
                    <div className="dashboard-completed-request-cards">
                        <Grid container spacing={2}>
                            {declinedRequestsToShow}
                        </Grid>
                    </div>

                    {declinedRequests.length >
                        declinedRequestsToShow.length && (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                                marginBottom: '56px',
                            }}
                        >
                            <Button
                                sx={{ color: '#444444' }}
                                variant="text"
                                onClick={() =>
                                    navigate('/dashboard/declined-requests')
                                }
                            >
                                VIEW ALL DECLINED REQUESTS
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

function IntroPrompt(props: {
    user: UserModel;
    requests: ReferenceCardProps[];
}) {
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isMain = !window.location.toString().includes('help');
    const link = `refbuilder.com/intake/${props.user.username}`;
    const navigate = useNavigate();
    const [showNumberOpen, setShowNumberOpen] = useState(3);
    const user = props.user;
    const requests = props.requests;
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
    setIsOpen(!isOpen);
    };
    const numberOpenRequests = requests
        .map(
            (request) =>
                request.requests.filter(
                    (application) =>
                        application['status'] === RequestStatus.REQUEST_OPEN
                ).length
        )
        .reduce((p, a) => p + a, 0);        

    if (numberOpenRequests > 0) {
        return (
            <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        fontSize={{ xs: '24px', sm: '28px', md: '32px' }}
                        mt={!isMobile ? '3rem !important' : '1rem !important'}
                        width={'95% !important'}
                        color={'#999999'}
                        className="dashboard-intro-prompt"
                    >
                        Welcome back {user.name.split(' ')[0]}, you currently
                        have
                        <span
                            style={{ color: '#444444' }}
                        >{` ${numberOpenRequests} `}</span>
                        open reference{' '}
                        {numberOpenRequests > 1 ? 'requests' : 'request'} from:
                    </Typography>
                </div>

                <div className="dashboard-request-cards">
                    {isMobile ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                overflowX: 'scroll',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '1rem',
                            }}
                        >
                            {requests
                                .filter((request) =>
                                    request.requests.find(
                                        (application) =>
                                            application['status'] ===
                                            RequestStatus.REQUEST_OPEN
                                    )
                                )
                                .sort((request1, request2) => {
                                    return (
                                        request1.requests.sort(
                                            (application1, application2) => {
                                                return (
                                                    application1['date_due'] -
                                                    application2['date_due']
                                                );
                                            }
                                        )[0]['date_due'] -
                                        request2.requests.sort(
                                            (application1, application2) => {
                                                return (
                                                    application1['date_due'] -
                                                    application2['date_due']
                                                );
                                            }
                                        )[0]['date_due']
                                    );
                                })
                                .map((request, _) => {
                                    return <ReferenceCard {...request} />;
                                })}
                        </div>
                    ) : (
                        <Grid
                            container
                            spacing={0}
                            justifyContent="center"
                            rowSpacing={2}
                        >
                            {requests
                                .filter((request) =>
                                    request.requests.find(
                                        (application) =>
                                            application['status'] ===
                                            RequestStatus.REQUEST_OPEN
                                    )
                                )
                                .sort((request1, request2) => {
                                    return (
                                        request1.requests.sort(
                                            (application1, application2) => {
                                                return (
                                                    application1['date_due'] -
                                                    application2['date_due']
                                                );
                                            }
                                        )[0]['date_due'] -
                                        request2.requests.sort(
                                            (application1, application2) => {
                                                return (
                                                    application1['date_due'] -
                                                    application2['date_due']
                                                );
                                            }
                                        )[0]['date_due']
                                    );
                                })
                                .map((request, index) => {
                                    if (index < showNumberOpen)
                                        return (
                                            <Grid item xs={12} sm={6} md={4}>
                                                <ReferenceCard {...request} />
                                            </Grid>
                                        );

                                    return undefined;
                                })}
                        </Grid>
                    )}
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}
                >
                    {numberOpenRequests > 3 && (
                        <Button
                            sx={{ color: '#444444' }}
                            variant="text"
                            onClick={() => {
                                if (isMobile)
                                    navigate('/dashboard/all-requests');
                                showNumberOpen === 3
                                    ? setShowNumberOpen(9)
                                    : setShowNumberOpen(3);
                                window.scrollTo(0, 0);
                            }}
                        >
                            {isMobile || showNumberOpen === 3
                                ? 'VIEW ALL OPEN REFERENCE REQUESTS'
                                : 'VIEW FEWER REFERENCE REQUESTS'}
                        </Button>
                    )}
                </div>
            </div>
        );
        
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {isMain && (
                <>
                    <div
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            display: 'block',
                            justifyContent: 'center',
                        }}
                    >
                        {!isOpen &&(
                            <Typography
                                mt={
                                    !isMobile
                                        ? '3rem !important'
                                        : '1rem !important'
                                }
                                color={'#999999'}
                                className="dashboard-intro-prompt"
                                fontSize={{ xs: '24px', sm: '28px', md: '32px' }}
                                width={
                                    !isMobile ? '100% !important' : '95% !important'
                                }
                            >
                                Welcome {user.name.split(' ')[0]}, this is your
                                account dashboard. Get started by watching our quick{' '} 
                                <span id="accordion-text" onClick={toggleAccordion} 
                                    style={{
                                        cursor: 'pointer', 
                                        textDecoration: "underline", 
                                        textDecorationColor: "#f9d37f" 
                                    }}
                                >
                                    tutorial video
                                </span>

                            </Typography>
                        )}

                        {isOpen &&(
                            <Typography
                                mt={
                                    !isMobile
                                        ? '3rem !important'
                                        : '1rem !important'
                                }
                                color={'#999999'}
                                className="dashboard-intro-prompt"
                                fontSize={{ xs: '24px', sm: '28px', md: '32px' }}
                                width={
                                    !isMobile ? '100% !important' : '95% !important'
                                }
                            >
                                Welcome {user.name.split(' ')[0]}, this is your
                                account dashboard. Get started by watching our quick video. {' '} 
                                <span id="accordion-text" onClick={toggleAccordion} 
                                    style={{
                                        cursor: 'pointer', 
                                        textDecoration: "underline", 
                                        textDecorationColor: "#f9d37f", 
                                        fontWeight: "600"
                                    }}
                                >
                                    Hide video. 
                                </span>

                            </Typography>
                        )}

                        



                            {isOpen &&(
                            <div className="youtube-video-dashboard">
                                <div>
                                    <iframe
                                        src="https://www.youtube.com/embed/OtfmjobMIXw?si=PdTqRP8iO8uieHFO"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                            )}
                    </div>
                    <div
                        style={{
                            marginTop: !isMobile ? '3rem' : '1rem',
                            marginBottom: !isMobile ? '1rem' : '0',
                        }}
                    >
                        <DashboardHelperBoxes link={link} />
                    </div>
                </>
            )}
        </div>
    );
}

export function Help(props: { user: UserModel }) {
    const user = props.user;
    const link = `refbuilder.com/intake/${user.username}`;

    return (
        <div className="dashboard-intro-prompt-helper">
            <Support link={link} />
        </div>
    );
}
