import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PrivacyFooter } from '../../components/footer/Footer';

export default function DashboardFooter() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    if (isMobile) return <PrivacyFooter />;

    return (
        <>
            <div className="dashboard-navigator">
                <div className="dashboard-navigator-background"></div>
                <Button
                    sx={{ color: '#444444' }}
                    onClick={() => {
                        navigate('/dashboard');
                    }}
                    className="dashboard-navigator-button"
                >
                    DASHBOARD
                </Button>
                <Button
                    sx={{ color: '#444444' }}
                    onClick={() => {
                        navigate('/dashboard/help');
                    }}
                    className="dashboard-navigator-button"
                >
                    HELP AND SUPPORT
                </Button>
                <Button
                    sx={{ color: '#444444' }}
                    onClick={() => {
                        navigate('/dashboard/account');
                    }}
                    className="dashboard-navigator-button"
                >
                    MANAGE ACCOUNT
                </Button>
            </div>

            <PrivacyFooter />
        </>
    );
}
