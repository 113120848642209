import {
    ArrowBack,
    CloseSharp,
    CheckSharp,
    LockOutlined,
} from '@mui/icons-material';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Switch,
    SwitchProps,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
    JSXElementConstructor,
    ReactElement,
    ReactNode,
    ReactPortal,
    useEffect,
    useState,
} from 'react';
import Cropper from 'react-easy-crop';
import { useLocation, useNavigate } from 'react-router';
import ConfirmationPopup from '../../../components/confirmationPopup/ConfirmationPopup';
import { CropImage } from '../../../components/form/ReffyForm';
import Loading from '../../../components/loading/Loading';
import FeedbackPopup from '../../../components/notification/FeedbackPopup';
import {
    useEditUserMutation,
    useEditWriterMutation,
} from '@cosmogony/common/reffy-hooks/artemis/artemis_hooks';
import { useKratosLogoutMutation } from '@cosmogony/common/reffy-hooks/kratos/kratos_hooks';
import {
    UserModel,
    WriterModel,
} from '@cosmogony/common/reffy-pb/artemis-svc/pkg/pb/artemis';
import ProfileAvatar from '../../../components/profileAvatar/ProfileAvatar';
import {
    GetWriterSubscriptionRequest,
    WriterSubscriptionModel,
} from '@cosmogony/common/reffy-pb/plutus-svc/pkg/pb/plutus';
import { EnterpriseModel } from '@cosmogony/common/reffy-pb/apollo-svc/pkg/pb/apollo';
import {
    useCancelSubscriptionMutation,
    useCheckoutRequestMutation,
} from '@cosmogony/common/reffy-hooks/plutus/plutus_hooks';
import './ManageAccount.css';
import { Boolean, DocumentRecord } from '@cosmogony/reffy-pb/common/pb/common';
import { useCreateAssetsMutation } from '@cosmogony/reffy-hooks';
import Prompt from '../../../components/prompt/Prompt';
import { isPro } from '../../../api/api';

export default function ManageAccount(props: {
    user: UserModel;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    writer: WriterModel;
}) {
    const { user, writer } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [cancel, setCancel] = useState(false);
    const [save, setSave] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const editUser = useEditUserMutation();
    const editWriter = useEditWriterMutation();
    const createAsset = useCreateAssetsMutation();
    const logout = useKratosLogoutMutation();
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    // Requests confirmation when trying to refresh or leave the page
    // at the browser level.
    const confirmLeave = (e: {
        preventDefault: () => void;
        returnValue: string;
    }) => {
        // BUG: (RE-318) still shows confirmation even when no changes
        // e.preventDefault();
        // e.returnValue = '';
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('beforeunload', confirmLeave);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('beforeunload', confirmLeave);
        };
    }, []);

    const location = useLocation();
    const state = location.state;

    const [scrollLocation, setScrollLocation] = useState(null);
    useEffect(() => {
        if (state && state.scroll) {
            setScrollLocation(state.scroll);
        }
    }, [state]);

    useEffect(() => {
        let element: HTMLElement | null = null;
        if (scrollLocation === 'manage') {
            element = document.getElementById('writers-desk');
        } else if (scrollLocation === 'details') {
            element = document.getElementById('personal-details');
        }
        if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            setScrollLocation(null);
        }
    }, [scrollLocation]);

    const defaultUser = {
        ...user,
        ...writer,
        preferredGreeting:
            user['preferred_greeting'] ?? "Welcome, let's get started!",
        lettersPerYear: user['letters_per_year'],
        additionalQuestions:
            writer.additionalQuestions.length === 0
                ? ['', '', '']
                : writer.additionalQuestions,
        additionalDocuments:
            writer.additionalDocuments.length === 0
                ? ['', '', '']
                : writer.additionalDocuments,
        password: '',
    };

    const [details, setDetails] = useState(defaultUser);

    function hasChanged(): boolean {
        // ignore updatedAt timestamp when comparing objects
        return !deepEqual(
            { ...defaultUser, updatedAt: 0 },
            { ...details, updatedAt: 0 }
        );
    }

    function hasWritersDeskChanged(): boolean {
        return !deepEqual(
            {
                additionalQuestions: defaultUser.additionalQuestions,
                additionalDocuments: defaultUser.additionalDocuments,
            },
            {
                additionalQuestions: details.additionalQuestions,
                additionalDocuments: details.additionalDocuments,
            }
        );
    }

    function havePersonalDetailsChanged(): boolean {
        return !deepEqual(
            {
                name: defaultUser.name,
                pfp: defaultUser.pfp,
                username: defaultUser.username,
                email: defaultUser.email,
                role: defaultUser.role,
                field: defaultUser.field,
                letterHead: defaultUser.letterHead,
            },
            {
                name: details.name,
                pfp: details.pfp,
                username: details.username,
                email: details.email,
                role: details.role,
                field: details.field,
                letterHead: details.letterHead,
            }
        );
    }

    function hasAccountInformationChanged(): boolean {
        return !deepEqual(
            {
                notificationsDisabled: defaultUser.notificationsDisabled,
            },
            {
                notificationsDisabled: details.notificationsDisabled,
            }
        );
    }

    if (editUser.isLoading || editWriter.isLoading) {
        return <Loading />;
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
                paddingLeft: isMobile ? 0 : '50px',
                paddingRight: isMobile ? 0 : '50px',
                paddingTop: '20px',
                gap: '1rem',
            }}
        >
            <Prompt
                when={hasChanged()}
                message={
                    'You have unsaved changes. Are you sure you want to leave?'
                }
            />
            {cancel && (
                <ConfirmationPopup
                    promptText="Are you sure you want to cancel? Your changes won't be saved!"
                    leftButtonText="Cancel Changes"
                    rightButtonText="Go Back"
                    handleLeft={() => {
                        setCancel(false);
                        setDetails(defaultUser);
                    }}
                    handleRight={() => {
                        setCancel(false);
                    }}
                    leftIcon={<CloseSharp />}
                    rightIcon={<ArrowBack />}
                    rightUI={
                        <Button
                            sx={{
                                color: '#999999',
                                height: '50px',
                                ':hover': {
                                    boxShadow:
                                        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                                },
                            }}
                            variant="text"
                            startIcon={<ArrowBack />}
                            onClick={() => setCancel(false)}
                        >
                            <Typography
                                variant="body2"
                                style={{ color: '#999999' }}
                            >
                                Go back
                            </Typography>
                        </Button>
                    }
                />
            )}

            {save && (
                <ConfirmationPopup
                    promptText="Are you sure? You're about to update your account settings!"
                    leftButtonText="Save My Changes"
                    rightButtonText="Take Me Back"
                    handleRight={() => {
                        setSave(false);
                    }}
                    handleLeft={() => {
                        setSave(false);

                        const editUserCallback = (pfp: DocumentRecord) => {
                            editUser.mutate({
                                Name: details.name || user.name,
                                Username: details.username || user.username,
                                Email: details.email || user.email,
                                Pfp: {
                                    Url: pfp.url,
                                    Name: pfp.name,
                                },
                                NotificationsDisabled:
                                    details.notificationsDisabled ??
                                    Boolean.BOOLEAN_FALSE,
                            });
                        };

                        const editWriterCallback = (
                            letterHead: DocumentRecord
                        ) => {
                            editWriter.mutate({
                                Field: details.field,
                                Role: details.role,
                                PreferredGreeting: details.preferredGreeting,
                                Organization: details.organization,
                                StayInformed: details.stayInformed,
                                LetterHead: {
                                    Url: letterHead.url,
                                    Name: letterHead.name,
                                },
                                AdditionalQuestions:
                                    details.additionalQuestions,
                                AdditionalDocuments:
                                    details.additionalDocuments,
                            });
                        };

                        // Create user assets or update
                        if (
                            details.pfp?.url !== defaultUser.pfp?.url &&
                            details.pfp &&
                            details.pfp.url
                        ) {
                            createAsset.mutate({
                                documents: [details.pfp.url],
                                assetRequest: {
                                    uuid: defaultUser.uuid,
                                    public: Boolean.BOOLEAN_TRUE,
                                },
                                settle: (urls) => {
                                    const pfpUrl = urls[0];
                                    const pfpName =
                                        details.pfp?.name ?? 'pfp.jpeg';
                                    editUserCallback({
                                        url: pfpUrl,
                                        name: pfpName,
                                    });

                                    // Changes URL to use settled URL instead of raw link
                                    setDetails({
                                        ...details,
                                        pfp: { url: pfpUrl, name: pfpName },
                                    });
                                },
                            });
                        } else {
                            editUserCallback({
                                url: details.pfp?.url ?? '',
                                name: details.pfp?.name ?? '',
                            });
                        }

                        // Create writer assets or update
                        if (
                            details.letterHead?.url !==
                            defaultUser.letterHead?.url &&
                            details.letterHead &&
                            details.letterHead.url
                        ) {
                            createAsset.mutate({
                                documents: [details.letterHead.url],
                                assetRequest: {
                                    uuid: defaultUser.uuid,
                                    public: Boolean.BOOLEAN_FALSE,
                                },
                                settle: (urls) => {
                                    const letterHeadUrl = urls[0];
                                    const letterHeadName =
                                        details.letterHead?.name ??
                                        'letter_head.pdf';
                                    editWriterCallback({
                                        url: letterHeadUrl,
                                        name: letterHeadName,
                                    });

                                    // Changes URL to use settled URL instead of raw link
                                    setDetails({
                                        ...details,
                                        letterHead: {
                                            url: letterHeadUrl,
                                            name: letterHeadName,
                                        },
                                    });
                                },
                            });
                        } else {
                            editWriterCallback({
                                url: details.letterHead?.url ?? '',
                                name: details.letterHead?.name ?? '',
                            });
                        }
                    }}
                />
            )}

            <div className="magage-top-row">
                {editUser.error !== null && (
                    <Typography
                        fontSize={{ md: '18px', xs: '12px' }}
                        color="error"
                    >
                        {String(editUser.error)}
                    </Typography>
                )}
                <Button
                    sx={{
                        marginRight: 'auto',
                        marginLeft: '1rem',
                        padding: '0px',
                    }}
                    variant="text"
                    color="secondary"
                    startIcon={<ArrowBack />}
                    onClick={() => navigate('/dashboard')}
                >
                    Dashboard
                </Button>
            </div>

            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    gap: '2rem',
                    marginTop: isMobile ? 0 : '50px',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                {!isMobile && (
                    <div
                        style={{
                            position: 'sticky',
                            top: '5%',
                            width: '400px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '1rem',
                            }}
                        >
                            <Button
                                variant="text"
                                color="secondary"
                                fullWidth
                                disabled={!hasChanged()}
                                onClick={() => setCancel(true)}
                            >
                                Cancel Changes
                            </Button>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={!hasChanged()}
                                onClick={() => setSave(true)}
                            >
                                Save Changes
                            </Button>
                        </div>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                document
                                    .getElementById('writers-desk')
                                    ?.scrollIntoView({
                                        block: 'center',
                                        behavior: 'smooth',
                                    });
                            }}
                            sx={{
                                backgroundColor:
                                    scrollPosition < 800 ? 'primary' : 'white',
                                ':hover': { backgroundColor: 'primary' },
                            }}
                        >
                            Writers Desk
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                document
                                    .getElementById('personal-details')
                                    ?.scrollIntoView({
                                        block: 'center',
                                        behavior: 'smooth',
                                    });
                            }}
                            sx={{
                                backgroundColor:
                                    scrollPosition > 800 &&
                                        scrollPosition < 1520
                                        ? 'primary'
                                        : 'white',
                                ':hover': { backgroundColor: 'primary' },
                            }}
                        >
                            Personal Details
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                document
                                    .getElementById('account-information')
                                    ?.scrollIntoView({
                                        block: 'center',
                                        behavior: 'smooth',
                                    });
                            }}
                            sx={{
                                backgroundColor:
                                    scrollPosition > 1520 ? 'primary' : 'white',
                                ':hover': { backgroundColor: 'primary' },
                            }}
                        >
                            Account Information
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: '#f5f5f5',
                                ':hover': { backgroundColor: '#ec6763' },
                            }}
                            onClick={() => {
                                queryClient.removeQueries({
                                    queryKey: ['user-authenticated'],
                                });
                                logout.mutate();
                                navigate('/');
                            }}
                        >
                            Logout
                        </Button>
                    </div>
                )}
                <div style={{}}>
                    <WritersDesk
                        user={user}
                        mobile={isMobile}
                        subscription={props.subscription}
                        enterprise={props.enterprise}
                        desk={details}
                        setDesk={setDetails}
                        hasChanged={hasWritersDeskChanged}
                        onCancel={() => setCancel(true)}
                        onSave={() => setSave(true)}
                    />
                    <PersonalDetails
                        user={user}
                        mobile={isMobile}
                        subscription={props.subscription}
                        enterprise={props.enterprise}
                        info={details}
                        setInfo={setDetails}
                        hasChanged={havePersonalDetailsChanged}
                        onCancel={() => setCancel(true)}
                        onSave={() => setSave(true)}
                    />
                    <AccountInformation
                        user={user}
                        mobile={isMobile}
                        subscription={props.subscription}
                        enterprise={props.enterprise}
                        info={details}
                        setInfo={setDetails}
                        hasChanged={hasAccountInformationChanged}
                        onCancel={() => setCancel(true)}
                        onSave={() => setSave(true)}
                    />
                </div>
            </div>
        </div>
    );
}

function Label(props: { title: string; color?: string }) {
    return (
        <Typography
            color={props.color || '#444444'}
            fontSize={{ md: '18px', xs: '16px' }}
            fontWeight="bold"
        >
            {props.title}
        </Typography>
    );
}
function Row(props: {
    mobile: boolean;
    label: string;
    children:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
    alignItems?: string;
    labelColor?: string;
}) {
    return (
        <Grid container alignItems={props.alignItems || 'center'}>
            <Grid item xs={12} sm={4}>
                <Label title={props.label} color={props.labelColor} />
            </Grid>

            <Grid item xs={12} sm={8}>
                <div style={{ width: '100%' }}>{props.children}</div>
            </Grid>
        </Grid>
    );
}

function WritersDesk(props: {
    user: UserModel;
    mobile: boolean;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    desk: any;
    setDesk: any;
    hasChanged: () => boolean;
    onCancel: () => void;
    onSave: () => void;
}) {
    const presetQuestions = [
        '',
        'What are you passionate about?',
        'What impact have you had in your previous roles?',
        'In which ways have you demonstrated leadership?',
        'In which ways have you demonstrated teamwork?',
        'Which classes, activities, tasks, or projects have you enjoyed the most?',
        'What are five adjectives that people who know you best might use to describe you?',
        'Have you had to overcome any major challenges or obstacles to get to this point in your life or career?',
        'Describe your professional specialization or unique skills.',
        'Custom (add below)',
    ];

    const documentPresets = [
        '',
        'Personal statement / cover letter',
        'College application essay(s)',
        'Academic transcripts',
        'Writing Sample',
        'Academic CV (or list of publications)',
        'Professional certification documents/licenses',
        'Share link to cloud work portfolio folder',
        'Other: Request a different document',
    ];

    const isProUser: boolean = isPro(props.subscription, props.enterprise);

    return (
        <div
            id="writers-desk"
            style={{
                position: 'relative',
                backgroundColor: 'white',
                width: '100%',
                maxWidth: props.mobile ? '100%' : '800px',
                marginBottom: '50px',
                boxSizing: 'border-box',
                padding: '2rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '2.5rem',
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                }}
            >
                <Typography
                    fontSize={{ md: '48px', xs: '32px' }}
                    color="primary"
                >
                    01.
                </Typography>
                <Typography
                    fontSize={{ md: '24px', xs: '18px' }}
                    color={'#444444'}
                >
                    Writer's Desk (Pro Users)
                </Typography>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem',
                }}
            >
                <Label title="CUSTOMIZE YOUR FORM QUESTIONS" />
                <Typography
                    fontSize={{ md: '18px', xs: '18px' }}
                    color="#444444"
                >
                    It's time to customize your form! Reference Builder collects
                    general information about each applicant's goals,
                    qualifications, and applications. As a Pro user, you can
                    also ask applicants to answer specific questions to help
                    tailor your letters! Start customizing your form using the
                    options below.
                </Typography>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                    }}
                >
                    {[0, 1, 2].map((idx) => {
                        return (
                            <FormControl fullWidth key={idx}>
                                <InputLabel
                                    id={`wd-additional-question-${idx}`}
                                >
                                    Add another question...
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId={`wd-additional-question-${idx}`}
                                    label="Add Additional Question"
                                    variant="filled"
                                    value={
                                        presetQuestions.filter(
                                            (v) =>
                                                v ===
                                                props.desk.additionalQuestions[
                                                idx
                                                ]
                                        ).length === 0
                                            ? 'Custom (add below)'
                                            : props.desk.additionalQuestions[
                                            idx
                                            ]
                                    }
                                    onChange={(e) => {
                                        let a: string[] = [];
                                        for (
                                            let i = 0;
                                            i <
                                            props.desk.additionalQuestions
                                                .length;
                                            i++
                                        ) {
                                            if (i === idx)
                                                a.push(
                                                    e.target.value as string
                                                );
                                            else
                                                a.push(
                                                    props.desk
                                                        .additionalQuestions[i]
                                                );
                                        }
                                        props.setDesk({
                                            ...props.desk,
                                            additionalQuestions: a,
                                        });
                                    }}
                                >
                                    {presetQuestions.map((v, i) => (
                                        <MenuItem
                                            key={i}
                                            value={v}
                                            sx={{
                                                whiteSpace: props.mobile
                                                    ? 'normal'
                                                    : '',
                                            }}
                                        >
                                            {v === '' ? 'None' : `– ${v}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {props.desk.additionalQuestions[idx] !== '' &&
                                    (props.desk.additionalQuestions[idx] ===
                                        'Custom (add below)' ||
                                        presetQuestions.filter(
                                            (v) =>
                                                v ===
                                                props.desk.additionalQuestions[
                                                idx
                                                ]
                                        ).length === 0) && (
                                        <TextField
                                            InputLabelProps={{
                                                style: { color: '#f9d37f' },
                                            }}
                                            sx={{ marginTop: '1rem' }}
                                            label="Enter your custom question here..."
                                            fullWidth
                                            variant="filled"
                                            value={
                                                props.desk.additionalQuestions[
                                                    idx
                                                ] === 'Custom (add below)'
                                                    ? ''
                                                    : props.desk
                                                        .additionalQuestions[
                                                    idx
                                                    ]
                                            }
                                            onChange={(e) => {
                                                let a: string[] = [];
                                                for (
                                                    let i = 0;
                                                    i <
                                                    props.desk
                                                        .additionalQuestions
                                                        .length;
                                                    i++
                                                ) {
                                                    if (i === idx)
                                                        a.push(
                                                            e.target
                                                                .value as string
                                                        );
                                                    else
                                                        a.push(
                                                            props.desk
                                                                .additionalQuestions[
                                                            i
                                                            ]
                                                        );
                                                }
                                                props.setDesk({
                                                    ...props.desk,
                                                    additionalQuestions: a,
                                                });
                                            }}
                                        />
                                    )}
                            </FormControl>
                        );
                    })}
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.5rem',
                }}
            >
                <Label title="COLLECT MORE DOCUMENTS" />
                <Typography
                    fontSize={{ md: '18px', xs: '18px' }}
                    color="#444444"
                >
                    Need more documents or files? Use your applicant
                    questionnaire to collect everything you need to write a
                    great letter.
                </Typography>
                {[0, 1, 2].map((idx) => {
                    return (
                        <FormControl fullWidth key={idx}>
                            <InputLabel id={`wd-additional-document-${idx}`}>
                                Request another document...
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId={`wd-additional-document-${idx}`}
                                label="Add Additional Document"
                                variant="filled"
                                value={
                                    documentPresets.filter(
                                        (v) =>
                                            v ===
                                            props.desk.additionalDocuments[idx]
                                    ).length === 0
                                        ? 'Other: Request a different document'
                                        : props.desk.additionalDocuments[idx]
                                }
                                onChange={(e) => {
                                    let a: string[] = [];
                                    for (
                                        let i = 0;
                                        i <
                                        props.desk.additionalDocuments.length;
                                        i++
                                    ) {
                                        if (i === idx)
                                            a.push(e.target.value as string);
                                        else
                                            a.push(
                                                props.desk.additionalDocuments[
                                                i
                                                ]
                                            );
                                    }
                                    props.setDesk({
                                        ...props.desk,
                                        additionalDocuments: a,
                                    });
                                }}
                            >
                                {documentPresets.map((v, i) => (
                                    <MenuItem
                                        key={i}
                                        value={v}
                                        sx={{
                                            whiteSpace: props.mobile
                                                ? 'normal'
                                                : '',
                                        }}
                                    >
                                        {v === '' ? 'None' : v}
                                    </MenuItem>
                                ))}
                            </Select>
                            {props.desk.additionalDocuments[idx] !== '' &&
                                (props.desk.additionalDocuments[idx] ===
                                    'Other: Request a different document' ||
                                    documentPresets.filter(
                                        (v) =>
                                            v ===
                                            props.desk.additionalDocuments[idx]
                                    ).length === 0) && (
                                    <TextField
                                        sx={{ marginTop: '1rem' }}
                                        label="Request Additional Document"
                                        fullWidth
                                        variant="filled"
                                        value={
                                            props.desk.additionalDocuments[
                                                idx
                                            ] ===
                                                'Other: Request a different document'
                                                ? ''
                                                : props.desk
                                                    .additionalDocuments[idx]
                                        }
                                        onChange={(e) => {
                                            let a: string[] = [];
                                            for (
                                                let i = 0;
                                                i <
                                                props.desk.additionalDocuments
                                                    .length;
                                                i++
                                            ) {
                                                if (i === idx)
                                                    a.push(
                                                        e.target.value as string
                                                    );
                                                else
                                                    a.push(
                                                        props.desk
                                                            .additionalDocuments[
                                                        i
                                                        ]
                                                    );
                                            }
                                            props.setDesk({
                                                ...props.desk,
                                                additionalDocuments: a,
                                            });
                                        }}
                                    />
                                )}
                        </FormControl>
                    );
                })}
            </div>

            {!isProUser && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: '1',
                        boxSizing: 'border-box',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            background: 'rgba(245, 245, 245, 0.5)',
                        }}
                    >
                        <div
                            style={{
                                width: '480px',
                                height: '305px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '1rem',
                                padding: '1rem',
                                background: 'white',
                                boxShadow:
                                    '0px 2px 40px 0px rgba(0, 0, 0, 0.13)',
                            }}
                        >
                            <LockOutlined
                                color="secondary"
                                style={{ width: 64, height: 64 }}
                            />
                            <Typography fontSize={'24px'} textAlign="center">
                                Writers Desk is a premium feature. Upgrade your
                                account to unlock.
                            </Typography>
                            <Button href="/pricing" variant="contained">
                                VIEW PLANS & PRICING
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {props.mobile && (
                <SaveOrCancelButtons
                    hasChanged={props.hasChanged}
                    onCancel={props.onCancel}
                    onSave={props.onSave}
                />
            )}
        </div>
    );
}

function PersonalDetails(props: {
    user: UserModel;
    mobile: boolean;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    info: any;
    setInfo: any;
    hasChanged: () => boolean;
    onCancel: () => void;
    onSave: () => void;
}) {
    const details = props.info;
    const setDetails = props.setInfo;
    const isProUser: boolean = isPro(props.subscription, props.enterprise);
    const [showImgCrop, setShowImgCrop] = useState(false);
    const [uploadedImg, setUploadedImg] = useState<DocumentRecord>(
        props.info.pfp
    );
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

    return (
        <div
            id="personal-details"
            style={{
                backgroundColor: 'white',
                width: '100%',
                maxWidth: props.mobile ? '100%' : '800px',
                marginTop: props.mobile ? 0 : '50px',
                marginBottom: '50px',
                boxSizing: 'border-box',
                padding: '2rem',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    marginBottom: '32px',
                }}
            >
                <Typography
                    fontSize={{ md: '48px', xs: '32px' }}
                    color="primary"
                >
                    02.
                </Typography>
                <Typography
                    fontSize={{ md: '24px', xs: '18px' }}
                    color={'#444444'}
                >
                    Personal Details
                </Typography>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                }}
            >
                <Row label="FULL NAME:" mobile={props.mobile}>
                    <TextField
                        variant="filled"
                        value={details.name}
                        onChange={(e) =>
                            setDetails({
                                ...details,
                                name: e.target.value as string,
                            })
                        }
                    />
                </Row>

                <Row label="PROFILE IMAGE:" mobile={props.mobile}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProfileAvatar
                            className="reference-card-pfp"
                            sx={{ width: 55, height: 55 }}
                            url={props.info.pfp.url || ''}
                            name={props.user.name}
                        />
                        <Button
                            style={{ textTransform: 'none' }}
                            variant="text"
                            color="secondary"
                            component="label"
                        >
                            Add/Change
                            <input
                                hidden
                                accept="image/*"
                                type="file"
                                id={`personal-details-image`}
                                onChange={(event) => {
                                    const fileReader = new FileReader();
                                    let base64: string = '';

                                    fileReader.onload = (fileLoadedEvent) => {
                                        base64 =
                                            fileLoadedEvent.target?.result?.toString() ||
                                            '';
                                        setUploadedImg({
                                            url: base64,
                                            name: 'pfp',
                                        });
                                        setShowImgCrop(true);
                                    };

                                    // Convert data to base64
                                    if (event?.currentTarget?.files) {
                                        fileReader.readAsDataURL(
                                            event.currentTarget.files[0]
                                        );
                                        event.target.value = '';
                                    }
                                }}
                            />
                        </Button>
                        <Button
                            style={{ textTransform: 'none' }}
                            variant="text"
                            sx={{ color: '#cc0000' }}
                            onClick={() => {
                                setDetails({
                                    ...details,
                                    pfp: { name: '', url: '' },
                                });
                                setShowImgCrop(false);
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                </Row>

                {showImgCrop && (
                    <div
                        className="reffy-form-image-crop"
                        style={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: '99999',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                        onClick={(e) => {
                            if (
                                e.target['className'] ===
                                'reffy-form-image-crop'
                            ) {
                                setDetails({ ...details, picture: ' ' });
                                setShowImgCrop(false);
                            }
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'white',
                                width: '500px',
                                height: '500px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    backgroundColor: 'white',
                                    width: '80%',
                                    height: '80%',
                                }}
                            >
                                <Cropper
                                    image={uploadedImg.url}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    cropShape="round"
                                    showGrid={false}
                                    onCropComplete={(_, croppedAreaPixels_) =>
                                        setCroppedAreaPixels(croppedAreaPixels_)
                                    }
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#444444',
                                    }}
                                    onClick={async () => {
                                        setDetails({
                                            ...details,
                                            picture: ' ',
                                        });
                                        setShowImgCrop(false);
                                    }}
                                >
                                    cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={async () => {
                                        await CropImage(
                                            'pfp.jpeg',
                                            uploadedImg.url,
                                            croppedAreaPixels,
                                            0,
                                            {
                                                horizontal: false,
                                                vertical: false,
                                            },
                                            (v) => {
                                                setDetails({
                                                    ...details,
                                                    pfp: {
                                                        url: v.split(
                                                            'FILENAMESPLIT'
                                                        )[1],
                                                        name: v.split(
                                                            'FILENAMESPLIT'
                                                        )[0],
                                                    },
                                                });
                                                setUploadedImg({
                                                    url: '',
                                                    name: '',
                                                });
                                            }
                                        );
                                        setShowImgCrop(false);
                                    }}
                                >
                                    UPLOAD
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                <Row label="FORM URL:" mobile={props.mobile}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Grid
                            container
                            alignItems="center"
                            spacing={{ md: 6, xs: 2 }}
                        >
                            <Grid item xs={12} md={4}>
                                <Typography
                                    fontSize={{ md: '18px', xs: '16px' }}
                                    color={'#999999'}
                                >
                                    https://refbuilder.com/
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="filled"
                                    value={details.username}
                                    onChange={(e) => {
                                        setDetails({
                                            ...details,
                                            username: e.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Row>

                {/*<Row label="PRONOUNS:" mobile={props.mobile}><TextField size="small" variant="filled" value={"UNIMPLEMENTED"} /></Row>*/}
                <Row label="EMAIL ADDRESS:" mobile={props.mobile}>
                    <TextField
                        size="small"
                        variant="filled"
                        value={details.email}
                        onChange={(e) =>
                            setDetails({ ...details, email: e.target.value })
                        }
                    />
                </Row>
                <Row label="PROFESSION / ROLE" mobile={props.mobile}>
                    <TextField
                        size="small"
                        variant="filled"
                        value={details.role === 'DEFAULT' ? '' : details.role}
                        onChange={(e) =>
                            setDetails({ ...details, role: e.target.value })
                        }
                    />
                </Row>
                <Row label="FIELD OF WORK" mobile={props.mobile}>
                    <TextField
                        size="small"
                        variant="filled"
                        value={details.field}
                        onChange={(e) =>
                            setDetails({ ...details, field: e.target.value })
                        }
                    />
                </Row>
                <Row label="MY LETTERHEAD:" mobile={props.mobile}>
                    {isProUser && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Typography fontSize={{ md: '20px', xs: '14px' }}>
                                {details.letterHead?.name ??
                                    '[No File Uploaded]'}
                            </Typography>
                            <Button
                                style={{ textTransform: 'none' }}
                                variant="text"
                                color="secondary"
                                component="label"
                            >
                                Add/Change
                                <input
                                    hidden
                                    accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    type="file"
                                    id={`personal-details-document`}
                                    onChange={(event) => {
                                        const fileReader = new FileReader();
                                        let base64: string = '';

                                        if (
                                            event?.currentTarget?.files &&
                                            event.currentTarget.files[0]
                                        ) {
                                            const fname =
                                                event.currentTarget.files[0]
                                                    .name;
                                            fileReader.onload = (
                                                fileLoadedEvent
                                            ) => {
                                                base64 =
                                                    fileLoadedEvent.target?.result?.toString() ||
                                                    '';
                                                setDetails({
                                                    ...details,
                                                    letterHead: {
                                                        url: base64,
                                                        name: fname,
                                                    },
                                                });
                                            };

                                            fileReader.readAsDataURL(
                                                event.currentTarget.files[0]
                                            );
                                            event.target.value = '';
                                        }
                                    }}
                                />
                            </Button>
                            <Button
                                style={{ textTransform: 'none' }}
                                variant="text"
                                sx={{ color: '#cc0000' }}
                                onClick={() =>
                                    setDetails({
                                        ...details,
                                        letterHead: { url: '', name: '' },
                                    })
                                }
                            >
                                Remove
                            </Button>
                        </div>
                    )}

                    {!isProUser && (
                        <Button variant="contained" href="/pricing">
                            Go Pro to upload your letterhead
                        </Button>
                    )}
                </Row>
            </div>
            {props.mobile && (
                <SaveOrCancelButtons
                    hasChanged={props.hasChanged}
                    onCancel={props.onCancel}
                    onSave={props.onSave}
                />
            )}
        </div>
    );
}

function AccountInformation(props: {
    user: UserModel;
    mobile: boolean;
    subscription: WriterSubscriptionModel;
    enterprise: EnterpriseModel | undefined;
    info: any;
    setInfo: any;
    hasChanged: () => boolean;
    onCancel: () => void;
    onSave: () => void;
}) {
    const info = props.info;
    const setInfo = props.setInfo;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [clickCancel, setClickCancel] = useState(false);
    const [hasRedirected, setHasRedirected] = useState(false);
    const [autoRenew, setAutoRenew] = useState(
        props.subscription.autoRenew === Boolean.BOOLEAN_TRUE
    );
    const [cancelResponseMessage, setCancelResponseMessage] = useState('');
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const isProUser = isPro(props.subscription, props.enterprise);
    const secSubTypes = ['Monthly', '4 Months', 'Yearly'];
    const renewalDate = new Date(
        props.subscription.subscriptionExpiration * 1000
    ).toLocaleDateString();
    const checkoutMutation = useCheckoutRequestMutation();
    const cancelMembershipMutation = useCancelSubscriptionMutation();
    const logout = useKratosLogoutMutation();

    useEffect(() => {
        if (cancelMembershipMutation.isSuccess) {
            setAutoRenew(false);
            setCancelResponseMessage(
                `Cancellation confirmed. Your subscription will no longer automatically renew, but the good news is that you can still enjoy Pro features until your current subscription period ends on ${renewalDate}! Feel free to come back anytime you'd like help with your reference letters in the future! 😊`
            );
        }
        if (cancelMembershipMutation.isError) {
            setCancelResponseMessage(
                'Something went wrong while processing your request. Please try again.'
            );
        }
    }, [cancelMembershipMutation.isSuccess, cancelMembershipMutation.isError]);

    if (checkoutMutation.isSuccess && !hasRedirected) {
        setHasRedirected(true);
        setTimeout(() => {
            const payload: any = checkoutMutation.data;
            window.location.href = payload.url;
        }, 2 * 1000);
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <Typography variant="body1" color="success">
                    Redirecting you to the Checkout Page!
                </Typography>
            </div>
        );
    }

    if (checkoutMutation.isError) {
        navigate('/');
    }

    function handleCancelMembership() {
        const cancelRequest: GetWriterSubscriptionRequest = {
            wuuid: props.user.uuid,
        };
        setClickCancel(false);
        cancelMembershipMutation.mutate(cancelRequest);
    }

    return (
        <div
            id="account-information"
            style={{
                backgroundColor: 'white',
                width: '100%',
                maxWidth: props.mobile ? '100%' : '800px',
                marginTop: props.mobile ? 0 : '50px',
                marginBottom: '50px',
                boxSizing: 'border-box',
                padding: '2rem',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* No and Yes has been flipped. */}
            {clickCancel && (
                <ConfirmationPopup
                    promptText="Are you sure you want to cancel your subscription to Reference Builder Pro? After your current subscription period expires, you will lose access to Pro features like AI-generated Pro Drafts and form customization."
                    leftButtonText="Go back"
                    rightButtonText="Yes"
                    handleLeft={() => {
                        setClickCancel(false);
                    }}
                    handleRight={() => {
                        handleCancelMembership();
                    }}
                    handleClickout={() => {
                        setClickCancel(false);
                    }}
                    leftIcon={<ArrowBack />}
                    rightIcon={<CheckSharp />}
                    rightUI={
                        <Button
                            sx={{
                                color: '#999999',
                                height: '50px',
                                ':hover': {
                                    boxShadow:
                                        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                                },
                            }}
                            variant="text"
                            startIcon={<CloseSharp />}
                            onClick={() => handleCancelMembership()}
                        >
                            <Typography
                                variant="body2"
                                style={{ color: '#999999' }}
                            >
                                Cancel my subscription
                            </Typography>
                        </Button>
                    }
                />
            )}
            {cancelResponseMessage !== '' && (
                <FeedbackPopup
                    message={cancelResponseMessage}
                    duration={10000}
                />
            )}
            <div
                style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    marginBottom: '32px',
                }}
            >
                <Typography
                    fontSize={{ md: '48px', xs: '32px' }}
                    color="primary"
                >
                    03.
                </Typography>
                <Typography
                    fontSize={{ md: '24px', xs: '18px' }}
                    color={'#444444'}
                >
                    Account Information
                </Typography>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                }}
            >
                <Row
                    label="CURRENT PLAN:"
                    alignItems="flex-start"
                    mobile={props.mobile}
                >
                    <Grid
                        container
                        spacing={4}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            xs={6}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <div
                                style={{
                                    width: 'auto',
                                    maxWidth: '240px',
                                    aspectRatio: '2 / 1',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '16px',
                                    border: !isProUser
                                        ? '10px solid rgb(247, 205, 127)'
                                        : '10px solid white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button variant="text">
                                    <img
                                        alt="logo"
                                        src="/assets/images/logos/reffy_logo.svg"
                                        style={{ width: '70%' }}
                                    />
                                </Button>
                            </div>
                            {isProUser ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        fontSize={{ md: '14px', xs: '14px' }}
                                        color="secondary"
                                    >
                                        <i>Basic Plan</i>
                                    </Typography>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        fontSize={{ md: '14px', xs: '14px' }}
                                        color="secondary"
                                    >
                                        <i>Current Plan</i>
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <div
                                style={{
                                    width: 'auto',
                                    maxWidth: '240px',
                                    aspectRatio: '2 / 1',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '16px',
                                    border: isProUser
                                        ? '10px solid rgb(247, 205, 127)'
                                        : '',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button variant="text" href="/pricing">
                                    <img
                                        alt="pro logo"
                                        src="/assets/images/logos/reffy_pro_logo.svg"
                                        style={{ width: '70%' }}
                                    />
                                </Button>
                            </div>
                            {isProUser ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{ textDecoration: 'underline' }}
                                        fontWeight={'bold'}
                                        fontSize={{ md: '14px', xs: '14px' }}
                                        color="secondary"
                                    >
                                        <i>Reference Builder Pro</i>
                                    </Typography>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{ textDecoration: 'underline' }}
                                        fontWeight={'bold'}
                                        fontSize={{ md: '14px', xs: '14px' }}
                                        color="secondary"
                                    >
                                        <i>Get Pro Features</i>
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Row>
                <Row label="SUBSCRIPTION:" mobile={props.mobile}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '.5rem',
                            flexDirection: !isMobile ? 'row' : 'column',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: isMobile ? 'row' : 'column',
                                justifyContent: 'space-evenly',
                                width: '100%',
                            }}
                        >
                            {(!isProUser && (props.enterprise === undefined || props.enterprise?.euuid === '')) && (
                                <>
                                    <Typography
                                        fontSize={{ md: '20px', xs: '20px' }}
                                    >{`Free/Basic Reference Builder`}</Typography>
                                    <Typography
                                        fontSize={{ md: '16px', xs: '16px' }}
                                        color="gray"
                                    >{`No Subscription`}</Typography>
                                </>
                            )}
                            {(isProUser && (props.enterprise && props.enterprise?.euuid === '')) && (
                                <>
                                    <Typography
                                        fontSize={{ md: '20px', xs: '20px' }}
                                    >{`Reference Builder Pro - ${secSubTypes[props.subscription.type]
                                        }`}</Typography>
                                    <Typography
                                        fontSize={{ md: '16px', xs: '16px' }}
                                        color="gray"
                                    >
                                        {autoRenew
                                            ? `Renews ${renewalDate}`
                                            : `Ends ${renewalDate}`}
                                    </Typography>
                                </>
                            )}
                            {(props.enterprise && props.enterprise?.euuid !== '') && (
                                <>
                                    {(props.enterprise?.owner ===
                                        props.user.uuid ||
                                        props.enterprise?.admins.filter(
                                            (id) => id === props.user.uuid
                                        ) !== undefined) && (
                                        <Link
                                            href="https://enterprise.refbuilder.com"
                                            color="primary"
                                            underline="hover"
                                            fontSize={{
                                                md: '20px',
                                                xs: '20px',
                                            }}
                                        >
                                            {`Your subscription is managed by ${props.enterprise.name}`}
                                        </Link>
                                    )}
                                    {props.enterprise?.owner !==
                                        props.user.uuid &&
                                        props.enterprise?.admins.filter(
                                            (id) => id === props.user.uuid
                                        ) === undefined && (
                                            <Typography
                                                fontSize={{
                                                    md: '20px',
                                                    xs: '20px',
                                                }}
                                            >
                                                {`Your subscription is managed by ${props.enterprise?.name}`}
                                            </Typography>
                                        )}
                                    <Typography
                                        fontSize={{ md: '16px', xs: '16px' }}
                                        color="gray"
                                    >
                                        {`${new Date(
                                            (props.enterprise?.subscriptionStart ?? 0) *
                                                1000
                                        ).toLocaleDateString()} to ${new Date(
                                            (props.enterprise?.subscriptionEnd ?? 0) *
                                                1000
                                        ).toLocaleDateString()}`}
                                    </Typography>
                                </>
                            )}
                        </div>
                        {(autoRenew && (!props.enterprise || props.enterprise?.euuid === '')) && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        setClickCancel(true);
                                    }}
                                    variant="text"
                                    sx={{
                                        width: '200px',
                                        color: 'rgba(0, 0, 0, 0.38)',
                                        padding: 0,
                                        minHeight: '40px',
                                    }}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        fontSize={{ md: '14px', xs: '14px' }}
                                    >
                                        Cancel Subscription
                                    </Typography>
                                </Button>
                            </div>
                        )}
                    </div>
                </Row>
                <Row label="EMAIL NOTIFICATIONS:" mobile={props.mobile}>
                    <div
                        style={{
                            width: '100%',
                            height: '64px',
                            marginTop: '.5rem',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <FormControl fullWidth>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}
                            >
                                <ReffySwitch
                                    checked={
                                        info.notificationsDisabled !==
                                        Boolean.BOOLEAN_TRUE
                                    }
                                    onChange={(_) => {
                                        setInfo({
                                            ...info,
                                            notificationsDisabled:
                                                info.notificationsDisabled ===
                                                    Boolean.BOOLEAN_TRUE
                                                    ? Boolean.BOOLEAN_FALSE
                                                    : Boolean.BOOLEAN_TRUE,
                                        });
                                    }}
                                />
                                <Typography
                                    fontSize={{ md: '14px', xs: '14px' }}
                                    color="secondary"
                                >
                                    Email Notifications{' '}
                                    {info.notificationsDisabled ===
                                        Boolean.BOOLEAN_TRUE
                                        ? 'Disabled'
                                        : 'Enabled'}
                                </Typography>
                            </div>
                        </FormControl>
                    </div>
                </Row>
                <Row label="PASSWORD:" mobile={props.mobile}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="text"
                            onClick={async () => {
                                queryClient.removeQueries({
                                    queryKey: ['user-authenticated'],
                                });
                                navigate(
                                    `/auth/reset-password?id=${props.user.uuid}&vs=${props.user.verificationSecret}`
                                );
                            }}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={{ xs: '14px' }}
                            >
                                Change Password
                            </Typography>
                        </Button>
                    </div>
                </Row>
                {isMobile && (
                    <Row mobile={props.mobile} label="LOGOUT OF YOUR ACCOUNT:">
                        <Button
                            sx={{ marginTop: '.5rem' }}
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={async () => {
                                queryClient.removeQueries({
                                    queryKey: ['user-authenticated'],
                                });
                                logout.mutate();
                                navigate('/');
                            }}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={{ xs: '14px' }}
                            >
                                Logout
                            </Typography>
                        </Button>
                    </Row>
                )}
                <Row label="DELETE ACCOUNT:" mobile={props.mobile}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="text"
                            onClick={() => {
                                alert(
                                    'This feature is not supported yet. Please contact support if you wish to delete your account'
                                );
                            }}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={{ xs: '14px' }}
                            >
                                Delete My Account
                            </Typography>
                        </Button>
                    </div>
                </Row>
            </div>
            {props.mobile && (
                <SaveOrCancelButtons
                    hasChanged={props.hasChanged}
                    onCancel={props.onCancel}
                    onSave={props.onSave}
                />
            )}
        </div>
    );
}

function ReffySwitch(props: JSX.IntrinsicAttributes & SwitchProps) {
    const theme = useTheme();

    return (
        <Switch
            {...props}
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            sx={{
                width: 80,
                height: 43,
                padding: 0,
                '& .MuiSwitch-switchBase': {
                    height: '100%',
                    padding: 0,
                    marginLeft: '2px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(100%)',
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                            opacity: 1,
                            border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: '#33cf4d',
                        border: '6px solid #fff',
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: '#cccccc',
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.7,
                    },
                },
                '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    width: 38,
                    height: 38,
                },
                '& .MuiSwitch-track': {
                    borderRadius: 26 / 2,
                    backgroundColor: '#E9E9EA',
                    opacity: 1,
                    transition: theme.transitions.create(['background-color'], {
                        duration: 500,
                    }),
                },
            }}
        />
    );
}

function SaveOrCancelButtons(props: {
    hasChanged: () => boolean;
    onCancel: () => void;
    onSave: () => void;
}) {
    return (
        <div className="magage-top-row-buttons" style={{ marginTop: '1em' }}>
            <Button
                variant="text"
                color="secondary"
                sx={{
                    display: !props.hasChanged() ? 'none' : '',
                    width: '150px',
                }}
                onClick={() => props.onCancel()}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                disabled={!props.hasChanged()}
                sx={{ width: '150px' }}
                onClick={() => props.onSave()}
            >
                Save Changes
            </Button>
        </div>
    );
}

// Performs deep equality check between two objects by comparing key values
function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}
