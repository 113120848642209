import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import { useEditUserMutation, useGoogleMutation } from '@cosmogony/reffy-hooks';
import Loading from '../../../components/loading/Loading';

export default function SignInWithGoogle() {
    const navigate = useNavigate();
    const google = useGoogleMutation();

    const googleLogin = useGoogleLogin({
        onSuccess: async (request) => {
            google.mutate({ requestCode: request.code, scope: request.scope });
        },
        flow: 'auth-code',
    });

    if (google.isSuccess) {
        navigate('/dashboard');
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Button
                fullWidth
                sx={{
                    color: '#ffffff',
                    backgroundColor: '#4b80e0',
                    ':hover': {
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
                        backgroundColor: '#4b80e0',
                        color: 'white',
                    },
                }}
                startIcon={
                    <div
                        style={{
                            height: '30px',
                            width: '30px',
                            background: '#ffffff',
                            borderRadius: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '4px',
                        }}
                    >
                        <FcGoogle />
                    </div>
                }
                onClick={() => googleLogin()}
            >
                <Typography
                    color={'#ffffff'}
                    fontWeight={'bold'}
                    fontSize={{ md: '18px', xs: '16px' }}
                >
                    CONTINUE WITH GOOGLE
                </Typography>
            </Button>
        </div>
    );
}
